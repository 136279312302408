import React, { useContext, useLayoutEffect, useState } from 'react';
import './DialogsInfo.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { SubscriptionCardAddonType, selectContext } from "../../../SubscriptionContext";
import { tarifName, TariffType } from "../../../../../../utils/dialogs";
import { getSubscriptionDialogs } from '../../../SubscriptionsAPI';
import { SubscriptionStatusEnum } from '../../../types';
import Button from '../../../../../../components/Button/Button';


function DialogsInfo({ subscription }: SubscriptionCardAddonType) {
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const { setPay } = useContext(selectContext);
  const [dialogs, setDialogs] = useState<number>();
  const tarif = tarifName(currentCompany?.waba360DialogPartnerId as TariffType);
  const showPayDialog = !currentCompany.paymentsLocked
    && tarif === 'russia'
    && subscription.status !== SubscriptionStatusEnum.test;

  useLayoutEffect(() => {
    if (currentCompany) {
      getSubscriptionDialogs({ companyId: currentCompany.id })
        .then((res) => {
          const data = res.find(item => item.type === 'WABA_DIALOGS');
          if (data) setDialogs(data.value);
        });
    }
  }, [currentCompany]);

  const onPayClick = () => {
    subscription.selected = true;
    if (setPay) setPay();
  };

  if (tarif !== 'russia' ) return null;

  return (
    <div className='dialogsInfo'>
      <p className={Number(dialogs) <= 0 ? 'warn' : ''}>{t('subscriptions.card.waba.balance')}: {dialogs}</p>
      {showPayDialog &&
        <Button type='button' color='white' textType='small' text={t('subscriptions.card.waba.extra')}
          onClick={onPayClick} />}
    </div>
  );
}

export default DialogsInfo;
