import { RootState } from '../../../store/store';

export const contactsSelector = (state: RootState) => state.contacts.contacts;
export const currentContactSelector = (state: RootState) => state.contacts.currentContact;
export const selectContactNotesSelector = (state: RootState) => state.contacts.selectContactNotes;
export const allTagsContactSelector = (state: RootState) => state.contacts.allTagsContact;
export const disableInProgressSelector = (state: RootState) => state.contacts.disableInProgress;
export const statusRejectSelector = (state: RootState) => state.contacts.statusReject;
export const contactsTotalSelector = (state: RootState) => state.contacts.contactsTotal;
export const currentContactNoteSelector = (state: RootState) => state.contacts.currentEditNote;
export const isLoaderContactsSelector = (state: RootState) => state.contacts.isLoader;
