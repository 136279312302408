type StepsAnchorsUris = {
  [index: number]: {
    [index: string]: string;
    ru: string;
    en: string;
    es: string;
  };
};

export const stepsAnchorsUris: StepsAnchorsUris = {
  2: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/polzovateli',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/users',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/usuarios',
  },
  3: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/podklyucheniya',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/connections',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/conexiones',
  },
  4: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/integrations',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/integracion',
  },
  5: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/chaty',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/chats',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/chat ',
  },
  6: {
    ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/chaty/sozdanie-chata',
    en: 'https://docs.radist.online/radist-online-docs-global/our-products/radist-web/chats/create-a-chat',
    es: 'https://docs.radist.online/radist-online-es/productos-1/radist-web/chat/crear-chat',
  },
};
