import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from "react-phone-number-input";
import CountrySelector from '../CountrySelector';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { getCodeCountry, getLabelsLocalization, validatePhone } from '../utils';
import 'react-phone-number-input/style.css'
import '../PhoneInput.scss';


const validatePhoneValue = (value: string, required: boolean = false, nonGeo?: boolean ) => {
  if (required && value.length === 0) return 'error.form.empty_field'
  return validatePhone(value, nonGeo === undefined ? false : nonGeo);
};

type PhoneElementProps = {
  phone: string;
  setPhone: (value: string) => void;
  check?: boolean;
  required?: boolean;
  setValid?: (value: boolean) => void;
  nonGeo?: boolean;  // allow non geographic numbers as valid
};

const PhoneElement = ({ phone, setPhone, setValid, check=false, required=false, nonGeo=false }: PhoneElementProps) => {
  const { t, i18n } = useTranslation();
  const country = getCodeCountry(i18n.language);
  const [error, setError] = useState<string>('')
  
  const validate = (value: string) => {
    let isValid: boolean | string = true;
    if (check || setValid) {
      isValid = validatePhoneValue(value, required, nonGeo);
      if (setValid) setValid(typeof(isValid) === 'string' ? false : isValid);
      const result = !check || (typeof(isValid) === 'string' ? t(isValid) : isValid);
      if (typeof result === 'string') setError(result);
      if (result === true) setError('');
      return result;
    }
    return isValid;
  };
  
  const onChange = (value?: string) => {
    if (value) {
      validate(value);
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber) {
        setPhone(phoneNumber.format('E.164'));
      } else {
        setPhone(value);
      }
    };
  };

  return (
    <div className='phoneField'>
      <PhoneInput
        value={phone}
        international
        onChange={(value) => onChange(value)}
        labels={getLabelsLocalization(i18n.language)}
        defaultCountry={country}
        countrySelectComponent={CountrySelector}
        className={error ? 'error' : ''}
      />
      {error && <span className="errorMessage">{error}</span>}
    </div>
  );
};

export default PhoneElement;
