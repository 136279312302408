import { makeStyles } from '@material-ui/styles';
import { TFunction } from 'react-i18next';
import {
  keysPermission,
  PERMISSIONS,
  VALIDATIONS_PERMISSIONS,
} from '../../../../utils/WrapperPermission/permission-maps';

export type PermissionWithKeys = Record<string, Record<string, boolean>>

export const getConvertArrStrPermissions = (permissions: PermissionWithKeys) => {
  const result: Array<string> = [];
  Object.entries(permissions).forEach(([_, value]) => {
    if (Object.keys(value).length > 0) {
      Object.entries(value).forEach(([k, v]) => {
        if (v) result.push(k);
      });
    }
  });
  return result;
};

export const getConvertPermissions = (permissionsCurrentRole: Array<string>) => {
  const allPermissions: Array<string> = Object.values(PERMISSIONS);
  const resultPermissions: PermissionWithKeys = {};

  allPermissions.forEach(permission => {
      const isPermission = permissionsCurrentRole.length > 0 && permissionsCurrentRole.find(p => p === permission);
      const findKeyPermission = keysPermission.find(key => permission.includes(key));

      if (findKeyPermission) {

        const setPermission = (k: string, v: boolean) => {
          if (resultPermissions[findKeyPermission]) {
            const isDuplicate = resultPermissions[findKeyPermission][k];
            if (!isDuplicate) {
              resultPermissions[findKeyPermission][k] = v;
            }
          } else {
            resultPermissions[findKeyPermission] = {
              [k]: v,
            };
          }
        };

        if (isPermission) {
          setPermission(isPermission, true);
        } else {
          // is it Members_view permissions? then true otherwise false (all roles have permissions to view members)
          const isMembersView = permission.includes('members_view');
          setPermission(permission, isMembersView);
        }
      }
    }
  );

  return resultPermissions;
};

export const getNameRole = (roleName: string, isOwner: boolean, t: TFunction<'translation'>) => {
  if (roleName === 'ADMINISTRATOR' || roleName === 'MANAGER') {
    if (roleName === 'ADMINISTRATOR') {
      if (isOwner) {
        return t('settings.users.added.owner');
      }
      return t('settings.users.added.administrator');
    }
    return t('settings.users.added.manager');
  }
  return roleName;
};

export const getValidatePermission = (
  permissions: PermissionWithKeys,
  keySection: string,
  namePermission: string,
  value: boolean) => {
  permissions[keySection][namePermission] = value;
  const results: PermissionWithKeys = { ...permissions };

  if (value) {
    if (VALIDATIONS_PERMISSIONS[namePermission]) {
      VALIDATIONS_PERMISSIONS[namePermission].necessary.forEach(necessaryPermission => {
        if (!results[keySection][necessaryPermission]) {
          const findKeySectionPermission = keysPermission.find(key => necessaryPermission.includes(key));
          if (findKeySectionPermission) {
            results[findKeySectionPermission][necessaryPermission] = true;
          }
        }
      });
    }
  } else {
    Object.values(results).forEach(permissionObj => {
      Object.keys(permissionObj).forEach(permission => {
        const forShutdowns = VALIDATIONS_PERMISSIONS[namePermission]?.uncheckWhenDisable?.includes(permission);
        if (forShutdowns) {
          permissionObj[permission] = false;
        }
      });
    });
  }
  return results;
};

export const useStylesUserEdit = makeStyles({
  root: {
    border: 'none !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fonWeight: 400,
    fontSize: 14,
    color: '#333333',
    height: 36,

    '&.MuiOutlinedInput-root': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fonWeight: 400,
      fontSize: 14,
      color: '#333333',
      height: 36,
      borderRadius: 6,
      width: 336,
      background: '#F4F9FF',
      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.2)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '3px solid #8BACFF !important',
    },

  },

  menu: {
    borderRadius: 6,
    boxShadow: 'none !important',
    padding: '0 5px',
    backgroundColor: '#F4F9FF !important',
    width: 336,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    background: '#F4F9FF',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fonWeight: 400,
    fontSize: 14,
    color: '#333333',
    maxHeight: 270,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  select: {

    '&.MuiMenuItem-root': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fonWeight: 400,
      fontSize: 14,
      position: 'relative',
      boxSizing: 'border-box',
      width: 'auto',
      maxWidth: '100%',

      '&::after': {
        content: '" "',
        position: 'absolute',
        height: 16,
        width: 32,
        right: 0,
        top: 8,
        bottom: 8,
        background: 'linear-gradient(90deg, rgba(244, 249, 255, 0) 0%, rgba(244, 249, 255, 0.0086472) 6.67%, rgba(244, 249, 255, 0.03551) 13.33%, rgba(244, 249, 255, 0.0816599) 20%, rgba(244, 249, 255, 0.147411) 26.67%, rgba(244, 249, 255, 0.231775) 33.33%, rgba(244, 249, 255, 0.331884) 40%, rgba(244, 249, 255, 0.442691) 46.67%, rgba(244, 249, 255, 0.557309) 53.33%, rgba(244, 249, 255, 0.668116) 60%, rgba(244, 249, 255, 0.768225) 66.67%, rgba(244, 249, 255, 0.852589) 73.33%, rgba(244, 249, 255, 0.91834) 80%, rgba(244, 249, 255, 0.96449) 86.67%, rgba(244, 249, 255, 0.991353) 93.33%, #F4F9FF 100%)',

      },


      '&:hover': {
        background: '#FFE1CC',
        borderRadius: 6,
        position: 'relative',


        '&::after': {
          content: '" "',
          position: 'absolute',
          height: 16,
          width: 32,
          right: 0,
          top: 8,
          bottom: 8,
          background: 'linear-gradient(90deg, rgba(255, 225, 204, 0) 0%, rgba(255, 225, 204, 0.0086472) 6.67%, rgba(255, 225, 204, 0.03551) 13.33%, rgba(255, 225, 204, 0.0816599) 20%, rgba(255, 225, 204, 0.147411) 26.67%, rgba(255, 225, 204, 0.231775) 33.33%, rgba(255, 225, 204, 0.331884) 40%, rgba(255, 225, 204, 0.442691) 46.67%, rgba(255, 225, 204, 0.557309) 53.33%, rgba(255, 225, 204, 0.668116) 60%, rgba(255, 225, 204, 0.768225) 66.67%, rgba(255, 225, 204, 0.852589) 73.33%, rgba(255, 225, 204, 0.91834) 80%, rgba(255, 225, 204, 0.96449) 86.67%, rgba(255, 225, 204, 0.991353) 93.33%, #FFE1CC 100%)',
        },
      },
    },

    '&.MuiMenuItem-root.Mui-selected': {
      backgroundColor: '#FFE1CC !important',
      borderRadius: 6,

      '&::after': {
        content: '" "',
        position: 'absolute',
        height: 16,
        width: 32,
        right: 0,
        top: 8,
        bottom: 8,
        background: 'linear-gradient(90deg, rgba(255, 225, 204, 0) 0%, rgba(255, 225, 204, 0.0086472) 6.67%, rgba(255, 225, 204, 0.03551) 13.33%, rgba(255, 225, 204, 0.0816599) 20%, rgba(255, 225, 204, 0.147411) 26.67%, rgba(255, 225, 204, 0.231775) 33.33%, rgba(255, 225, 204, 0.331884) 40%, rgba(255, 225, 204, 0.442691) 46.67%, rgba(255, 225, 204, 0.557309) 53.33%, rgba(255, 225, 204, 0.668116) 60%, rgba(255, 225, 204, 0.768225) 66.67%, rgba(255, 225, 204, 0.852589) 73.33%, rgba(255, 225, 204, 0.91834) 80%, rgba(255, 225, 204, 0.96449) 86.67%, rgba(255, 225, 204, 0.991353) 93.33%, #FFE1CC 100%)',
      },
      '&.MuiMenuItem-root.Mui-selected:hover': {
        backgroundColor: '#FFE1CC',
      },
    },
  },

  focused: {
    border: '3px solid #8BACFF',
  },
  iconStyle: {
    position: 'absolute',
    marginTop: -3,
    right: 10,
    height: 20,
    width: 20,
    color: '#c0c0c0',
    pointerEvents: 'none',
  },
});

export const errorButtonPerm = {
  border: '1px solid #FFB2B2',
  boxShadow: '0px 4px 4px -2px rgba(141, 76, 0, 0.2)',
  padding: '6px 12px',
  fontSize: 14,
  color: '#EB5757',
};


