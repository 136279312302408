import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { ConditionsType, IConditions } from '../BroadcastsTypes';
import { getRecipientsCount } from '../BroadcastsAPI';
import { ContactSourceType } from './types';

type BroadcastRecipientsType = {
  contactSource: ContactSourceType;
  connectionId?: number;
  conditionsParams?: IConditions;
  recipients?: string[];
  fromActiveChat: boolean;
};

export function useBroadcastRecipients(params: BroadcastRecipientsType) {
  const paramsRef = useRef(params);
  const currentCompany = useSelector(currentCompanySelector);
  const [contactSource, setContactSource] = useState<ContactSourceType>(params.contactSource);
  const [connectionId, setConnectionId] = useState<number | undefined>(params.connectionId);
  const [conditionsParams, setConditionsParams] = useState<IConditions | undefined>(params.conditionsParams);
  const [recipients, setRecipients] = useState<number>(params.recipients?.length || 0);
  const [fromActiveChat, setFromActiveChat] = useState<boolean>(params.fromActiveChat || true);

  useEffect(() => {
    paramsRef.current = { ...params };
    setContactSource((prev) => {
      if (prev !== params.contactSource) return params.contactSource;
      return prev;
    });
    setConnectionId((prevConn) => {
      if (params.connectionId !== undefined && prevConn !== params.connectionId) return params.connectionId;
      return prevConn;
    });
    setConditionsParams((prevCond) => {
      if ((prevCond?.conditions !== params?.conditionsParams?.conditions)
        || (prevCond?.logicOperation !== params?.conditionsParams?.logicOperation)) {
        return params.conditionsParams;
      }
      return prevCond;
    });
    setRecipients((prevRecipient) => {
      if (params.contactSource === 'client' && prevRecipient !== params.recipients?.length) {
        return params.recipients?.length || 0;
      }
      return prevRecipient;
    });
    setFromActiveChat((prevChat) => {
      if (params.fromActiveChat !== undefined && prevChat !== params.fromActiveChat) return params.fromActiveChat;
      return prevChat;
    });
  }, [params])

  return useMemo(async () => {
    const getRecipients = async () => {
      let conditionsData: ConditionsType | undefined;
      if (conditionsParams && conditionsParams.conditions.length > 0) {
        conditionsData = {
          logic_operation: conditionsParams.logicOperation,
          conditions: conditionsParams.conditions.map(item => ({
            comparison: item.comparison, condition_type: item.conditionType, value: item.value
          }))
        };
      }
      if (connectionId) {
        const response = await getRecipientsCount({
          companyId: currentCompany.id,
          connectionId,
          from_active_chat: fromActiveChat,
          conditions: conditionsData
        });
        return response.estimatedRecipientsCount;
      }
      return 0;
    };

    if (contactSource === 'radist') return await getRecipients();
    if (contactSource === 'client' && recipients) return (recipients);
    return 0;      

  }, [contactSource, connectionId, conditionsParams, recipients, fromActiveChat]);
}
