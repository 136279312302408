import React, { ReactElement } from 'react';
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shopTabList } from "../../NavbarDesktop/SalesList/SalesList";
import { SubMenuTabType } from '../../subMenuLists';
import { ReactComponent as ArrowIcon } from "../../../../../assets/arrow-menu-right.svg";


type LinkProps = {
  location: SubMenuTabType;
  icon: ReactElement;
  setSelectSubTab: React.Dispatch<React.SetStateAction<SubMenuTabType | null>>;
};

const SubLink = ({ location, icon, setSelectSubTab }: LinkProps) => {
  const { t } = useTranslation();
  const currentTab = useRouteMatch<{ tab: string }>('/companies/:companyId/:tab');
  const settingsCurrentTab = useRouteMatch<{ tab: string }>('/companies/:companyId/settings/:tab');
  const isSalesTab = location === 'sales' && shopTabList.find((tab) => tab === currentTab?.params.tab);
  const isSettingsTab = settingsCurrentTab?.params.tab && location === 'settings';

  const onClick = () => {
    setSelectSubTab(location);
  };

  return (
    <div className={`tabShadow ${isSettingsTab || isSalesTab ? 'active' : ''}`} onClick={onClick}>
      <div className="tab" id={location}>
        {icon}
        <p>{t(`navbar.${location}`)}</p>
      </div>
      <ArrowIcon />
    </div>
  );
};

export default SubLink;
