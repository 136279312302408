import { createContext } from "react";
import { Product } from "../../api/SalesAPI";


export type CatalogType = {
  name: string;
  products: number[];
};

export type ProductChooseType = Product & {
  selected: boolean;
};

export type ProductItemContextType = {
  onEdit?: (product: Product) => void;
  onDelete?: (product: Product) => void;
  onPreview?: (product: Product) => void;
};

export const productItemContext = createContext<ProductItemContextType>({});

export type CatalogsUploadContextType = {
  hasMore: boolean;
  onUpload: () => void;
};

export const catalogsUploadContext = createContext<CatalogsUploadContextType>({ hasMore: false, onUpload: () => {} });

export type SelectContext = {
  checkSelected: (id?: number) => void;
};

export const selectContext = createContext<SelectContext>({ checkSelected: () => { } });
