import './WhatsAppPrompt.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../../../../../../assets/info-icon-black.svg';
import { ReactComponent as WhatsAppIcon } from '../../../../../../../assets/whatsApp-connectionLogo_blank.svg';


const WhatsAppPrompt = () => {
  const { t } = useTranslation();

  return (
    <span className='whatsAppPrompt'>
      <InfoIcon className='whatsAppPrompt_icon' />
      <div className='whatsAppPrompt_message'>
        <p>{t('settings.integrations.whatsapp.prompt_caption')}</p>
        {['register', 'no_register', 'no_verify'].map((i, index) =>
          <div key={i} className='whatsAppPrompt_item'>
            <WhatsAppIcon className={`whatsAppPrompt_wa_${i}`} />
            {t(`settings.integrations.whatsapp.prompt_item_${index + 1}`)}
          </div>
        )}
      </div>
  </span>
  );
};

export default WhatsAppPrompt;
