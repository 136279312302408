import moment from 'moment-timezone';

enum FormatsUsedDateTimeEnum {
  hourMinutes = 'hourMinutes',
  month = 'month',
  dayMonthYear = 'dayMonthYear',
  dayMonthYearHourMin = 'dayMonthYearHourMin',
  withCustomFormat = 'withCustomFormat'
}

type KeysFormatsUsed = keyof typeof FormatsUsedDateTimeEnum
const listFormat: Record<KeysFormatsUsed, string> = {
  hourMinutes: 'HH:mm',
  month: 'MM',
  dayMonthYear: 'DD.MM.YYYY',
  dayMonthYearHourMin: 'DD.MM.YYYY HH:mm',
  withCustomFormat: 'withCustomFormat',
};
export const getDateTime = () => ({
  moment: (date?: string | Date): moment.Moment => date ? moment(date) : moment(),
  format: (date: string, format: KeysFormatsUsed, withCustomFormat?: string): string => {
    if (withCustomFormat) {
      return moment(date).format(withCustomFormat);
    }
    return moment(date).format(listFormat[format]);
  },
  isPast:(date: string | Date): boolean => {
    const today = moment().startOf('day');
    return moment(date).startOf('day').isBefore(today)
  }
});

export function isTodayBetween(startDateTime: string, endDateTime: string) {
  return moment().isBetween(startDateTime, endDateTime);
}
