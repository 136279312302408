import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteCustomTemplate,
  getCustomTemplates,
  getWabaTemplates,
  patchCustomTemplate,
  patchInteractiveTemplate,
  postCustomTemplate,
  postInteractiveTemplate,
} from '../../api/ChatAPI';
import {
  CreateCustomTemplateRequestOptions,
  CreateInteractiveTemplateRequestData,
  CustomTemplate,
  PatchCustomTemplateRequestOptions,
  WabaTemplate,
} from '../../api/types';

type TemplatesSlice = {
  customTemplates: Array<CustomTemplate>;
  interactiveMessageTemplates: Array<CustomTemplate>;
  wabaTemplates: Array<WabaTemplate>;
};

const initialState: TemplatesSlice = {
  customTemplates: [],
  interactiveMessageTemplates: [],
  wabaTemplates: [],
};

export const fetchCustomTemplates = createAsyncThunk(
  'templates/getCustomTemplates',
  async (requestOptions: { companyId: number }) => getCustomTemplates(requestOptions)
);

export const fetchWabaTemplates = createAsyncThunk<Array<WabaTemplate>,
  {companyId: number, connectionId: number },
  { rejectValue: number }>('templates/getWabaTemplates', async (requestOptions, { rejectWithValue }) => {
  try {
    return await getWabaTemplates(requestOptions);
  } catch (e) {
    return rejectWithValue(e.response.data.error_code);
  }
});

export const editCustomTemplate = createAsyncThunk(
  'templates/editCustomTemplate',
  async (requestOptions: PatchCustomTemplateRequestOptions) => patchCustomTemplate(requestOptions)
);

export const createCustomTemplate = createAsyncThunk(
  'templates/createCustomTemplate',
  async (requestOptions: CreateCustomTemplateRequestOptions) => postCustomTemplate(requestOptions)
);

export const createInteractiveTemplate = createAsyncThunk(
  'templates/createInteractiveTemplate',
  async (requestOptions: CreateInteractiveTemplateRequestData) => postInteractiveTemplate(requestOptions)
);

export const editInteractiveTemplate = createAsyncThunk(
  'templates/editInteractiveTemplate',
  async (requestOptions: CreateInteractiveTemplateRequestData & { templateId: number }) =>
    patchInteractiveTemplate(requestOptions, requestOptions.templateId)
);

export const removeCustomTemplate = createAsyncThunk(
  'templates/removeCustomTemplate',
  async (requestOptions:{ companyId: number,templateId:number }, { dispatch }) => {
    await deleteCustomTemplate(requestOptions);
    dispatch(removeTemplate(requestOptions.templateId));
  }
);

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    removeTemplate: (state, action: PayloadAction<number>) => {
      const customTemplateIdx = state.customTemplates.findIndex((template) => template.id === action.payload);
      const interactiveTemplateIdx = state.interactiveMessageTemplates.findIndex(
        (template) => template.id === action.payload
      );
      if (customTemplateIdx !== -1) {
        state.customTemplates.splice(customTemplateIdx, 1);
      }
      if (interactiveTemplateIdx !== -1) {
        state.interactiveMessageTemplates.splice(interactiveTemplateIdx, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomTemplates.fulfilled, (state, action) => {
        const templates = action.payload.reverse();
        state.interactiveMessageTemplates = templates.filter((template) => template.type === 'waba_interactive');
        state.customTemplates = templates.filter((template) => template.type !== 'waba_interactive');
      })
      .addCase(fetchWabaTemplates.fulfilled, (state, action) => {
        state.wabaTemplates = action.payload;
      })
      .addCase(fetchWabaTemplates.rejected, (state) => {
        state.wabaTemplates = [];
      })
      .addCase(editCustomTemplate.fulfilled, (state, action: PayloadAction<CustomTemplate>) => {
        const customTemplateIdx = state.customTemplates.findIndex((template) => template.id === action.payload.id);
        state.customTemplates[customTemplateIdx] = { ...action.payload };
      })
      .addCase(createCustomTemplate.fulfilled, (state, action) => {
        state.customTemplates.unshift(action.payload);
      })
      .addCase(createInteractiveTemplate.fulfilled, (state, action) => {
        state.interactiveMessageTemplates.unshift(action.payload);
      })
      .addCase(editInteractiveTemplate.fulfilled, (state, action) => {
        const editedTemplateId = state.interactiveMessageTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (editedTemplateId !== -1) {
          state.interactiveMessageTemplates[editedTemplateId] = action.payload;
        }
      });
  },
});

export const { removeTemplate } = templatesSlice.actions;

export default templatesSlice.reducer;
