import { ReactNode } from 'react';
import './LableInfo.scss';
import { ReactComponent as InfoIcon } from '../../../../../../assets/info.svg';


type LableInfoProps = {
  lable: string;
  message: string;
  children: ReactNode;
  showInfo?: boolean;
};

function LableInfo({ lable, message, children, showInfo=true }: LableInfoProps) {
  return (
    <div className="lableInfo">
      <div className="lableInfo__lable">
        <p className='regularText'>{lable}</p>
        {showInfo && <>
          <span className="info__icon"><InfoIcon /></span>
          <div className="info__message">{message}</div>
        </>}
      </div>
      {children}
    </div>
  );
}

export default LableInfo;
