import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';
import { NewConnection } from '../../../../../api/CompanyAPI';
import { updateConnection } from '../../connectionsSlice';
import { ReactComponent as PenIcon } from '../../../../../assets/pen-icon.svg';
import { ReactComponent as ArrowBack } from '../../../../../assets/arrow-back.svg';
import './ConnectionNameEdit.scss';


type ConnectionNameEditProps = {
  connection: NewConnection;
  setEditName: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConnectionNameEdit = ({ connection, setEditName }: ConnectionNameEditProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const methods = useForm({ mode: 'onSubmit', defaultValues: { name: connection.name } })

  const onSubmitHandler = (data: { name: string; }) => {
    setIsClicked(true);
    dispatch(updateConnection({
      companyId: Number(currentCompanyId?.params.companyId),
      itemId: connection.id,
      type: connection.type,
      name: data.name
    }));
    setEditName(false);
  };

  const onCancelHandler = () => {
    setEditName(false);
  };

  return (
    <div className="connectionNameEdit">
      <form {...methods}>
        <input placeholder={t('error.form.emtpy_field')}{ ...methods.register('name', { required: true }) } />
        <div className="connectionNameEdit__buttonsWrapper">
          <button className="connectionNameEdit__buttonsWrapper_edit" type='submit'
            onClick={methods.handleSubmit(onSubmitHandler)}
            disabled={!methods.formState.isDirty || isClicked}>
            <PenIcon />
            {t('save')}
          </button>
          <button className="connectionNameEdit__buttonsWrapper_cancel" type='button' onClick={onCancelHandler}>
            <ArrowBack width={16} height={16} />
            {t('cancel')}
          </button>
        </div>
      </form>  
    </div>
  );
}

export default ConnectionNameEdit;
