import React, { useRef } from "react";
import './HelpTooltipPopup.scss';
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bdAnchors } from "../../../utils/consts";
import { ReactComponent as TailPopupIcon } from "../../../../../assets/dropdown-after-triangle.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/close-icon.svg";
import { cancelOnboarding, startOnboarding } from "../../../../Onboarding/onboardingSlice";
import { updateCurrentChat, updateMessages } from "../../../../../views/Chat/chatsSlice";
import { useOutsideClick } from "../../../../../components/utils/hooks";


type Props = {
  setIsShowingHelpTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpTooltipPopup = ({ setIsShowingHelpTooltip }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const triggerRef = useRef<HTMLDivElement>(null);

  const onStartOnboarding = () => {
    dispatch(startOnboarding());
    dispatch(updateCurrentChat({}));
    dispatch(updateMessages(null));
    setIsShowingHelpTooltip(false);
  };

  const onCloseHelp = () => {
    setIsShowingHelpTooltip(false);
    dispatch(cancelOnboarding());
  };

  useOutsideClick(triggerRef, onCloseHelp);

  return ReactDOM.createPortal(
    <div className="helpTooltipPopup" ref={triggerRef}>
      <CloseIcon className="closeIcon" onClick={onCloseHelp} />
      <p className="helpTooltipPopup__title">{t('onboarding.help_tooltip.title')}</p>
      <a className="helpTooltipPopup__bd" target="_blank" rel="noreferrer" href={bdAnchors[i18n.language]}>
        {t('onboarding.help_tooltip.db')}
      </a>
      <p className="helpTooltipPopup__meeting" onClick={onStartOnboarding}>
        {t('onboarding.help_tooltip.meeting')}
      </p>
      <div className="helpTooltipPopup__tail">
        <TailPopupIcon className="tailIcon" />
      </div>
    </div>,
    document.body
  );
}

export default HelpTooltipPopup;
