
export const ALL_CURRENCIES = ['RUB', 'USD'] as const;

export type CurrenciesType = typeof ALL_CURRENCIES[number];

export function currencyFormat(amount: number, currency: string, locale: string) {
  const rubSign = Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB', maximumFractionDigits: 0 })
    .format(0).replace('0', '');
  return Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount).replace('RUB', rubSign);
}
