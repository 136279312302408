import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deletePaymentRequisite,
  getPaymentRequisite, getSubscriptionDialogs, getSubscriptionInvoice,
  getSubscriptions, patchPaymentRequisite,
  PatchSubscription,
  patchSubscriptions,
  PaymentRequisite,
  postPaymentRequisite,
  PostPaymentRequisite, postSubscriptionInvoice, PostSubscriptionInvoice,
  Subscription, SubscriptionDialogs, SubscriptionDialogsRequest,
  SubscriptionInvoice, SubscriptionsInvoicesRequest, UpdatePaymentRequisite,
} from './SubscriptionsAPI';

export type EditParams = {
  isEdit: boolean;
  requisiteId: number | null;
};

const DEFAULT_INVOICES_LIMIT = 10;

export type SubscriptionsSlice = {
  subscriptions: Array<Subscription> | [];
  subscription: Subscription | null;
  requisites: Array<PaymentRequisite> | [];
  requisite: PaymentRequisite | null;
  invoices: Array<SubscriptionInvoice> | null;
  invoice: SubscriptionInvoice | null;
  dialogs: Array<SubscriptionDialogs> | null;
  isLoader: boolean;
  editRequisite: EditParams;
  hasMoreInvoices: boolean;
  numberInvoices: number;
};

const initialState: SubscriptionsSlice = {
  subscriptions: [],
  subscription: null,
  requisites: [],
  requisite: null,
  invoices: null,
  invoice: null,
  dialogs: null,
  isLoader: false,
  editRequisite: { isEdit:false, requisiteId: null },
  hasMoreInvoices: false,
  numberInvoices: DEFAULT_INVOICES_LIMIT,
};

export const fetchSubscriptions = createAsyncThunk<Subscription[],
  { companyId: number }, { rejectValue: number }>(
    'subscriptions/fetchSubscriptions', async (requestOptions,{ rejectWithValue }) => {
      try {
        return await getSubscriptions(requestOptions.companyId);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const updateSubscriptions = createAsyncThunk<Subscription,
  { subscriptionId: number, companyId: number, data: PatchSubscription },
  { rejectValue: number }>(
    'subscriptions/updateSubscriptions',
    async (payload, { rejectWithValue }) => {
      try {
        return await patchSubscriptions(payload.subscriptionId, payload.companyId, payload.data);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const fetchPaymentRequisite = createAsyncThunk<PaymentRequisite[],
  { company_id: number }, { rejectValue: number }>(
    'subscriptions/fetchPaymentRequisite', async (requestOptions,{ rejectWithValue }) => {
      try {
        return await getPaymentRequisite(requestOptions.company_id);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const updatePaymentRequisite = createAsyncThunk<PaymentRequisite,
  UpdatePaymentRequisite, { rejectValue: number }>(
    'subscriptions/updatePaymentRequisite',
    async (payload, { rejectWithValue }) => {
      try {
        return await patchPaymentRequisite(payload);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const removePaymentRequisite = createAsyncThunk<PaymentRequisite,
  {company_id: number, requisite_id: number}, { rejectValue: number }>(
  'subscriptions/removePaymentRequisite',
  async (payload, { rejectWithValue }) => {
    try {
      return await deletePaymentRequisite(payload.company_id, payload.requisite_id);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const addPaymentRequisite = createAsyncThunk<PaymentRequisite,
  PostPaymentRequisite, { rejectValue: number }>(
  'subscriptions/addPaymentRequisite',
  async (payload, { rejectWithValue }) => {
    try {
      return await postPaymentRequisite(payload);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const fetchSubscriptionInvoice =
  createAsyncThunk<SubscriptionInvoice[], SubscriptionsInvoicesRequest, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionInvoice', async (requestOptions, { dispatch, rejectWithValue }) => {
      try {
        const { companyId, limit, offset } = requestOptions;
        let requestLimit: number;
        if (limit === undefined) {
          requestLimit = DEFAULT_INVOICES_LIMIT;
        } else {
          requestLimit = limit;
        }
        dispatch(setNumberInvoicesFetching(requestLimit));
        return await getSubscriptionInvoice({ companyId, limit: requestLimit + 1, offset });
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

export const addSubscriptionInvoice = createAsyncThunk<SubscriptionInvoice,
  PostSubscriptionInvoice, { rejectValue: number }>(
    'subscriptions/addSubscriptionInvoice',
    async (payload, { rejectWithValue }) => {
      try {
        return await postSubscriptionInvoice(payload);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

type SubscriptionInvoicesRequest = [Promise<Subscription[]>, Promise<SubscriptionInvoice[]>];

const MIN_INVOICES_NUMBER = 3;

export const fetchSubscriptionsInfo =
  createAsyncThunk<void, { companyId: number }, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionsInfo',
    async (requestOption, { dispatch, rejectWithValue }) => {
      dispatch(clearSubscriptionsInfo());
      const requests: SubscriptionInvoicesRequest = [
        getSubscriptions(requestOption.companyId),
        getSubscriptionInvoice({ companyId: requestOption.companyId, limit: MIN_INVOICES_NUMBER + 1 })
      ]
      try {
        return await Promise.all(requests)
          .then((response) => {
            dispatch(updateSubscriptionsInfo(response));
            return undefined;
          });
      }
      catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  )

export const fetchSubscriptionDialogs =
  createAsyncThunk<SubscriptionDialogs[], SubscriptionDialogsRequest, { rejectValue: number }>(
    'subscriptions/fetchSubscriptionDialogs',
    async (requestOption, { dispatch, rejectWithValue }) => {
      try {
        return await getSubscriptionDialogs(requestOption);
      }
      catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  )

const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    updateEditRequisite: (state, action: PayloadAction<{ isEdit: boolean; requisiteId: number | null }>) => {
      state.editRequisite = action.payload;
    },
    clearSubscriptionPayInvoice: (state) => {
      state.invoice = null;
    },
    clearSubscriptionsInfo: (state) => {
      state.invoices = [];
      state.subscriptions = [];
      state.isLoader = true;
    },
    updateSubscriptionsInfo: (state, action: PayloadAction<[Subscription[], SubscriptionInvoice[]]>) => {
      const [subscriptions, invoices] = action.payload;
      state.isLoader = false;
      state.subscriptions = subscriptions;
      // limit first portion of invoices by MIN_INVOICES_NUMBER
      // but if there are more than MIN_INVOICES_NUMBER of invoices,
      // then set hasMoreInvoices flag
      const hasMoreInvoices = invoices.length > MIN_INVOICES_NUMBER;
      if (hasMoreInvoices) {
        state.invoices = invoices.slice(0, MIN_INVOICES_NUMBER);
      } else {
        state.invoices = invoices;
      }
      state.hasMoreInvoices = hasMoreInvoices
    },
    setNumberInvoicesFetching: (state, action: PayloadAction<number>) =>{
      state.numberInvoices = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptions.fulfilled, (state, action: PayloadAction<Subscription[]>) => {
        state.subscriptions = action.payload;
      })
      .addCase(fetchPaymentRequisite.fulfilled, (state, action: PayloadAction<PaymentRequisite[]>) => {
        state.requisites = action.payload;
      })
      .addCase(addPaymentRequisite.fulfilled, (state, action: PayloadAction<PaymentRequisite>) => {
        state.requisite = action.payload;
      })
      .addCase(fetchSubscriptionInvoice.fulfilled, (state, action: PayloadAction<SubscriptionInvoice[]>) => {
        if (state.invoices !== null) {
          const invoices = action.payload
          const hasMoreInvoices = invoices.length > state.numberInvoices;
          if (hasMoreInvoices) {
            state.invoices.push(...invoices.slice(0, state.numberInvoices));
          } else {
            state.invoices.push(...action.payload);
          }
          state.hasMoreInvoices = hasMoreInvoices;
        } else {
          state.invoices = action.payload;
        }
      })
      .addCase(addSubscriptionInvoice.fulfilled, (state, action: PayloadAction<SubscriptionInvoice>) => {
        if (!action.payload.preview) {
          if (state.invoices !== null) {
            state.invoices.unshift(action.payload);
          } else {
            state.invoices = [action.payload];
          }
        }
        state.invoice = action.payload;
      })
      .addCase(updateSubscriptions.fulfilled, (state, action) => {
        if (!action.meta.arg.data.preview) {
          if (state.subscriptions) {
            const index = state.subscriptions.findIndex((item) => item.id === action.payload.id);
            if (index !== -1) state.subscriptions[index] = action.payload;
          }
        }
      })
      .addCase(fetchSubscriptionDialogs.fulfilled, (state, action: PayloadAction<SubscriptionDialogs[]>) => {
        state.dialogs = action.payload;
      })
  },
});

export const {
  updateEditRequisite,
  updateSubscriptionsInfo,
  clearSubscriptionsInfo,
  clearSubscriptionPayInvoice,
  setNumberInvoicesFetching,
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
