import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { OverlayBackgroundType } from '../types';
import CloseIcon from "../../../assets/cancel.svg";
import './ModalWrapper.scss';


type ModalWrapperProps = {
  children: ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

function ModalWrapper({ children, setIsOpen, background }: ModalWrapperProps) {

  const onCloseDefault = () => {
    setIsOpen(false);
  };
  
  return ReactDOM.createPortal(
    <div className="modalWrapper__container">
      <div className={`modalWrapper__wrapper ${background}`} onClick={onCloseDefault}>{}</div>
      <div className="modalWrapper">
        <img alt="close" src={CloseIcon} onClick={onCloseDefault} />
        { children }
      </div>
    </div>, document.body
  );
}

export default ModalWrapper;
