import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addNewNote } from '../../contactsSlice/contactsSlice';
import UseModal from '../../utility/useModal';
import Button from "../../../../components/Button/Button";
import { ReactComponent as NotesAddIcon } from '../../../../assets/notes-add.svg';


type SubmitSchemaType = { createNote: string };

type AddNoteTypeProps = {
  contactId: string;
};

const AddNote: FC<AddNoteTypeProps> = ({ contactId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [toggleAddNote, setToggleAddNote] = useState<boolean>(false);

  const { reset, handleSubmit, register, formState: { isDirty } } = useForm<SubmitSchemaType>({
    mode: 'onSubmit',
    defaultValues: {
      createNote: '',
    },
  });

  const onSubmitCreateNote = (data:SubmitSchemaType) => {
    if (data && url?.params.companyId && contactId) {
      dispatch(addNewNote({
        text: data.createNote,
        contactId:(Number(contactId)),
        companyId:Number(url?.params.companyId)
      }))
      setToggleAddNote(false)
      reset({});
    }
  }

  return (
    <form>
      {toggleAddNote && (
        <UseModal
          disabled={isDirty}
          propsNameButton={t('contacts.detailContact.add')}
          title={t('contacts.detailContact.addNote')}
          cancelButton={() => {
            setToggleAddNote(!toggleAddNote);
            reset({ createNote: '' });
          }}
          onSubmit={handleSubmit(onSubmitCreateNote)}
        >
          <div className="useModalContact__content">
            <textarea placeholder={t('contacts.detailContact.textNote')} {...register('createNote')} />
          </div>
        </UseModal>
      )}
      <Button type='button' color="white" textType="small" text={t('contacts.detailContact.addNote')}
        image={<NotesAddIcon />} disabled={toggleAddNote} onClick={() => setToggleAddNote(!toggleAddNote)}
        data-testid='add-note' />
    </form>
  );
}

export default AddNote;
