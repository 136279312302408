import React, { useEffect, useRef, useState } from 'react';
import { BaseMessageComponent } from './types';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';

const MessageTemplateType: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [mediaUrl, setMediaUrl] = useState<string>();

  const getMediaType = (format: string) => {
    switch (format) {
      case 'image':
        return <img src={mediaUrl} alt="message media" />;
      case 'video':
        return (
          <video src={mediaUrl} controls>
            <track src="#" kind="captions" />
          </video>
        );
      default:
        return (
          <a href={mediaUrl} target="_blank" download rel="noreferrer">
            {message.wabaTemplate.mediaHeader!.fileName}
          </a>
        );
    }
  };

  useEffect(() => {
    if (message.wabaTemplate.mediaHeader?.fileUrl) {
      setMediaUrl(message.wabaTemplate.mediaHeader.fileUrl);
    }
  }, [message]);

  useEffect(() => {
    if (textElementRef.current && handleSizeChange) {
      handleSizeChange(textElementRef.current.offsetHeight);
    }
  }, [textElementRef.current]);

  return (
    <div ref={textElementRef} className="wabaTemplateMessage">
      {message.wabaTemplate.mediaHeader && (
        <div className="wabaTemplateMessage__media">{getMediaType(message.wabaTemplate.mediaHeader.format)}</div>
      )}
      <div className="wabaTemplateMessage__text">
        <LinkifyText text={message.wabaTemplate.text} />
      </div>
      <div className="wabaTemplateMessage__buttons">
        {message.wabaTemplate.buttons &&
          message.wabaTemplate.buttons.map((button) => (
            <button type="button" key={button.text}>
              {button.text}
            </button>
          ))}
      </div>
    </div>
  );
};

export default MessageTemplateType;
