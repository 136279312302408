import React from 'react';
import './UploadProgressBar.scss';
import { ReactComponent as FileLoader } from '../../../../assets/fileLoader.svg';

const UploadProgressBar: React.FC = () => (
  <div className="fileLoader">
    <FileLoader width={20} height={20} />
  </div>
);

export default UploadProgressBar;
