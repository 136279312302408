import React from 'react';
import './Textarea.scss';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

type Props = {
  label: string;
  name: string;
  rightText?: string;
  maxLength?: 500;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea: React.FC<Props> = ({ label, name, rightText, maxLength,...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="textareaContainer">
      <div className="textareaContainer_header">
        <div className="textareaContainer_label">{label}</div>
        {rightText && <div className="textareaContainer_rightText">{rightText}</div>}
      </div>
      <textarea maxLength={maxLength}
        className="textareaContainer_textarea" {...register(name)} />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p
          className="textareaContainer_errorMessage">{message}</p>}
      />
    </div>
  );
};

export default Textarea;
