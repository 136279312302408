import React from 'react';
import { BaseMessageComponent } from './types';
import VideoView from "../TextMessage/FilePreview/VideoView";

const MessageVideoType: BaseMessageComponent = ({ displayMessage, message }) =>
  <div className='messageVideo'>
    <p>{displayMessage.video ? displayMessage.video?.caption : message.video?.caption}</p>
    <VideoView attachedFileUrl={displayMessage.video ? displayMessage.video?.url : message.video?.url}/>
  </div>;

export default MessageVideoType;
