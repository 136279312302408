import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import Button from '../../../../../components/Button/Button';
import { ReactComponent as CopyIcon } from '../../../../../assets/copy.svg';
import './CopyLink.scss';


type CopyLinkProps = {
  shopLink: string;
  hide: () => void;
};

function CopyLink({ shopLink, hide }: CopyLinkProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCopy = () => {
    if (shopLink) {
      navigator.clipboard.writeText(shopLink);
      dispatch(addAlertWithCustomText({ message: t('sales.shops.refSuccessCopied') }));
    }
  };

  const onCancel = () => hide();

  return (
    <div className='copyLink'>
      <h2>{t('sales.shops.shopSuccessCreated')}</h2>
      <p className='regularText'>{t('sales.shops.shopSuccessCreatedText')}</p>
      <input disabled value={shopLink} />
      <div className='copyLink__buttons'>
        <Button type='button' color='orange' textType='regular' text={t('sales.shops.copyRef')} image={<CopyIcon />}
          onClick={onCopy} />
        <Button type='button' color='white' textType='regular' text={t('cancel')} onClick={onCancel}/>
      </div>
    </div>
  );
}

export default CopyLink;
