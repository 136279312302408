import React from 'react';
import { useTranslation } from 'react-i18next';

const SeventhStep = () => {
  const { t } = useTranslation();

  return (
    <div className="seventhStep">
      <p className="secondStep__title">{t('onboarding.seventhStep.title')}</p>
      <p className="secondStep__description">{t('onboarding.seventhStep.description')}</p>
    </div>
  );
};

export default SeventhStep;
