import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getInvoices, GetInvoiceWithParameters, getListTerminal,
  Invoice,
  InvoiceRequest,
  InvoicesRequest, postInvoice, PostNewInvoices, Terminal,
} from '../../api/BillAPI';
import { RadistOrderProducts } from "../../api/types";

export type TotalCountAndCountType = {
  count: number;
  totalCount: number;
};

export type ProfileInvoiceType = {
  isVisibleProfileInvoice: boolean;
  profileInvoice: Invoice | null;
};

export type RadistOrderType = {
  currency: string;
  amount: number;
  items: Array<RadistOrderProducts>;
  comment: string;
};

export type BillSlice = {
  totalCountAndCount: TotalCountAndCountType;
  invoices: Array<Invoice> | null;
  terminal: Array<Terminal>;
  urlInvoice: string | null;
  isVisibleLinkInvoice: boolean;
  isVisibleProfileInvoice: boolean,
  isActionBill: boolean;
  isPageBill: boolean;
  isLoadingAddInvoice: boolean;
  profileInvoice: Invoice | null;
  radistOrder: RadistOrderType | null;
}

const initialState: BillSlice = {
  totalCountAndCount: { totalCount: 100, count: 0 },
  invoices: null,
  terminal: [],
  urlInvoice: null,
  isVisibleLinkInvoice: false,
  isVisibleProfileInvoice: false,
  isActionBill: false,
  isPageBill: false,
  isLoadingAddInvoice: false,
  profileInvoice: null,
  radistOrder: null,
}

export const fetchInvoices = createAsyncThunk<GetInvoiceWithParameters, InvoicesRequest, {rejectValue:number}
>('banking/fetchInvoices', async (requestOptions) => {
  try{
    return await getInvoices(requestOptions)
  }catch (e) {
    return e.response.data.error_code
  }
  }
);
export const postInvoices = createAsyncThunk<InvoiceRequest,PostNewInvoices, {rejectValue:number}>(
  'banking/postInvoices',
  async (payload, { rejectWithValue }) => {
    try {
      return await postInvoice(payload);
    }
    catch (e) {
      return rejectWithValue(e.response.data.detail.error);
    }
  }
);

export const fetchTerminals = createAsyncThunk('banking/fetchTerminals', async (company_id: number) =>
  getListTerminal(company_id)
);

const billSlice = createSlice({
  name: 'banking',
  initialState,
  reducers: {
    updateIsVisibleLinkInvoice: (state, action: PayloadAction<boolean>) => {
      state.isVisibleLinkInvoice = action.payload;
    },
    updateIsVisibleProfileInvoice: (state, action: PayloadAction<ProfileInvoiceType>) => {
      state.isVisibleProfileInvoice = action.payload.isVisibleProfileInvoice;
      state.profileInvoice = action.payload.profileInvoice;
    },
    updateIsActionBill: (state, action: PayloadAction<boolean>) => {
      state.isActionBill = action.payload;
    },
    updateIsPageBill: (state, action: PayloadAction<boolean>) => {
      state.isPageBill = action.payload;
    },
    updateRadistOrder: (state, action: PayloadAction<RadistOrderType | null>) => {
      state.radistOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.fulfilled, (state, action: PayloadAction<GetInvoiceWithParameters>) => {
        if(action.payload){
          state.totalCountAndCount.count = action.payload.count;
          state.totalCountAndCount.totalCount = action.payload.totalCount;
          state.invoices = action.payload.items;
          state.isLoadingAddInvoice = false;
        }
      })
      .addCase(fetchInvoices.pending, (state) => {
        state.isLoadingAddInvoice = true;
      })
      .addCase(fetchInvoices.rejected, (state) => {
        state.isLoadingAddInvoice = false;
      })
      .addCase(fetchTerminals.fulfilled, (state, action) => {
        state.terminal = action.payload;
      })
      .addCase(postInvoices.pending, (state) => {
        state.isLoadingAddInvoice = true;
      })
      .addCase(postInvoices.rejected, (state) => {
        state.isLoadingAddInvoice = false;
      })
      .addCase(postInvoices.fulfilled, (state, action: PayloadAction<InvoiceRequest>) => {
        state.urlInvoice = action.payload.url;
        state.isLoadingAddInvoice = false;
      })
  },
});

export default billSlice.reducer;
export const {
  updateIsVisibleLinkInvoice,
  updateIsVisibleProfileInvoice,
  updateIsActionBill,
  updateIsPageBill,
  updateRadistOrder
} = billSlice.actions;

