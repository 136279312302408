import React, { useRef } from 'react';
import './Search.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoupeIcon } from '../../assets/loupe-icon.svg';
import { ReactComponent as ClearIcon } from "../../assets/cancel.svg";
import { useDispatch } from "react-redux";
import { updateIsViewNavbarMobile } from "../../views/Chat/companiesSlice";


type Props = {
  onChangeSearchInput: React.Dispatch<React.SetStateAction<string>>;
  value?: string;
  isMobileRolled?: boolean;
  setIsVisibleSearch?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Search: React.FC<Props> = ({ onChangeSearchInput, value, isMobileRolled = false, setIsVisibleSearch }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const showClearIcon = value ? value.length > 0 : false;

  const clearValue = () => {
    onChangeSearchInput('');
  };

  const onChangeHandler = () => {
    if (inputRef.current) {
      onChangeSearchInput(inputRef.current.value);
    }
  };

  const onClickSearch = () => {
    if (isMobileRolled && setIsVisibleSearch) {
      setIsVisibleSearch(true);
      dispatch(updateIsViewNavbarMobile(false));
    }
  }

  return (
    <fieldset className={`search ${isMobileRolled ? 'mobile' : ''}`} onClick={onClickSearch}>
      <LoupeIcon className="search_icon" />
      {!isMobileRolled &&
        <input ref={inputRef} className="search__input" placeholder={t('search')} value={value && value} onChange={onChangeHandler} />}
      {!isMobileRolled && showClearIcon && <ClearIcon className="search_clear" onClick={clearValue} />}
    </fieldset>
  );
};

export default Search;
