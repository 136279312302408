import React, { FC } from 'react';
import './PermissionItem.scss';
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStylesCheckAccess } from '../../../../../../utils/utils';
import { PERMISSIONS } from '../../../../../../utils/WrapperPermission/permission-maps';

const optionsArr = ['view', 'create', 'add', 'edit', 'delete'];
const getCrudName = (name: string) => optionsArr.find(i => name.includes(i));

type PermissionItemType = {
  keyPermission: string
  listPermission: Record<string, boolean>
  isAdminManager: boolean
  onChangePermission: (obj: Record<string, Record<string, boolean>>) => void
}

const getDisabled = (permission: string, isAdminManager: boolean) =>
  permission === PERMISSIONS.membersView ? true : isAdminManager;

const PermissionItem: FC<PermissionItemType> = ({
                                                  keyPermission,
                                                  listPermission,
                                                  isAdminManager,
                                                  onChangePermission,
                                                }) => {
  const { t } = useTranslation();
  const classes = useStylesCheckAccess();

  const onChangeCrudCheckValue = (e: React.ChangeEvent<HTMLInputElement>, nameKey: string) => {
    onChangePermission({ [keyPermission]: { [nameKey]: e.target.checked } });
  };

  return (
    <div className='permissionItem'>
      <div className='permissionItem__withAccess'>
        <div className='permissionItem__withAccess__title'>
          {t(`settings.users.user.permissions.${keyPermission}.title`)}
        </div>
        <div className='permissionItem__withAccess__accessList'>
          {Object.keys(listPermission)?.length > 0 && Object.entries(listPermission).map(([key, value]) => (
              <label id={key} key={key}
                     className='permissionItem__withAccess__accessList__item'>
                <div
                  className='permissionItem__withAccess__accessList__item_switch'>
                  <Checkbox
                    disabled={getDisabled(key, isAdminManager)}
                    checked={value}
                    onChange={e => onChangeCrudCheckValue(e, key)}
                    classes={{
                      checked: getDisabled(key, isAdminManager) ? classes.disabled : classes.checked,
                      root: classes.root,
                    }}
                  />
                </div>
                <div
                  className='permissionItem__withAccess__accessList__item_name'>
                  {t(`settings.users.user.permissions.${keyPermission}.${getCrudName(key)}`)}
                </div>
              </label>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default PermissionItem;
