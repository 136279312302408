import React, { useState } from 'react';
import './PaymentMethods.scss';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../../../../../components/utils/hooks';
import { METHODS_PAYMENT } from '../helper';
import RadioButton from '../../../../../../components/RadioControl';
import PopupWithTail from '../PopupWithTail/PopupWithTail';
import MobileInfo from '../../../../../../components/Modals/MobileInfo';


function PaymentMethod() {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const onInfoClick = () => {
    if (isMobile) setInfoOpen(!infoOpen);
  };

  return (
    <div className='subscriptionsInvoicePay__payment'>
      <h3>{t('subscriptions.invoice.payment.title')}</h3>
      {METHODS_PAYMENT.map((item) =>
        <div key={item} className='subscriptionsInvoicePay__payment_method'>
          <RadioButton key={item}
            label={t(`subscriptions.invoice.payment.${item}`)} value={item} name='payment_method' />
          {item === 'BANK_TRANSFER' && (
            <PopupWithTail handlerClickIcon={onInfoClick}>
              {t('subscriptions.invoice.payment.info')}
            </PopupWithTail>
          )}
          {infoOpen && <MobileInfo setIsOpen={setInfoOpen} message={t('subscriptions.invoice.payment.info')} />}
        </div>
      )}
    </div>
  );
}

export default PaymentMethod;
