import { MessageRequest } from '../../../../api/types';
import { BaseMessageComponent } from '../MessageTypes/types';
import MessageTextType from '../MessageTypes/MesssageTextType';
import MessageTemplateType from '../MessageTypes/MessageTemplateType';
import MessageImageType from '../MessageTypes/MessageImageType';
import MessageFileType from '../MessageTypes/MessageFileType';
import MessageAudioType from '../MessageTypes/MessageAudioType';
import MessageVideoType from '../MessageTypes/MessageVideoType';
import MessageInteractiveType from '../MessageTypes/MessageInteractiveType';
import MessageWabaCart from "../MessageTypes/MessageWabaCart";
import { ReactComponent as MessageCheckmark } from '../../../../assets/message-checkmark.svg';
import { ReactComponent as SingleCheckmark } from '../../../../assets/message-single-checkmark.svg';
import { ReactComponent as MessageDeliveryErrorIcon } from '../../../../assets/message-delivery-error.svg';
import { ReactComponent as MessageDeliveredIcon } from '../../../../assets/message-delivered-icon.svg';


export const getMessageStatusIcon = (message: MessageRequest) => {
  if (message.direction === 'inbound') return undefined;
  if (message.errorAt) {
    return <MessageDeliveryErrorIcon />;
  }
  if (message.readAt) {
    return <MessageCheckmark />;
  }
  if (message.deliveredAt) {
    return <MessageDeliveredIcon />;
  }
  return <SingleCheckmark />;
};

export const messageTypeComponents = new Map<string, BaseMessageComponent>([
  ['text', MessageTextType],
  ['waba_template', MessageTemplateType],
  ['image', MessageImageType],
  ['file', MessageFileType],
  ['audio', MessageAudioType],
  ['voice', MessageAudioType],
  ['video', MessageVideoType],
  ['waba_interactive', MessageInteractiveType],
  ['radist_order', MessageWabaCart],
  ['waba_order', MessageWabaCart],
]);
