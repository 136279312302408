import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RequisitesSelector } from '../../SubscriptionsSelector';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import CreateAndEditRequisites from '../CreateAndEditRequisites/CreateAndEditRequisites';
import RequisitesPlug from '../RequisitesList/RequisitesPlug';
import RequisitesList from '../RequisitesList/RequisitesList';
import Button from '../../../../../components/Button/Button';


function RequisitesView() {
  const { t } = useTranslation();
  const requisitesList = useSelector(RequisitesSelector);
  const [isVisibleForm, setIsVisibleForm] = useState<boolean>(false);

  const onClickRequisite = () => {
    setIsVisibleForm(!isVisibleForm);
  };

  return (<>
    {isVisibleForm && <CreateAndEditRequisites setIsVisibleForm={setIsVisibleForm} />}
    {(!requisitesList || requisitesList.length <= 0) ? (
      <RequisitesPlug setIsVisibleForm={setIsVisibleForm} />
    ) : (
      <>
        <WrapperPermission permission='subscriptionsEdit'>
          <Button text={t('subscriptions.requisites.add_requisites')} textType='regular' color='orange'
            onClick={onClickRequisite} />
        </WrapperPermission>
        <RequisitesList requisitesList={requisitesList} setIsVisibleForm={setIsVisibleForm} />
      </>
    )}
  </>);
};

export default RequisitesView;
