import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonRunIcon } from '../../../../assets/person-run.svg';


type PropsType = {
  toggleVisibleProfile: () => void;
  isGroup: boolean;
};

const ChatHeaderModal: FC<PropsType> = ({ toggleVisibleProfile, isGroup }) => {
  const { t } = useTranslation();

  return (
    <div className="chatHeader__wrapperModal__modal">
      <div className="chatHeader__wrapperModal__modal__icon" onClick={toggleVisibleProfile}>
        <PersonRunIcon />
        <p>{isGroup
          ? t('contacts.detailContact.group')
          : t('contacts.detailContact.profile')
        }</p>
      </div>
    </div>
  );
};

export default ChatHeaderModal;
