import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { NoConnections } from './NoConnections/NoConnections';
import { connectionsUploadContext, getServiceNameByUrl } from './utils/connections';
import { fetchSubscriptions } from '../Subscriptions/SubscriptionsSlice';
import { useAppSelector } from '../../../store/store';
import { ConnectionsType } from './ConnectionsTypes';
import { ConnectionsStoreType, fetchConnectionsList, setConnectionsLoad } from './connectionsSlice';
import ConnectionView from './components/ConnectionView';
import loader from '../../../assets/grid.svg';


const CONNECTIONS_LIMIT = 25;

type RouteMatchType = {
  companyId: string;
  serviceUrl: string;
};

const isNoConnections = (connections: ConnectionsStoreType, service: ConnectionsType) => {
  const serviceConnection = connections[service];
  return (serviceConnection && serviceConnection.length === 0);
};

const Connection = () => {
  const params = useRouteMatch<RouteMatchType>('/companies/:companyId/settings/connections/:serviceUrl');
  const connections = useAppSelector((state) => state.connections.connectionsStore);
  const loading = useAppSelector((state) => state.connections.connectionsLoad);
  const companyId = useAppSelector((state) => state.connections.companyId);
  const hasMore = useAppSelector((state) => state.connections.hasMore);
  const [load, setLoad] = useState<boolean>(true);
  const service = getServiceNameByUrl(params?.params.serviceUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(fetchSubscriptions({ companyId: Number(params?.params.companyId) }));
      if (service) {
        dispatch(setConnectionsLoad(true));
        dispatch(fetchConnectionsList({
          companyId: Number(params?.params.companyId),
          limit: CONNECTIONS_LIMIT,
          types: service as ConnectionsType
        }));
      }
    }
  }, [params?.params.companyId]);

  useEffect(() => {
    setLoad(loading);
  }, [loading]);

  const onUpload = () => {
    if (service && params?.params.companyId) {
      dispatch(fetchConnectionsList({
        companyId: Number(params?.params.companyId),
        limit: CONNECTIONS_LIMIT,
        offset: connections[service].length,
        types: service as ConnectionsType
      }));
    }
  };

  if (load || loading) {
    return (<div className="load"><img src={loader} alt='loading...' /></div>);
  }

  if (service) {
    if (isNoConnections(connections, service)) {
      return <NoConnections service={service} />
    }
    return (
      <connectionsUploadContext.Provider value={{ service, hasMore, onUpload }}>
        <ConnectionView />
      </connectionsUploadContext.Provider>
    );
  }
  return (<></>);
};

export default Connection;
