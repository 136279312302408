import { useTranslation } from "react-i18next";
import { isTodayBetween } from "./getDateTime/getDateTime";
import promotionBannerRu from '../assets/promotion-ru.png';
import promotionBannerEn from '../assets/promotion-en.png';
import promotionBannerEs from '../assets/promotion-es.png';


const eventStart = '2024-01-25T12:01:00';
const eventEnd = '2024-01-28T23:59:00';

type eventDetails = {
  active: boolean;
  discount: boolean;
  link: string;
  banner: string;
};

type PromotionType = {
  image: string;
  link: string;
};

const promotionDetails: Record<string, PromotionType> = {
  ru: { image: promotionBannerRu, link: '' },
  en: { image: '', link: '' },
  es: { image: '', link: '' },
};

export function useEvent(): eventDetails {
  const { i18n } = useTranslation();

  return {
    active: isTodayBetween(eventStart, eventEnd),
    banner: promotionDetails[i18n.language].image,
    discount: false,
    link: promotionDetails[i18n.language].link
  };
}
