import React from 'react';
import './StatusBar.scss';


type StatusBarProps = {
  children: React.ReactNode;
};

const StatusBar = ({ children }: StatusBarProps) => (
  <div className="statusBar">{children}</div>
);

export default StatusBar;
