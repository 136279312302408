import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { IntegrationType } from './IntegrationTypes';
import { integrationsState, integrationsStateInfo } from './integrationSlice';
import { integrationsListSelector, integrationStateInfoSelector, integrationStateSelector, integrationsTypesSelector } from './integrationSelectors';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import { fetchConnections } from '../../Chat/companiesSlice';
import { ReactComponent as WarningIcon } from '../../../assets/warning-icon.svg';
import logoAmo from '../../../assets/button-logo-amo.png';


const IntegrationDescription = React.memo(() => {
  const dispatch = useDispatch();
  const url = useRouteMatch<{ id: string }>('/companies/:id');
  const { t } = useTranslation();
  const { integrationName } = useParams<{ integrationName: string }>();
  const currentCompany = useSelector(currentCompanySelector);
  const integrationsTypesArr = useSelector(integrationsTypesSelector);
  const integrationsArr = useSelector(integrationsListSelector);
  const stateIntegration = useSelector(integrationStateSelector);
  const stateInfo = useSelector(integrationStateInfoSelector);
  const [itemIntegration, setItemIntegration] = useState<IntegrationType>();
  const [buttonIsSetted, setButtonIsSetted] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const divRef = useRef<HTMLDivElement>(null);

  const activeSetNotification = () => {
    const integration = integrationsTypesArr.find((item) => item.type === integrationName);
    if (integration) {
      window.open(
        `https://www.amocrm.ru/oauth/?state=${stateIntegration.state}&mode=post_message&client_id=${integration?.params.amocrm.clientUuid}`,
        'Popup',
        'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=650, height=500, top=100,left=500'
      );
    }
  };

  const activeWhatsApp = () => {
    const findWhatsApp = integrationsTypesArr?.find((item) => item.type === 'whatsapp_amocrm');
    if (findWhatsApp) {
      dispatch(push(`/companies/${currentCompany.id}/settings/integrations/description/${findWhatsApp.type}`));
      window.location.reload();
    }
  };

  const stopCheckStateInfo = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  };

  useEffect(() => {
    if (currentCompany.id) {
      stopCheckStateInfo();
    }
  }, [currentCompany]);

  const reState = () => {
    // get new state
    if (url?.params.id) {
      dispatch(integrationsState(Number(url?.params.id)));
    }
  };

  useEffect(() => {
    // update buttons
    if (buttonIsSetted) {
      const amoScripts = document.querySelectorAll('.amocrm_oauth');
      for(const amoScript of amoScripts) {
        if (amoScript instanceof HTMLElement) {
          amoScript.dataset.state = stateIntegration?.state;
        }
      }
    }
  }, [stateIntegration]);

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId]
  );

  useEffect(() => {
    if ((stateInfo?.integrationId && intervalId) || (stateInfo?.error && intervalId)) {
      // stop check integration state info
      stopCheckStateInfo();
      // check if integration already exists
      let integrationExist: boolean = false;
      if (integrationsArr) {
        for (const item of integrationsArr) {
          if (item.id === stateInfo?.integrationId) {
            integrationExist = true;
            break;
          }
        }
      }
      if (!integrationExist) {
        // if new integrations then check error and redirect if no error
        if (!stateInfo?.error) {
         if (url?.params.id) dispatch(fetchConnections({ companyId:Number(url?.params.id) }))
          dispatch(push(`${url!.url}/settings/integrations/${stateInfo?.integrationId}`));
        } else {
          let errorMessage: string
          if (t(`errors.${stateInfo.error}`) !== `errors.${stateInfo.error}`) {
            errorMessage = t(`errors.${stateInfo.error}`);
          } else {
            errorMessage = t(`errors.${stateInfo?.error}`).startsWith('errors.')
              ? t('settings.integrations.connection_error')
              : t(`errors.${stateInfo?.error}`);
          }
          dispatch(addAlertWithCustomText({
            message: errorMessage,
            color: "#F54242"
          }))
        }
      } else {
        // if integration already exists then redirect to integration settings
        if (url?.params.id) dispatch(fetchConnections({ companyId:Number(url?.params.id) }))
        dispatch(push(`${url!.url}/settings/integrations/${stateInfo?.integrationId}`));
      }
      // make new state
      reState();
    }
  }, [stateInfo]);

  const checkStateInfo = (): void => {
    stopCheckStateInfo();
    if (buttonIsSetted) {
      const amoScripts = document.querySelectorAll('.amocrm_oauth');
      const amoScript = amoScripts[0];
      let stateData: string;
      if (amoScript instanceof HTMLElement) {
        stateData = amoScript.dataset.state ? amoScript.dataset.state : '';
      }
      const id = setInterval(async () => {
        if (url?.params.id){
          dispatch(integrationsStateInfo({
            companyId:Number(url?.params.id),
            state_value:stateData
          }));
        }
      }, 5000);
      setIntervalId(id);
    }
  };

  useLayoutEffect(() => {
    const integration = integrationsTypesArr.find((item) => item.type === integrationName);
    if (
      divRef.current &&
      integration &&
      !buttonIsSetted &&
      stateIntegration.state &&
      integration.type !== 'retie_whatsappchatapi_amocrm'
    ) {
      const scriptRU = document.createElement('script');
      const scriptCOM = document.createElement('script');

      if (integration.type !== 'odnoklassniki_amocrm'){
        scriptRU.src = `${process.env.PUBLIC_URL}/amocrm-button.js`;
        scriptRU.classList.add('amocrm_oauth');
        scriptRU.dataset.domen = 'com';
        scriptRU.dataset.clientId = integration.params.amocrm.clientUuid;
        scriptRU.dataset.title = t('settings.integrations.install');
        scriptRU.dataset.compact = 'false';
        scriptRU.dataset.className = 'amocrm_install_button';
        scriptRU.dataset.color = 'white';
        scriptRU.dataset.state = stateIntegration.state;
        scriptRU.dataset.mode = 'post_message';
        scriptRU.dataset.errorCallback = 'onAmoError';
        scriptRU.async = true;
      }

      scriptCOM.src = `${process.env.PUBLIC_URL}/amocrm-button.js`;
      scriptCOM.classList.add('amocrm_oauth');
      scriptCOM.dataset.clientId = integration.params.amocrm.clientUuid;
      scriptCOM.dataset.title = t('settings.integrations.installRU');
      scriptCOM.dataset.compact = 'false';
      scriptCOM.dataset.className = 'amocrm_install_button';
      scriptCOM.dataset.color = 'white';
      scriptCOM.dataset.state = stateIntegration.state;
      scriptCOM.dataset.mode = 'post_message';
      scriptCOM.dataset.errorCallback = 'onAmoError';
      scriptCOM.async = true;
      scriptCOM.onload = () => {
        // @ts-ignore
        window.onload();
      };

      divRef.current.appendChild(scriptRU);
      divRef.current.appendChild(scriptCOM);
      setButtonIsSetted(true);
    }
    setItemIntegration(integration);
  }, [integrationsTypesArr, stateIntegration]);

  return (
    <div className="integrationDescription">
      <h1>{itemIntegration?.name}</h1>
      {itemIntegration?.type === 'retie_whatsappchatapi_amocrm' && (
        <div className="integrationDescription__header">
          <div className="integrationDescription__header__notification">
            <div className="integrationDescription__header__notification__title">
              <WarningIcon /> {t('settings.integrations.retie.attention')}
            </div>
            <div className="integrationDescription__header__notification__text">
              <div>{t('settings.integrations.retie.text')}</div>
              <div>
                {t('settings.integrations.retie.text2')},{' '}
                <span onClick={activeWhatsApp}>{t('settings.integrations.retie.click')}</span>
              </div>
            </div>
          </div>
          <div onClick={activeSetNotification} className="integrationDescription__header__oneButton">
            <div className="integrationDescription__header__oneButton__logo">
              <img src={logoAmo} alt="logo" />
            </div>
            <div className="integrationDescription__header__oneButton__text">
              {t('settings.integrations.installRU')}
            </div>
          </div>
        </div>
      )}
      <div
        ref={divRef}
        className="integrationDescription__Description"
        onClick={checkStateInfo}
       />
      <div className="integrationDescription__text">{itemIntegration ? parse(itemIntegration.description) : ''}</div>
    </div>
  );
});

export default IntegrationDescription;
