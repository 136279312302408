import { createContext, useEffect, useState } from "react";
import { IntegrationsItem } from "../IntegrationTypes";
import { Named } from "../types";


type IntegrationTerminals = {
  integration: IntegrationsItem;
  available: Named[];
  connected: Named[];
};

export function useTerminals({ integration, available, connected }: IntegrationTerminals) {
  const [initTerminals, setInitTerminals] = useState<Named[]>([]);
  const [terminals, setTerminals] = useState<Named[]>([]);
  const [addons, setAddons] = useState<Named[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    const isEqual = !(initTerminals.length !== terminals.length) &&
      initTerminals?.every((m) => terminals?.some((i) => {
        if (i.id === m.id) {
          return m.name === i.name;
        }
        return false;
      }));
    setIsChanged(!isEqual);
  }, [terminals]);

  useEffect(() => {
    setAddons(available);
  }, [available]);

  useEffect(() => {
    setInitTerminals(connected);
    setTerminals(connected.map(item => ({ ...item })));
  }, [connected]);

  const onDelete = (terminal: Named) => {
    const index = terminals.findIndex((item) => item.id === terminal.id);
    if (index !== -1) {
      setTerminals((prevTerminals) => {
        prevTerminals.splice(index, 1);
        return [...prevTerminals];
      });
      setAddons((prevAddons) => [...prevAddons, { ...terminal }]);
    }
  };

  const onInsert = (terminal: Named) => {
    const index = addons.findIndex((item) => item.id === terminal.id);
    if (index !== -1) {
      setTerminals((prevTerminals) => [...prevTerminals, { ...terminal }]);
      setAddons((prevAddons) => {
        prevAddons.splice(index, 1);
        return [...prevAddons];
      });
    }
  };

  const onReset = () => {
    setInitTerminals(terminals.map((item) => ({ ...item })));
    setIsChanged(false);
  };

  return { terminals, addons, isChanged, initTerminals, onDelete, onInsert, onReset };
}

type TerminalsContext = {
  onDelete: (terminal: Named) => void;
};

export const terminalsContext = createContext<TerminalsContext>({} as TerminalsContext);
