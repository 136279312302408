import React from 'react';
import { useTranslation } from 'react-i18next';
import { bdAnchorsSupport } from '../../pages/Home/utils/consts';
import './CallSupport.scss';


function CallSupport() {
  const { t, i18n } = useTranslation();

  return (
    <p className='callSupport'>
      {t('error.call_support.call')}&nbsp;
      <a href={bdAnchorsSupport[i18n.language]} target='_blank' rel='noreferrer'>{t('error.call_support.link')}</a>.
    </p>
  );
}

export default CallSupport;
