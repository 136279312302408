import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addAlertWithCustomText } from "../../../../components/Alert/alertSlice";
import { IntegrationsItem } from "../IntegrationTypes";
import { Areas, MessagingPipeline, MessagingOption, Named } from "../types";


type IntegrationOptions = {
  area: Areas;
  integration: IntegrationsItem;
  available: Named[];
  connected: Named[];
  pipelines: MessagingPipeline[];
  fetched: boolean;
};

const formatNewLeads = (
  integration: IntegrationsItem, connected: Named[], pipelines: MessagingPipeline[]): MessagingOption[] =>
  connected.map((item) => {
    return ({
    id: item.id,
    name: item.name,
    pipeID: integration.params.newLeads[item.id]?.pipelineId || null,
    statusID: integration.params.newLeads[item.id]?.statusId || (pipelines.findIndex(
      pipe => pipe.id === integration.params.newLeads[item.id]?.pipelineId) !== -1 ? 0 : null)
  })});

const formatOpenLines = (integration: IntegrationsItem, connected: Named[]): MessagingOption[] =>
  connected.map((item) => ({
    id: item.id,
    name: item.name,
    pipeID: integration.params.openLines[item.id] || null,
    statusID: null
  }));

export function useOptions({ area, integration, available, connected, pipelines, fetched }: IntegrationOptions) {
  const dispatch = useDispatch();
  const [initOptions, setInitOptions] = useState<MessagingOption[]>([]);
  const [options, setOptions] = useState<MessagingOption[]>([]);
  const [addons, setAddons] = useState<Named[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const pipe: number | null = pipelines.length > 0 ? pipelines[0].id : null;

  useEffect(() => {
    const isEqual = !(initOptions.length !== options.length) &&
    initOptions?.every((m) => options?.some((i) => {
      if (i.id === m.id) {
        return m.pipeID === i.pipeID && m.statusID === i.statusID;
      }
      return false;
    }));
    setIsChanged(!isEqual);
  }, [options]);

  useEffect(() => {
    setAddons(available);
  }, [available]);

  useEffect(() => {
    if (connected && fetched) {
      const optionsList = area === 'amocrm'
        ? formatNewLeads(integration, connected, pipelines)
        : formatOpenLines(integration, connected);
      setInitOptions([...optionsList]);
      setOptions(optionsList.map((item) => ({ ...item })));
    }
  }, [connected, fetched]);

  const onChange = (option: MessagingOption) => {
    const index = options.findIndex((item) => item.id === option.id);
    if (index !== -1) setOptions((prevOptions) => prevOptions.map((item, i) => i === index ? option : item));
  };

  const onDelete = (option: MessagingOption) => {
    const index = options.findIndex((item) => item.id === option.id);
    if (index !== -1) {
      setOptions((prevOptions) => {
        prevOptions.splice(index, 1);
        return [...prevOptions];
      });
      setAddons((prevAddons) => [...prevAddons, { id: option.id, name: option.name  }]);
    }
  };

  const beforeInsert = (): boolean => {
    let response = true;
    if (area === 'bitrix') {
      if (pipelines.length === 0) {
        response = false;
        dispatch(addAlertWithCustomText({ message: 'settings.integrations.bitrix.no_open_lines', type: 'alarm' }));
      } else if (pipelines.length <= options.length) {
        response = false;
        dispatch(addAlertWithCustomText({ message: 'settings.integrations.bitrix.no_free_lines', type: 'alarm' }));
      }
    }
    return response;
  };

  const onInsert = (option: Named) => {
    if (!beforeInsert()) return;
    const index = addons.findIndex((item) => item.id === option.id);
    if (index !== -1) {
      setOptions((prevOptions) => [...prevOptions, { ...option, pipeID: pipe, statusID: pipe ? 0 : null }]);
      setAddons((prevAddons) => {
        prevAddons.splice(index, 1);
        return [...prevAddons];
      });
    }
  };

  const onReset = () => {
    setInitOptions(options.map((item) => ({ ...item })));
    setIsChanged(false);
  };

  return { options, addons, isChanged, initOptions, onChange, onDelete, onInsert, onReset };
}

type OptionsContext = {
  pipelines: MessagingPipeline[];
  onChange: (option: MessagingOption) => void;
  onDelete: (option: MessagingOption) => void;
};

export const optionsContext = createContext<OptionsContext>({} as OptionsContext);
