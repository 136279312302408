import React from 'react';
import './Switcher.scss'


type Props = {
  toggled: boolean;
  onToggle: () => void;
  caption?: string;
  lable?: string;
} & React.InputHTMLAttributes<HTMLDivElement>;

const Switcher: React.FC<Props> = ({ caption, lable, toggled, onToggle, ...props }) => (
  <div className='switcher__wrapper' {...props}>
    {caption && <p>{caption}</p>}
    <div className='switcher' >
      <div className={`isoSwitch switch-btn ${toggled && 'switch-on'}`} onClick={onToggle} />
      {lable}
    </div>
  </div>
);

export default Switcher;
