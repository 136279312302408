import React from 'react';
import ReactDOM from 'react-dom';

type Props = {
  imageUrl: string;
  isOpen: boolean;
  setImageModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ImageModal: React.FC<Props> = ({ imageUrl, isOpen, setImageModal }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="overlay"
      style={{
        display: 'block',
      }}
      onClick={() => {
        setImageModal(!isOpen);
      }}
    >
      <div
        className="imageModal"
        style={{
          display: 'flex',
        }}
      >
        <img src={imageUrl} alt="modal" />
      </div>
    </div>,
    document.body
  );
};

export default ImageModal;
