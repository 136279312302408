import axios from 'axios';
import keycloak from '../keycloak';
import { convertor } from './messageConvertor';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
      try {
        await keycloak.updateToken(30);
        config.headers = config.headers || {};
        config.headers.authorization = `Bearer ${keycloak.token}`;
      } catch (error) {
        keycloak.login()
      }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use((response) => convertor(response.data));

export default axiosInstance
