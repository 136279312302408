
const dayInMinutes = 1440;
const hourInMinutes = 60;

export function getLifetime(minutesLifetime: number) {
  let days = 0;
  let hours = 0;
  let minutes = 0;

  const remainderDays = minutesLifetime % dayInMinutes;
  days = (minutesLifetime - remainderDays) / dayInMinutes;
  const remainderHours = remainderDays % hourInMinutes;
  hours = (remainderDays - remainderHours) / hourInMinutes;
  minutes = remainderHours;

  return { days, hours, minutes };
}

export function setLifetime(days: number, hours: number, minutes: number) {
  const totalDays = days * dayInMinutes;
  const totalHours = hours * hourInMinutes;

  return Number(totalDays) + Number(totalHours) + Number(minutes);
}
