import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';
import { TabsListType } from '../../subMenuLists';
import LinkForSubMenu from "../../components/LinkForSubMenu/LinkForSubMenu";
import { ReactComponent as ArrowLeft } from '../../../../../assets/arrow-left.svg';


type Props = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentSubTabs: React.Dispatch<React.SetStateAction<TabsListType | null>>;
  currentSubTabs: TabsListType;
};

const SubMenu = ({ setIsOpen, setCurrentSubTabs, currentSubTabs }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const onBackHandler = () => {
    setCurrentSubTabs(null);
  };

  const onLinkHandler = () => {
    setIsOpen(false);
    setCurrentSubTabs(null);
  };

  return (
    <div className='SubMenu'>
      <div className='SubMenu_header'>
        <h1>{t(`navbar.${currentSubTabs.title}`)}</h1>
        <div className='SubMenu_back' onClick={onBackHandler}>
          <ArrowLeft />
          <p>{t('back')}</p>
        </div>
      </div>
      <div className='SubMenu_tabs'>
        {currentSubTabs.tabs.map((item) =>
          <LinkForSubMenu key={item.tab} tab={item} isActive={location.pathname.includes(item.url)}
            onClick={onLinkHandler} />)}
      </div>
    </div>
  );
};

export default SubMenu;
