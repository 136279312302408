import React, { createContext, useContext, useReducer } from 'react';
import {
  ActionStickyType, ActionStickyTypes,
  InitialDispatchType,
  InitialStateStickyType,
  RefType,
} from './types';

const initialState: InitialStateStickyType = {
  containerRef: null,
  stickyRefs: new Map()
};

const StickyStateContext = createContext<InitialStateStickyType>(initialState);
const StickyDispatchContext = createContext<InitialDispatchType>({
  addStickyRef: () => {
  },
  setContainerRef: () => {
  },
});

function reducer(state: InitialStateStickyType, action: ActionStickyTypes) {

  switch (action.type) {
    case ActionStickyType.setContainerRef:
      // Reassigning a new ref, will infinitely re-load!
      return Object.assign(state, {
        containerRef: action.containerRef,
      });
    case ActionStickyType.addStickyRef:
      action.payload.topSentinelRef?.current
      && state.stickyRefs.set(action.payload.topSentinelRef?.current, action.payload.stickyRef);
      action.payload.bottomSentinelRef?.current
      && state.stickyRefs.set(action.payload.bottomSentinelRef?.current, action.payload.stickyRef);

      return Object.assign(state, {
        stickyRefs: state.stickyRefs,
      });
    default:
      return state;
  }
}

const StickyProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setContainerRef = (containerRef: RefType) =>
    dispatch({ type: ActionStickyType.setContainerRef, containerRef });

  const addStickyRef = (topSentinelRef: RefType, bottomSentinelRef: RefType, stickyRef: HTMLDivElement) =>
    dispatch({
      type: ActionStickyType.addStickyRef,
      payload: { topSentinelRef, bottomSentinelRef, stickyRef },
    });

  const actionsDispatch = {
    setContainerRef,
    addStickyRef
  };

  return (
    <StickyStateContext.Provider value={state}>
      <StickyDispatchContext.Provider value={actionsDispatch}>
        {children}
      </StickyDispatchContext.Provider>
    </StickyStateContext.Provider>
  );
};

function useStickyState() {
  const context = useContext(StickyStateContext);
  if (context === undefined)
    throw Error('"useStickyState should be used under "StickyStateContext');
  return context;
}

function useStickyActions() {
  const context = useContext(StickyDispatchContext);
  if (context === undefined)
    throw Error(
      '"useStickyActions should be used under "StickyDispatchContext'
    );
  return context;
}

type InitialSectionValuesType = {
  topSentinelRef: null | React.RefObject<HTMLDivElement>,
  bottomSentinelRef: null | React.RefObject<HTMLDivElement>
}

const initialSectionValues: InitialSectionValuesType = {
  topSentinelRef: null,
  bottomSentinelRef: null,
};

const StickySectionContext = createContext(initialSectionValues);

export type ChatContext = {
  chatContentRef: null | React.RefObject<HTMLDivElement>,
  contactName?: string;
  avatarUrl?: string;
  isGroup?: boolean;
  chatPhone?: string;
};

const chatValues: ChatContext = {
  chatContentRef: null,
};

const chatContext = createContext(chatValues);

export {
  StickyProvider,
  useStickyState,
  useStickyActions,
  StickySectionContext,
  chatContext
};
