import './WhatsAppEdit.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { WhatsAppConnection } from '../../../../../../api/CompanyAPI';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { FormProvider, useForm } from 'react-hook-form';
import { updateConnection } from '../../../connectionsSlice';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';
import SwitchControl from '../../../../../../components/SwitchControl';


type SubmitType = {
  name: string;
  group_chats_enabled: boolean;
};

function WhatsAppEdit() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const history = useHistory();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.whatsapp);
  const [currentConnect, setCurrentConnect] = useState<WhatsAppConnection | null>(null);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    group_chats_enabled: yup.boolean(),
  });

  const methods = useForm<SubmitType>({
    mode: 'onChange', defaultValues: { name: '', group_chats_enabled: false }, resolver: yupResolver(schema) });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as WhatsAppConnection;
    if (connect) {
      setCurrentConnect(connect);
      methods.reset({
        name: connect?.name,
        group_chats_enabled: connect?.params.groupChatsEnabled
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmit = (data: SubmitType) => {
    if (currentConnect && currentCompanyId?.params.companyId) {
      dispatch(updateConnection({
        itemId: currentConnect.id,
        type: 'whatsapp',
        companyId: Number(currentCompanyId.params.companyId),
        name: data.name,
        params: { group_chats_enabled: data.group_chats_enabled }
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          history.push(`${currentCompanyId.url}/settings/connections/whatsapp`);
        }
      })
    }
  };

  if (currentConnect) {
    return (
      <GoBackWrapper title={t('edit')}>
        <FormProvider {...methods}>
          <div className='whatsappEdit'>
          <p>{t('connections.connection_number')}: #{currentConnect.id}</p>
          <Input name='name' label={t('connections.connection_name')} />
          <SwitchControl name='group_chats_enabled' label={t('connections.whatsapp.group_chats')} />
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
            onClick={methods.handleSubmit(onSubmit)} disabled={!methods.formState.isDirty} />
          </div>
        </FormProvider>
      </GoBackWrapper>
    );
  }

  return null;
}

export default WhatsAppEdit;
