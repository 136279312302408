import React from 'react';
import { useTranslation } from 'react-i18next';
import './PermissionsList.scss'
import { PermissionWithKeys } from '../../utlity';
import PermissionItem from '../PermessionItem/PermissionItem';

type PermissionsListType = {
  permissions: PermissionWithKeys
  onChangePermission: (data: Record<string, Record<string, boolean>>) => void
  isAdminManager:boolean
}
const PermissionsList = ({ permissions,onChangePermission,isAdminManager }:PermissionsListType) => {
  const { t } = useTranslation();
  return (
    <div className='permissionsList'>
      <div><h2>{t('settings.users.user.accessRights')}</h2>
      </div>
      <div className='permissionsList__list'>
        {Object.entries(permissions).map(([key, value]) =>
          <PermissionItem
            key={key}
            keyPermission={key}
            listPermission={value}
            isAdminManager={isAdminManager}
            onChangePermission={onChangePermission}
          />
        )}
      </div>
    </div>
  );
};

export default PermissionsList;
