import axiosInstance from './AxiosInstance';

export type Phone = {
  id: number;
  type: string;
  value: string;
};

export type Email = {
  id: number;
  type: string;
  value: string;
};
export type AllTagType = {
  id: number;
  companyId: number;
  name: string;
  description: string;
  backgroundColor: string;
};

export type Tag = {
  id: number;
  name: string;
  description: string;
  backgroundColor: string;
};

export type TagType = {
  id: number;
  name: string;
  description: string;
  backgroundColor: string;
};
export type UpdateDateContactEditedAPIType = {
  contactId: string;
  companyId:number
  payload: UpdateContactPayload;
};
export type UpdateTagsContactType =  {
  companyId:number
  contactId: string;
  idAllTagsContact: Array<number> }


type DetailError = {
  location: string;
  message: string;
  type: string;
};
export type ErrorMessageContact = {
  detail: Array<DetailError>;
  error_code: number;
  message: string;
};

export type ChatType = {
  connectionId: number;
  chatId: number;
  sourceChatId: string;
  responsibleMemberId: number;
  name: string;
  phone: string;
  profileLink: string;
};

export type ContactType = {
  id: number;
  companyId: number;
  name: string;
  avatarUrl: string;
  phones: Array<Phone>;
  emails: Array<Email>;
  tags: Array<TagType>;
  chats: Array<ChatType>;
};


export type ContactNotesType = {
  id: number;
  contactId: number;
  type: string | null;
  text: string | '';
  createdAt: string | null;
  updatedAt: string | null;
  createdBy?: number | null;
  updatedBy?: number | null;
};

export type GetContactsWithParameters = {
  count: number;
  totalCount: number;
  order_by: string;
  items: Array<ContactType>;
};

export type UpdateAllTagsContactType = {
  contactId: number;
  tagId: number;
};

export type UpdateContactPayload = {
  name: string;
  phones?: Array<{ value: string | number }> |null;
  emails: Array<{ value: string }> | null;
  tags:Array<number>
};

export type CreateContactApiType = {
  companyId: number;
  name: string;
  phones: Array<{ value: string }>;
  emails: Array<{ value: string }>;
  tags: Array<number>;
  notes: Array<{ text: string }>;
};

export type SearchByCriteriaType = {
  companyId: number;
  query?: string ;
  sort?: string;
  tags?: Array<number>;
  limit?: number;
  offset?: number;
};

export type ContactChatList = {
  connectionId: number;
  chatId: number;
  sourceChatId: string;
  responsibleMemberId: number;
  name: string;
};

export type CreateNewNoteType={
  contactId: number
  companyId:number
  text: string
}
export type EditNoteAPIType={
  companyId:number,
  contactId: string;
  noteId: number;
  text: string
}
export type RemoveNoteAPIType={ contact_id: number; note_id: number,  companyId:number, }

export type ParamsContactId={
  contactId: number
  companyId: number
}



// СТРАНИЦА- СПИСОК КОНТАКТОВ
export const getContacts = async (requestOptions: SearchByCriteriaType): Promise<GetContactsWithParameters> => {
  const URL = new URLSearchParams();
  if (requestOptions.tags && requestOptions.tags.length > 0){
    requestOptions.tags.forEach((item) => URL.append('tags', item.toString()));
  }
  if (requestOptions.sort && requestOptions.sort?.length > 0){
    URL.set('order_by', `${requestOptions.sort}`);
  }
  if (requestOptions.query && requestOptions.query?.length > 0) {
    URL.set('query', `${requestOptions.query}`);
  }
  URL.set('offset', `${requestOptions.offset}`);
  URL.set('limit', `${requestOptions.limit}`);
  URL.set('company_id', requestOptions.companyId.toString());
  return axiosInstance.get(`/companies/${requestOptions.companyId}/contacts/`, {
    params: URL,
  });
};


export const getContactAPI = async (requestOptions: ParamsContactId): Promise<ContactType> =>
  axiosInstance.get(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}`)


export const getAllTags = async (requestOptions: { companyId: number }): Promise<Array<AllTagType>> =>
  axiosInstance.get(`/companies/${requestOptions.companyId}/tags/`)


export const getContactChatList = async (requestOptions: ParamsContactId): Promise<ContactChatList[]> =>
  axiosInstance.get(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}/chats/`);

export const getContactNotes = async (requestOptions: ParamsContactId): Promise<Array<ContactNotesType>> =>
  axiosInstance.get(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}/notes/`);

export const createNewNote = async (requestOptions: CreateNewNoteType): Promise<ContactNotesType> =>
  axiosInstance.post(
    `/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}/notes/`,
    {
      text: requestOptions.text,
    }
  );

export const editNoteAPI = async (requestOptions: EditNoteAPIType): Promise<ContactNotesType> =>
  axiosInstance.patch(
    `/companies/${requestOptions.companyId}/
contacts/${requestOptions.contactId}/notes/${requestOptions.noteId}`,
    {
      text: requestOptions.text,
    }
  );

export const removeNoteAPI = async (requestOptions: RemoveNoteAPIType) =>
  axiosInstance.delete(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contact_id}/notes/${requestOptions.note_id}`);

// СТРАНИЦА РЕДАКТИРОВАНИЯ

export const updateDateContactEditedAPI = async (
  requestOptions: UpdateDateContactEditedAPIType
): Promise<ContactType> => axiosInstance.patch(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}`, requestOptions.payload);

export const updateTagsContactAPI = async (
  requestOptions: UpdateTagsContactType): Promise<Array<UpdateAllTagsContactType>> =>
  axiosInstance.put(`/companies/${requestOptions.companyId}/contacts/${requestOptions.contactId}/tags/`, requestOptions.idAllTagsContact);

// СТРАНИЦА СОЗДАНИЯ КОНТАКТА

export const createContactApi = async (payload: CreateContactApiType): Promise<ContactType> =>{
  const { companyId,...rest }=payload
  return  axiosInstance.post(`/companies/${companyId}/contacts/`, { ...rest });
}


export const getChatTagsApi = async (chatId: number): Promise<Tag[]> => axiosInstance.get(`messaging/chats/${chatId}/tags/`);
