import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { hasFirstOptionMessaging, integrationContext } from '../../../../utils';
import { optionsContext } from '../../../../api';
import { MessagingOption } from '../../../../types';
import { WrapperPermission } from '../../../../../../../utils/WrapperPermission/WrapperPermission';
import ActionDialog from '../../../../../../../components/Modals/ActionDialog';
import Button from '../../../../../../../components/Button/Button';
import OptionSelect from '../OptionSelect';
import SwitchControl from '../../../../../../../components/SwitchControl';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/delete-icon-black.svg';
import './OptionItem.scss';


type OptionItemProps = {
  connection: MessagingOption;
};

function OptionItem({ connection }: OptionItemProps) {
  const { t } = useTranslation();
  const { area, service } = useContext(integrationContext);
  const { control } = useFormContext();
  const { onDelete } = useContext(optionsContext);
  const currentWrite = useWatch({ control, name: 'writeFirstConn' });
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const hasFirstOption = area && area === 'amocrm' && service && hasFirstOptionMessaging[service];

  const onDeleteClick = () => setDeleteConfirm(true);

  const onHandlerDelete = () => {
    onDelete(connection);
    setDeleteConfirm(false);
  };

  if (connection) return (
    <>
      {deleteConfirm &&
        <ActionDialog onAction={onHandlerDelete} setIsOpen={setDeleteConfirm} action={t('disable')} cancel={t('cancel')}
          background='light' title={t('settings.integrations.titleDisableIntegration')}
          message={t('settings.integrations.disableIntegration')} />
      }
      <div className={`optionItem ${hasFirstOption ? '' : 'hide'}`}>
        {hasFirstOption && <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
          <SwitchControl name='writeFirstConn' value={connection.id} type='radio' label=''
            defaultChecked={currentWrite === connection.id} />
        </WrapperPermission>}
        <p>{connection.name}</p>
        <OptionSelect connection={connection} />
        <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
          <Button type='button' color='white' image={<DeleteIcon />} textType='small' onClick={onDeleteClick} />
        </WrapperPermission>
      </div>
    </>
  );
  return <></>;
}

export default OptionItem;
