import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import '../ChatContent.scss';
import { chatContext, StickyProvider, StickySectionContext, useStickyActions } from './Context';
import { OnChangeType, SortMessagesType, StickyBoundaryProps } from './types';
import { useObserveBottomSentinels, useObserveTopSentinels, useSentinelOffsets } from './hooks';
import { fetchMessages } from '../../../messagesThunks';
import { currentChatSelector } from '../../../chatsSelector';
import { useAppDispatch } from '../../../../../store/store';
import { delay } from '../../../../../utils/utils';
import ChatMessage from '../../ChatMessage/ChatMessage';


const Sticky: FC = ({ children }) => {
  const { topSentinelRef, bottomSentinelRef } = useContext(StickySectionContext);
  const dispatch = useStickyActions();

  // So that we can retrieve correct child target element
  // from either a top sentinel or a bottom sentinel
  const addStickyRef = (stickyRef: HTMLDivElement) => {
    dispatch.addStickyRef(topSentinelRef, bottomSentinelRef, stickyRef);
  };

  return (
    <div ref={addStickyRef} className="chatContent__section__sticky">
      {children}
    </div>
  );
};

/**
 * A section, in which <Sticky /> element element is observed
 */

const StickyBoundary: FC<StickyBoundaryProps> = ({ onChange, onStuck, onUnstuck, children, ...rest }) => {
  const topSentinelRef = useRef<HTMLDivElement>(null);
  const bottomSentinelRef = useRef<HTMLDivElement>(null);

  const { bottomSentinelHeight, topSentinelMarginTop } = useSentinelOffsets(topSentinelRef);

  useObserveTopSentinels(topSentinelRef, {
    onChange,
    onStuck,
    onUnstuck,
  });

  useObserveBottomSentinels(bottomSentinelRef, {
    onChange,
    onStuck,
    onUnstuck,
  });

  const value = { topSentinelRef, bottomSentinelRef };

  return (
    <StickySectionContext.Provider value={value}>
      <div className="chatContent__section" {...rest}>
        <div
          ref={topSentinelRef}
          style={{ marginTop: `-${topSentinelMarginTop}` }}
          className="chatContent__section__sentinel_top"
        />
        {children}
        <div
          ref={bottomSentinelRef}
          style={{
            height: `${bottomSentinelHeight}`,
          }}
          className="chatContent__section__sentinel_bottom"
        />
      </div>
    </StickySectionContext.Provider>
  );
};

const handleIsSticky = (t: OnChangeType, isScroll: boolean) => {
  const { type, target } = t;

  if (type === 'unstuck') {
    target.classList.remove('remove');
    target.classList.add('active');
    return;
  }

  if (type === 'stuck' && isScroll) {
    target.classList.remove('remove');
    target.classList.add('active');
    return;
  }
  if (type === 'stuck') {
    target.classList.remove('active');
    target.classList.add('remove');
  }
};


const StickyRoot: FC<SortMessagesType> = ({ list, messages }) => {
  const dispatch = useAppDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const currentChat = useSelector(currentChatSelector);
  const dispatchSticky = useStickyActions();
  const { chatContentRef } = useContext(chatContext);
  const [isScroll, setIsScroll] = useState(false);

  const scrolling = () => {
    setIsScroll(true);
    delay(() => setIsScroll(false), 1000);
  };

  useEffect(() => {
    if (chatContentRef && chatContentRef.current) {
      dispatchSticky.setContainerRef(chatContentRef);
    }
  }, []);

  return (
    <>
      {messages && messages.length > 0 && (
        <InfiniteScroll
          next={() => {
            dispatch(
              fetchMessages({
                companyId: Number(currentCompanyId?.params.companyId),
                ...currentChat,
                limit: 10,
                until: messages[0].createdAt,
              })
            );
          }}
          onScroll={scrolling}
          hasMore={true}
          inverse={true}
          scrollableTarget="scrollableDiv"
          loader={null}
          dataLength={messages.length}
        >
          <div ref={chatContentRef} id="scrollableDiv" className="chatContent">
            {' '}
            {list.map((i) => (
              <StickyBoundary key={i.date} onStuck={() => {}} onUnstuck={() => {}}
                onChange={(typeTarget) => handleIsSticky(typeTarget, isScroll)} >
                <Sticky key={i.date}>
                  <div className="chatContent__section__sticky__messagesDate">
                    <div className="chatContent__section__sticky__messagesDate__date">{i.date}</div>
                  </div>
                </Sticky>
                {i.messages.map((m) => (
                  <ChatMessage key={m.messageId} message={m} />
                ))}
              </StickyBoundary>
            ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

const StickyViewport: React.FC<SortMessagesType> = ({ messages, list }) => (
  <StickyProvider>
    <StickyRoot list={list} messages={messages} />
  </StickyProvider>
);

export { StickyViewport, StickyBoundary, Sticky };
