import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector } from '../views/Chat/companiesSelector';
import ContactsRoute from '../views/Contacts/ContactsRoute';
import Orders from '../views/Sales/Orders/components/Orders';
import Products from '../views/Sales/Products/Products';
import CatalogsRoute from './CatalogsRoute';
import BroadcastsRoute from './BroadcastsRoute';
import ChatsRoute from "../views/Chat/ChatsRoute";
import ShopsRoute from '../views/Sales/Shops/ShopsRoute';
import TemplatesRoute from '../views/Templates/TemplatesRoute';


export const listWithPermissionRoutes = [
  { permission: 'messaging_chat_view', path: '/chats', Component: ChatsRoute, exact: false },
  { permission: 'templates_view', path: '/templates', Component: TemplatesRoute, exact: false },
  { permission: 'contacts_view', path: '/contacts', Component: ContactsRoute, exact: false },
  { permission: 'broadcasts_view', path: '/broadcasts', Component: BroadcastsRoute, exact: false },
  { permission: 'sales_shop_view', path: '/shops', Component: ShopsRoute, exact: false },
  { permission: 'sales_product_view', path: '/products', Component: Products, exact: false },
  { permission: 'sales_catalog_view', path: '/catalogs', Component: CatalogsRoute, exact: false },
  { permission: 'banks_invoice_view', path: '/orders', Component: Orders, exact: false }
];

export function usePermittedRoutes() {
  const currentRole = useSelector(getCurrentCompanyRoleSelector);

  return useMemo(() =>
    listWithPermissionRoutes.filter(({ permission }) => currentRole?.permissions.includes(permission)), [currentRole]);
}
