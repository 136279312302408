import moment from 'moment-timezone';
import 'moment/locale/ru';
import 'moment/locale/es';
import { ConnectionServiceTypes, settingConnections } from "../../Connections/utils/connections";
import { Subscription, SubscriptionServiceType, subscriptionServiceMap } from "../SubscriptionsAPI";
import { SubscriptionStatusEnum } from '../types';
import { ConnectionsType } from "../../Connections/ConnectionsTypes";
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';


const DAYS_LEFT_TO_WARN = 1;
const DAYS_PAID_TO_WARN = 5;
const DAYS_END_TEST = 1;


class UserSubscription {
  selected: boolean;

  private statusSubscription: SubscriptionStatusEnum;

  public subscription: Subscription;

  constructor(subscription: Subscription) {
    this.subscription = subscription;
    this.statusSubscription = this.getSubscriptionStatus();
    this.selected = this.statusSubscription !== SubscriptionStatusEnum.paused;
  }

  /**
   * noWarningStatuses - array of statuses for which no need to appear paid or pause warnings
   */
  public static noWarningStatuses() : Array<SubscriptionStatusEnum> {
    return [SubscriptionStatusEnum.expired, SubscriptionStatusEnum.test, SubscriptionStatusEnum.testEnd];
  }

  public get status(): SubscriptionStatusEnum {
    return this.statusSubscription;
  }

  public updateStatus() {
    this.statusSubscription = this.getSubscriptionStatus();
    this.selected = this.statusSubscription === SubscriptionStatusEnum.paused ? false : this.selected;
  }

  private getSubscriptionStatus() {
    if (this.subscription.pausedAt) {
      return SubscriptionStatusEnum.paused;
    }
    const now = getDateTime().moment();
    if (now.isBefore(this.subscription.testUntil)) {
      if (getDateTime().moment(this.subscription.testUntil).diff(now, "days") <= DAYS_END_TEST) {
        return SubscriptionStatusEnum.testEnd;
      }
      return SubscriptionStatusEnum.test;
    }
    if (!this.subscription.paidUntil) {
      return SubscriptionStatusEnum.unpaid;
    }
    if (getDateTime().moment(this.subscription.paidUntil).isBefore(now)) {
      return SubscriptionStatusEnum.expired;
    }
    if (getDateTime().moment(this.subscription.paidUntil).diff(now, "days") <= DAYS_LEFT_TO_WARN) {
      return SubscriptionStatusEnum.warn;
    }
    return SubscriptionStatusEnum.paid;
  }

  /**
   * formatSubscriptionDateStatus
   */
  public formatSubscriptionDateStatus(locale: string) {
    let formatDate = '';
    moment.locale(locale);
    switch (this.statusSubscription) {
      case SubscriptionStatusEnum.test:
      case SubscriptionStatusEnum.testEnd:
        formatDate = `${moment(this.subscription.testUntil).format('L')}`;
        break;
      case SubscriptionStatusEnum.paid:
      case SubscriptionStatusEnum.warn:
      case SubscriptionStatusEnum.expired:
        formatDate = `${moment(this.subscription.paidUntil).format('L')}`;
        break;
      case SubscriptionStatusEnum.paused:
        formatDate = `${moment(this.subscription.paidUntil).diff(moment(this.subscription.pausedAt), 'days')}`;
        break;
    }
    return formatDate;
  };

  /**
   * checkPaidDaysLeft
   */
  public checkPaidDaysLeft() {
    if (UserSubscription.noWarningStatuses().includes(this.status)) {
      return false;
    }
    const now = getDateTime().moment();
    const difference = getDateTime().moment(this.subscription.paidUntil).diff(now, "hours");
    return (difference < DAYS_PAID_TO_WARN * 24);
  };

  public formatPaidUntil(locale: string) {
    getDateTime().moment().locale(locale);
    return  `${getDateTime().format(this.subscription.paidUntil,'dayMonthYear')}`
  };

  public get id(): number {
    return this.subscription.id;
  }

  public get paidUntil(): string {
    return this.subscription.paidUntil;
  }

  public get testUntil(): string {
    return this.subscription.testUntil;
  }

  public get pausedAt(): string | null {
    return this.subscription.pausedAt;
  }

  public get currentSize(): number {
    return this.subscription.currentSize;
  }

  public get type(): SubscriptionServiceType {
    return subscriptionServiceMap[this.subscription.subscriptionType];
  }

  public get statusName(): string {
    return SubscriptionStatusEnum[this.status];
  }

  public get name(): string {
    // name of type connections of this subscriptions
    const connectionService = settingConnections[this.type as ConnectionsType];
    if (connectionService) {
      return connectionService.name;
    }
    return '';
  }

  public get serviceType(): ConnectionServiceTypes | string {
    const connectionService = settingConnections[this.type as ConnectionsType];
    if (connectionService) {
      return connectionService.type;
    }
    return 'unknown';
  }

  public get connections(): number[] {
    return this.subscription.connections;
  }

}

export default UserSubscription;
