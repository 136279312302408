import { TFunction } from 'react-i18next';
import { getDateTime } from '../../../../../utils/getDateTime/getDateTime';
import { Invoice, Terminal } from '../../../../../api/BillAPI';
import { ReactComponent as TinkoffIcon } from '../../../../../assets/logo-tinkoff.svg';
import { ReactComponent as SberIcon } from '../../../../../assets/sberbank-logo.svg';
import { ReactComponent as PaykeeperIcon } from '../../../../../assets/paykeeper-logo.svg';
import { ReactComponent as ModulbankIcon } from '../../../../../assets/modulbank-logo.svg';
import { ReactComponent as BePaidIcon } from '../../../../../assets/bePaid-logo.svg';
import { ReactComponent as PaySelectionIcon } from '../../../../../assets/paySelection-logo.svg';
import { ReactComponent as YookassaIcon } from '../../../../../assets/logo-yookassa.svg';
import { getCustomMonth } from '../../../../../utils/getCustomMonth';


export const getInvoiceDate = (date: string, t: TFunction<'translation'>, withMount: boolean=false, withTime: boolean=false) => {
  if (!date) {
    return null;
  }
  const { months,month } = getCustomMonth(date,t);
  if (!withMount && withTime) {
    return getDateTime().format(date,'dayMonthYearHourMin')
  }
  if (!withMount && !withTime) {
    return getDateTime().format(date,'dayMonthYear')
  }
  return getDateTime().format(date,'withCustomFormat',`DD [${months[month]}] YYYY, HH:mm`)
};

export const getInvoiceStatus = (invoice: Invoice, t: TFunction<'translation'>) => {

  if (!invoice) {
    return null;
  }

  if (invoice.status === 'new') {
    return <p style={{ color: '#333333' }}>{t('orders.status.new')}</p>;
  }
  if (invoice.status === 'confirmed') {
    return <p style={{ color: '#2CBA5F' }}>{t('orders.status.confirmed')}</p>;
  }
  if (invoice.status === 'rejected') {
    return <p style={{ color: '#333333' }}>{t('orders.status.rejected')}</p>;
  }
  if (invoice.status === 'expired') {
    return <p style={{ color: '#F54242' }}>{t('orders.status.expired')}</p>;
  }
  if (invoice.status === 'refunded') {
    return <p style={{ color: '#333333' }}>{t('orders.status.refunded')}</p>;
  }
  if (invoice.status === 'partial_refunded') {
    return <p style={{ color: '#333333' }}>{t('orders.status.partial_refunded')}</p>;
  }

  return ``;
};

export const getSymbolCurrency = (invoice: Invoice) => {
  switch (invoice.currency) {
    case 840:
      return '$';
    case 978:
      return '€';
    case 933:
      return 'Br';
    case 944:
      return '₼';
    case 156:
      return '¥';
    case 417:
      return 'KGS';
    case 398:
      return '₸';
    case 498:
      return 'L';
    case 980:
      return '₴';
    default:
      return '₽';
  }
};

type SVGElementType = React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
type KeyListSVGElementsType = Record<string, SVGElementType>;
const terminalLogos: KeyListSVGElementsType = {
  tinkoff: TinkoffIcon,
  sberbank: SberIcon,
  paykeeper: PaykeeperIcon,
  modulbank: ModulbankIcon,
  bepaid: BePaidIcon,
  yookassa: YookassaIcon,
  payselection: PaySelectionIcon
};

export const getTerminalAndLogo = (invoice: Invoice, terminals: Terminal[] | null) => {
  if (terminals) {
    const terminal = terminals.find((terminal) => terminal.id === invoice.connectionId);
    
    const getIcon = (terminalType: string) => {
      const Icon = terminalLogos[terminalType];
      if (Icon) return <Icon />;
      return null;
    };
    
    if (terminal) {
      return (
        <div className='terminalAndLogo'>
          {getIcon(terminal.type)}
          {terminal.name}
        </div>
      );
    }

    return null;
  }
};
