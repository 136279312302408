import { useContext } from 'react';
import './NotificationCard.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { NotificationChannel } from '../../../../../api/CompanyAPI';
import { usePhoneSchema } from '../../../../../components/PhoneInput/utils';
import { ChannelSchema, notificationContext } from '../../types/types';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../components/Button/Button';
import NotificationListEvents from '../NotificationListEvents';
import PhoneField from '../../../../../components/PhoneInput/PhoneField';
import { ReactComponent as DeleteIcon } from '../../../../../assets/trash.svg';


export type NotificationCardProps = {
  channel: NotificationChannel;
};

const defaultValues: ChannelSchema = { events: { broadcast: false, integration: false, payment: false }, phone: '' };

function NotificationCard({ channel }: NotificationCardProps) {
  const { t } = useTranslation();
  const { onCreate, onDelete, onEdit } = useContext(notificationContext);

  const schema = yup.object().shape({
    ...{ events: yup.object().shape({
      integration: yup.boolean(),
      payment: yup.boolean(),
      broadcast: yup.boolean()
    }) }, ...usePhoneSchema({ required: true })
  });

  const methods = useForm<ChannelSchema>({ mode: 'onSubmit', resolver: yupResolver(schema),
    defaultValues: (channel 
      ? { phone: channel.whatsapp.phone, 
        events: {
          broadcast: channel.params.events.includes('broadcast_status'),
          integration: channel.params.events.includes('integration_status'),
          payment: channel.params.events.includes('payment_status')
        } }
      : defaultValues ) });

  const onSubmit = (data: ChannelSchema) => {
    const events: string[] = [];
    if (data.events.integration) events.push('integration_status');
    if (data.events.payment) events.push('payment_status');
    if (data.events.broadcast) events.push('broadcast_status');

    const channelData: NotificationChannel  = {
      id: channel.id,
      companyId: channel.companyId,
      name: channel.name,
      params: { events },
      type: 'whatsapp',
      whatsapp: { phone: data.phone }
    };

    if (channel.id === 0) {
      if (onCreate) onCreate(channelData);
    } else {
      if (onEdit) onEdit(channelData);
    }
  };

  const onDeleteHandler = () => {
    if (channel.id !== 0 && onDelete) {
      onDelete(channel);
    } else {
      methods.reset(defaultValues);
    }
  };

  return (
    <div className='NotificationCard'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='NotificationCard__item'>
            <div className='NotificationCard__item_phone'>
              <WrapperPermission permission='notificationsEdit' disabledProps={{ disabled: true }}>
                <PhoneField name='phone' />
              </WrapperPermission>
              <WrapperPermission permission='notificationsDelete'>
                <Button type='button' color='white' textType='small' onClick={onDeleteHandler} image= {<DeleteIcon />} />
              </WrapperPermission>
            </div>
            <NotificationListEvents />
          </div>
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
};

export default NotificationCard;
