import React, { useEffect, useRef, useState } from "react";
import './CreateAndEditRequisites.scss'
import * as yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../store/store";
import { EditRequisiteSelector, RequisitesSelector } from "../../SubscriptionsSelector";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import {
  addPaymentRequisite, fetchPaymentRequisite, updateEditRequisite, updatePaymentRequisite
} from "../../SubscriptionsSlice";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Button/Button";
import { RequisiteSchema } from "../../schema/schema";
import { useOutsideClick } from "../../../../../components/utils/hooks";
import CloseIcon from '../../../../../assets/cancel.svg';


type Props = {
  setIsVisibleForm: React.Dispatch<React.SetStateAction<boolean>>;
};

const nameRegex = new RegExp(/^[0-9]+$/);

function CreateAndEditRequisites({ setIsVisibleForm }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const editRequisite = useSelector(EditRequisiteSelector);
  const requisitesList = useSelector(RequisitesSelector);
  const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLImageElement>(null);
  
  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(256, t('error.form.max_length', { limit: 256 })),
    tin: yup.string().required(t('error.form.empty_field'))
      .matches(nameRegex, t('subscriptions.requisites.modal.type_error'))
      .min(10, t('error.form.min_length', { limit: 10 }))
      .max(12, t('error.form.max_length', { limit: 12 })),
  });

  const methods = useForm<RequisiteSchema>({
    mode: 'onChange', resolver: yupResolver(schema), defaultValues: { name: '', tin: '' }
  });

  useEffect(() => {
    if (editRequisite.isEdit) {
      const requisite = requisitesList.find((item) => item.id === editRequisite.requisiteId);
      if (requisite) {
        methods.reset({ name: requisite.name, tin: requisite.tin });
      }
    }
  }, []);

  const onClose = () => {
    setIsVisibleForm(false);
    setIsSuccessSubmit(false);
    dispatch(updateEditRequisite({ isEdit: false, requisiteId: null }));
  };

  const onSubmit = (data: RequisiteSchema) => {
    if (editRequisite.isEdit && editRequisite.requisiteId) {
      dispatch(updatePaymentRequisite({
        requisite_id: editRequisite.requisiteId,
        company_id: Number(params?.params.companyId),
        name: data.name,
        tin: data.tin
      })).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(addAlertWithCustomText({
            message: t('subscriptions.requisites.modal.success_edit'),
            color: '#2CBA5F'
          }));
          setIsVisibleForm(false);
          dispatch(fetchPaymentRequisite({ company_id: Number(params?.params.companyId) }));
        } else {
          dispatch(addAlertWithCustomText({
            message: t('subscriptions.requisites.modal.error_edit'),
            color: '#F54242'
          }));
        }
      });
    } else {
      dispatch(addPaymentRequisite({
        company_id: Number(params?.params.companyId),
        name: data.name,
        tin: data.tin
      })).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setIsSuccessSubmit(true);
          dispatch(fetchPaymentRequisite({ company_id: Number(params?.params.companyId) }))
        } else {
          dispatch(addAlertWithCustomText({
            message: t('subscriptions.requisites.modal.error_add'),
            color: '#F54242'
          }));
        }
      });
    }
  };

  useOutsideClick(contentRef, onClose, triggerRef);

  if (isSuccessSubmit) {
    return (
      <div className='createAndEditRequisites' ref={contentRef}>
        <div className='createAndEditRequisites_header'>
          <h5>{t('subscriptions.requisites.modal.title_success')}</h5>
          <img ref={triggerRef} alt='close' src={CloseIcon} onClick={onClose} />
        </div>
        <div className='createAndEditRequisites_input'>
          <p>{t('subscriptions.requisites.modal.modal_text')}</p>
        </div>
        <div className='createAndEditRequisites_close'>
          <Button text={t('confirm')} color='orange' textType='small' onClick={onClose} />
        </div>
      </div>
    );
  }

  return (
    <div className='createAndEditRequisites' ref={contentRef}>
      <div className='createAndEditRequisites_header'>
        <h5>
          {editRequisite.isEdit 
            ? t('subscriptions.requisites.modal.title_edit') : t('subscriptions.requisites.modal.title_create')}
          </h5>
        <img ref={triggerRef} alt='close' src={CloseIcon} onClick={onClose} />
      </div>
      <FormProvider {...methods}>
        <div className='createAndEditRequisites_input'>
          <Input label={t('subscriptions.requisites.req_list.name')} name='name' />
          <Input label={t('subscriptions.requisites.req_list.tin')} name='tin' type='number' />
        </div>
        <div className='createAndEditRequisites_bottom'>
          <Button text={editRequisite ? t('save') : t('add')} color='orange'
            textType='small' onClick={methods.handleSubmit(onSubmit)} disabled={!methods.formState.isDirty} />
          <Button text={t('cancel')} color='white' textType='small' onClick={onClose} />
        </div>
      </FormProvider>
    </div>
  );
};

export default CreateAndEditRequisites;
