import React, { useEffect, useState } from 'react';
import './WabaEdit.scss';
import { goBack } from 'connected-react-router';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { WabaConnection } from '../../../../../../api/CompanyAPI';
import { disableWabaCatalog, updateConnection } from '../../../connectionsSlice';
import { FacebookCatalogStatus, WabaCatalogStatus } from '../../../ConnectionsTypes';
import { wabaCatalogStausIcons } from '../utils';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Switcher from '../../../../../../components/Switcher/Switcher';
import SidePanel from '../../../../../../components/Modals/SidePanel';
import WabaCatalogs from '../WabaCatalogs';
import ActionDialog from '../../../../../../components/Modals/ActionDialog';


type EditData = {
  name: string;
};

const WabaEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const allConnections = useAppSelector((state) => state.connections.connectionsStore);
  const catalogStatuses = useAppSelector((state) => state.connections.wabaCatalogsStatus);
  const [connection, setConnection] = useState<WabaConnection>();
  const [clicked, setClicked] = useState<boolean>(false);
  const [showAddCatalog, setShowAddCatalog] = useState<boolean>(false);
  const [catalogStatus, setCatalogStatus] = useState<WabaCatalogStatus>();
  const [showRequest, setShowRequest] = useState<boolean>(false);
  const methods = useForm<EditData>({ mode: 'onChange', resolver: yupResolver(yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
   })) });

  useEffect(() => {
    const itemConnection = allConnections.waba.find((item) => item.id === Number(connectionId));
    if (itemConnection) {
      setConnection(itemConnection  as WabaConnection);
      if (catalogStatuses) setCatalogStatus(catalogStatuses[itemConnection.id]);
      methods.setValue('name', itemConnection.name);
    }
  }, [allConnections, catalogStatuses]);

  const toggleCatalog = () => {
    if (catalogStatus?.status !== 'connected') {
      setShowAddCatalog(true);
    } else if (catalogStatus?.status === 'connected') {
      setShowRequest(true);
    }
  };

  const gotoBack = () => {
    dispatch(goBack());
  };

  const getIcon = (status: FacebookCatalogStatus) => {
    const Icon = wabaCatalogStausIcons[status];
    if (Icon) return <Icon />;
    return null;
  };

  const onSave = (data: EditData) => {
    if (connection) {
      setClicked(true);
      dispatch(updateConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        itemId: connection.id,
        name: data.name,
        type: connection.type,
      })).then(() => {gotoBack()});
    }
  };

  const onDisable = () => {
    if (connection) dispatch(disableWabaCatalog({
      company_id: Number(currentCompanyId?.params.companyId),
      waba_connection_id: connection.id
    }));
    setShowRequest(false);
  };

  return (
    <>
    {showRequest &&
      <ActionDialog action={t('disable')} cancel={t('cancel')} onAction={onDisable}
        setIsOpen={setShowRequest} background='light' 
        title={t('connections.waba.catalogs.disable.title')}
        message={t('connections.waba.catalogs.disable.message')} />
    }
    <GoBackWrapper title={t('edit')}>
      <div className="wabaEdit">
        {showAddCatalog && connection &&
          <SidePanel setIsOpen={setShowAddCatalog}>
            <WabaCatalogs connection={connection} setShow={setShowAddCatalog} />
          </SidePanel>
        }
        {connection && (<>
          <p>{t('connections.connection_number')}: #{connection.id}</p>
          <p>{connection.params.phone}</p>
          <FormProvider {...methods}>
            <Input type='text' label={t('connections.connection_name')} name='name' />
            <h3>{t('connections.waba.catalogs.caption')}</h3>
            <Switcher lable={t('connections.waba.catalogs.connect')}
              onToggle={toggleCatalog} toggled={catalogStatus?.status === 'connected'} />
            {connection && catalogStatus &&
              <div className={`wabaEdit__catalog ${catalogStatus.status}`}>
                {getIcon(catalogStatus.status)}
                <p>
                  {t(`connections.waba.catalogs.status.${catalogStatus.status}`)}
                  {catalogStatus.status === 'connected' && <span>:&nbsp;{catalogStatus.payload}</span>}
                </p>
              </div>
            }
            <div className={`wabaEdit__footer ${clicked ? 'clicked': ''}`}>
              <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
                onClick={methods.handleSubmit(onSave)} disabled={!methods.formState.isDirty} />
            </div>
          </FormProvider>
        </>)}
      </div>
    </GoBackWrapper>
    </>
  );
};

export default WabaEdit;
