import React, { useState } from 'react';
import './RequisitesList.scss';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store/store";
import { fetchPaymentRequisite, removePaymentRequisite, updateEditRequisite } from '../../SubscriptionsSlice';
import { PaymentRequisite } from "../../SubscriptionsAPI";
import { WrapperPermission } from "../../../../../utils/WrapperPermission/WrapperPermission";
import Button from "../../../../../components/Button/Button";
import editIcon from '../../../../../assets/edit.svg';
import deleteIcon from '../../../../../assets/deleteIcon.svg';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import { useRouteMatch } from 'react-router-dom';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';

export type DeleteParams = {
  isDelete: boolean;
  requisiteId: number | null;
};

type Props = {
  requisitesList: Array<PaymentRequisite>;
  setIsVisibleForm: React.Dispatch<React.SetStateAction<boolean>>;
};

function RequisitesList({ requisitesList, setIsVisibleForm }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const [deleteRequisite, setDeleteRequisite] = useState<DeleteParams>({ isDelete: false, requisiteId: null });

  const onDelete = (requisite_id: number) => setDeleteRequisite({ isDelete: true, requisiteId: requisite_id })

  const activeDelete = () => {
    if (deleteRequisite.requisiteId) {
      dispatch(removePaymentRequisite({
        company_id: Number(params?.params.companyId),
        requisite_id: deleteRequisite.requisiteId,
      })).then((res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(fetchPaymentRequisite({ company_id: Number(params?.params.companyId) }));
            dispatch(addAlertWithCustomText({
              message: t('subscriptions.requisites.delete.success'),
              color: '#2CBA5F'
            }));
            setDeleteRequisite({ isDelete: false, requisiteId: null })
          } else {
            dispatch(addAlertWithCustomText({
              message: t('subscriptions.requisites.delete.error'),
              color: '#F54242'
            }));
          }
        }
      );
    }
  };

  const onClose = () => setDeleteRequisite({ isDelete: false, requisiteId: null })

  const onEdit = (requisite_id: number) => {
    setIsVisibleForm(true);
    dispatch(updateEditRequisite({ isEdit: true, requisiteId: requisite_id }));
  };

  return (
    <div className='requisites__list'>
      {deleteRequisite.isDelete && (
        <ActionDialog
          title={t('subscriptions.requisites.delete.title')}
          message={t('subscriptions.requisites.delete.confirm')}
          action={t('delete')} cancel={t('cancel')}
          onAction={activeDelete} onClose={onClose}
          setIsOpen={() => {}} background="light"
        />
      )}
      <table>
        <thead>
        <tr className='requisites__list_header'>
          <th className='smallText semibold'>{t('subscriptions.requisites.req_list.name')}</th>
          <th className='smallText semibold'>{t('subscriptions.requisites.req_list.tin')}</th>
          <th className='smallText semibold'>{t('subscriptions.requisites.req_list.status')}</th>
        </tr>
        </thead>
      {requisitesList.map((item) =>
        <tr key={item.id} className='requisites__list_item'>
          <td className='requisites__list_item_text' data-title={t('subscriptions.requisites.req_list.name')}>
            {item.name}</td>
          <td className='requisites__list_item_text' data-title={t('subscriptions.requisites.req_list.tin')}>
            {item.tin}</td>
          <td className={item.approvalStatus.toLowerCase()} data-title={t('subscriptions.requisites.req_list.status')}>
            <span>{t(`subscriptions.requisites.status.${item.approvalStatus.toLowerCase()}`)}</span>
            <WrapperPermission permission='subscriptionsEdit'>
              <div className="requisites__list_buttons">
                <Button text='' color='white' textType='regular' image={editIcon} onClick={() => onEdit(item.id)} />
                <Button text='' color='white' textType='regular' image={deleteIcon} onClick={() => onDelete(item.id)} />
              </div>
            </WrapperPermission>
          </td>
        </tr>
      )}
      </table>
    </div>
  );
}

export default RequisitesList;
