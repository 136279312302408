import { memo } from 'react';
import './PeriodSelector.scss';
import { useTranslation } from 'react-i18next';
import { getMonthPeriods } from '../helper';
import { useEvent } from '../../../../../../utils/event';
import RadioButton from '../../../../../../components/RadioControl';


function PeriodSelector() {
  const { t } = useTranslation();
  const event = useEvent();

  return (
    <div className='subscriptionsInvoicePay__period'>
      <h3>{t('subscriptions.invoice.period.title')}</h3>
      {getMonthPeriods(event.active && event.discount).map((month, index) =>
        <div key={month.month} className='subscriptionsInvoicePay__period_month'>
          <RadioButton name='period_months' defaultChecked={index === 0 && true}
            label={`${month.month} ${t(month.comment)}`} value={month.month} />
          <div className='addon'>
            {month.component && month.component}
            <div className='discount smallText' hidden={index === 0 && true}>{month.discount}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(PeriodSelector);
