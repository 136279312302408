import React from 'react';
import { useTranslation } from 'react-i18next';
import { stepsAnchorsUris } from '../OnboardingPopup/stepsAnchorsUris';

const ThirdStep = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="thirdStep">
      <p className="secondStep__title">{t('onboarding.thirdStep.title')}</p>
      <p className="secondStep__description">{t('onboarding.thirdStep.description')}</p>
      <a className="secondStep__more" href={stepsAnchorsUris[3][i18n.language]} target="_blank" rel="noreferrer">
        {t('onboarding.more_anchor')}
      </a>
    </div>
  );
};

export default ThirdStep;
