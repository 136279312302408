import React from 'react';
import { BaseMessageComponent } from './types';

const MessageInteractiveType: BaseMessageComponent = ({ message }) => {
  const { wabaInteractive } = message;

  const getInteractiveAttachmentTypeElement = () => {
    switch (wabaInteractive.header.type) {
      case 'text':
        return null;
      case 'video':
        return (
          <video src={wabaInteractive.header.video.video} controls>
            <track src="#" kind="captions" />
          </video>
        );
      case 'image':
        return <img src={wabaInteractive.header.image.image} alt="attachment preview" />;
      case 'document':
        return (
          <a href={wabaInteractive.header.document.document} target="_blank" rel="noreferrer">
            {wabaInteractive.header.document.filename}
          </a>
        );
      default:
        return null;
    }
  };

  const getActionType = () => {
    if (wabaInteractive.action.buttons) {
      const { buttons } = wabaInteractive.action;
      return (
        <div className="buttonsContainer">
          {buttons.map((button) => (
            <div className="buttonsContainer__button" key={button.reply.id}>
              {button.reply.title}
            </div>
          ))}
        </div>
      );
    }

    if (wabaInteractive.action.sections) {
      const { sections } = wabaInteractive.action;
      return (
        <div className="section">
          <p className="section__title">{wabaInteractive.action.button}</p>
          {sections.map((section) => (
            <div key={section.rows.length} className="section__rowsContainer">
              {section.rows.map((row) => (
                <div className="section__rowsContainer_row" key={row.id}>
                  <p className="section__rowsContainer_row__title">{row.title}</p>
                  <p className="section__rowsContainer_row__description">{row.description}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="wabaInteractiveMessage">
      {wabaInteractive.header && (
        <div className="wabaInteractiveMessage__attachment">{getInteractiveAttachmentTypeElement()}</div>
      )}
      <div className="wabaInteractiveMessage__textSection">
        {wabaInteractive.header && wabaInteractive.header.type === 'text' && (
          <p className="wabaInteractiveMessage__textSection_attachmentTitle">{wabaInteractive.header.text}</p>
        )}
        <p className="wabaInteractiveMessage__textSection_body">{wabaInteractive.body.text}</p>
        {wabaInteractive.footer?.text && (
          <p className="wabaInteractiveMessage__textSection_footer">{wabaInteractive.footer.text}</p>
        )}
      </div>
      <div className="wabaInteractiveMessage__actionSection">{getActionType()}</div>
    </div>
  );
};

export default MessageInteractiveType;
