import { AttachmentPreviewComponent } from './types';
import AttachmentDocumentPreview from '../../AttachmentsPreviews/AttachmentDocumentPreview';
import AttachmentImagePreview from '../../AttachmentsPreviews/AttachmentImagePreview';
import AttachmentVideoPreview from '../../AttachmentsPreviews/AttachmentVideoPreview';

export const templateTypeViews: Map<string, AttachmentPreviewComponent> = new Map([
  ['document', AttachmentDocumentPreview],
  ['image', AttachmentImagePreview],
  ['video', AttachmentVideoPreview],
]);
