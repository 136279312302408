import React from 'react';
import './CompanyLogo.scss';
import { useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../../../../assets/logo-r-online.svg";
import { currentCompanySelector } from "../../../../../views/Chat/companiesSelector";


type Props = {
  isRolledUp?: boolean;
}

const CompanyLogo = ({ isRolledUp }: Props) => {
  const currentCompany = useSelector(currentCompanySelector);

  return (
    <>
      <div className='companyLogo__logo'>
        <Logo />
      </div>
      <div className={`companyLogo__id ${isRolledUp ? 'rolledUp' : ''}`}>
        ID {currentCompany.id?.toString().padStart(6, '0')}
      </div>
    </>
  );
};

export default CompanyLogo;
