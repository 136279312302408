import React, { useEffect, useRef } from 'react';
import { BaseMessageComponent } from './types';
import { ReactComponent as FileLoader } from '../../../../assets/fileLoader.svg';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';

const MessageFileType: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const fileContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (fileContainerRef.current) {
      if (handleSizeChange) {
        handleSizeChange(fileContainerRef.current.offsetHeight);
      }
    }
  }, [message.file.url]);

  return message.file.url ? (
    <div className="fileContent" ref={fileContainerRef}>
        <a href={message.file.url} target="_blank" download rel="noreferrer">
          {message.file.name}
        </a>
      <pre>
        <LinkifyText text={message.file.caption} />
      </pre>
    </div>
  ) : (
    <FileLoader width={40} height={40} />
  );
};

export default MessageFileType;
