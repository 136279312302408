import { ReactComponent as NumberOne } from '../assets/number1.svg';
import { ReactComponent as NumberTwo } from '../assets/number2.svg';
import { ReactComponent as NumberThree } from '../assets/number3.svg';
import { ReactComponent as NumberFour } from '../assets/number4.svg';

export const listOfNumbers=[
  { id:1, Component:NumberOne },
  { id:2, Component:NumberTwo },
  { id:3, Component:NumberThree },
  { id:4, Component:NumberFour },
]

