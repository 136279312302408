import { useEffect } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentCompanySelector } from "./companiesSelector";
import { clearContactsChats, updateCurrentChat, updateMessages } from "./chatsSlice";
import { fetchChatsWithContact } from "./messagesThunks";
import { getAllConnectionForChat, updateIsViewNavbarMobile } from "./companiesSlice";
import { fetchTerminals, updateIsVisibleLinkInvoice } from "./billSlice";
import { useMobile } from "../../components/utils/hooks";
import ChatPlug from "./components/ChatPlug/ChatPlug";
import ChatBill from "./components/ChatBill/ChatBill";
import Chat from "./components/Chat";
import ConversationListContainer from "./components/ConversationListContainer";
import { useHasPermission } from "../../utils/WrapperPermission/WrapperPermission";


const ChatsRoute = () => {
  const dispatch = useDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/chats');
  const currentCompany = useSelector(currentCompanySelector);
  const invoiceCreatePermission = useHasPermission("banksInvoiceCreate");
  const isMobile = useMobile();

  useEffect(
    () => () => {
      dispatch(updateCurrentChat({}));
      dispatch(updateMessages(null));
      dispatch(updateIsVisibleLinkInvoice(false));
      dispatch(updateIsViewNavbarMobile(true));
      dispatch(clearContactsChats());
    }, []);

  useEffect(() => {
    if (currentCompany) {
      const companyId = currentCompany.id;
      dispatch(getAllConnectionForChat({ companyId }));
      dispatch(fetchChatsWithContact({ companyId }));
      if (invoiceCreatePermission) dispatch(fetchTerminals(companyId));
    }
  }, [currentCompany]);

  return (
    <>
      {isMobile ? <>
        <Switch>
          <Route path={url?.url} exact>
            <ConversationListContainer />
          </Route>
          <Route path={`${url?.url}/:contactId/:chatId`} exact>
            <Chat />
          </Route>
          <Redirect to={`${url?.url}/:contactId/:chatId`}
            from={`${url?.url}/:contactId/:chatId/bill`} />
        </Switch>
      </> : <>
        <ConversationListContainer />
        <Switch>
          <Route path={`${url?.url}/:contactId/:chatId/bill`} exact>
            <ChatBill />
          </Route>
          <Route path={`${url?.url}/:contactId/:chatId`} exact>
            <Chat />
          </Route>
          <Route>
            <ChatPlug />
          </Route>
        </Switch>
      </>}
    </>
  );
};

export default ChatsRoute;
