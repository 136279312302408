import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWabaCatalogStatus } from '../../ConnectionsViews/Waba/utils';
import './CatalogStatus.scss';


function CatalogStatus () {
  const { t } = useTranslation();
  const status = useWabaCatalogStatus();

  return (
    <div className='catalogStatus'>
      {status
        ? <>
            <div className={`catalogStatus_point ${status.status}`} />
            <p className={status.status}>
              {t(`connections.waba.catalogs.status.${status.status}`)}
              {status.status === 'connected' && <span>:&nbsp;{status.payload}</span>}
            </p>
          </>
        : null
      }
    </div>
  );
}

export default CatalogStatus;
