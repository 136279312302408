import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { ReactComponent as ShowIcon } from '../../../../../../../assets/arrow-down.svg';
import { useStylesUserEdit } from '../../../utlity';

type RolesDropdownProps = {
  list: { id: number, name: string }[]
  value: number
  onChange: (value: number) => void
}
const RolesDropdown = ({ list, value, onChange }: RolesDropdownProps) => {
  const classesDropdown = useStylesUserEdit();
  return (
    <Select
      IconComponent={ShowIcon}
      displayEmpty
      value={value}
      onChange={e => {
        if (typeof e.target.value === 'number') {
          onChange(e.target.value);
        }
      }
      }
      classes={{ icon: classesDropdown.iconStyle }}
      className={classesDropdown.root}
      fullWidth
      MenuProps={{
        classes: {
          list: classesDropdown.list,
          paper: classesDropdown.menu,
        },
      }}
    >
      {list.map((item) => (
        <MenuItem
          id='ignoreClick'
          className={classesDropdown.select} key={item.id}
          value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RolesDropdown;
