import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { optionsContext } from '../../../../api';
import { MessagingOption } from '../../../../types';
import { hasFirstOptionMessaging, integrationContext } from '../../../../utils';
import OptionItem from '../OptionItem';
import './OptionList.scss';


type OptionListProps = {
  options: MessagingOption[];
};

function OptionList({ options }: OptionListProps) { 
  const { t } = useTranslation();
  const { area, service } = useContext(integrationContext);
  const { pipelines } = useContext(optionsContext);
  const hasFirstOption = area && area === 'amocrm' && service && hasFirstOptionMessaging[service];
  
  return (
    <div className='optionList'>
      <div className={`optionList__title ${ hasFirstOption ? '' : 'hide'}`}>
        {hasFirstOption && <div>{t('settings.integrations.use_by_default')}</div>}
        <div>{t(`settings.integrations.${service}.connected`)}</div>
        <div>{t('settings.integrations.options')}</div>
      </div>
      {pipelines && pipelines?.length > 0 && options?.length > 0 && options?.map((option) => (
        <OptionItem key={option.id} connection={option} />
      ))}
    </div>
  );
}

export default OptionList;
