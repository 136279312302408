import { useState } from 'react';
import './CreateNotes.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { setIsDirtyContacts } from '../../../../contactsSlice/contactsSlice';
import TextareaAutosize from 'react-textarea-autosize';
import Button from "../../../../../../components/Button/Button";
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus-edit.svg';


const CreateNotes = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <>
      {isVisible && (
        <div className="createNotes">
          <div className='createNotes__header'>
            <p className='regularText middle'>{t('contacts.editContact.noteOne')}</p>
          </div>
          <div className="createNotes__note">
            <Controller
              name="note"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextareaAutosize
                  minRows={1}
                  maxRows={22}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    dispatch(setIsDirtyContacts(true));
                  }}
                  placeholder={t('contacts.detailContact.textNote')}
                  className="createNotes__note_textarea"
                  data-testid='note'
                />
              )}
            />
          </div>
        </div>
      )}
      {!isVisible && (
        <div className="createNotes__button">
          <Button text={t('contacts.detailContact.addNote')} color='white' textType='small'
            image={<PlusIcon/>} onClick={() => setIsVisible(!isVisible)} data-testid='add-note' />
        </div>
      )}
    </>
  );
};

export default CreateNotes;
