import { useEffect, useState } from 'react';
import './AddUser.scss';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postRefreshLink } from '../../../Chat/companiesSlice';
import { RootState } from '../../../../store/store';
import { ReactComponent as CopyIcon } from '../../../../assets/copy.svg';
import reload from '../../../../assets/reload.svg';
import Button from '../../../../components/Button/Button';


const AddUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const inviteLink = useSelector((state: RootState) => state.companies.url);
  const [alert, setAlert] = useState(false);

  const refreshButton = () => {
    if (currentCompanyId?.params.companyId) {
      dispatch(postRefreshLink(Number(currentCompanyId?.params.companyId)));
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setAlert(true);
  };

  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    }
  }, [alert]);


  return (
    <div className='addUser'>
      {alert && (
        <div className='addUser__alert'>
          <div className='addUser__alert__item'
               style={{ background: '#2CBA5F' }}>
            <p>{t('settings.users.modal_text')}</p>
          </div>
        </div>
      )}
      <div className='addUser__title'>
        <h1>{t('settings.users.title')}</h1>
      </div>
      <div className='addUser__text'>
        <p>{t('settings.users.text_info')}</p>
      </div>
      <div className='addUser__content'>
        <h3>{t('settings.users.unique_link')}</h3>
        <div className='addUser__content__iventeLink'>
          <input type='text' value={inviteLink} id='linkInput' />
          <button className='addUser__content__iventeLink__reloadButton' type='button' onClick={refreshButton} >
            <img src={reload} alt='reload' />
            {t('settings.users.refresh_link')}
          </button>
        </div>
      </div>
      <Button type='button' color='orange' textType='regular' text={t('settings.users.сopy')}
        image={<CopyIcon />} onClick={onCopy} /> 
    </div>
  );
};

export default AddUser;
