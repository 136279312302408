import { useTranslation } from 'react-i18next';
import './ProfileGroup.scss';
import Avatar from '../../../../components/Avatar';
import { ReactComponent as CloseIcon } from '../../../../assets/cross.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/chat-group.svg';


type ProfileGroupProps = {
  name: string;
  avatarUrl: string;
  toggleVisibleProfile: () => void;
};

function ProfileGroup({ name, avatarUrl, toggleVisibleProfile }: ProfileGroupProps) {
  const { t } = useTranslation();

  return (
    <div className="profileGroup">
      <div className="profileGroup__header">
        <p className="regularText semibold">{t('chats.chat.profile')}</p>
        <CloseIcon onClick={toggleVisibleProfile} />
      </div>
      <Avatar image={avatarUrl} name={name} />
      <div className="profileGroup__detail">
          <p className="regularText semibold">{name}</p>
        <div className="profileGroup__detail_group">
          <div className="chatGroup__icon"><GroupIcon/></div>
          <p className="smallText">{t('chats.chat.group')}</p>
        </div>
      </div>
    </div>
  );
}

export default ProfileGroup;
