import './YookassaCreate.scss';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { setHadConnections } from '../../../../../Chat/companiesSlice';
import { fetchConnectionById } from '../../../connectionsSlice';
import { postConnectionState } from '../../../ConnectionsAPI';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import { ReactComponent as YookassaIcon } from '../../../../../../assets/icon-yookassa.svg';
import { ReactComponent as QuestionIcon } from '../../../../../../assets/question-icon.svg';


const YOOKASSA_CLIENT_ID = process.env.REACT_APP_YOOKASSA_CLIENT_ID as string;
const YOOKASSA_ORIGIN = process.env.REACT_APP_YOOKASSA_PERMISSION_ORIGIN as string;

const bdAnchorsYookassa: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii/internet-ekvairingi-bankov/podklyuchenie-magazina-v-lk/yukassa',
  en: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii/internet-ekvairingi-bankov/podklyuchenie-magazina-v-lk/yukassa',
  es: 'https://docs.radist.online/radist.online-docs/nashi-produkty/radist-web/integracii/internet-ekvairingi-bankov/podklyuchenie-magazina-v-lk/yukassa'
};

function YookassaCreate() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [popupYookassa, setPopupYookassa] = useState<Window | null>(null);

  useEffect(() => {
    const onPopupMessage = (e: MessageEvent) => {
      if (!e.origin.startsWith(YOOKASSA_ORIGIN)) {
        return null;
      }
      if (e.data.success) {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        dispatch(fetchConnectionById({ companyId: currentCompany.id, connectionId: e.data.connection_id }));
        history.push({ pathname: `${url.replace('create', 'edit')}/${e.data.connection_id}`, state: true });
      }
      return undefined;
    }

    if (popupYookassa) window.addEventListener('message', onPopupMessage, false);

    return () => window.removeEventListener('message', onPopupMessage);

  }, [popupYookassa]);

  const showAuthWindow = (stateConnection: string): void => {
    const windowHeight = 900;
    const windowWidth = 600;
    const topOffset = window.innerHeight / 2 + window.screenTop - windowHeight / 2;
    const leftOffset = window.innerWidth / 2 + window.screenLeft - windowWidth / 2;
    const popup = window.open(
     `https://yookassa.ru/oauth/v2/authorize?response_type=code&client_id=${YOOKASSA_CLIENT_ID}&state=${stateConnection}`,
     'YookassaAuth', `popup, location=0,
      width=${windowWidth}, height=${windowHeight}, top=${topOffset}, left=${leftOffset}`);
      setPopupYookassa(popup);
  };

  const authyookassa = async () => {
    const stateConnection = await postConnectionState(currentCompany.id);
    showAuthWindow(stateConnection.state);
  };

  return (
    <GoBackWrapper title={t('connections.title.yookassa')}>
      <div className='yookassaCreate'>
        <button type='button' className="yookassaCreate__auth" onClick={authyookassa}>
          <YookassaIcon />
          {t('connections.yookassa.info.auth_text')}
        </button>
        <div className="yookassaCreate__information">
          <QuestionIcon />
          <p className="regularText">
            <Trans i18nKey={`connections.yookassa.info.guide_link`}>
              link<a target="_blank" rel="noreferrer" href={bdAnchorsYookassa[i18n.language]}>link</a>text
            </Trans>
          </p>
        </div>
      </div>
    </GoBackWrapper>
  );
}

export default YookassaCreate;
