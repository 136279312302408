import { useEffect, useState } from 'react';
import './Notifications.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { useSelector } from 'react-redux';
import {
  createNotificationsChannel, deleteNotificationChannel, getNotificationsChannels, updateNotificationsChannel
} from '../../Chat/companiesSlice';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { NotificationChannel } from '../../../api/CompanyAPI';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import { notificationContext } from './types/types';
import ActionDialog from '../../../components/Modals/ActionDialog';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import NotificationsList from './components/NotificationsList';
import PlusIcon from '../../../assets/plus-edit.svg';


const notificationNumber = '+79046761146';
const maxChannel = 2;

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const company = useSelector(currentCompanySelector);
  const notificationChannels = useAppSelector(state => state.companies.notifications);
  const [channelsWhatsapp, setChannelsWhatsapp] = useState<NotificationChannel[]>([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [removeChannelId, setRemoveChannelId] = useState<number | null>(null);
  
  const emptyChannel: NotificationChannel = {
    companyId: company.id,
    id: 0,
    name: '',
    params: { events: [] },
    type: 'whatsapp',
    whatsapp: { phone: '' }
  };

  useEffect(() => {
    if (url?.params?.companyId)
      dispatch(getNotificationsChannels({ companyId: url?.params?.companyId }));
  }, [url?.params?.companyId]);

  useEffect(() => {
    setChannelsWhatsapp(
      notificationChannels?.some(c => c.type === 'whatsapp')
        ? notificationChannels.filter(c => c.type === 'whatsapp')
        : [emptyChannel]
    );
  }, [notificationChannels]);

  const onCreate = (channel: NotificationChannel) => {
    dispatch(createNotificationsChannel({
      companyId: channel.companyId,
      type: channel.type,
      whatsapp: { phone: channel.whatsapp.phone },
      params: { events: channel.params.events }
    }));
  };
  
  const deleteChannel = () => {
    if (removeChannelId && url?.params.companyId) {
      dispatch(deleteNotificationChannel({
        channelId: removeChannelId,
        companyId: url?.params.companyId
      }));
    }
    setToggleModal(false);
    setRemoveChannelId(null);
  };

  const onDelete = (channel: NotificationChannel) => {
    setRemoveChannelId(channel.id);
    setToggleModal(true);
  };

  const onEdit = (channel: NotificationChannel) => {
    dispatch(updateNotificationsChannel({
      companyId: channel.companyId,
      channelId: channel.id,
      name: channel.name,
      type: channel.type,
      whatsapp: { phone: channel.whatsapp.phone },
      params: { events: channel.params.events }
    }));
  };  

  const addChannel = () => {
    setChannelsWhatsapp(prev => [...prev, {
      companyId: company.id, id: 0, name: '', params: { events: [] }, type: 'whatsapp', whatsapp: { phone: '' } }
    ]);
  };

  const getChannelName = (channelId: number | null) => {
    if (channelId) {
      const channel = channelsWhatsapp.find(channel => channel.id === channelId);
      if (channel) return channel.name;
    };
    return '';
  };

  if (!channelsWhatsapp) {
    return <Loader />;
  }

  return (
    <div className="notifications">
      {toggleModal &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          message={t('settings.notifications.delete.message', { number: getChannelName(removeChannelId) })}
          title={t('settings.notifications.delete.title')}
          onAction={deleteChannel} setIsOpen={setToggleModal} />
      }
      <h1>{t('settings.notifications.notifications')}</h1>
      <p className='regularText'>{t('settings.notifications.phone_text', { number: notificationNumber })}</p>
      <notificationContext.Provider value={{ onCreate, onDelete, onEdit }}>
        <NotificationsList channels={channelsWhatsapp} />
      </notificationContext.Provider>
      {channelsWhatsapp && channelsWhatsapp.length > 0 && channelsWhatsapp.length < maxChannel &&
        <div className='notifications__add'>
          <WrapperPermission permission='notificationsCreate'>
            <Button type='button' color='white' textType='small' text={t('contacts.editContact.addPhoneNumber')}
              image={PlusIcon} onClick={addChannel} disabled={channelsWhatsapp.some(c => c.id === 0)} />
          </WrapperPermission>
        </div>
      }
    </div>
  );
};

export default Notifications;
