import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InteractiveMessagesIcon } from '../../../../assets/interactiveMessages-icon.svg';

const InteractiveMessagesLink = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const urlData = useRouteMatch<{ tab: string }>('/companies/:companyId/templates/:tab');
  const { t } = useTranslation();

  return (
    <div
      className={`templatesList__item ${urlData?.params.tab === 'interactive-messages' && 'active'}`}
      onClick={() => history.push(`${url}/interactive-messages`)}
    >
      <div className="templatesList__item_content">
        <InteractiveMessagesIcon />
        <p className="templatesList__item_content__name">{t('templates.interactive_messages')}</p>
      </div>
    </div>
  );
};

export default InteractiveMessagesLink;
