import { nanoid } from 'nanoid';
import React from 'react';

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>;
  attachedFile?: File;
  attachedFileUrl?: string;
  width?: number;
  height?: number;
};

const Video: React.FC<Props> = React.memo(({ videoRef, attachedFile, width, height, attachedFileUrl }) => (
  <video
    src={attachedFile ? URL.createObjectURL(attachedFile) : attachedFileUrl}
    controls={false}
    key={nanoid()}
    ref={videoRef}
    width={width}
    height={height}
  >
    <track src="#" kind="captions" />
  </video>
));

export default Video;
