import { FC, useContext, useState } from 'react';
import './ChatHeader.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../store/store';
import { updateIsPageBill } from '../../billSlice';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { useMobile } from "../../../../components/utils/hooks";
import { chatContext } from '../ChatContent/StickyViewport/Context';
import ChatHeaderModal from '../ChatHeaderModal/ChatHeaderModal';
import Button from '../../../../components/Button/Button';
import Avatar from "../../../../components/Avatar";
import { ReactComponent as ThreeDotsIcon } from '../../../../assets/threeDots.svg';
import { ReactComponent as ReceiptIcon } from '../../../../assets/receipt.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/chat-group.svg';


type TypeProps = {
  toggleVisibleProfile: () => void;
};

const ChatHeader: FC<TypeProps> = ({ toggleVisibleProfile }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
    '/companies/:companyId/chats/:contactId/:chatId');
  const { contactName, avatarUrl, isGroup, chatPhone } = useContext(chatContext);
  const [toggle, setToggle] = useState(false);
  const isMobile = useMobile();

  const toggleProfile = () => {
    toggleVisibleProfile();
    setToggle(false);
  };

  const getImage = () => {
    if (isGroup) {
      return !avatarUrl ? <GroupIcon /> : avatarUrl;
    }
    return avatarUrl ?? ''
  };

  return (
    <div className="chatHeader">
      <div className="chatHeader__content">
        <div className="chatHeader__content_userInfo">
          <div className="chatHeader__content_userInfo__avatar" onClick={toggleVisibleProfile}>
            <Avatar image={getImage()} name={contactName ?? ''} />
          </div>
          <div className="chatHeader__content_userInfo__description" onClick={toggleVisibleProfile}>
            <p className="chatHeader__content_userInfo__description_name">{contactName ?? ''}</p>
            {chatPhone && (
              <p className="chatHeader__content_userInfo__description_phone">{chatPhone}</p>
            )}
          </div>
        </div>
        {(!isMobile && !isGroup) &&
          <WrapperPermission permission="banksInvoiceCreate">
            <div className="chatHeader__content_bill">
              <Link to={`${url?.url}/bill`}>
                <Button text={!isMobile ? t('chats.bill.title_bill') : ''}
                  image={<ReceiptIcon />} color="white" textType="small"
                  onClick={() => {dispatch(updateIsPageBill(true))}} />
              </Link>
            </div>
          </WrapperPermission>}
      </div>
      <div className="chatHeader__threeDots" onClick={() => setToggle(!toggle)}>
        <ThreeDotsIcon />
      </div>
      {toggle && (
        <div className="chatHeader__wrapperModal">
          <ChatHeaderModal toggleVisibleProfile={toggleProfile} isGroup={isGroup ?? false} />
        </div>
      )}
    </div>
  );
};

export default ChatHeader;
