import React from 'react';
import './LifetimeInput.scss';
import { useFormContext } from "react-hook-form";


type Props = {
  name: string;
  label: string;
  max: number;
  value: number;
}

const LifetimeInput = ({ name, label, max, value }: Props) => {
  const { register, setValue } = useFormContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > max) {
      setValue(name, max);
    } else if (value < 0) {
      setValue(name, 0);
    }
  };

  return (
    <div className='lifespanInput'>
      <p className='regularText middle'>{label}</p>
      <input className='lifespanInput__input' {...register(name, { onChange })} type='number' min={0} max={max} defaultValue={value} />
    </div>
  );
};

export default LifetimeInput;
