import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { broadcastsSourceSelector } from '../BroadcastsSelector';
import { currentCompanySelector } from "../../Chat/companiesSelector";
import { ItemWithLogo, SourceType, addLogosToSources, isDateBefore, BroadcastsServicesType } from "../utils";
import { tarifName } from '../../../utils/dialogs';
import { getSources } from '../BroadcactsSlice';
import { addAlertWithCustomText, setAlertWithClosing } from '../../../components/Alert/alertSlice';
import { DialogsCountsType } from '../BroadcastsTypes';
import { getBroadcastDialogsCounters } from '../BroadcastsAPI';


export function useSources() {
  const dispatch = useDispatch();
  const connections = useSelector(broadcastsSourceSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const [sources, setSources] = useState<Array<ItemWithLogo<SourceType>>>();

  useEffect(() => {
    if (connections.length === 0) dispatch(getSources({ companyId: currentCompany.id }));
  }, []);

  useEffect(() => {
    const sourceConnections: SourceType[] = connections.map(item => ({
      id: item.connectionId, name: item.name, type: item.type, subscription: item.subscription }));
    setSources(addLogosToSources(sourceConnections));
  }, [connections]);

  return sources;
}

const WABA_DIALOGS_WARN_LIMIT = 0;  // minimum number of waba dialogs for set warning

export function useCheckSource(source: SourceType | null) {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const sourceRef = useRef(source);
  const [dialogs, setDialogs] = useState<DialogsCountsType[]>();
  const [enoughDialogs, setEnoughDialogs] = useState<boolean>(true);

  useLayoutEffect(() => {
    sourceRef.current = source;
  });

  useEffect(() => {
    getBroadcastDialogsCounters(company.id).then(res => setDialogs(res));
  }, []);

  const checkSource = useCallback(() => {
    const connection = sourceRef.current;

    if (connection) {
      if (connection.subscription.id === null) {
        dispatch(addAlertWithCustomText({ message: 'broadcasts.alert.no_subscription', type: 'alarm' }));
      } else if (isDateBefore(connection.subscription.paidUntil)) {
        dispatch(addAlertWithCustomText({ message: 'broadcasts.alert.unpaid_subsription', type: 'alarm' }));
      }

      let hasDialogs = true;
      if (connection.type === 'waba' 
        && (!company.waba360DialogPartnerId || tarifName(company.waba360DialogPartnerId) === 'russia')) {
        const dialog = dialogs?.find(({ type }) => type === 'WABA_DIALOGS')
        if (dialog) hasDialogs =  dialog.value > WABA_DIALOGS_WARN_LIMIT;

        if (!hasDialogs) {
         dispatch(setAlertWithClosing({ type: 'alarm', inner: 'broadcasts_waba_dialog' }));
        }
      }
      setEnoughDialogs(hasDialogs)
    }
  }, [sourceRef, dialogs]); 

  return { checkSource, enoughDialogs };
}

const servicesWithInfo = ['telegram_bot', 'waba'];

export function useShowServiceInfo(service: BroadcastsServicesType | undefined) {
  const showInfo = service ? servicesWithInfo.includes(service) : false;

  return showInfo;
}
