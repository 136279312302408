import React from 'react';
import './WhatsApp.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { ReactComponent as CopyIcon } from '../../../../../assets/copy.svg';


type Prop = {
  codeQr: string;
}

const WhatsAppCopyQr: React.FC<Prop> = ({ codeQr }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const copyCode = () => {
    navigator.clipboard.writeText(codeQr);
    dispatch(addAlertWithCustomText({ message: t('connections.whatsapp.main-view.copy_done') }))
  };

  return (
    <Button
      text={t('connections.whatsapp.main-view.copy_code')}
      textType='small'
      color='orange'
      onClick={copyCode}
      image={<CopyIcon />}
    />
  );
};

export default WhatsAppCopyQr;
