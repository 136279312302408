import { FC, useEffect, useState } from 'react';
import './UserEdit.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { RoleType, UsersType } from '../../../../api/CompanyAPI';
import {
  getConvertPermissions,
  getConvertArrStrPermissions,
  getValidatePermission,
  PermissionWithKeys, errorButtonPerm,
} from './utlity';
import { addRoleMember, changePermissionRoleMember, changeRoleMember } from '../../../Chat/companiesSlice';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { useAppDispatch } from '../../../../store/store';
import { isDifferenceArrayStr } from '../../../../utils/utils';
import PermissionsList from './components/PermissionsList/PermissionsList';
import RoleRemove from './components/PermissionRemove/RoleRemove';
import Button from '../../../../components/Button/Button';
import TrashIcon from '../../../../assets/trash.svg';
import RolesList from './components/RolesList/RolesList';


type Props = {
  user: UsersType;
  roles: RoleType[];
  isOwner: boolean;
  onCloseEditCard: () => void;
  initialStateCurrentRoleEdit: RoleType;
};

const UserEdit: FC<Props> = ({ user, roles, isOwner, onCloseEditCard, initialStateCurrentRoleEdit }) => {
  const companyId = useRouteMatch<{ companyId: string }>('/companies/:companyId')?.params.companyId;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isRoleDelete, setIsRoleDelete] = useState<number | null>(null);
  const [currentRoles, setCurrentRoles] = useState<Array<RoleType>>(roles);
  const [currentRole, setCurrentRole] = useState<RoleType | null>(initialStateCurrentRoleEdit);
  const [addedRole, setAddedRole] = useState<string>('');
  const [permissionsAll, setPermissionsAll] = useState<PermissionWithKeys>({});
  const convertArrStrPermissions = getConvertArrStrPermissions(permissionsAll);
  const isAdminOrManager = currentRole?.name === 'ADMINISTRATOR' || currentRole?.name === 'MANAGER';
  const isCreateRole = addedRole.length === 0;
  const isModeAddNewRole = currentRole?.mockId;

  const havePermissionsChanged = !!(currentRole?.permissions &&
    isDifferenceArrayStr(currentRole?.permissions,  convertArrStrPermissions))
  const haveRoleChanged = currentRole?.id !== initialStateCurrentRoleEdit?.id || addedRole.length > 0
  const isDirty = !(havePermissionsChanged || haveRoleChanged);

  const newRoleHasPermissions = !currentRoles.some(i => !!(i.mockId && convertArrStrPermissions.length > 0));
  const hasNewRole = currentRoles.some(i => i.mockId);
  const isDisabled = hasNewRole ? newRoleHasPermissions : isDirty;

  const successAlert = () => {
    dispatch(addAlertWithCustomText({
      message: t(`errors.${100000}`),
      color: '#2CBA5F',
    }));
  };

  const onSubmit = async () => {
    const permissions = convertArrStrPermissions;
    if (companyId) {
      if (isCreateRole && currentRole) {
        const payload = {
          companyId,
          name: currentRole.name,
          permissions,
          roleId: currentRole.id,
        };
        onCloseEditCard();
        if (isOwner) { // in the owner role, you can only change the permissions of other roles
          const res = await dispatch(changePermissionRoleMember(payload));
          if (res.meta.requestStatus === 'fulfilled') successAlert();
          return;
        }
        if (haveRoleChanged && !havePermissionsChanged) { // only the role has changed
         await dispatch(changeRoleMember(
            { companyId: payload.companyId, roleId: payload.roleId, memberId: user.id }));
          return;
        }
        if (!haveRoleChanged && havePermissionsChanged) { // only the permission has changed
          const res = await dispatch(changePermissionRoleMember(payload))
          if (res.meta.requestStatus === 'fulfilled') successAlert();
          return;
        }
        const res = await dispatch(changePermissionRoleMember(payload));
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(changeRoleMember({ companyId: payload.companyId, roleId: payload.roleId, memberId: user.id }));
        }
      } else {
        const res = await dispatch(addRoleMember({ companyId, name: addedRole, permissions }));
        if (res.meta.requestStatus === 'fulfilled' && res.payload && typeof res.payload !== 'number') {
          const result = await dispatch(changeRoleMember({ companyId, roleId: res.payload.id, memberId: user.id }));
          if (result.meta.requestStatus === 'fulfilled') {
            setAddedRole('');
            onCloseEditCard();
          }
        } else {
          setAddedRole(addedRole);
        }
      }
    }
  };

  const onChangePermission = (data: Record<string, Record<string, boolean>>) => {
    const keySection = Object.keys(data)[0];
    const valueSection = Object.values(data)[0];
    const namePermission = Object.keys(valueSection)[0];
    const value = Object.values(valueSection)[0];

    const resultValidated = getValidatePermission(permissionsAll, keySection, namePermission, value);
    setPermissionsAll(resultValidated);
  };

  const onHandlerDeleteRole = () => {
    if (isModeAddNewRole) {
      onCloseEditCard();
      return;
    }
    if (currentRole) {
      setIsRoleDelete(currentRole.id);
    }
  };

  useEffect(() => {
    if (currentRole && currentRole.permissions) {
      const data = getConvertPermissions(currentRole.permissions);
      setPermissionsAll(data);
    }
  }, [currentRole]);

  return (
    <div className='infoUsers__content__userEdit'>
      <RolesList currentRole={currentRole} currentRoles={currentRoles}
                 setCurrentRole={setCurrentRole} currentAddRole={addedRole}
                 setCurrentRoles={setCurrentRoles} isOwner={isOwner}
                 setCurrentAddRole={setAddedRole}
      />
      <RoleRemove isRoleDelete={isRoleDelete}
                  setIsRoleDelete={setIsRoleDelete} roles={roles}
                  onCloseEditCard={onCloseEditCard}
                  currentRole={currentRole}
                  isOwner={isOwner}
      />
      <PermissionsList
        permissions={permissionsAll}
        onChangePermission={onChangePermission}
        isAdminManager={isAdminOrManager}
      />
      <div className='infoUsers__content__userEdit__rolesButtons'>
        <div className='infoUsers__content__userEdit__rolesButtons_save'>
          <Button
            disabled={isDisabled}
            onClick={onSubmit}
            text={t('save_edit')} type='submit' color='white'
            textType='regular' />
        </div>
        {currentRole && !isAdminOrManager && !isRoleDelete && (
          <Button
            onClick={onHandlerDeleteRole}
            style={errorButtonPerm}
            image={isModeAddNewRole ? undefined : TrashIcon}
            text={isModeAddNewRole
              ? t('cancel')
              : t('settings.users.user.deleteRole')
            } type='button'
            color='white'
            textType='regular'
          />
        )
        }
      </div>
    </div>
  );
};

export default UserEdit;
