import React, { CSSProperties } from 'react';
import { MessageRequest } from '../../../../../api/types';


export type RefType = null | React.RefObject<HTMLDivElement>

export type OnChangeType = {
  type: string, target: HTMLDivElement
}

export type EventsStickyType = {
  onStuck: (target: Element) => void
  onUnstuck: (target: Element) => void
  onChange: (payload: OnChangeType) => void
}

export type SortMessagesType = {
  messages: MessageRequest[] | null
  list: Array<{
    date: string,
    messages: MessageRequest[]
  }>
}

export type StickyBoundaryProps = {
  style?: CSSProperties,
  onStuck: (target: Element) => void
  onUnstuck: (target: Element) => void
  onChange: (payload: OnChangeType) => void
}

export enum ActionStickyType {
  setContainerRef = 'set container ref',
  addStickyRef = 'add sticky ref'
}

export type InitialStateStickyType = {
  containerRef: RefType,
  stickyRefs: Map<Element | null, HTMLDivElement | null>
};

export type InitialDispatchType = {
  setContainerRef: (containerRef: RefType) => void,
  addStickyRef: (topSentinelRef: RefType,
                 bottomSentinelRef: RefType, stickyRef: HTMLDivElement) => void
}

export type InferActionTypes<T> = T extends { [key: string]: (...args: any[]) => infer U } ? U : never

const actions = {
  setContainerRef: (containerRef: RefType) => ({
    type: ActionStickyType.setContainerRef,
    containerRef,
  } as const),
  addStickyRef: (topSentinelRef: RefType, bottomSentinelRef: RefType, stickyRef: HTMLDivElement) => ({
    type: ActionStickyType.addStickyRef,
    payload: {
      topSentinelRef,
      bottomSentinelRef,
      stickyRef,
    },
  } as const)
};

export type ActionStickyTypes = InferActionTypes<typeof actions>


