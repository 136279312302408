import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WabaConnection } from "../../../../../api/CompanyAPI";
import { useAppSelector } from "../../../../../store/store";
import { currentCompanySelector } from "../../../../Chat/companiesSelector";
import { getWabaCatalogStatus } from "../../connectionsSlice";
import { FacebookCatalogStatus, WabaCatalogStatus } from "../../ConnectionsTypes";
import { connectionItemContext } from "../../utils/connections";
import { ReactComponent as DisconnectedIcon } from '../../../../../assets/folder.svg';
import { ReactComponent as AuthorizateIcon } from '../../../../../assets/person-run.svg';


export function useWabaCatalogStatus() {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const catalogStatuses = useAppSelector((state) => state.connections.wabaCatalogsStatus);
  const { connection } = useContext(connectionItemContext);
  const [catalogStatus, setCatalogStatus] = useState<WabaCatalogStatus>();

  useEffect(() => {
    if (company && connection && (connection as WabaConnection).params.facebookCatalogEnabled) {
      dispatch(getWabaCatalogStatus({ company_id: company.id, waba_connection_id: connection.id }));
    }
  }, [company, connection]);

  useEffect(() => {
    if (catalogStatuses) {
      setCatalogStatus(catalogStatuses[connection.id]);
    }
  }, [catalogStatuses])

  return catalogStatus;
}

type StatusIcons = Partial<Record<FacebookCatalogStatus,  React.FC<React.SVGProps<SVGSVGElement>>>>;

export const wabaCatalogStausIcons: StatusIcons = {
  disconnected: DisconnectedIcon,
  expired: AuthorizateIcon,
};

export type AuthData = {
  id: string;
  secret: string;
};

export const LinksWabaCatalogInstruction: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/whatsapp-business-api/dobavlenie-prilozheniya-dlya-katalogov-v-meta-for-developers',
  en: 'https://docs.radist.online/docs/our-products/whatsapp-business-api/adding-a-catalog-application-to-meta-for-developers',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp-business-api/anadir-una-aplicacion-de-catalogo-a-meta-for-developers'
};

export const LinksWabaWorkRules: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-produkty/whatsapp-business-api/pravila-raboty-s-waba',
  en: 'https://docs.radist.online/docs/our-products/whatsapp-business-api/rules-for-working-with-waba',
  es: 'https://docs.radist.online/docs/v/espanol-1/productos-1/whatsapp-business-api/normas-para-trabajar-con-waba'
}

export const LinksWabaTariff: Record<string, string> = {
  ru: 'https://docs.radist.online/radist.online-docs/nashi-uslugi/tarify#1.-tarif-s-oplatoi-v-radist.online',
  en: 'https://docs.radist.online/docs/our-services/tariffs',
  es: 'https://docs.radist.online/docs/our-services/tariffs'
}

