import React from 'react';
import loader from '../../assets/grid.svg';

const Loader = () => (
  <div className="loaderContainer">
    <img src={loader} alt="" />
  </div>
)

export default Loader;
