import { useTranslation } from 'react-i18next';
import { Named } from '../../../types';
import TerminalItem from '../TerminalItem';
import './TerminalList.scss';


type TerminalListProps = {
  terminals: Named[];
};

function TerminalList({ terminals }: TerminalListProps) {
  const { t } = useTranslation();

  return (
    <div className='terminalList'>
      {terminals && terminals.length > 0
        ? terminals.map((terminal) => <TerminalItem key={terminal.id} terminal={terminal} />)
        : <p>{t('settings.integrations.banking.noConnectedConnections')}</p>
      }
    </div>
  );
}

export default TerminalList;
