import React from 'react';
import './FirstStep.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cancelOnboarding, incrementStep } from '../onboardingSlice';
import { updateFirstLogin } from '../../../views/Chat/companiesSlice';
import { profileSettingsInfo } from '../../../views/Chat/companiesSelector';

const FirstStep = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profileSettings = useSelector(profileSettingsInfo);

  const onStartOnboarding = () => {
    dispatch(incrementStep());
  };

  const onCancelOnboarding = () => {
    dispatch(cancelOnboarding());
    if (profileSettings && profileSettings.firstLogin) {
      dispatch(updateFirstLogin());
    }
  };

  return (
    <div className="firstStep">
      <p className="firstStep__title">{t('onboarding.firstStep.title')}</p>
      <p className="firstStep__description">{t('onboarding.firstStep.description')}</p>
      <div className="firstStep__buttonsGroup">
        <button className="firstStep__buttonsGroup_beginButton" onClick={onStartOnboarding}>
          {t('onboarding.firstStep.start_button')}
        </button>
        <button className="firstStep__buttonsGroup_laterButton" onClick={onCancelOnboarding}>
          {t('onboarding.firstStep.cancel_button')}
        </button>
      </div>
    </div>
  );
};

export default FirstStep;
