import { isPossibleNumber, ParsedNumber, parseNumber } from 'libphonenumber-js';
import { RecalculatedContactsType, SelectedServiceContactType } from './types';
import { LIST_DELIMETER, regexContactsBroadcasts } from './consts';


export const trimInLines = (str: string) =>
  str.replace(regexContactsBroadcasts.linesTrimmer, '$1;$2');

export const isContactInvalid = (contact: string, selectedServiceContact: SelectedServiceContactType) => {
  let isValid = false;

  if (contact.startsWith('+')) {  // is phone
    const validatorPassed = !!contact.match(regexContactsBroadcasts.phoneValidator)
      && isPossibleNumber(parseNumber(contact) as ParsedNumber);
    isValid = validatorPassed && (selectedServiceContact === 'phone' || selectedServiceContact === 'phone_username');
  }
  else if (contact.startsWith('@')) {  // is username
    const validatorPassed = !!contact.match(regexContactsBroadcasts.telegramUsernameValidator);
    isValid = validatorPassed && (selectedServiceContact === 'username' || selectedServiceContact === 'phone_username');
  }

  return !isValid;
};

const sepateContactName = (contactLine: string) => {
  if (contactLine.includes(LIST_DELIMETER)) {
    return [
      contactLine.slice(0, contactLine.indexOf(LIST_DELIMETER)),  // left part from the first delimeter in the contact line
      contactLine.slice(contactLine.indexOf(LIST_DELIMETER) + 1).trim()  // right part w.o. spaces
    ];
  }
  return [contactLine];
};

type ValidatedContactsType = {
  valueTextarea: string;
  selectedServiceContact: SelectedServiceContactType;
};

export function CalculateValidContacts ({ valueTextarea, selectedServiceContact }: ValidatedContactsType) {
  const results: RecalculatedContactsType = {
    all: [],
    allWithoutNames: [],
    recipients: [],
    duplicates: 0,
    errors: 0,
  };

  const setContact = (str: string) => {
    const items = sepateContactName(str);
    const [contact] = items;
    const contactStr = items.join(';');
    const isDuplicateContact = results?.allWithoutNames && results.allWithoutNames.find(i => i === contact);

    if (results.allWithoutNames) {
      results.allWithoutNames.push(contact);
    }
    results.all.push(contactStr);

    const isErrorContact = isContactInvalid(contact, selectedServiceContact);
    if (isErrorContact) {
      results.errors += 1;
      return;
    }

    if (isDuplicateContact) {
      results.duplicates += 1;
      return;
    }
    results.recipients.push(contactStr);
  };

  if (valueTextarea.length > 0) {
    valueTextarea.match(regexContactsBroadcasts.returnArrStr)?.forEach(str => setContact(str));
  }

  return results;
};
