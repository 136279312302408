import React, { useEffect, useState } from 'react';
import './InteractiveMessageSettingsActions.scss';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SwitchTabs from '../../../../components/SwitchTabs/SwitchTabs';
import { interactiveMessageActions } from './consts';
import { CreateInteractiveTemplateData, CustomTemplate } from '../../../../api/types';

type Props = {
  editTemplateData?: CustomTemplate;
  currentMessageHeadingType: string;
};

const InteractiveMessageSettingsActions: React.FC<Props> = ({ editTemplateData, currentMessageHeadingType }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<CreateInteractiveTemplateData>();
  const [selectedTab, setSelectedTab] = useState<string>(editTemplateData?.params?.type || 'button');
  const ActionsContainerComponent = interactiveMessageActions[selectedTab];

  const tabs = [
    {
      tabName: t('templates.add_interactive_templates.template_actions.actions.button'),
      tabId: 'button',
    },
    {
      tabName: t('templates.add_interactive_templates.template_actions.actions.list'),
      tabId: 'list',
    },
  ];

  useEffect(() => {
    if (currentMessageHeadingType !== 'text') {
      setSelectedTab('button');
    }
  }, [currentMessageHeadingType]);

  const onSelectHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = e.currentTarget.id;
    setSelectedTab(selectedId);
    setValue('waba_interactive.type', selectedId as 'button' | 'list');
  };

  const getTabsByCurrentMessageHeadingType = () => {
    switch (currentMessageHeadingType) {
      case 'text':
        return tabs;
      case 'withoutAttachment':
        return tabs;
      default:
        return [tabs[0]];
    }
  };

  return (
    <div className="interactiveMessageSettingsActions">
      <div className="interactiveMessageSettingsActions__header">
        <p>{t('templates.add_interactive_templates.template_actions.title')}</p>
        <SwitchTabs
          tabs={getTabsByCurrentMessageHeadingType()}
          onSelectHandler={onSelectHandler}
          selectedTab={selectedTab}
        />
      </div>
      <div className="interactiveMessageSettingsActions__actionsContainer">
        <ActionsContainerComponent />
      </div>
    </div>
  );
};

export default InteractiveMessageSettingsActions;
