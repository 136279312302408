import { AllTypesConnectedType } from '../../../api/CompanyAPI';
import { ReactComponent as WhatsappIcon } from '../../../assets/logo-whatsapp.svg'
import { ReactComponent as WabaIcon } from '../../../assets/logo-whatsapp-buiness.svg';
import { ReactComponent as TelegramIcon } from '../../../assets/logo-telegram.svg';
import { ReactComponent as TelegramBotIcon } from '../../../assets/logo-telegram-bot.svg';


type ListConnections = 'whatsapp' | 'telegram' | 'waba' | 'telegram_bot';

type RequiredTypesForLogoChat = Extract<AllTypesConnectedType, ListConnections>;
type LogoChatElements = Record<RequiredTypesForLogoChat, JSX.Element>;

export const listLogosChatsContact: LogoChatElements = {
  whatsapp: <WhatsappIcon />,
  waba: <WabaIcon />,
  telegram: <TelegramIcon />,
  telegram_bot: <TelegramBotIcon />
};
