import { YMInitializer } from 'react-yandex-metrika';
import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store/store';
import { useQuery } from '../../components/utils/hooks';
import { companiesSelector,currentCompanySelector, invitationCompanyNameSelector, profileSettingsInfo } from '../../views/Chat/companiesSelector';
import { fetchCompanyName, fetchInitialData } from '../../views/Chat/companiesSlice';
import { updateIsActionBill } from '../../views/Chat/billSlice';
import { YandexMetrikaCall } from '../../utils/utils';
import moment from 'moment-timezone';
import CompaniesRoute from '../../routes/CompaniesRoute';
import SocketIOProvider from '../../views/Chat/utils/soketio/socketIoContext';
import Registration from '../Registration/Registration';
import Error404 from '../Error404/Error404';
import loader from '../../assets/grid.svg';


const yandexId = Number(process.env.REACT_APP_YANDEX_METRIKA_ID);

const Home = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const companies = useSelector(companiesSelector);
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const history = useHistory();
  const { i18n } = useTranslation();
  const infoProfile = useSelector(profileSettingsInfo);
  const currentCompany = useSelector(currentCompanySelector);
  const invitationCompanyName = useSelector(invitationCompanyNameSelector);
  const code = query.get('code');

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(fetchInitialData(Number(params.params.companyId)));
    } else {
      dispatch(fetchInitialData(undefined));
    }
  }, []);

  useEffect(() => {
    if (currentCompany) {
      moment.tz.setDefault(currentCompany.timezone);
    } else moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [currentCompany]);

  useEffect(() => {
    if (infoProfile) {
      i18n.changeLanguage(infoProfile.language);
      if (infoProfile.firstLogin) {
        YandexMetrikaCall('reachGoal', 'registration_completed');
      }
    }
  }, [infoProfile]);

  useEffect(() => {
    if (history.location.pathname.endsWith('bill')) {
      dispatch(updateIsActionBill(true));
    } else dispatch(updateIsActionBill(false));
  }, [history.location]);

  useEffect(() => {
    if (code) {
      dispatch(fetchCompanyName(code)).then(res => {
        if (res.meta.requestStatus === 'rejected') {
          dispatch(push('/404'));
        }
      });
    }
  }, [code]);

  if (companies === null) {
    return (
      <div className='loaderContainer'>
        <img src={loader} alt='loader' />
      </div>
    );
  }

  return (
    <>
      {yandexId > 0 && <YMInitializer accounts={[yandexId]}
        options={{ clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true, trackHash: true }}
        version="2" />}
      <SocketIOProvider>
        <Switch>
          {companies.length > 0 &&
            <Route path="/companies">
              <CompaniesRoute />
            </Route>
          }
          <Route path="/registration">
            <Registration invitationCompanyName={invitationCompanyName} />
          </Route>
          <Route path="/404">
            <Error404 />
          </Route>
          <Route path="/invite">
            {invitationCompanyName && code &&
              <Redirect to={`/registration/invitation/${code}`} />
            }
          </Route>
          {companies.length > 0
            ? <Redirect exact from="/" to={`/companies/${companies[0].id}`} />
            : <Redirect to="/registration" />
          }
          <Redirect to="/404" />
        </Switch>
      </SocketIOProvider>
    </>
  );
};

export default Home;
