import './App.scss';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { AuthClientTokens } from '@react-keycloak/core/lib/types';
import { Provider } from 'react-redux';
import keycloak from '../keycloak';
import axiosInstance from '../api/AxiosInstance';
import chatStore from '../store/store';
import AppRouter from '../routes/AppRouter';

const App = () => {
  const tokenLogger = (tokens: AuthClientTokens) => {
    axiosInstance.defaults.headers.authorization = `Bearer ${tokens.token}`;
  };

  return (
    <div className="app">
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={tokenLogger}>
        <Provider store={chatStore}>
          <AppRouter />
        </Provider>
      </ReactKeycloakProvider>
    </div>
  );
};

export default App;
