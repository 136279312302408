import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useOutsideClick } from '../../../../../components/utils/hooks';
import { useTags } from '../../../api';
import { IItemsApi } from '../../../api/types';
import { ConditionType, ICondition } from '../../../BroadcastsTypes';
import './ChooseModal.scss';


type ChooseModalProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addCondition?: (condition: ICondition) => void;
  conditionType : ConditionType;
};

const itemsApi: Record<ConditionType, () => IItemsApi> = {
  tag: useTags,
};

function ChooseModal({ setIsOpen, addCondition, conditionType }: ChooseModalProps) {
  const { t } = useTranslation();
  const { items, hasMore, onChoose, onUpload } = itemsApi[conditionType]();
  const contentRef = useRef<HTMLDivElement>(null);
  const [match, setMatch] = useState<boolean>(true);

  useOutsideClick(contentRef, () => setIsOpen(false));

  const onConditionSelet = (id: number) => {
    if (addCondition) {
      const condition: ICondition = { conditionType, comparison: match ? '=' : '!=', value: id };
      addCondition(condition);
      setIsOpen(false);
      onChoose(id);
    }
  };

  return (
    <div className='chooseModal' ref={contentRef}>
      <div className='chooseModal__match'>
        <h5>{t('broadcasts.form.tag')}</h5>
        <ul>
          <li className={`${match ? 'match' : ''}`} onClick={() => setMatch(true)}>
            {t('broadcasts.form.condition_equal')}
          </li>
          <li className={`${!match ? 'match' : ''}`} onClick={() => setMatch(false)}>
            {t('broadcasts.form.condition_noequal')}
          </li>
        </ul>
      </div>
      <InfiniteScroll
        next={onUpload}
        hasMore={hasMore}
        inverse={false}
        scrollableTarget='scrollUl'
        loader={null}
        dataLength={items.length}
      >
        <ul className='chooseModal__list' id='scrollUl'>
          {items && items.map((item) => <li key={item.id} onClick={() => onConditionSelet(item.id)}>{item.name}</li>)}
        </ul>
      </InfiniteScroll>
    </div>
  );
}

export default ChooseModal;
