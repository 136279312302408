import React, { useEffect, useState } from 'react';
import './BroadcastPreview.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg';
import { ReactComponent as WrenchIcon } from '../../../../assets/wrench.svg';
import { ReactComponent as DownloadIcon } from '../../../../assets/file-arrow-down.svg';
import {
  BroadcastStatusAliasType, BroadcastsServicesType, broadcastStatusAlias,
  dateTimeFormat, checkIConditions, broadcastErrorAlias
} from '../../utils';
import Button from '../../../../components/Button/Button';
import GoBackWrapper from '../../../../components/GoBackWrapper';
import StatisticPreview from './StatisticPreview';
import ItemConnection from '../ItemConnection';
import TitleComponent from './TitleComponent';
import ConditionsForm from '../ConditionsForm/ConditionsForm';
import loader from '../../../../assets/grid.svg';
import { broadcastsSourceSelector, broadcastsStatusSelector } from '../../BroadcastsSelector';
import { useBroadcastItem, useExportStatistic } from '../../api';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { BaseMessageComponent } from "../MessageTypes/types";
import MessageTextType from '../MessageTypes/MessageTextType';
import MessageFileType from "../MessageTypes/MessageFileType";
import MessageImageType from "../MessageTypes/MessageImageType";
import MessageVideoType from "../MessageTypes/MessageVideoType";
import MessageWabaType from "../MessageTypes/MessageWabaType";


const arrStatistic: Record<BroadcastStatusAliasType, string[]> = {
  planned: ['recipients'],
  deliver: ['recipients'],
  finish: ['recipients', 'delivered', 'read'],
  error: ['recipients'],
};

const messageTypeComponents: Map<string | undefined, BaseMessageComponent> = new Map([
  ['text', MessageTextType],
  ['image', MessageImageType],
  ['file', MessageFileType],
  ['video', MessageVideoType],
  ['waba_template', MessageWabaType],
]);

const BroadcastPreview = () => {
  const { t, i18n } = useTranslation();
  const url = useRouteMatch<{ companyId: string; broadcastId: string }>('/companies/:companyId/broadcasts/:broadcastId');
  const history = useHistory();
  const [status, setStatus] = useState<BroadcastStatusAliasType>('planned');
  const broadcastItem = useBroadcastItem(Number(url?.params.broadcastId));
  const broadcastLoader = useSelector(broadcastsStatusSelector);
  const connections = useSelector(broadcastsSourceSelector);
  const connection = connections.find(con => con.connectionId === broadcastItem?.connectionId);
  const ComponentType: BaseMessageComponent | undefined = messageTypeComponents.get(broadcastItem?.message.messageType);
  const { download, ref, file, fileName } = useExportStatistic(
    { companyId: url?.params.companyId, broadcastId: broadcastItem?.id, name: broadcastItem?.name });

  useEffect(() => {
    if (broadcastItem) setStatus(broadcastStatusAlias[broadcastItem.status]);
  }, [broadcastItem]);

  const onEdit = () => {
    history.push(`/companies/${url?.params.companyId}/broadcasts/edit/${url?.params.broadcastId}`);
  };

  const onCopy = () => {
    history.push({
      pathname: `/companies/${url?.params.companyId}/broadcasts/create`,
      state: broadcastItem?.id
    });
  };
  
  const onExportStatistic = () => download();

  if (broadcastLoader === 'load' || !broadcastItem 
    || (broadcastItem && broadcastItem.id !== Number(url?.params.broadcastId ))) {
    return (
      <div className="broadcastsPreview">
        <div className="loaderContainer">
          <img src={loader} alt="loader" />
        </div>
      </div>
    );
  }

  const getTitleConditions = () => {
    if(checkIConditions(broadcastItem.params.conditions)){
      if(broadcastItem.params.conditions.conditions.length === 0){
        return `${t('broadcasts.params.recipients')}: ${t('broadcasts.form.all')}`;
      } return `${t('broadcasts.params.recipients')}:${t('broadcasts.form.contacts_radist')}`;
    }
    return t('broadcasts.params.recipients');
  }

  return (
    <div className="broadcastsPreview">
      <GoBackWrapper title={broadcastItem.name}>
        <div className="broadcastsPreview__statistic">
          {arrStatistic[status].map((statistic) => (
            <StatisticPreview key={statistic} statistic={statistic} arrStatistics={broadcastItem.statistics} />
          ))}
        </div>
        <div className="broadcastsPreview__settings">
          <div className="broadcastsPreview__settings_header">
            <h5>
              <WrenchIcon /> {t('broadcasts.preview.settings_applied')}
            </h5>
            {status === 'planned' && (
              <WrapperPermission permission='broadcastsEdit'>
                <Button color="white" textType="small" image={<EditIcon />} text={t('edit')} onClick={onEdit} />
              </WrapperPermission>
            )}
          </div>
          <div className="broadcastsInfo">
            {status === 'error' && <div className="errorSubscriptions">{t(`broadcasts.error.${broadcastErrorAlias[broadcastItem.errorType]}`)}</div>}
            <div className="broadcastsInfo__right">
              <div className={`broadcastsInfo__status ${status}`}>{t(`broadcasts.status.${status}`)}</div>
              {status !== 'planned' && (
                <>
                <Button color="white" textType="small" text={t('broadcasts.preview.save_declaration')}
                  image={<DownloadIcon />} onClick={onExportStatistic}  />
                <a ref={ref} className='hidden' href={file} download={fileName}>statistic</a>
                </>
              )}
            </div>
            <TitleComponent title={t('broadcasts.preview.starting')}>
              <p>{dateTimeFormat(broadcastItem.plannedTime!, 'shortDate', i18n.language)}, {dateTimeFormat(broadcastItem.plannedTime!, 'shortTime', i18n.language)}</p>
            </TitleComponent>
            <TitleComponent title={t('broadcasts.preview.number_broadcast')}>
              <ItemConnection service={connection?.type as BroadcastsServicesType} name={connection?.name} />
            </TitleComponent>
            <TitleComponent title={t('broadcasts.preview.text_broadcast')}>
              {ComponentType &&
                <ComponentType displayMessage={broadcastItem.displayMessage} message={broadcastItem.message}/>}
            </TitleComponent>
            {broadcastItem.params.conditions &&
              <TitleComponent
                title={getTitleConditions()}>
                {checkIConditions(broadcastItem.params.conditions)
                  ? <ConditionsForm
                      conditions={broadcastItem.params.conditions.conditions}
                      operation={broadcastItem.params.conditions.logicOperation}
                      viewOnly />
                  : <textarea readOnly={true} wrap='hard' value={broadcastItem.params.conditions.chats.join('\n')} />
                }
              </TitleComponent>}
            {(status === 'finish' || status === 'error') && (
              <WrapperPermission permission='broadcastsCreate'>
                <Button color="white" textType="small" text={t('broadcasts.preview.create_copy')} onClick={onCopy} />
              </WrapperPermission>
            )}
          </div>
        </div>
      </GoBackWrapper>
    </div>
  );
};

export default BroadcastPreview;
