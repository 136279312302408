import React from 'react';
import './ProfileInvoiceItem.scss';
import { Invoice, InvoiceItem } from '../../../../../../api/BillAPI';
import { getSymbolCurrency } from '../../OrderItem/helpers';

type Props = {
  product: InvoiceItem,
  forCurrency: Invoice
}

const ProfileInvoiceItem: React.FC<Props> = ({ product, forCurrency }) => (
  <div className='profileInvoiceItem'>
    <div className='profileInvoiceItem_name'>{product.name}</div>
    <div className='profileInvoiceItem_count'>{product.quantity}</div>
    <div className='profileInvoiceItem_price'>{product.price} {getSymbolCurrency(forCurrency)}</div>
  </div>
);

export default ProfileInvoiceItem;
