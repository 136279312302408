import { useTranslation } from 'react-i18next';
import {
  CountryCode, isSupportedCountry, parsePhoneNumberFromString, validatePhoneNumberLength,
  ValidatePhoneNumberLengthResult
} from 'libphonenumber-js';
import es from 'react-phone-number-input/locale/es.json';
import ru from 'react-phone-number-input/locale/ru.json';
import * as yup from 'yup';


export function getLabelsLocalization(locale: string) {
  switch (locale) {
    case 'ru':
      return ru;
    case 'es':
      return es;
    default:
      return undefined;
  }
}

export function getDefaultCountry(locale: string) {
  switch (locale) {
    case 'ru':
      return 'ru';
    case 'es':
      return 'es';
    case 'en':
      return 'gb';
    default:
      return 'us';
  }
}

export function getCodeCountry(locale: string) {
  const country = getDefaultCountry(locale);
  if (isSupportedCountry(country.toUpperCase() as CountryCode)) return country.toUpperCase() as CountryCode;
  return 'RU';
}

const phoneInvalidMessages: Record<ValidatePhoneNumberLengthResult, string> = {
  INVALID_COUNTRY: 'error.form.phone_field.invalid_country',
  INVALID_LENGTH: 'error.form.phone_field.invalid_length',
  NOT_A_NUMBER: 'error.form.phone_field.not_a_number',
  TOO_LONG: 'error.form.phone_field.too_long',
  TOO_SHORT: 'error.form.phone_field.too_short'
};

const nonGeographicCodes = ['800', '808', '870', ' 878', '881', '882', '883', '888', '979'];

export const validatePhone = (value: string | undefined, nonGeo: boolean, country?: CountryCode) => {
  let message = 'error.form.phone_field.invalid_phone';
  if (value && value?.length > 0) {
    const phone = parsePhoneNumberFromString(value);
    if (nonGeo && phone && phone.isNonGeographic() && nonGeographicCodes.includes(phone.countryCallingCode)) {
      return true;
    }
    const validLengthResult = validatePhoneNumberLength(value, country);
    if (validLengthResult) message = phoneInvalidMessages[validLengthResult];
    return phone?.isPossible() || message;
  }
  return true;
};

type PhoneShemaType = {
  fieldName?: string;
  required?: boolean;
  nonGeo?: boolean;  // allow non geographic numbers as valid
};

export const usePhoneSchema = ({ fieldName = 'phone', required = false, nonGeo = false }: PhoneShemaType) => {
  const { t } = useTranslation();

  return {
    [fieldName]: yup.string().when({
      is: () => required,
      then: yup.string().required(t('error.form.empty_field'))
    }).test({
      name: 'invalid_phone',
      test: (value, { createError } ) => {
        const isValid = validatePhone(value, nonGeo);
        return (typeof isValid === 'string') ? createError({ message: t(isValid) }) : isValid;
      }
    })
  };
};
