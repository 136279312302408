import React from 'react';
import { ConnectionsType } from '../ConnectionsTypes';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIsConnectMaxLimit } from '../../../../utils';
import { profileSettingsInfo } from '../../../Chat/companiesSelector';
import { ConnectionServiceType, settingConnections } from '../utils/connections';
import { useHasPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import NoConnectionsVideo from './NoConnectionsVideo';
import NoConnectionsIcon from './NoConnectionsIcon';


type Props = {
  service: ConnectionsType;
};

type NoConnectionsFormType = {
  service: ConnectionsType;
  connection: ConnectionServiceType;
  onAddConnect: () => void;
  video: string;
};

const ListNoConnections: Record<ConnectionsType, React.FunctionComponent<NoConnectionsFormType>> = {
  waba: NoConnectionsVideo,
  whatsapp: NoConnectionsIcon,
  telegram: NoConnectionsIcon,
  telegram_bot: NoConnectionsIcon,
  odnoklassniki: NoConnectionsIcon,
  bepaid: NoConnectionsIcon,
  tinkoff: NoConnectionsIcon,
  sberbank: NoConnectionsIcon,
  modulbank: NoConnectionsIcon,
  paykeeper: NoConnectionsIcon,
  amocrm: NoConnectionsIcon,
  bitrix: NoConnectionsIcon,
  paypal: NoConnectionsIcon,
  lifepay: NoConnectionsIcon,
  chatapi_instagram: NoConnectionsIcon,
  yclients: NoConnectionsIcon,
  yookassa: NoConnectionsIcon
};

export const NoConnections = ({ service }: Props) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const isMaxLimit = useIsConnectMaxLimit(service);
  const infoProfile = useSelector(profileSettingsInfo);
  const connection = settingConnections[service];
  const isCreateConnection = useHasPermission('connectionsCreate');

  const videoList: Record<string, string> = {
    waba: 'https://radist.cdn.radist.online/static/video/j2.mp4'
  }

  const onAddConnect = () => {
    if (!isMaxLimit()) dispatch(push(`${url}/create`));
  };

  const NoConnectionsComponent = ListNoConnections[service];

  return connection ? (
    isCreateConnection && infoProfile.language === 'ru' ? (
      <NoConnectionsComponent connection={connection} service={service} onAddConnect={onAddConnect}
        video={videoList[service]} />
    ) : (
      <NoConnectionsIcon connection={connection} service={service} onAddConnect={onAddConnect} />)
  ) : <></>;
};
