import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { integrationContext } from '../../utils';
import { Named } from '../../types';
import Button from '../../../../../components/Button/Button';
import Select from '../../../../../components/Select';
import PlusIcon from '../../../../../assets/plus-edit.svg';
import './ConnectionAdd.scss';


type ConnectionAddProps = {
  addons: Named[];
  onInsert: (item: Named) => void;
  caption?: string;
};

function ConnectionAdd({ addons, onInsert, caption }: ConnectionAddProps) {
  const { t } = useTranslation();
  const { service } = useContext(integrationContext);
  const [showAdd, setShowAdd] = useState(false);
  const [selected, setSelected] = useState<Named>();

  const onSelectAddon = (addon: Named) => {
    setSelected(addons.find((item) => item.id === addon.id));
  };

  const onAdd = () => {
    if (selected) {
      onInsert(selected);
      setSelected(undefined);
      setShowAdd(false);
    }
  };

  return (
    <div className='connectionAdd'>
      {showAdd ? (
        <div className='connectionAdd__select'>
          <Select labelKey='name' items={addons} caption={caption} onChoose={onSelectAddon}
            value={selected?.name || ''}
            placeholder={addons?.length === 0
              ? t('settings.integrations.noConnectionAvailable')
              : t(`settings.integrations.${service}.select`)
            }
            disabled={addons?.length === 0} />
          <Button type='button' color='orange' textType='regular' text={t('add')}
            onClick={onAdd} disabled={addons?.length === 0} />
        </div>
      ) : (
        <Button type='button' image={PlusIcon} color='white' textType='small'
          text={t(`settings.integrations.${service}.add`)} onClick={() => setShowAdd(!showAdd)} />
      )}
    </div>
  );
}

export default ConnectionAdd;
