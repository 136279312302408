import { createContext } from 'react';
import { NotificationChannel } from '../../../../api/CompanyAPI';

export type ChannelSchema = {
  phone: string;
  events: {
    integration: boolean;
    payment: boolean;
    broadcast: boolean;
  };
};

export type NotificationContext = {
  onCreate?: (channel: NotificationChannel) => void;
  onDelete?: (channel: NotificationChannel) => void;
  onEdit?: (channel: NotificationChannel) => void;
};

export const notificationContext = createContext<NotificationContext>({});
