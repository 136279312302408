import React from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from "../../../assets/cancel.svg";
import { OverlayBackgroundType } from '../types';
import './SidePanel.scss';


type SidePanelProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
  children?: React.ReactNode;
};

const SidePanel = ({ setIsOpen, background='none', children }: SidePanelProps) => {
  const onClose = () => {
    setIsOpen(false);
  };

  return ReactDOM.createPortal(
    <>
    <div className={`sidePanel__wrapper ${background}`} onClick={onClose}>{}</div>
    <div className="sidePanel">
      <img alt="close" src={CloseIcon} onClick={onClose} />
      <div className="sidePanel__content">{children}</div>
    </div>
    </>, document.body
  );
}

export default SidePanel;
