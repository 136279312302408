import React from 'react';
import { useTranslation } from 'react-i18next';
import CatalogsList from '../CatalogsList';
import UserCatalog from '../../models/UserCatalog';
import './CatalogsView.scss';
import Button from '../../../../../components/Button/Button';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';


type CatalogsViewProps = {
  catalogs: UserCatalog[];
  createCatalog: () => void;
};

const CatalogsView = ({ catalogs, createCatalog }: CatalogsViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="catalogsView">
    <div className="catalogsView__header">
      <h1>{t('sales.catalogs.title')}</h1>
      <WrapperPermission permission='salesCatalogCreate'>
        <Button
          color='orange'
          textType='regular'
          text={t('sales.catalogs.create_catalog')}
          onClick={createCatalog}
        />
      </WrapperPermission>
    </div>
    <div className="catalogsView__list"><CatalogsList catalogs={catalogs} /></div>
  </div>
  );
};

export default CatalogsView;
