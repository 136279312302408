import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutsideClick } from '../../../../../components/utils/hooks';
import { conditionsLogicOperations, ConditionsLogicOperations } from '../../../BroadcastsTypes';
import './SelectModal.scss';


type SelectModalProps = {
  selected: ConditionsLogicOperations;
  onSelect: (item: ConditionsLogicOperations) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function SelectModal({ selected, onSelect, setIsOpen }: SelectModalProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div className='selectModal' ref={ref}>
      <ul>
        {conditionsLogicOperations.map((item) => 
          <li key={item} className={`${item === selected ? 'selected' : null}`} onClick={() => onSelect(item)}>
            {t(`broadcasts.form.condition_${item}`)}</li>)}
      </ul>
    </div>
  );
}

export default SelectModal;
