import { createContext } from "react";
import UserSubscription from "./models/UserSubscription";


export type SelectContext = {
  checkSelected: (id?: number) => void;
  setPay?: () => void;
};

export const selectContext = createContext<SelectContext>({ checkSelected: () => { } });

export type SubscriptionCardAddonType = { subscription: UserSubscription; };
