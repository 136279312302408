import React, { useRef, useState } from 'react';
import './DropdownWithCheckbox.scss';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { useOutsideClick } from '../utils/hooks';


type PropsType<T> = {
  name: string;
  label: string;
  listArray: Array<T>,
};

type Item = {
  id: number;
  name: string;
  backgroundColor?: string;
}

export const DropdownWithCheckbox = <T extends Item>({ name, label, listArray }: PropsType<T>) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(contentRef, () => setIsOpen(false), triggerRef);

  const getPlaceholder = () => {
    const filterArray = listArray.filter(item => selectedItems.includes(item.id));

    if (filterArray.length > 0) {
      return filterArray.map((item) => (
        <div key={item.id} className="tagPreview" style={{ backgroundColor: item.backgroundColor ? item.backgroundColor : '#FFFFFF' }}>
          {item.name}
          <CloseIcon className="close" onClick={(e) => onDelete(e, item)} />
        </div>
      ));
    }
    return t('select');
  };

  const onSelect = (e: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>, item: Item) => {
    e.stopPropagation();
    if (selectedItems.includes(item.id)) {
      const index = selectedItems.findIndex((value) => value === item.id);
      selectedItems.splice(index, 1);
      setSelectedItems((prevState) => {
        return [...prevState];
      });
      setValue(name, selectedItems);
    } else {
      selectedItems.push(item.id);
      setSelectedItems((prevState) => {
        return [...prevState];
      });
      setValue(name, selectedItems);
    }
  };

  const onDelete = (e: React.MouseEvent<SVGSVGElement>, item: Item) => {
    e.stopPropagation();
    const index = selectedItems.findIndex((value) => value === item.id);
    selectedItems.splice(index, 1);
    setSelectedItems((prevState) => {
      return [...prevState];
    });
    setValue(name, selectedItems);
  };

  const getItem = (item: Item) => {
    if (item.backgroundColor) {
      return <div className="tag" style={{backgroundColor: item.backgroundColor}}>
        {item.name}
      </div>
    } else {
      return <div className="dropdownWithCheckbox__content_item">
        {item.name}
      </div>
    }
  }

  return (
    <div className="dropdownWithCheckbox">
      <p className="dropdownWithCheckbox_label">{label}</p>
      <div className="dropdownWithCheckbox__dropdown" onClick={() => setIsOpen(!isOpen)} ref={triggerRef}>
        <div className="previewTags">{getPlaceholder()}</div>
        <ArrowIcon className={`arrow ${isOpen ? 'open' : ''}`} />
        {isOpen && (
          <div className="dropdownWithCheckbox__content" ref={contentRef}>
            {listArray.map((item) => (
              <div key={item.id} className={`dropTag ${selectedItems.includes(item.id) ? 'active' : ''}`}
                   onClick={(e) => onSelect(e, item)}>
                <label className="checkbox">
                  <input type="checkbox" checked={selectedItems.includes(item.id)} onChange={(e) => onSelect(e, item)} />
                  <span className="checkmark" />
                </label>
                {getItem(item)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownWithCheckbox;
