import { useEffect, useState } from 'react';
import './TinkoffEdit.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useParams, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { TinkoffConnection } from '../../../../../../api/CompanyAPI';
import { RuTaxation } from '../../../ConnectionsTypes';
import { updateConnection } from '../../../connectionsSlice';
import { MAX_LIFETIME, MIN_LIFETIME, taxationTypes } from "../../../utils/terminals";
import { transformEmptyStringToUndefined } from '../../../utils/connections';
import Input from '../../../../../../components/Input/Input';
import Dropdown from '../../../../Dropdown/Dropdown';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Button from '../../../../../../components/Button/Button';
import LifetimePayment from "../../../components/LifetimePayment";


export type OnSubmitSchema = {
  name: string;
  params?: {
    taxation: RuTaxation;
    terminal_password: string;
    invoice_lifetime_minutes: number;
  };
};

const TinkoffEdit = () => {
  const { t } = useTranslation();
  const dispatch =  useAppDispatch();
  const { connectionId } = useParams<{ connectionId: string }>();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.tinkoff);
  const [connection, setConnection] = useState<TinkoffConnection>();

  const schema = yup.object({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object({
        terminal_password: yup.string().max(128, t('error.form.max_length', { limit: 128 }))
          .transform(transformEmptyStringToUndefined),
        taxation: yup.string(),
        invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
          .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
      })
      .transform((value) => {
        if (value && Object.values(value).some((v) => v !== '')) {
          return value;
        }
        return undefined;
      })
      .default(undefined),
  });

  const methods = useForm<OnSubmitSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        taxation: connection?.params.taxation as RuTaxation,
        terminal_password: '',
        invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes,
      }
    }
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as TinkoffConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect.name,
        params: {
          taxation: connect.params.taxation as RuTaxation,
          invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes
        }
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<OnSubmitSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }
    if (currentCompanyId?.params.companyId) {
      dispatch(updateConnection({
          companyId: Number(currentCompanyId?.params.companyId),
          itemId: Number(connectionId),
          name: data.name,
          type: 'tinkoff',
          ...(data.params ? { params: { ...data.params } } : {}),
        })
      ).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/tinkoff`));
        }
      });
    }
  };

  return connection ? (
    <GoBackWrapper title={t('edit')}>
      <div className="tinkoffEditPage">
        <p className="tinkoffEditPage__connectionNumber">{t('connections.connection_number')}: #{connection.id}</p>
        <p className="tinkoffEditPage__terminalKey">Terminal key: {connection.params.terminalId}</p>
        <FormProvider {...methods}>
          <div className="tinkoffEditPage__form">
            <Input label={t('connections.connection_name')} name="name" />
            <Input label="Обновить Terminal Secret" name="params.terminal_password" inputType="password" />
            <Dropdown
              items={taxationTypes}
              label="Система налогообложения"
              name="params.taxation"
              labelKey="option"
              valueKey="id"
              style={{ width: '400px' }}
            />
            <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
          </div>
        </FormProvider>
        <Button type="submit" color='orange' textType='regular' text={t('save_edit')}
          onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
      </div>
    </GoBackWrapper>
  ) : null;
};

export default TinkoffEdit;
