import "./NotificationsList.scss";
import { NotificationChannel } from "../../../../../api/CompanyAPI";
import NotificationCard from '../NotificationCard';


type NotificationsListProps = {
  channels: NotificationChannel[];
};


const NotificationsList = ({ channels }: NotificationsListProps) => (
  <div className='NotificationsList'>
    {channels.map(channel => <NotificationCard key={channel.id} channel={channel} />)}
  </div>
);

export default NotificationsList;
