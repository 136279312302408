import React from 'react';
import { ReactComponent as AttachmentIcon } from '../../../../../assets/attachment-icon.svg';


type Props = {
  onAttachmentFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileIconComponent = ({ onAttachmentFile }: Props) => (
  <>
    <input
      className="inputFile"
      type="file"
      id="file-attachment"
      onChange={onAttachmentFile}
      onKeyDown={(event) => event.preventDefault()}
    />
    <label htmlFor="file-attachment">
      <AttachmentIcon />
    </label>
  </>
);

export default FileIconComponent;
