import React, { useCallback, useState } from 'react';
import './ChatMessage.scss';
import { MessageLoadingPayload, MessageRequest } from '../../../../api/types';
import { getDateTime } from '../../../../utils/getDateTime/getDateTime';
import { getMessageStatusIcon, messageTypeComponents } from './helpers';
import MessageLoadingType from '../MessageTypes/MessageLoadingType';
import { ReactComponent as MessageTail } from '../../../../assets/message-tail.svg';


type Props = {
  message: MessageRequest;
};

const ChatMessage: React.FC<Props> = React.memo(({ message }) => {
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [isMultiline, setIsMultiline] = useState(true);
  const MessageView = messageTypeComponents.get(message.messageType);

  const handleSizeChange = useCallback(
    (height: number) => {
      if (height <= 30) {
        setIsMultiline(false);
      } else {
        setIsMultiline(true);
      }
    },
    [setIsMultiline]
  );
  
  return isFileLoaded ? null : (
    <div className={`chatMessage ${message.direction === 'outbound' ? 'outbound' : 'inbound'}`}>
      <div className={`message ${message.direction === 'outbound' ? 'outbound' : 'inbound'}`}>
        <div className="mediaContainer">
          {message.author && 
            <p className="author">{message.author.name}&nbsp;{message.author.phone}</p>
          }
          {MessageView 
            ? <MessageView message={message} handleSizeChange={handleSizeChange} />
            : <MessageLoadingType message={message as MessageRequest & MessageLoadingPayload}
                handleSizeChange={handleSizeChange} setIsFileLoaded={setIsFileLoaded} />
          }
        </div>
        <div className="messageDate" style={{ flexDirection: isMultiline ? 'column' : 'row' }}>
          <p>{getDateTime().format(message.createdAt, 'hourMinutes')}</p>
          {getMessageStatusIcon(message)}
        </div>
        <span className="message_tail">
          <MessageTail />
        </span>
      </div>
    </div>
  );
});

export default ChatMessage;
