import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Button from '../../../../components/Button/Button';
import './BroadcastAlertWarn.scss';
import { bdAnchorsBroadcast, getResourceForAlertWarning, necessaryResourcesAlertWarn } from './lib/consts';
import { SourceType } from '../../utils';

type AlertBroadcastType = 'telegram' | 'whatsapp' | 'CANCEL_MODE_EDIT'
type BroadcastAlertWarnProps = {
  connectionId?: number
  selectedSource: SourceType | null
}
const BroadcastAlertWarn = ({ connectionId, selectedSource }: BroadcastAlertWarnProps) => {
  const { t, i18n } = useTranslation();
  const modeEdit = useRouteMatch<{ broadcastId: string }>('/companies/:companyId/broadcasts/edit/:broadcastId');
  const { reset, setValue, getValues, formState: { isDirty } } = useFormContext();
  const currentBroadcast = getValues();
  const [currentResource, setCurrentResource] = useState<AlertBroadcastType | null>(null);

  const onSuccess = () => {
    setCurrentResource(null);
  };

  const onCancel = () => {
    if (modeEdit && connectionId) {
      reset({ ...currentBroadcast, connection_id: connectionId });
      setCurrentResource('CANCEL_MODE_EDIT');
    } else {
      setValue('connection_id', '');
      setCurrentResource(null);
    }
  };

  useEffect(() => {
    if (selectedSource?.type && isDirty) {
      const resourceForAlertWarning = getResourceForAlertWarning(selectedSource.type);
      if (resourceForAlertWarning) {
        setCurrentResource(prevState => {
          if (prevState === 'CANCEL_MODE_EDIT') {
            return null;
          }
          return resourceForAlertWarning;
        });
      }
    }
  }, [selectedSource]);

  if (!currentResource || currentResource === 'CANCEL_MODE_EDIT') {
    return null;
  }

  return (
    <div className='broadcastAlertWarn'>
      <div className='broadcastAlertWarn__wrapper'>
        <div className='broadcastAlertWarn__wrapper__alert'>
          <div className='broadcastAlertWarn__wrapper__alert__title'>{t('broadcasts.form.alert.title')}</div>
          <div className='broadcastAlertWarn__wrapper__alert__content'>
            <div className='broadcastAlertWarn__wrapper__alert__content_text'>
              <div>{t('broadcasts.form.alert.text.1', { resource: necessaryResourcesAlertWarn[currentResource] })}</div>
              <div>{t('broadcasts.form.alert.text.2')}</div>
            </div>
            <div className='broadcastAlertWarn__wrapper__alert__content_text'>{t('broadcasts.form.alert.text.3')}
              <a href={bdAnchorsBroadcast[currentResource][i18n.language]} target='_blank' rel='noreferrer'>
                {t(`broadcasts.form.alert.link.${currentResource}`)}
              </a>{t('broadcasts.form.alert.text.4')}
            </div>
            <div className='broadcastAlertWarn__wrapper__alert__content_text'>{t('broadcasts.form.alert.text.5')} </div>
            <div className='broadcastAlertWarn__wrapper__alert__content_text'>{t('broadcasts.form.alert.text.6')} </div>
          </div>
          <div className='broadcastAlertWarn__wrapper__alert__buttons'>
            <Button
              type='button'
              color='orange'
              textType='regular'
              text={t('broadcasts.form.alert.yes_continue')}
              onClick={onSuccess}
            />
            <Button
              type='button'
              color='white'
              textType='regular'
              text={t('cancel')}
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastAlertWarn;
