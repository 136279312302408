import React, { useEffect, useState } from 'react';
import './InteractiveMessageSettings.scss';
import cleanDeep from 'clean-deep';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CreateInteractiveTemplateData, CustomTemplate } from '../../../../api/types';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { uploadFile } from '../../../../api/ChatAPI';
import { useAppDispatch } from '../../../../store/store';
import { createInteractiveTemplate, editInteractiveTemplate } from '../../../Chat/templatesSlice';
import InteractiveMessageSettingsActions from '../InteractiveMessageSettingsActions/InteractiveMessageSettingsActions';
import InteractiveMessageSettingsAttachmentPayloadTypes from '../InteractiveMessageSettingsAttachmentPayloadTypes/InteractiveMessageSettingsAttachmentPayloadTypes';
import Textarea from '../Textarea/Textarea';
import SwitchTabs from '../../../../components/SwitchTabs';
import Button from '../../../../components/Button/Button';
import { ReactComponent as TextTypeIcon } from '../../../../assets/interactiveMessagesTextType-icon.svg';
import { ReactComponent as ImageTypeIcon } from '../../../../assets/interactiveMessagesImageType-icon.svg';
import { ReactComponent as VideoTypeIcon } from '../../../../assets/interactiveMessagesVideoType-icon.svg';
import { ReactComponent as DocumentTypeIcon } from '../../../../assets/templates-icon.svg';


type Props = {
  editTemplateData?: CustomTemplate;
};

const InteractiveMessageSettings: React.FC<Props> = ({ editTemplateData }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const currentCompany = useSelector(currentCompanySelector);
  const methods = useForm<CreateInteractiveTemplateData>({
    mode: 'onChange',
    defaultValues: {
      type: 'waba_interactive',
      name: editTemplateData?.name || '',
      waba_interactive: {
        type: editTemplateData?.params.type || 'button',
        header: {
          type: editTemplateData?.params?.header?.type || 'text',
          text: editTemplateData?.params?.header?.text || '',
        },
        body: editTemplateData?.params.body || '',
        footer: editTemplateData?.params.footer || '',
        action: {
          buttons: editTemplateData?.params.action?.buttons || [
            { type: 'reply', reply: { title: '' } },
            { type: 'reply', reply: { title: '' } },
          ],
          sections: {
            button: editTemplateData?.params.action.sections?.button || '',
            sections: editTemplateData?.params.action.sections?.sections || [
              { rows: [{ title: '', description: '' }] },
            ],
          },
        },
      },
    },
  });
  const [currentMessageHeadingType, setCurrentMessageHeadingType] = useState<string>('text');
  const [attachedFile, setAttachedFile] = useState<File>();
  const [editTemplateDataFileUrl, setEditTemplateFileUrl] = useState<{
    fileUrl: string;
    documentName: string | null;
  } | null>(null);
  const [attachmentValidationError, setAttachmentValidationError] = useState(false);

  useEffect(() => {
    if (editTemplateData && editTemplateData.fileUrl && editTemplateData.params.header) {
      setEditTemplateFileUrl({
        fileUrl: editTemplateData.fileUrl,
        documentName: editTemplateData.params.header.filename,
      });
      setCurrentMessageHeadingType(editTemplateData.params.header.type);
    }
    if (editTemplateData && !editTemplateData.params.header) {
      setCurrentMessageHeadingType('withoutAttachment');
    }
  }, [editTemplateData]);

  const tabs = [
    {
      tabName: t('templates.interactive_attachments_types.text'),
      tabId: 'text',
      tabIcon: TextTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.image'),
      tabId: 'image',
      tabIcon: ImageTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.video'),
      tabId: 'video',
      tabIcon: VideoTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.document'),
      tabId: 'document',
      tabIcon: DocumentTypeIcon,
    },
    {
      tabName: t('templates.interactive_attachments_types.no_title'),
      tabId: 'withoutAttachment',
    },
  ];

  const onSelectTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selectedId = e.currentTarget.id;
    setCurrentMessageHeadingType(selectedId);
    methods.setValue('waba_interactive.header.type', selectedId as 'text' | 'image' | 'video' | 'document');
  };

  const checkEditTemplateDataFileUrlIsDirty = () =>
    editTemplateData && editTemplateData.fileUrl && !editTemplateDataFileUrl;

  const validateAttachmentFileByHeaderType = () =>
    currentMessageHeadingType !== 'text' &&
    currentMessageHeadingType !== 'withoutAttachment' &&
    !attachedFile &&
    !editTemplateDataFileUrl;

  const gotoBack = () => history.goBack();

  const onSubmit: SubmitHandler<CreateInteractiveTemplateData> = async (data) => {
    let actionType;
    let footer: string | undefined;
    let header:
      | {
          type: 'text' | 'image' | 'video' | 'document';
          text?: string;
          url?: string;
          filename?: string;
        }
      | undefined;

    if (data.waba_interactive.type === 'button') {
      actionType = {
        key: 'buttons',
        data: data.waba_interactive.action.buttons,
      };
    } else {
      actionType = {
        key: 'sections',
        data: cleanDeep(data.waba_interactive.action.sections),
      };
    }

    if (attachedFile && currentCompanyId?.params.companyId) {
      const { url: FileUrl } =
        await uploadFile({ file:attachedFile,companyId:Number(currentCompanyId?.params.companyId) });
      header = {
        type: data.waba_interactive.header.type,
        url: FileUrl,
        filename: attachedFile.name,
      };
    } else if (editTemplateData && editTemplateDataFileUrl) {
      header = {
        type: data.waba_interactive.header.type,
        text: editTemplateData.params.header.text || undefined,
        url: editTemplateData.params.header.url,
        filename: editTemplateData.params.header.filename || undefined,
      };
    } else if (currentMessageHeadingType === 'text') {
      header = {
        type: data.waba_interactive.header.type,
        text: data.waba_interactive.header.text || undefined,
      };
    } else {
      header = undefined;
    }

    if (data.waba_interactive.footer) {
      footer = data.waba_interactive.footer;
    } else {
      footer = undefined;
    }

    const postPayload = {
      type: data.type,
      name: data.name,
      waba_interactive: {
        type: data.waba_interactive.type,
        header,
        body: data.waba_interactive.body,
        footer,
        action: {
          [actionType.key]: actionType.data,
        },
      },
      company_id: currentCompany.id,
    };

    if (validateAttachmentFileByHeaderType()) {
      setAttachmentValidationError(true);
      return;
    }

    if (editTemplateData) {
      dispatch(editInteractiveTemplate({ ...postPayload, templateId: editTemplateData.id })).then(() => gotoBack());
    } else {
      dispatch(createInteractiveTemplate(postPayload)).then(() => gotoBack());
    }
  };

  return (
    <div className="interactiveMessageSettings">
      <h1>{editTemplateData 
        ? t('templates.add_interactive_templates.edit_title')
        : t('templates.add_interactive_templates.title')
      }</h1>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="interactiveMessageSettings__wrapper">
          <Textarea
            title={t('templates.add_interactive_templates.template_name')}
            minRows={1}
            maxRows={2}
            {...methods.register('name', {
              minLength: {
                value: 1,
                message: t('templates.add_interactive_templates.template_name_errors.min_length'),
              },
              maxLength: {
                value: 128,
                message: t('templates.add_interactive_templates.template_name_errors.max_length'),
              },
              required: true,
            })}
            maxLength={128}
            errorMessage={
              (methods.formState.errors.name?.type === 'required' && (
                <span className="errorMessage">
                  {t('templates.add_interactive_templates.template_name_errors.required')}
                </span>
              )) ||
              (methods.formState.errors.name && (
                <span className="errorMessage">{methods.formState.errors.name.message}</span>
              ))
            }
          />
          <div className="interactiveMessageSettings__wrapper__typesContainer">
            <p>{t('templates.add_interactive_templates.template_header.title')}</p>
            <SwitchTabs tabs={tabs} onSelectHandler={onSelectTab} selectedTab={currentMessageHeadingType} />
          </div>
          <div className="interactiveMessageSettings__wrapper__payloadContent">
            <InteractiveMessageSettingsAttachmentPayloadTypes
              payloadType={currentMessageHeadingType}
              onChangeAttachedFile={setAttachedFile}
              editTemplateData={editTemplateData}
              setEditTemplateFileUrl={setEditTemplateFileUrl}
              editTemplateDataFileUrl={editTemplateDataFileUrl}
              fileAttachmentError={attachmentValidationError}
            />
          </div>
          <div className="interactiveMessages__wrapper__actions">
            <InteractiveMessageSettingsActions
              editTemplateData={editTemplateData}
              currentMessageHeadingType={currentMessageHeadingType}
              />
          </div>
        </div>
        <div className="interactiveMessageSettings__footer">
          <Button type='submit' color='orange' text={t('save_edit')} textType='regular'
            disabled={!methods.formState.isDirty && !attachedFile && !checkEditTemplateDataFileUrlIsDirty()} />
          <Button type='button' color='white' text={t('cancel')} textType='regular' onClick={gotoBack} />
        </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default InteractiveMessageSettings;
