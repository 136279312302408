import React, { useEffect, useState } from 'react';
import './WabaCatalogs.scss';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { WabaConnection } from '../../../../../../api/CompanyAPI';
import { useLinkCatalog } from '../../../api/catalogs';
import { transformEmptyStringToUndefined } from '../../../utils/connections';
import { AuthData, LinksWabaCatalogInstruction } from '../utils';
import ListItems from '../../../../../../components/ListItems';
import Input from '../../../../../../components/Input';


type WabaCatalogsProps = {
  connection: WabaConnection;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

function WabaCatalogs({ connection, setShow }: WabaCatalogsProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { linkFB } = useLinkCatalog(connection, setShow);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [appConfig, setAppConfig] = useState<AuthData>();
  
  const schema = yup.object({
    id: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    secret: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 }))
  });
  
  const methods = useForm<AuthData>({ mode: 'onChange', resolver: yupResolver(schema) });
  
  const setupFacebook = (data: AuthData) => {
    if (!document.getElementById('facebook-jssdk')) {
      loadSdkAsynchronously();
    }
    setAppConfig({ ...data});
  };

  useEffect(() => () => document.getElementById('facebook-jssdk')?.remove(), []);

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      try {
        FB.init({
          appId: appConfig?.id,
          autoLogAppEvents: false,
          xfbml: false,
          version: 'v17.0'
        });
        FB.getLoginStatus(function(response) {
          checkStatusCallback(response);
        });
      } catch (e) {
        dispatch(addAlertWithCustomText({ message: 'error.unknown', type: 'alarm' }));
      }
    };
  };

  const loadSdkAsynchronously = () =>
    (function (d: Document, s: string, id: string) {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element as Element;
      let js = element as any;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode!.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

  const checkStatusCallback = (response: fb.StatusResponse) => {
    if (response.status === 'connected') {
      setShowLogin(false);
    } else {
      setShowLogin(true);
    }
  };

  useEffect(() => {
    if (appConfig?.id) {
      setFbAsyncInit();
    }
  }, [appConfig]);

  const fbAuthorization = () => {
    try {
      if (FB && appConfig) {
        FB.login((response: fb.StatusResponse) => {linkFB(response, appConfig)}, {
          scope: 'public_profile,catalog_management,whatsapp_business_management'
        });
      }
    } catch (e) {
      dispatch(addAlertWithCustomText({ message: 'error.unknown', type: 'alarm' }));
    }
  };

  return (
    <div className='wabaCatalogs'>
      <h1>{t('connections.waba.catalogs.title')}</h1>
      <p>{t('connections.waba.catalogs.action')}</p>
      <ol>
        <li>
          <Trans i18nKey='connections.waba.catalogs.register'>
            plain<a target='_blank' rel='noreferrer' href='https://developers.facebook.com'>link</a>
          </Trans>
        </li>
      </ol>
      <ListItems typeList='ol' items={t('connections.waba.catalogs.instruction').split('\n')} />
      <a target='_blank' rel='noreferrer' href={LinksWabaCatalogInstruction[i18n.language]}>
        {t('connections.waba.catalogs.instruction_link')}
      </a>
      <FormProvider {...methods}>
        <Input label={t('connections.waba.catalogs.app_id')} name='id' inputType='text'/>
        <Input label={t('connections.waba.catalogs.app_secret')} name='secret' inputType='password' />
        {!showLogin &&
          <button type='submit' onClick={methods.handleSubmit(setupFacebook)} disabled={!methods.formState.isDirty}>
            {t('next')}
          </button>
        }
      </FormProvider>
      {showLogin &&
        <button className='fb-authorization' type='button' onClick={fbAuthorization}>
          {t('connections.waba.catalogs.authorization')}{i18n.language === 'ru' ? ' *' : ''}
        </button>
      }
      {i18n.language === 'ru' && <>
        <span>{t('connections.waba.catalogs.vpn')}</span>
        <span className='warn'>*&nbsp;{t('connections.waba.catalogs.warn')}</span>
      </>}
    </div>
  );
}

export default WabaCatalogs;
