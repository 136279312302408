import React from 'react';
import './OrderPlug.scss';
import { useTranslation } from 'react-i18next';
import orderLogo from '../../../../../assets/order-plug.png';

const OrderPlug = () => {
  const { t } = useTranslation();
  return (
    <div className='orders'>
      <div className='OrderPlug'>
        <img src={orderLogo} alt='order logo'/>
        <h1>{t('orders.title')}</h1>
        <p>{t('orders.text_null_one')}</p>
        <p>{t('orders.text_null_two')}</p>
      </div>
    </div>
  );
};

export default OrderPlug;
