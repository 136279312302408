export const taxArr = [
  { id: 'none', name: 'Без НДС' },
  { id: 'vat0', name: '0%' },
  { id: 'vat10', name: '10%' },
  { id: 'vat20', name: '20%' },
  { id: 'vat110', name: '10/110' },
  { id: 'vat120', name: '20/120' },
];

export const taxBepaidArr = [
  { id: 'none', name: 'Без НДС' },
  { id: 'vat0', name: '0%' },
  { id: 'vat10', name: '10%' },
  { id: 'vat20', name: '20%' },
  { id: 'vat25', name: '25%' },
  { id: 'vat110', name: '10/110' },
  { id: 'vat120', name: '20/120' },
];

export const typeArr = [
  { id: 'another', name: 'Иной' },
  { id: 'commodity', name: 'Товар' },
  { id: 'excise', name: 'Подакцизный товар' },
  { id: 'job', name: 'Работа' },
  { id: 'service', name: 'Услуга' },
  { id: 'gambling_bet', name: 'Ставка азартной игры' },
  { id: 'gambling_prize', name: 'Выигрыш азартной игры' },
  { id: 'lottery', name: 'Лотерейный билет' },
  { id: 'lottery_prize', name: 'Выигрыш лотереи' },
  { id: 'intellectual_activity', name: 'Предоставление РИД' },
  { id: 'payment', name: 'Платеж' },
  { id: 'agent_commission', name: 'Агентское вознаграждение' },
  { id: 'composite', name: 'Составной предмет расчета' },
];

export const paymentArr = [
  { id: 'full_prepayment', name: 'Предоплата 100%' },
  { id: 'prepayment', name: 'Предоплата' },
  { id: 'advance', name: 'Аванс' },
  { id: 'full_payment', name: 'Полный расчет' },
  { id: 'partial_payment', name: 'Частичный расчет и кредит' },
  { id: 'credit', name: 'Передача в кредит' },
  { id: 'credit_payment', name: 'Оплата кредита' },
];
