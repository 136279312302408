import React, { ReactElement } from 'react';
import ReactDOM from "react-dom";
import './PopupTitle.scss';


type Props = {
  image: ReactElement;
  text: string;
  refPopup?: React.RefObject<HTMLDivElement | HTMLAnchorElement>;
  viewPopup?: boolean;
}

const PopupTitle = ({ image, text, refPopup, viewPopup }: Props) => {
  const topTitle = refPopup?.current?.getBoundingClientRect().y! - refPopup?.current?.getBoundingClientRect().left!;

  const stylePopup = {
    top: topTitle + 8,
    left: 62,
    display: 'inline-block',
    animation: 'fadein 400ms ease-in',
  };

  return (
    <div className="popup">
      {image}
      {refPopup && refPopup.current
        ? ReactDOM.createPortal(<div className="popupTitle" style={viewPopup ? stylePopup : {}}>{text}</div>, document.body)
        : <div className="popupTitle">{text}</div>}
    </div>
  );
};

export default PopupTitle;
