import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAlertWithCustomText, setAlertWithClosing } from "../../../../components/Alert/alertSlice";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { getIntegrationItemFilteredAPI } from "../IntegrationAPI";
import { IntegrationsFilter } from "../IntegrationTypes";
import { DomainConnection, Named } from "../types";
import { integrationContext } from "../utils";


type ItegrationInfoRequest = [Promise<IntegrationsFilter>, Promise<IntegrationsFilter>];

export function useConnectionsInfo(integrationId: number) {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const { area } = useContext(integrationContext);
  const [connected, setConnected] = useState<Named[]>([]);
  const [available, setAvailable] = useState<Named[]>([]);
  const [domain, setDomain] = useState<string>();

  useEffect(() => {
    let isFetchData = true;
    const fetchInfo = async () => {
      const requests: ItegrationInfoRequest = [
        getIntegrationItemFilteredAPI({ companyId: company.id, item_id: integrationId, filter: "connected" }),
        getIntegrationItemFilteredAPI({ companyId: company.id, item_id: integrationId, filter: "available" })
      ];

      const [connectedData, availableData] = await Promise.all(requests);

      if (isFetchData) {
        setConnected(connectedData.connections.filter((item) => item.type !== area)
          .map((con) => ({ id: con.id, name: con.name })));
        setAvailable(availableData.connections
          .filter((item) => !connectedData.connections?.some((con) => con.id === item.id))
          .map((con) => ({ id: con.id, name: con.name })));
        const domainConnection = connectedData.connections.find(
          (connection) => connection.type === area) as DomainConnection;
        setDomain(domainConnection?.params.domain);
      }
    };

    fetchInfo().catch(e => {
      if (e.response) {
        dispatch(addAlertWithCustomText({ code: e.response.data.error_code, type: 'alarm' }));
      } else {
        dispatch(setAlertWithClosing({ payload: 'error.amoPipelines', type: 'alarm' }));
      }
    });

    return () => { isFetchData = false; };

  }, [integrationId]);

  return { connected, available, domain };
}
