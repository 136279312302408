import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../store/store';
import './WabaNoCatalog.scss';


function WabaNoCatalog () {
  const { t } = useTranslation();
  const wabaConnection = useAppSelector((state) => state.connections.wabaLinkError);
  const link =`https://business.facebook.com/wa/manage/catalog-linking/?business_id=${wabaConnection?.params.facebookBusinessId}&waba_id=${wabaConnection?.params.wabaBusinessId}`;

  return (
  <p className='wabaNoCatalog'>
    {t('connections.waba.catalogs.no_catalog.message')}&nbsp;
    <a target='_blank' rel='noreferrer' href={link}>{t('here')}</a>.
  </p>
);
}

export default WabaNoCatalog;
