import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tagsSelector } from '../../Settings/tagsSelector';
import { unstashTag, initStashTags, initTagState } from '../BroadcactsSlice';
import { ConditionsLogicOperations, ICondition, IConditions } from '../BroadcastsTypes';


export function useContidionList(initConditions?: IConditions) {
  const dispatch = useDispatch();
  const tags = useSelector(tagsSelector);
  const [conditions, setConditions] = useState<ICondition[]>([]);
  const [isChangedCondtitions, setIsChangedConditions] = useState<boolean>(false);
  const [operation, setOperation] = useState<ConditionsLogicOperations>('and');

  useEffect(() => () => {
    dispatch(initTagState());
  }, []);

  useEffect(() => {
    if (initConditions) {
      setConditions([...initConditions.conditions]);
      setOperation(initConditions.logicOperation);
      // initiate tags store: push to stash
      if (tags) {
        const ids = initConditions.conditions.filter(element => element.conditionType === 'tag')
          .map(condition => condition.value);
        const items = tags.filter(item => ids.includes(item.id));
        if (items.length > 0)
          dispatch(initStashTags(items));
      }
    }
  }, [initConditions, tags]);

  useEffect(() => {
    let isChanged: boolean;
    if (initConditions) {
      isChanged = initConditions.conditions.length !== conditions.length;
    } else {
      isChanged = conditions.length > 0;
    }
    setIsChangedConditions(isChanged);
  }, [conditions]);

  const onOperation = (item: ConditionsLogicOperations) => {
    setOperation(item);
  };

  const onInsert = (condition: ICondition) => {
    setConditions((prev) => {
      prev.push(condition);
      return [...prev];
    });
  };

  const onDelete = (condition: ICondition) => {
    const index = conditions
      .findIndex((item) => item.conditionType === condition.conditionType && item.value === condition.value);
    if (index !== -1) {
      dispatch(unstashTag(condition.value));
      setConditions((prev) => {
        prev.splice(index, 1);
        return [...prev];
      });
    }
  };

  return { conditions, isChangedCondtitions, onInsert, onDelete, operation, onOperation };
}
