import React, { useEffect, useState } from 'react';

export const WindowContext = React.createContext<boolean>(true)

export const WindowContextProvider:React.FunctionComponent  = (props) => {
  const [windowIsActive, setWindowIsActive] = useState(true)

  function handleActivity(forcedFlag:"visible"|"hidden") {
    return forcedFlag === "visible" ? setWindowIsActive(true) : setWindowIsActive(false)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', ()=>
      handleActivity(document.visibilityState)
    )

    return () => {
      document.removeEventListener('visibilitychange', () =>
        handleActivity(document.visibilityState)
      )
    }
  }, [])

  return <WindowContext.Provider value={ windowIsActive }>{props.children}</WindowContext.Provider>
}
