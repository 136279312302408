import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import { ReactComponent as FileDownload } from '../../../../../assets/file-arrow-down.svg';
import UserSubscriptionInvoice from '../../models/UserSubscriptionInvoice';
import './Invoice.scss';

type InvoiceProps = {
  invoiceItem: UserSubscriptionInvoice | undefined
};

const Invoice = ({ invoiceItem }: InvoiceProps) => {
  const { t } = useTranslation();

  const onPay = (url: string) => {
    window.open(url, '_blank');
  };

  if (invoiceItem) {
    return (
      <tr className={`bills__list_item ${invoiceItem.status}`}>
        <td data-title={t('subscriptions.bills.number')}>{invoiceItem.number}, {invoiceItem.date}</td>
        <td data-title={t('subscriptions.bills.amount')}>{invoiceItem.amount}</td>
        <td data-title={t('subscriptions.bills.status')}>
          {invoiceItem.formatStatus()}
          {invoiceItem.status === 'new' &&
            <Button text={t('subscriptions.pay')} textType='regular' color='white'
              onClick={() => onPay(invoiceItem.previewUrl)} />
          }
          {invoiceItem.status === 'confirmed' && invoiceItem.actUrl ?
            <p className='downloadFile' onClick={() => onPay(invoiceItem.actUrl)}>
              <FileDownload /> {t('subscriptions.bills.act')}</p> : null
          }
        </td>
      </tr>
    )
  }
  return null;
};

export default Invoice;
