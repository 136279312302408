import React from 'react';
import { Switch } from 'react-router-dom';
import { Route, useRouteMatch } from 'react-router';
import Catalogs from '../views/Sales/Catalogs';
import CatalogsContent from '../views/Sales/Catalogs/components/CatalogsContent';


const CatalogsRoute = () => {
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId/catalogs');

  return (
    <Switch>
      <Route path={[url!.url]} exact>
        <Catalogs />
      </Route>
      <Route path={`${url!.url}/:catalogId`}>
        <CatalogsContent />
      </Route>
    </Switch>
  );
};

export default CatalogsRoute;
