import React, { ReactElement } from 'react';
import './Button.scss';

type ColorType = 'white' | 'orange';
type TextType = 'regular' | 'small';

type Props = {
  text?: string;
  image?: string | ReactElement;
  color: ColorType;
  textType: TextType;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({ text, image, color, textType, type = 'button', ...props }) => {

  const getImage = () => {
    if(typeof image === 'string'){
      return(
        <img className='GlobalButton_img' alt='img' src={image} />
      )
    } if(typeof image === 'object'){
      return { ...image }
    }
    return <></>
  }

  return (
    <button
      className={`GlobalButton ${props.disabled ? 'disabled' : ''} ${color} ${textType} ${text && image ? 'isImage' : ''}`}
      type={type}
      {...props}
    >
      {getImage()}
      {text}
    </button>
  );
};

export default Button;
