

export type TaxationListItemType = { id: string | null; option: string; };

export type TaxationListType = TaxationListItemType[];
export const taxationTypes: TaxationListType = [
  { id: 'osn', option: 'Общая' },
  { id: 'usn_income', option: 'Упрощенная (доходы)' },
  { id: 'usn_income_outcome', option: 'Упрощенная (доходы - расходы)' },
  { id: 'envd', option: 'Единый налог на вмененный доход' },
  { id: 'esn', option: 'Единый сельскохозяйственный налог' },
  { id: 'patent', option: 'Патентная' },
];

export const surePreffix = (value: string, prefix: string): string => {
  if (value.startsWith(prefix)) return value;
  return `${prefix}${value}`;
};

export const getMethodPaymentModulbank = (payment: 'card_sbp' | 'card' | 'sbp' | undefined) => {
  switch (payment) {
    case 'card': {
      return ['card'];
    }
    case 'sbp': {
      return ['sbp'];
    }
    case 'card_sbp': {
      return [];
    }
    default: {
      return [];
    }
  }
};

export const switchItems = [
  { payload: false, id: 1, name: 'Настоящий магазин' },
  { payload: true, id: 2, name: 'Тестовый магазин' },
];

export const DEFAULT_LIFETIME = 10080;
export const MIN_LIFETIME = 30;
export const MAX_LIFETIME = 30;
