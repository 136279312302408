import React from 'react';
import AttachmentDocumentPreview from '../../AttachmentsPreviews/AttachmentDocumentPreview';

const getInteractiveAttachmentType = (type: string, url: string, docName: string) => {
  switch (type) {
    case 'image':
      return <img src={url} alt="attachment preview" />;
    case 'video':
      return (
        <video src={url} width="100%" controls>
          <track src="#" kind="captions" />
        </video>
      );
    case 'document':
      return (
        <AttachmentDocumentPreview
          customTemplateEditPayload={{ documentName: docName, fileUrl: url }}
          setAttachedFiles={() => {}}
          width={200}
          height={200}
          removeTrashIcon
        />
      );
    default:
      return null;
  }
};

export default getInteractiveAttachmentType;
