export const timezone: Record<string, Array<{ id: string, name: string }>> = {
  'en': [
    {
      'id': 'Africa/Abidjan',
      'name': '(GMT +00:00) Abidjan',
    },
    {
      'id': 'Europe/Lisbon',
      'name': '(GMT +00:00) Lisbon',
    },
    {
      'id': 'Atlantic/Faroe',
      'name': '(GMT +00:00) Faroe',
    },
    {
      'id': 'Atlantic/Madeira',
      'name': '(GMT +00:00) Madeira',
    },
    {
      'id': 'Atlantic/Reykjavik',
      'name': '(GMT +00:00) Reykjavik',
    },
    {
      'id': 'Africa/Lome',
      'name': '(GMT +00:00) Lome',
    },
    {
      'id': 'Atlantic/St_Helena',
      'name': '(GMT +00:00) St Helena',
    },
    {
      'id': 'Europe/London',
      'name': '(GMT +00:00) London',
    },
    {
      'id': 'Europe/Jersey',
      'name': '(GMT +00:00) Jersey',
    },
    {
      'id': 'Africa/Freetown',
      'name': '(GMT +00:00) Freetown',
    },
    {
      'id': 'Europe/Isle_of_Man',
      'name': '(GMT +00:00) Isle of Man',
    },
    {
      'id': 'Europe/Guernsey',
      'name': '(GMT +00:00) Guernsey',
    },
    {
      'id': 'Africa/Monrovia',
      'name': '(GMT +00:00) Monrovia',
    },
    {
      'id': 'Europe/Dublin',
      'name': '(GMT +00:00) Dublin',
    },
    {
      'id': 'Africa/Nouakchott',
      'name': '(GMT +00:00) Nouakchott',
    },
    {
      'id': 'Africa/Ouagadougou',
      'name': '(GMT +00:00) Ouagadougou',
    },
    {
      'id': 'Africa/Sao_Tome',
      'name': '(GMT +00:00) Sao-Tome',
    },
    {
      'id': 'Antarctica/Troll',
      'name': '(GMT +00:00) Troll',
    },
    {
      'id': 'Atlantic/Canary',
      'name': '(GMT +00:00) Canary',
    },
    {
      'id': 'UTC',
      'name': '(GMT +00:00) UTC',
    },
    {
      'id': 'Africa/Accra',
      'name': '(GMT +00:00) Accra',
    },
    {
      'id': 'America/Danmarkshavn',
      'name': '(GMT +00:00) Danmarkshavn',
    },
    {
      'id': 'Africa/Dakar',
      'name': '(GMT +00:00) Dakar',
    },
    {
      'id': 'Africa/Conakry',
      'name': '(GMT +00:00) Conakry',
    },
    {
      'id': 'Africa/Bamako',
      'name': '(GMT +00:00) Bamako',
    },
    {
      'id': 'Africa/Banjul',
      'name': '(GMT +00:00) Banjul',
    },
    {
      'id': 'Africa/Bissau',
      'name': '(GMT +00:00) Bissau',
    },
    {
      'id': 'Europe/Vienna',
      'name': '(GMT +01:00) Vienna',
    },
    {
      'id': 'Europe/Tirane',
      'name': '(GMT +01:00) Tirane',
    },
    {
      'id': 'Europe/Busingen',
      'name': '(GMT +01:00) Busingen',
    },
    {
      'id': 'Europe/Sarajevo',
      'name': '(GMT +01:00) Sarajevo',
    },
    {
      'id': 'Europe/Copenhagen',
      'name': '(GMT +01:00) Copenhagen',
    },
    {
      'id': 'Europe/Gibraltar',
      'name': '(GMT +01:00) Gibraltar',
    },
    {
      'id': 'Europe/Vaduz',
      'name': '(GMT +01:00) Vaduz',
    },
    {
      'id': 'Europe/Vatican',
      'name': '(GMT +01:00) Vatican',
    },
    {
      'id': 'Europe/Prague',
      'name': '(GMT +01:00) Prague',
    },
    {
      'id': 'Europe/Ljubljana',
      'name': '(GMT +01:00) Ljubljana',
    },
    {
      'id': 'Europe/San_Marino',
      'name': '(GMT +01:00) San Marino',
    },
    {
      'id': 'Europe/Podgorica',
      'name': '(GMT +01:00) Podgorica',
    },
    {
      'id': 'Europe/Luxembourg',
      'name': '(GMT +01:00) Luxembourg',
    },
    {
      'id': 'Europe/Warsaw',
      'name': '(GMT +01:00) Warsaw',
    },
    {
      'id': 'Europe/Madrid',
      'name': '(GMT +01:00) Madrid',
    },
    {
      'id': 'Europe/Malta',
      'name': '(GMT +01:00) Malta',
    },
    {
      'id': 'Europe/Monaco',
      'name': '(GMT +01:00) Monaco',
    },
    {
      'id': 'Europe/Zagreb',
      'name': '(GMT +01:00) Zagreb',
    },
    {
      'id': 'Europe/Budapest',
      'name': '(GMT +01:00) Budapest',
    },
    {
      'id': 'Europe/Oslo',
      'name': '(GMT +01:00) Oslo',
    },
    {
      'id': 'Europe/Paris',
      'name': '(GMT +01:00) Paris',
    },
    {
      'id': 'Europe/Zurich',
      'name': '(GMT +01:00) Zurich',
    },
    {
      'id': 'Europe/Rome',
      'name': '(GMT +01:00) Rome',
    },
    {
      'id': 'Africa/Niamey',
      'name': '(GMT +01:00) Niamey',
    },
    {
      'id': 'Europe/Skopje',
      'name': '(GMT +01:00) Skopje',
    },
    {
      'id': 'Africa/Casablanca',
      'name': '(GMT +01:00) Casablanca',
    },
    {
      'id': 'Arctic/Longyearbyen',
      'name': '(GMT +01:00) Longyearbyen',
    },
    {
      'id': 'Africa/Douala',
      'name': '(GMT +01:00) Douala',
    },
    {
      'id': 'Africa/El_Aaiun',
      'name': '(GMT +01:00) El Aaiun',
    },
    {
      'id': 'Africa/Brazzaville',
      'name': '(GMT +01:00) Brazzaville',
    },
    {
      'id': 'Africa/Kinshasa',
      'name': '(GMT +01:00) Kinshasa',
    },
    {
      'id': 'Africa/Tunis',
      'name': '(GMT +01:00) Tunis',
    },
    {
      'id': 'Africa/Lagos',
      'name': '(GMT +01:00) Lagos',
    },
    {
      'id': 'Africa/Libreville',
      'name': '(GMT +01:00) Libreville',
    },
    {
      'id': 'Africa/Luanda',
      'name': '(GMT +01:00) Luanda',
    },
    {
      'id': 'Europe/Amsterdam',
      'name': '(GMT +01:00) Amsterdam',
    },
    {
      'id': 'Africa/Malabo',
      'name': '(GMT +01:00) Malabo',
    },
    {
      'id': 'Europe/Andorra',
      'name': '(GMT +01:00) Andorra',
    },
    {
      'id': 'Africa/Bangui',
      'name': '(GMT +01:00) Bangui',
    },
    {
      'id': 'Africa/Algiers',
      'name': '(GMT +01:00) Algiers',
    },
    {
      'id': 'Europe/Belgrade',
      'name': '(GMT +01:00) Belgrade',
    },
    {
      'id': 'Europe/Berlin',
      'name': '(GMT +01:00) Berlin',
    },
    {
      'id': 'Europe/Bratislava',
      'name': '(GMT +01:00) Bratislava',
    },
    {
      'id': 'Africa/Ndjamena',
      'name': '(GMT +01:00) Ndjamena',
    },
    {
      'id': 'Europe/Stockholm',
      'name': '(GMT +01:00) Stockholm',
    },
    {
      'id': 'Africa/Porto-Novo',
      'name': '(GMT +01:00) Porto-Novo',
    },
    {
      'id': 'Europe/Brussels',
      'name': '(GMT +01:00) Brussels',
    },
    {
      'id': 'Africa/Ceuta',
      'name': '(GMT +01:00) Ceuta',
    },
    {
      'id': 'Asia/Gaza',
      'name': '(GMT +02:00) Gaza',
    },
    {
      'id': 'Asia/Famagusta',
      'name': '(GMT +02:00) Famagusta',
    },
    {
      'id': 'Asia/Hebron',
      'name': '(GMT +02:00) Hebron',
    },
    {
      'id': 'Asia/Damascus',
      'name': '(GMT +02:00) Damascus',
    },
    {
      'id': 'Europe/Bucharest',
      'name': '(GMT +02:00) Bucharest',
    },
    {
      'id': 'Asia/Beirut',
      'name': '(GMT +02:00) Beirut',
    },
    {
      'id': 'Europe/Riga',
      'name': '(GMT +02:00) Riga',
    },
    {
      'id': 'Asia/Jerusalem',
      'name': '(GMT +02:00) Jerusalem',
    },
    {
      'id': 'Asia/Nicosia',
      'name': '(GMT +02:00) Nicosia',
    },
    {
      'id': 'Europe/Kiev',
      'name': '(GMT +02:00) Kiev',
    },
    {
      'id': 'Europe/Kaliningrad',
      'name': '(GMT +02:00) Kaliningrad',
    },
    {
      'id': 'Europe/Athens',
      'name': '(GMT +02:00) Athens',
    },
    {
      'id': 'Europe/Chisinau',
      'name': '(GMT +02:00) Chisinau',
    },
    {
      'id': 'Europe/Mariehamn',
      'name': '(GMT +02:00) Mariehamn',
    },
    {
      'id': 'Europe/Helsinki',
      'name': '(GMT +02:00) Helsinki',
    },
    {
      'id': 'Europe/Sofia',
      'name': '(GMT +02:00) Sofia',
    },
    {
      'id': 'Africa/Maputo',
      'name': '(GMT +02:00) Maputo',
    },
    {
      'id': 'Africa/Maseru',
      'name': '(GMT +02:00) Maseru',
    },
    {
      'id': 'Africa/Mbabane',
      'name': '(GMT +02:00) Mbabane',
    },
    {
      'id': 'Africa/Tripoli',
      'name': '(GMT +02:00) Tripoli',
    },
    {
      'id': 'Africa/Windhoek',
      'name': '(GMT +02:00) Windhoek',
    },
    {
      'id': 'Africa/Lubumbashi',
      'name': '(GMT +02:00) Lubumbashi',
    },
    {
      'id': 'Africa/Kigali',
      'name': '(GMT +02:00) Kigali',
    },
    {
      'id': 'Africa/Khartoum',
      'name': '(GMT +02:00) Khartoum',
    },
    {
      'id': 'Africa/Juba',
      'name': '(GMT +02:00) Juba',
    },
    {
      'id': 'Europe/Tallinn',
      'name': '(GMT +02:00) Tallinn',
    },
    {
      'id': 'Africa/Johannesburg',
      'name': '(GMT +02:00) Johannesburg',
    },
    {
      'id': 'Africa/Harare',
      'name': '(GMT +02:00) Harare',
    },
    {
      'id': 'Africa/Gaborone',
      'name': '(GMT +02:00) Gaborone',
    },
    {
      'id': 'Africa/Lusaka',
      'name': '(GMT +02:00) Lusaka',
    },
    {
      'id': 'Europe/Vilnius',
      'name': '(GMT +02:00) Vilnius',
    },
    {
      'id': 'Europe/Uzhgorod',
      'name': '(GMT +02:00) Uzhgorod',
    },
    {
      'id': 'Africa/Blantyre',
      'name': '(GMT +02:00) Blantyre',
    },
    {
      'id': 'Africa/Cairo',
      'name': '(GMT +02:00) Cairo',
    },
    {
      'id': 'Europe/Zaporozhye',
      'name': '(GMT +02:00) Zaporozhye',
    },
    {
      'id': 'Africa/Bujumbura',
      'name': '(GMT +02:00) Bujumbura',
    },
    {
      'id': 'Indian/Comoro',
      'name': '(GMT +03:00) Comoro',
    },
    {
      'id': 'Indian/Antananarivo',
      'name': '(GMT +03:00) Antananarivo',
    },
    {
      'id': 'Europe/Simferopol',
      'name': '(GMT +03:00) Simferopol',
    },
    {
      'id': 'Africa/Addis_Ababa',
      'name': '(GMT +03:00) Addis Ababa',
    },
    {
      'id': 'Africa/Mogadishu',
      'name': '(GMT +03:00) Mogadishu',
    },
    {
      'id': 'Africa/Nairobi',
      'name': '(GMT +03:00) Nairobi',
    },
    {
      'id': 'Africa/Asmara',
      'name': '(GMT +03:00) Asmara',
    },
    {
      'id': 'Europe/Volgograd',
      'name': '(GMT +03:00) Volgograd',
    },
    {
      'id': 'Africa/Kampala',
      'name': '(GMT +03:00) Kampala',
    },
    {
      'id': 'Asia/Qatar',
      'name': '(GMT +03:00) Qatar',
    },
    {
      'id': 'Asia/Riyadh',
      'name': '(GMT +03:00) Riyadh',
    },
    {
      'id': 'Europe/Minsk',
      'name': '(GMT +03:00) Minsk',
    },
    {
      'id': 'Europe/Istanbul',
      'name': '(GMT +03:00) Istanbul',
    },
    {
      'id': 'Asia/Kuwait',
      'name': '(GMT +03:00) Kuwait',
    },
    {
      'id': 'Antarctica/Syowa',
      'name': '(GMT +03:00) Syowa',
    },
    {
      'id': 'Europe/Kirov',
      'name': '(GMT +03:00) Kirov',
    },
    {
      'id': 'Asia/Bahrain',
      'name': '(GMT +03:00) Bahrain',
    },
    {
      'id': 'Europe/Moscow',
      'name': '(GMT +03:00) Moscow',
    },
    {
      'id': 'Indian/Mayotte',
      'name': '(GMT +03:00) Mayotte',
    },
    {
      'id': 'Asia/Aden',
      'name': '(GMT +03:00) Aden',
    },
    {
      'id': 'Asia/Amman',
      'name': '(GMT +03:00) Amman',
    },
    {
      'id': 'Africa/Dar_es_Salaam',
      'name': '(GMT +03:00) Dar es Salaam',
    },
    {
      'id': 'Asia/Baghdad',
      'name': '(GMT +03:00) Baghdad',
    },
    {
      'id': 'Africa/Djibouti',
      'name': '(GMT +03:00) Djibouti',
    },
    {
      'id': 'Asia/Tehran',
      'name': '(GMT +03:30) Tehran',
    },
    {
      'id': 'Europe/Astrakhan',
      'name': '(GMT +04:00) Astrakhan',
    },
    {
      'id': 'Asia/Dubai',
      'name': '(GMT +04:00) Dubai',
    },
    {
      'id': 'Asia/Yerevan',
      'name': '(GMT +04:00) Yerevan',
    },
    {
      'id': 'Indian/Mauritius',
      'name': '(GMT +04:00) Mauritius',
    },
    {
      'id': 'Asia/Baku',
      'name': '(GMT +04:00) Baku',
    },
    {
      'id': 'Asia/Muscat',
      'name': '(GMT +04:00) Muscat',
    },
    {
      'id': 'Indian/Mahe',
      'name': '(GMT +04:00) Mahe',
    },
    {
      'id': 'Indian/Reunion',
      'name': '(GMT +04:00) Reunion',
    },
    {
      'id': 'Europe/Samara',
      'name': '(GMT +04:00) Samara',
    },
    {
      'id': 'Europe/Saratov',
      'name': '(GMT +04:00) Saratov',
    },
    {
      'id': 'Europe/Ulyanovsk',
      'name': '(GMT +04:00) Ulyanovsk',
    },
    {
      'id': 'Asia/Tbilisi',
      'name': '(GMT +04:00) Tbilisi',
    },
    {
      'id': 'Asia/Kabul',
      'name': '(GMT +04:30) Kabul',
    },
    {
      'id': 'Indian/Maldives',
      'name': '(GMT +05:00) Maldives',
    },
    {
      'id': 'Indian/Kerguelen',
      'name': '(GMT +05:00) Kerguelen',
    },
    {
      'id': 'Asia/Aqtau',
      'name': '(GMT +05:00) Aqtau',
    },
    {
      'id': 'Asia/Aqtobe',
      'name': '(GMT +05:00) Aqtobe',
    },
    {
      'id': 'Asia/Ashgabat',
      'name': '(GMT +05:00) Ashgabat',
    },
    {
      'id': 'Asia/Atyrau',
      'name': '(GMT +05:00) Atyrau',
    },
    {
      'id': 'Asia/Samarkand',
      'name': '(GMT +05:00) Samarkand',
    },
    {
      'id': 'Antarctica/Mawson',
      'name': '(GMT +05:00) Mawson',
    },
    {
      'id': 'Asia/Yekaterinburg',
      'name': '(GMT +05:00) Ekaterinburg',
    },
    {
      'id': 'Asia/Qyzylorda',
      'name': '(GMT +05:00) Qyzylorda',
    },
    {
      'id': 'Asia/Karachi',
      'name': '(GMT +05:00) Karachi',
    },
    {
      'id': 'Asia/Dushanbe',
      'name': '(GMT +05:00) Dushanbe',
    },
    {
      'id': 'Asia/Oral',
      'name': '(GMT +05:00) Oral',
    },
    {
      'id': 'Asia/Tashkent',
      'name': '(GMT +05:00) Tashkent',
    },
    {
      'id': 'Asia/Colombo',
      'name': '(GMT +05:30) Colombo',
    },
    {
      'id': 'Asia/Kolkata',
      'name': '(GMT +05:30) Kolkata',
    },
    {
      'id': 'Asia/Kathmandu',
      'name': '(GMT +05:45) Kathmandu',
    },
    {
      'id': 'Asia/Qostanay',
      'name': '(GMT +06:00) Qostanay',
    },
    {
      'id': 'Antarctica/Vostok',
      'name': '(GMT +06:00) Vostok',
    },
    {
      'id': 'Asia/Urumqi',
      'name': '(GMT +06:00) Urumqi',
    },
    {
      'id': 'Asia/Almaty',
      'name': '(GMT +06:00) Almaty',
    },
    {
      'id': 'Asia/Thimphu',
      'name': '(GMT +06:00) Thimphu',
    },
    {
      'id': 'Asia/Bishkek',
      'name': '(GMT +06:00) Bishkek',
    },
    {
      'id': 'Indian/Chagos',
      'name': '(GMT +06:00) Chagos',
    },
    {
      'id': 'Asia/Dhaka',
      'name': '(GMT +06:00) Dhaka',
    },
    {
      'id': 'Asia/Omsk',
      'name': '(GMT +06:00) Omsk',
    },
    {
      'id': 'Asia/Yangon',
      'name': '(GMT +06:30) Yangon',
    },
    {
      'id': 'Indian/Cocos',
      'name': '(GMT +06:30) Cocos',
    },
    {
      'id': 'Asia/Jakarta',
      'name': '(GMT +07:00) Jakarta',
    },
    {
      'id': 'Asia/Bangkok',
      'name': '(GMT +07:00) Bangkok',
    },
    {
      'id': 'Antarctica/Davis',
      'name': '(GMT +07:00) Davis',
    },
    {
      'id': 'Asia/Barnaul',
      'name': '(GMT +07:00) Barnaul',
    },
    {
      'id': 'Indian/Christmas',
      'name': '(GMT +07:00) Christmas',
    },
    {
      'id': 'Asia/Krasnoyarsk',
      'name': '(GMT +07:00) Krasnoyarsk',
    },
    {
      'id': 'Asia/Ho_Chi_Minh',
      'name': '(GMT +07:00) Ho Chi Minh',
    },
    {
      'id': 'Asia/Hovd',
      'name': '(GMT +07:00) Hovd',
    },
    {
      'id': 'Asia/Novokuznetsk',
      'name': '(GMT +07:00) Novokuznetsk',
    },
    {
      'id': 'Asia/Novosibirsk',
      'name': '(GMT +07:00) Novosibirsk',
    },
    {
      'id': 'Asia/Vientiane',
      'name': '(GMT +07:00) Vientiane',
    },
    {
      'id': 'Asia/Phnom_Penh',
      'name': '(GMT +07:00) Phnom Penh',
    },
    {
      'id': 'Asia/Pontianak',
      'name': '(GMT +07:00) Pontianak',
    },
    {
      'id': 'Asia/Tomsk',
      'name': '(GMT +07:00) Tomsk',
    },
    {
      'id': 'Asia/Shanghai',
      'name': '(GMT +08:00) Shanghai',
    },
    {
      'id': 'Asia/Hong_Kong',
      'name': '(GMT +08:00) Hong Kong',
    },
    {
      'id': 'Australia/Perth',
      'name': '(GMT +08:00) Perth',
    },
    {
      'id': 'Asia/Choibalsan',
      'name': '(GMT +08:00) Choibalsan',
    },
    {
      'id': 'Asia/Brunei',
      'name': '(GMT +08:00) Brunei',
    },
    {
      'id': 'Asia/Chungking',
      'name': '(GMT +08:00) Chongqing',
    },
    {
      'id': 'Asia/Ulaanbaatar',
      'name': '(GMT +08:00) Ulaan Bataar',
    },
    {
      'id': 'Asia/Taipei',
      'name': '(GMT +08:00) Taipei',
    },
    {
      'id': 'Asia/Singapore',
      'name': '(GMT +08:00) Singapore',
    },
    {
      'id': 'Asia/Manila',
      'name': '(GMT +08:00) Manila',
    },
    {
      'id': 'Asia/Makassar',
      'name': '(GMT +08:00) Makassar',
    },
    {
      'id': 'Asia/Kuching',
      'name': '(GMT +08:00) Kuching',
    },
    {
      'id': 'Asia/Macau',
      'name': '(GMT +08:00) Macau',
    },
    {
      'id': 'Asia/Kuala_Lumpur',
      'name': '(GMT +08:00) Kuala Lumpur',
    },
    {
      'id': 'Asia/Irkutsk',
      'name': '(GMT +08:00) Irkutsk',
    },
    {
      'id': 'Australia/Eucla',
      'name': '(GMT +08:45) Eucla',
    },
    {
      'id': 'Asia/Tokyo',
      'name': '(GMT +09:00) Tokyo',
    },
    {
      'id': 'Asia/Chita',
      'name': '(GMT +09:00) Chita',
    },
    {
      'id': 'Asia/Yakutsk',
      'name': '(GMT +09:00) Yakutsk',
    },
    {
      'id': 'Asia/Khandyga',
      'name': '(GMT +09:00) Khandyga',
    },
    {
      'id': 'Asia/Pyongyang',
      'name': '(GMT +09:00) Pyongyang',
    },
    {
      'id': 'Asia/Dili',
      'name': '(GMT +09:00) Dili',
    },
    {
      'id': 'Asia/Jayapura',
      'name': '(GMT +09:00) Jayapura',
    },
    {
      'id': 'Asia/Seoul',
      'name': '(GMT +09:00) Seoul',
    },
    {
      'id': 'Pacific/Palau',
      'name': '(GMT +09:00) Palau',
    },
    {
      'id': 'Australia/Darwin',
      'name': '(GMT +09:30) Darwin',
    },
    {
      'id': 'Australia/Brisbane',
      'name': '(GMT +10:00) Brisbane',
    },
    {
      'id': 'Pacific/Port_Moresby',
      'name': '(GMT +10:00) Port Moresby',
    },
    {
      'id': 'Australia/Lindeman',
      'name': '(GMT +10:00) Lindeman',
    },
    {
      'id': 'Pacific/Saipan',
      'name': '(GMT +10:00) Saipan',
    },
    {
      'id': 'Antarctica/DumontDUrville',
      'name': '(GMT +10:00) Dumont-d\'Urville',
    },
    {
      'id': 'Asia/Ust-Nera',
      'name': '(GMT +10:00) Ust-Nera',
    },
    {
      'id': 'Pacific/Guam',
      'name': '(GMT +10:00) Guam',
    },
    {
      'id': 'Asia/Vladivostok',
      'name': '(GMT +10:00) Vladivostok',
    },
    {
      'id': 'Pacific/Chuuk',
      'name': '(GMT +10:00) Chuuk',
    },
    {
      'id': 'Australia/Adelaide',
      'name': '(GMT +10:30) Adelaide',
    },
    {
      'id': 'Australia/Broken_Hill',
      'name': '(GMT +10:30) Broken Hill',
    },
    {
      'id': 'Asia/Sakhalin',
      'name': '(GMT +11:00) Sakhalin',
    },
    {
      'id': 'Pacific/Kosrae',
      'name': '(GMT +11:00) Kosrae',
    },
    {
      'id': 'Australia/Sydney',
      'name': '(GMT +11:00) Sydney',
    },
    {
      'id': 'Pacific/Efate',
      'name': '(GMT +11:00) Efate',
    },
    {
      'id': 'Australia/Melbourne',
      'name': '(GMT +11:00) Melbourne',
    },
    {
      'id': 'Australia/Lord_Howe',
      'name': '(GMT +11:00) Lord Howe',
    },
    {
      'id': 'Asia/Srednekolymsk',
      'name': '(GMT +11:00) Srednekolymsk',
    },
    {
      'id': 'Australia/Hobart',
      'name': '(GMT +11:00) Hobart',
    },
    {
      'id': 'Pacific/Bougainville',
      'name': '(GMT +11:00) Bougainville',
    },
    {
      'id': 'Pacific/Noumea',
      'name': '(GMT +11:00) Noumea',
    },
    {
      'id': 'Asia/Magadan',
      'name': '(GMT +11:00) Magadan',
    },
    {
      'id': 'Pacific/Guadalcanal',
      'name': '(GMT +11:00) Guadalcanal',
    },
    {
      'id': 'Pacific/Pohnpei',
      'name': '(GMT +11:00) Pohnpei',
    },
    {
      'id': 'Australia/Canberra',
      'name': '(GMT +11:00) Canberra',
    },
    {
      'id': 'Antarctica/Macquarie',
      'name': '(GMT +11:00) Macquarie',
    },
    {
      'id': 'Antarctica/Casey',
      'name': '(GMT +11:00) Casey',
    },
    {
      'id': 'Asia/Anadyr',
      'name': '(GMT +12:00) Anadyr',
    },
    {
      'id': 'Pacific/Kwajalein',
      'name': '(GMT +12:00) Kwajalein',
    },
    {
      'id': 'Asia/Kamchatka',
      'name': '(GMT +12:00) Kamchatka',
    },
    {
      'id': 'Pacific/Wallis',
      'name': '(GMT +12:00) Wallis',
    },
    {
      'id': 'Pacific/Wake',
      'name': '(GMT +12:00) Wake',
    },
    {
      'id': 'Pacific/Tarawa',
      'name': '(GMT +12:00) Tarawa',
    },
    {
      'id': 'Pacific/Fiji',
      'name': '(GMT +12:00) Fiji',
    },
    {
      'id': 'Pacific/Funafuti',
      'name': '(GMT +12:00) Funafuti',
    },
    {
      'id': 'Pacific/Norfolk',
      'name': '(GMT +12:00) Norfolk',
    },
    {
      'id': 'Pacific/Nauru',
      'name': '(GMT +12:00) Nauru',
    },
    {
      'id': 'Pacific/Majuro',
      'name': '(GMT +12:00) Majuro',
    },
    {
      'id': 'Pacific/Apia',
      'name': '(GMT +13:00) Apia',
    },
    {
      'id': 'Pacific/Auckland',
      'name': '(GMT +13:00) Auckland',
    },
    {
      'id': 'Pacific/Kanton',
      'name': '(GMT +13:00) Kanton',
    },
    {
      'id': 'Pacific/Fakaofo',
      'name': '(GMT +13:00) Fakaofo',
    },
    {
      'id': 'Antarctica/McMurdo',
      'name': '(GMT +13:00) McMurdo',
    },
    {
      'id': 'Pacific/Tongatapu',
      'name': '(GMT +13:00) Tongatapu',
    },
    {
      'id': 'Pacific/Chatham',
      'name': '(GMT +13:45) Chatham',
    },
    {
      'id': 'Pacific/Kiritimati',
      'name': '(GMT +14:00) Kiritimati',
    },
    {
      'id': 'Atlantic/Azores',
      'name': '(GMT -01:00) Azores',
    },
    {
      'id': 'Atlantic/Cape_Verde',
      'name': '(GMT -01:00) Cape Verde Is.',
    },
    {
      'id': 'America/Scoresbysund',
      'name': '(GMT -01:00) Scoresbysund',
    },
    {
      'id': 'Atlantic/South_Georgia',
      'name': '(GMT -02:00) South Georgia',
    },
    {
      'id': 'America/Noronha',
      'name': '(GMT -02:00) Mid-Atlantic',
    },
    {
      'id': 'America/Cayenne',
      'name': '(GMT -03:00) Cayenne',
    },
    {
      'id': 'America/Fortaleza',
      'name': '(GMT -03:00) Fortaleza',
    },
    {
      'id': 'America/Maceio',
      'name': '(GMT -03:00) Maceio',
    },
    {
      'id': 'Antarctica/Palmer',
      'name': '(GMT -03:00) Palmer',
    },
    {
      'id': 'America/Bahia',
      'name': '(GMT -03:00) Bahia',
    },
    {
      'id': 'America/Miquelon',
      'name': '(GMT -03:00) Miquelon',
    },
    {
      'id': 'Atlantic/Stanley',
      'name': '(GMT -03:00) Stanley',
    },
    {
      'id': 'America/Montevideo',
      'name': '(GMT -03:00) Montevideo',
    },
    {
      'id': 'America/Belem',
      'name': '(GMT -03:00) Belem',
    },
    {
      'id': 'America/Argentina/Cordoba',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Asuncion',
      'name': '(GMT -03:00) Asuncion',
    },
    {
      'id': 'America/Argentina/Ushuaia',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Tucuman',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/San_Juan',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Salta',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Rio_Gallegos',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Mendoza',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Araguaina',
      'name': '(GMT -03:00) Araguaina',
    },
    {
      'id': 'America/Argentina/Buenos_Aires',
      'name': '(GMT -03:00) Buenos Aires',
    },
    {
      'id': 'America/Argentina/La_Rioja',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Jujuy',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Catamarca',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/San_Luis',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'Antarctica/Rothera',
      'name': '(GMT -03:00) Rothera',
    },
    {
      'id': 'America/Recife',
      'name': '(GMT -03:00) Recife',
    },
    {
      'id': 'America/Sao_Paulo',
      'name': '(GMT -03:00) Sao Paulo',
    },
    {
      'id': 'America/Santiago',
      'name': '(GMT -03:00) Santiago',
    },
    {
      'id': 'America/Nuuk',
      'name': '(GMT -03:00) Nuuk',
    },
    {
      'id': 'America/Paramaribo',
      'name': '(GMT -03:00) Paramaribo',
    },
    {
      'id': 'America/Santarem',
      'name': '(GMT -03:00) Santarem',
    },
    {
      'id': 'America/Punta_Arenas',
      'name': '(GMT -03:00) Punta Arenas',
    },
    {
      'id': 'America/St_Johns',
      'name': '(GMT -03:30) St. Johns',
    },
    {
      'id': 'America/Curacao',
      'name': '(GMT -04:00) Curacao',
    },
    {
      'id': 'America/St_Lucia',
      'name': '(GMT -04:00) St. Lucia',
    },
    {
      'id': 'America/La_Paz',
      'name': '(GMT -04:00) La Paz',
    },
    {
      'id': 'America/Kralendijk',
      'name': '(GMT -04:00) Kralendijk',
    },
    {
      'id': 'America/Aruba',
      'name': '(GMT -04:00) Aruba',
    },
    {
      'id': 'America/St_Kitts',
      'name': '(GMT -04:00) St. Kitts',
    },
    {
      'id': 'America/Cuiaba',
      'name': '(GMT -04:00) Cuiaba',
    },
    {
      'id': 'America/Porto_Velho',
      'name': '(GMT -04:00) Porto Velho',
    },
    {
      'id': 'America/Dominica',
      'name': '(GMT -04:00) Dominica',
    },
    {
      'id': 'America/Barbados',
      'name': '(GMT -04:00) Barbados',
    },
    {
      'id': 'America/St_Barthelemy',
      'name': '(GMT -04:00) St. Barthelemy',
    },
    {
      'id': 'America/Santo_Domingo',
      'name': '(GMT -04:00) Santo Domingo',
    },
    {
      'id': 'America/Halifax',
      'name': '(GMT -04:00) Atlantic Time (Canada)',
    },
    {
      'id': 'America/Guyana',
      'name': '(GMT -04:00) Guyana',
    },
    {
      'id': 'America/Glace_Bay',
      'name': '(GMT -04:00) Glace Bay',
    },
    {
      'id': 'America/Guadeloupe',
      'name': '(GMT -04:00) Guadeloupe',
    },
    {
      'id': 'America/Goose_Bay',
      'name': '(GMT -04:00) Goose Bay',
    },
    {
      'id': 'America/Lower_Princes',
      'name': '(GMT -04:00) Lower Princes',
    },
    {
      'id': 'America/St_Thomas',
      'name': '(GMT -04:00) St. Thomas',
    },
    {
      'id': 'America/Campo_Grande',
      'name': '(GMT -04:00) Campo Grande',
    },
    {
      'id': 'America/Puerto_Rico',
      'name': '(GMT -04:00) Puerto Rico',
    },
    {
      'id': 'Atlantic/Bermuda',
      'name': '(GMT -04:00) Bermuda',
    },
    {
      'id': 'America/Blanc-Sablon',
      'name': '(GMT -04:00) Blanc-Sablon',
    },
    {
      'id': 'America/Anguilla',
      'name': '(GMT -04:00) Anguilla',
    },
    {
      'id': 'America/Boa_Vista',
      'name': '(GMT -04:00) Boa Vista',
    },
    {
      'id': 'America/Montserrat',
      'name': '(GMT -04:00) Montserrat',
    },
    {
      'id': 'America/Antigua',
      'name': '(GMT -04:00) Antigua',
    },
    {
      'id': 'America/Tortola',
      'name': '(GMT -04:00) Tortola',
    },
    {
      'id': 'America/Manaus',
      'name': '(GMT -04:00) Manaus',
    },
    {
      'id': 'America/Moncton',
      'name': '(GMT -04:00) Moncton',
    },
    {
      'id': 'America/Caracas',
      'name': '(GMT -04:00) Caracas',
    },
    {
      'id': 'America/Thule',
      'name': '(GMT -04:00) Thule',
    },
    {
      'id': 'America/Port_of_Spain',
      'name': '(GMT -04:00) Port of Spain',
    },
    {
      'id': 'America/St_Vincent',
      'name': '(GMT -04:00) St. Vincent',
    },
    {
      'id': 'America/Martinique',
      'name': '(GMT -04:00) Martinique',
    },
    {
      'id': 'America/Marigot',
      'name': '(GMT -04:00) Marigot',
    },
    {
      'id': 'America/Grenada',
      'name': '(GMT -04:00) Grenada',
    },
    {
      'id': 'America/Detroit',
      'name': '(GMT -05:00) Detroit',
    },
    {
      'id': 'America/Rio_Branco',
      'name': '(GMT -05:00) Rio Branco',
    },
    {
      'id': 'America/Cancun',
      'name': '(GMT -05:00) Cancun',
    },
    {
      'id': 'America/Eirunepe',
      'name': '(GMT -05:00) Eirunepe',
    },
    {
      'id': 'America/Atikokan',
      'name': '(GMT -05:00) Atikokan',
    },
    {
      'id': 'America/Bogota',
      'name': '(GMT -05:00) Bogota',
    },
    {
      'id': 'America/Port-au-Prince',
      'name': '(GMT -05:00) Port-au-Prince',
    },
    {
      'id': 'America/Cayman',
      'name': '(GMT -05:00) Cayman',
    },
    {
      'id': 'America/Indiana/Marengo',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Grand_Turk',
      'name': '(GMT -05:00) Grand Turk',
    },
    {
      'id': 'America/Iqaluit',
      'name': '(GMT -05:00) Iqaluit',
    },
    {
      'id': 'America/Pangnirtung',
      'name': '(GMT -05:00) Pangnirtung',
    },
    {
      'id': 'America/Panama',
      'name': '(GMT -05:00) Panama',
    },
    {
      'id': 'America/Lima',
      'name': '(GMT -05:00) Lima',
    },
    {
      'id': 'America/Kentucky/Monticello',
      'name': '(GMT -05:00) Kentucky',
    },
    {
      'id': 'America/Nassau',
      'name': '(GMT -05:00) Nassau',
    },
    {
      'id': 'Pacific/Easter',
      'name': '(GMT -05:00) Easter',
    },
    {
      'id': 'America/Kentucky/Louisville',
      'name': '(GMT -05:00) Kentucky',
    },
    {
      'id': 'America/Jamaica',
      'name': '(GMT -05:00) Jamaica',
    },
    {
      'id': 'America/New_York',
      'name': '(GMT -05:00) Eastern Time (US & Canada)',
    },
    {
      'id': 'America/Toronto',
      'name': '(GMT -05:00) Toronto',
    },
    {
      'id': 'America/Nipigon',
      'name': '(GMT -05:00) Nipigon',
    },
    {
      'id': 'America/Indiana/Winamac',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Vincennes',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Vevay',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Petersburg',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Indianapolis',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Havana',
      'name': '(GMT -05:00) Havana',
    },
    {
      'id': 'America/Guayaquil',
      'name': '(GMT -05:00) Guayaquil',
    },
    {
      'id': 'America/Thunder_Bay',
      'name': '(GMT -05:00) Thunder Bay',
    },
    {
      'id': 'America/Winnipeg',
      'name': '(GMT -06:00) Winnipeg',
    },
    {
      'id': 'America/Rankin_Inlet',
      'name': '(GMT -06:00) Rankin Inlet',
    },
    {
      'id': 'America/Tegucigalpa',
      'name': '(GMT -06:00) Tegucigalpa',
    },
    {
      'id': 'America/Swift_Current',
      'name': '(GMT -06:00) Swift Current',
    },
    {
      'id': 'America/Rainy_River',
      'name': '(GMT -06:00) Rainy River',
    },
    {
      'id': 'America/Regina',
      'name': '(GMT -06:00) Regina',
    },
    {
      'id': 'America/Resolute',
      'name': '(GMT -06:00) Resolute',
    },
    {
      'id': 'Pacific/Galapagos',
      'name': '(GMT -06:00) Galapagos',
    },
    {
      'id': 'America/North_Dakota/Beulah',
      'name': '(GMT -06:00) North Dakota',
    },
    {
      'id': 'America/Indiana/Knox',
      'name': '(GMT -06:00) Indiana',
    },
    {
      'id': 'America/Bahia_Banderas',
      'name': '(GMT -06:00) Bahia Banderas',
    },
    {
      'id': 'America/Guatemala',
      'name': '(GMT -06:00) Guatemala',
    },
    {
      'id': 'America/North_Dakota/Center',
      'name': '(GMT -06:00) North Dakota',
    },
    {
      'id': 'America/North_Dakota/New_Salem',
      'name': '(GMT -06:00) North Dakota',
    },
    {
      'id': 'America/Monterrey',
      'name': '(GMT -06:00) Monterrey',
    },
    {
      'id': 'America/Mexico_City',
      'name': '(GMT -06:00) Central Time (US & Canada)',
    },
    {
      'id': 'America/Merida',
      'name': '(GMT -06:00) Merida',
    },
    {
      'id': 'America/Menominee',
      'name': '(GMT -06:00) Menominee',
    },
    {
      'id': 'America/Belize',
      'name': '(GMT -06:00) Belize',
    },
    {
      'id': 'America/Managua',
      'name': '(GMT -06:00) Managua',
    },
    {
      'id': 'America/Indiana/Tell_City',
      'name': '(GMT -06:00) Indiana',
    },
    {
      'id': 'America/Matamoros',
      'name': '(GMT -06:00) Matamoros',
    },
    {
      'id': 'America/El_Salvador',
      'name': '(GMT -06:00) El_Salvador',
    },
    {
      'id': 'America/Chicago',
      'name': '(GMT -06:00) Chicago',
    },
    {
      'id': 'America/Costa_Rica',
      'name': '(GMT -06:00) Costa Rica',
    },
    {
      'id': 'America/Dawson',
      'name': '(GMT -07:00) Dawson',
    },
    {
      'id': 'America/Yellowknife',
      'name': '(GMT -07:00) Yellowknife',
    },
    {
      'id': 'America/Boise',
      'name': '(GMT -07:00) Boise',
    },
    {
      'id': 'America/Whitehorse',
      'name': '(GMT -07:00) Whitehorse',
    },
    {
      'id': 'America/Cambridge_Bay',
      'name': '(GMT -07:00) Cambridge Bay',
    },
    {
      'id': 'America/Ojinaga',
      'name': '(GMT -07:00) Ojinaga',
    },
    {
      'id': 'America/Chihuahua',
      'name': '(GMT -07:00) Chihuahua',
    },
    {
      'id': 'America/Fort_Nelson',
      'name': '(GMT -07:00) Fort Nelson',
    },
    {
      'id': 'America/Mazatlan',
      'name': '(GMT -07:00) Mazatlan',
    },
    {
      'id': 'America/Creston',
      'name': '(GMT -07:00) Creston',
    },
    {
      'id': 'America/Dawson_Creek',
      'name': '(GMT -07:00) Dawson Creek',
    },
    {
      'id': 'America/Inuvik',
      'name': '(GMT -07:00) Inuvik',
    },
    {
      'id': 'America/Edmonton',
      'name': '(GMT -07:00) Edmonton',
    },
    {
      'id': 'America/Hermosillo',
      'name': '(GMT -07:00) Hermosillo',
    },
    {
      'id': 'America/Phoenix',
      'name': '(GMT -07:00) Phoenix',
    },
    {
      'id': 'America/Denver',
      'name': '(GMT -07:00) Mountain Time (US & Canada)',
    },
    {
      'id': 'Pacific/Pitcairn',
      'name': '(GMT -08:00) Pitcairn',
    },
    {
      'id': 'America/Vancouver',
      'name': '(GMT -08:00) Vancouver',
    },
    {
      'id': 'America/Tijuana',
      'name': '(GMT -08:00) Tijuana',
    },
    {
      'id': 'America/Los_Angeles',
      'name': '(GMT -08:00) Pacific Time (US & Canada)',
    },
    {
      'id': 'Pacific/Gambier',
      'name': '(GMT -09:00) Gambier',
    },
    {
      'id': 'America/Anchorage',
      'name': '(GMT -09:00) Anchorage',
    },
    {
      'id': 'America/Nome',
      'name': '(GMT -09:00) Nome',
    },
    {
      'id': 'America/Yakutat',
      'name': '(GMT -09:00) Yakutat',
    },
    {
      'id': 'America/Sitka',
      'name': '(GMT -09:00) Sitka',
    },
    {
      'id': 'America/Metlakatla',
      'name': '(GMT -09:00) Metlakatla',
    },
    {
      'id': 'America/Juneau',
      'name': '(GMT -09:00) Juneau',
    },
    {
      'id': 'Pacific/Marquesas',
      'name': '(GMT -09:30) Marquesas',
    },
    {
      'id': 'Pacific/Rarotonga',
      'name': '(GMT -10:00) Rarotonga',
    },
    {
      'id': 'Pacific/Honolulu',
      'name': '(GMT -10:00) Honolulu',
    },
    {
      'id': 'America/Adak',
      'name': '(GMT -10:00) Adak',
    },
    {
      'id': 'Pacific/Tahiti',
      'name': '(GMT -10:00) Tahiti',
    },
    {
      'id': 'Pacific/Midway',
      'name': '(GMT -11:00) International Date Line West',
    },
    {
      'id': 'Pacific/Niue',
      'name': '(GMT -11:00) Niue',
    },
    {
      'id': 'Pacific/Pago_Pago',
      'name': '(GMT -11:00) Pago Pago',
    },
    {
      'id': 'Pacific/Samoa',
      'name': '(GMT -11:00) Samoa',
    },
  ],
  'es': [
    {
      'id': 'Africa/Abidjan',
      'name': '(GMT +00:00) Abiyán',
    },
    {
      'id': 'Europe/Lisbon',
      'name': '(GMT +00:00) Lisboa',
    },
    {
      'id': 'Atlantic/Faroe',
      'name': '(GMT +00:00) Feroe',
    },
    {
      'id': 'Atlantic/Madeira',
      'name': '(GMT +00:00) Madeira',
    },
    {
      'id': 'Atlantic/Reykjavik',
      'name': '(GMT +00:00) Reikiavik',
    },
    {
      'id': 'Africa/Lome',
      'name': '(GMT +00:00) Lomé',
    },
    {
      'id': 'Atlantic/St_Helena',
      'name': '(GMT +00:00) Santa Elena',
    },
    {
      'id': 'Europe/London',
      'name': '(GMT +00:00) Londres',
    },
    {
      'id': 'Europe/Jersey',
      'name': '(GMT +00:00) Jersey',
    },
    {
      'id': 'Africa/Freetown',
      'name': '(GMT +00:00) Ciudad Libre',
    },
    {
      'id': 'Europe/Isle_of_Man',
      'name': '(GMT +00:00) Isla de Man',
    },
    {
      'id': 'Europe/Guernsey',
      'name': '(GMT +00:00) Guernesey',
    },
    {
      'id': 'Africa/Monrovia',
      'name': '(GMT +00:00) Monrovia',
    },
    {
      'id': 'Europe/Dublin',
      'name': '(GMT +00:00) Dublín',
    },
    {
      'id': 'Africa/Nouakchott',
      'name': '(GMT +00:00) Nuakchot',
    },
    {
      'id': 'Africa/Ouagadougou',
      'name': '(GMT +00:00) Uagadugú',
    },
    {
      'id': 'Africa/Sao_Tome',
      'name': '(GMT +00:00) Santo Tomé',
    },
    {
      'id': 'Antarctica/Troll',
      'name': '(GMT +00:00) Troll',
    },
    {
      'id': 'Atlantic/Canary',
      'name': '(GMT +00:00) Canarias',
    },
    {
      'id': 'UTC',
      'name': '(GMT +00:00) UTC',
    },
    {
      'id': 'Africa/Accra',
      'name': '(GMT +00:00) Acra',
    },
    {
      'id': 'America/Danmarkshavn',
      'name': '(GMT +00:00) Dinamarca',
    },
    {
      'id': 'Africa/Dakar',
      'name': '(GMT +00:00) Dakar',
    },
    {
      'id': 'Africa/Conakry',
      'name': '(GMT +00:00) Conakri',
    },
    {
      'id': 'Africa/Bamako',
      'name': '(GMT +00:00) Bamako',
    },
    {
      'id': 'Africa/Banjul',
      'name': '(GMT +00:00) Banjul',
    },
    {
      'id': 'Africa/Bissau',
      'name': '(GMT +00:00) Bisáu',
    },
    {
      'id': 'Europe/Vienna',
      'name': '(GMT +01:00) Viena',
    },
    {
      'id': 'Europe/Tirane',
      'name': '(GMT +01:00) Tirana',
    },
    {
      'id': 'Europe/Busingen',
      'name': '(GMT +01:00) Busingen',
    },
    {
      'id': 'Europe/Sarajevo',
      'name': '(GMT +01:00) Sarajevo',
    },
    {
      'id': 'Europe/Copenhagen',
      'name': '(GMT +01:00) Copenhague',
    },
    {
      'id': 'Europe/Gibraltar',
      'name': '(GMT +01:00) Gibraltar',
    },
    {
      'id': 'Europe/Vaduz',
      'name': '(GMT +01:00) Vaduz',
    },
    {
      'id': 'Europe/Vatican',
      'name': '(GMT +01:00) Vaticano',
    },
    {
      'id': 'Europe/Prague',
      'name': '(GMT +01:00) Praga',
    },
    {
      'id': 'Europe/Ljubljana',
      'name': '(GMT +01:00) Liubliana',
    },
    {
      'id': 'Europe/San_Marino',
      'name': '(GMT +01:00) San Marino',
    },
    {
      'id': 'Europe/Podgorica',
      'name': '(GMT +01:00) Podgorica',
    },
    {
      'id': 'Europe/Luxembourg',
      'name': '(GMT +01:00) Luxemburgo',
    },
    {
      'id': 'Europe/Warsaw',
      'name': '(GMT +01:00) Varsovia',
    },
    {
      'id': 'Europe/Madrid',
      'name': '(GMT +01:00) Madrid',
    },
    {
      'id': 'Europe/Malta',
      'name': '(GMT +01:00) Malta',
    },
    {
      'id': 'Europe/Monaco',
      'name': '(GMT +01:00) Mónaco',
    },
    {
      'id': 'Europe/Zagreb',
      'name': '(GMT +01:00) Zagreb',
    },
    {
      'id': 'Europe/Budapest',
      'name': '(GMT +01:00) Budapest',
    },
    {
      'id': 'Europe/Oslo',
      'name': '(GMT +01:00) Oslo',
    },
    {
      'id': 'Europe/Paris',
      'name': '(GMT +01:00) París',
    },
    {
      'id': 'Europe/Zurich',
      'name': '(GMT +01:00) Zúrich',
    },
    {
      'id': 'Europe/Rome',
      'name': '(GMT +01:00) Roma',
    },
    {
      'id': 'Africa/Niamey',
      'name': '(GMT +01:00) Niamey',
    },
    {
      'id': 'Europe/Skopje',
      'name': '(GMT +01:00) Skopie',
    },
    {
      'id': 'Africa/Casablanca',
      'name': '(GMT +01:00) Casablanca',
    },
    {
      'id': 'Arctic/Longyearbyen',
      'name': '(GMT +01:00) Longyearbyen',
    },
    {
      'id': 'Africa/Douala',
      'name': '(GMT +01:00) Duala',
    },
    {
      'id': 'Africa/El_Aaiun',
      'name': '(GMT +01:00) El Aaiún',
    },
    {
      'id': 'Africa/Brazzaville',
      'name': '(GMT +01:00) Brazzaville',
    },
    {
      'id': 'Africa/Kinshasa',
      'name': '(GMT +01:00) Kinsasa',
    },
    {
      'id': 'Africa/Tunis',
      'name': '(GMT +01:00) Túnez',
    },
    {
      'id': 'Africa/Lagos',
      'name': '(GMT +01:00) Lagos',
    },
    {
      'id': 'Africa/Libreville',
      'name': '(GMT +01:00) Libreville',
    },
    {
      'id': 'Africa/Luanda',
      'name': '(GMT +01:00) Luanda',
    },
    {
      'id': 'Europe/Amsterdam',
      'name': '(GMT +01:00) Ámsterdam',
    },
    {
      'id': 'Africa/Malabo',
      'name': '(GMT +01:00) Malabo',
    },
    {
      'id': 'Europe/Andorra',
      'name': '(GMT +01:00) Andorra',
    },
    {
      'id': 'Africa/Bangui',
      'name': '(GMT +01:00) Bangui',
    },
    {
      'id': 'Africa/Algiers',
      'name': '(GMT +01:00) Argel',
    },
    {
      'id': 'Europe/Belgrade',
      'name': '(GMT +01:00) Belgrado',
    },
    {
      'id': 'Europe/Berlin',
      'name': '(GMT +01:00) Berlín',
    },
    {
      'id': 'Europe/Bratislava',
      'name': '(GMT +01:00) Bratislava',
    },
    {
      'id': 'Africa/Ndjamena',
      'name': '(GMT +01:00) Ndjamena',
    },
    {
      'id': 'Europe/Stockholm',
      'name': '(GMT +01:00) Estocolmo',
    },
    {
      'id': 'Africa/Porto-Novo',
      'name': '(GMT +01:00) Porto Novo',
    },
    {
      'id': 'Europe/Brussels',
      'name': '(GMT +01:00) Bruselas',
    },
    {
      'id': 'Africa/Ceuta',
      'name': '(GMT +01:00) Ceuta',
    },
    {
      'id': 'Asia/Gaza',
      'name': '(GMT +02:00) Gaza',
    },
    {
      'id': 'Asia/Famagusta',
      'name': '(GMT +02:00) Famagusta',
    },
    {
      'id': 'Asia/Hebron',
      'name': '(GMT +02:00) Hebrón',
    },
    {
      'id': 'Asia/Damascus',
      'name': '(GMT +02:00) Damasco',
    },
    {
      'id': 'Europe/Bucharest',
      'name': '(GMT +02:00) Bucarest',
    },
    {
      'id': 'Asia/Beirut',
      'name': '(GMT +02:00) Beirut',
    },
    {
      'id': 'Europe/Riga',
      'name': '(GMT +02:00) Riga',
    },
    {
      'id': 'Asia/Jerusalem',
      'name': '(GMT +02:00) Jerusalén',
    },
    {
      'id': 'Asia/Nicosia',
      'name': '(GMT +02:00) Nicosia',
    },
    {
      'id': 'Europe/Kiev',
      'name': '(GMT +02:00) Kiev',
    },
    {
      'id': 'Europe/Kaliningrad',
      'name': '(GMT +02:00) Kaliningrado',
    },
    {
      'id': 'Europe/Athens',
      'name': '(GMT +02:00) Atenas',
    },
    {
      'id': 'Europe/Chisinau',
      'name': '(GMT +02:00) Chisináu',
    },
    {
      'id': 'Europe/Mariehamn',
      'name': '(GMT +02:00) Mariehamn',
    },
    {
      'id': 'Europe/Helsinki',
      'name': '(GMT +02:00) Helsinki',
    },
    {
      'id': 'Europe/Sofia',
      'name': '(GMT +02:00) Sofía',
    },
    {
      'id': 'Africa/Maputo',
      'name': '(GMT +02:00) Maputo',
    },
    {
      'id': 'Africa/Maseru',
      'name': '(GMT +02:00) Maseru',
    },
    {
      'id': 'Africa/Mbabane',
      'name': '(GMT +02:00) Mbabane',
    },
    {
      'id': 'Africa/Tripoli',
      'name': '(GMT +02:00) Trípoli',
    },
    {
      'id': 'Africa/Windhoek',
      'name': '(GMT +02:00) Windhoek',
    },
    {
      'id': 'Africa/Lubumbashi',
      'name': '(GMT +02:00) Lubumbashi',
    },
    {
      'id': 'Africa/Kigali',
      'name': '(GMT +02:00) Kigali',
    },
    {
      'id': 'Africa/Khartoum',
      'name': '(GMT +02:00) Jartum',
    },
    {
      'id': 'Africa/Juba',
      'name': '(GMT +02:00) Juba',
    },
    {
      'id': 'Europe/Tallinn',
      'name': '(GMT +02:00) Tallin',
    },
    {
      'id': 'Africa/Johannesburg',
      'name': '(GMT +02:00) Johannesburgo',
    },
    {
      'id': 'Africa/Harare',
      'name': '(GMT +02:00) Harare',
    },
    {
      'id': 'Africa/Gaborone',
      'name': '(GMT +02:00) Gaborone',
    },
    {
      'id': 'Africa/Lusaka',
      'name': '(GMT +02:00) Lusaka',
    },
    {
      'id': 'Europe/Vilnius',
      'name': '(GMT +02:00) Vilna',
    },
    {
      'id': 'Europe/Uzhgorod',
      'name': '(GMT +02:00) Úzhgorod',
    },
    {
      'id': 'Africa/Blantyre',
      'name': '(GMT +02:00) Blantyre',
    },
    {
      'id': 'Africa/Cairo',
      'name': '(GMT +02:00) El Cairo',
    },
    {
      'id': 'Europe/Zaporozhye',
      'name': '(GMT +02:00) Zaporozhye',
    },
    {
      'id': 'Africa/Bujumbura',
      'name': '(GMT +02:00) Buyumbura',
    },
    {
      'id': 'Indian/Comoro',
      'name': '(GMT +03:00) Comoras',
    },
    {
      'id': 'Indian/Antananarivo',
      'name': '(GMT +03:00) Antananarivo',
    },
    {
      'id': 'Europe/Simferopol',
      'name': '(GMT +03:00) Simferópol',
    },
    {
      'id': 'Africa/Addis_Ababa',
      'name': '(GMT +03:00) Addis Abeba',
    },
    {
      'id': 'Africa/Mogadishu',
      'name': '(GMT +03:00) Mogadiscio',
    },
    {
      'id': 'Africa/Nairobi',
      'name': '(GMT +03:00) Nairobi',
    },
    {
      'id': 'Africa/Asmara',
      'name': '(GMT +03:00) Asmara',
    },
    {
      'id': 'Europe/Volgograd',
      'name': '(GMT +03:00) Volgogrado',
    },
    {
      'id': 'Africa/Kampala',
      'name': '(GMT +03:00) Kampala',
    },
    {
      'id': 'Asia/Qatar',
      'name': '(GMT +03:00) Catar',
    },
    {
      'id': 'Asia/Riyadh',
      'name': '(GMT +03:00) Riad',
    },
    {
      'id': 'Europe/Minsk',
      'name': '(GMT +03:00) Minsk',
    },
    {
      'id': 'Europe/Istanbul',
      'name': '(GMT +03:00) Estambul',
    },
    {
      'id': 'Asia/Kuwait',
      'name': '(GMT +03:00) Kuwait',
    },
    {
      'id': 'Antarctica/Syowa',
      'name': '(GMT +03:00) Syowa',
    },
    {
      'id': 'Europe/Kirov',
      'name': '(GMT +03:00) Kirov',
    },
    {
      'id': 'Asia/Bahrain',
      'name': '(GMT +03:00) Baréin',
    },
    {
      'id': 'Europe/Moscow',
      'name': '(GMT +03:00) Moscú',
    },
    {
      'id': 'Indian/Mayotte',
      'name': '(GMT +03:00) Mayotte',
    },
    {
      'id': 'Asia/Aden',
      'name': '(GMT +03:00) Adén',
    },
    {
      'id': 'Asia/Amman',
      'name': '(GMT +03:00) Amán',
    },
    {
      'id': 'Africa/Dar_es_Salaam',
      'name': '(GMT +03:00) Dar es Salaam',
    },
    {
      'id': 'Asia/Baghdad',
      'name': '(GMT +03:00) Bagdad',
    },
    {
      'id': 'Africa/Djibouti',
      'name': '(GMT +03:00) Yibuti',
    },
    {
      'id': 'Asia/Tehran',
      'name': '(GMT +03:30) Teherán',
    },
    {
      'id': 'Europe/Astrakhan',
      'name': '(GMT +04:00) Astracán',
    },
    {
      'id': 'Asia/Dubai',
      'name': '(GMT +04:00) Dubái',
    },
    {
      'id': 'Asia/Yerevan',
      'name': '(GMT +04:00) Ereván',
    },
    {
      'id': 'Indian/Mauritius',
      'name': '(GMT +04:00) Mauricio',
    },
    {
      'id': 'Asia/Baku',
      'name': '(GMT +04:00) Bakú',
    },
    {
      'id': 'Asia/Muscat',
      'name': '(GMT +04:00) Mascate',
    },
    {
      'id': 'Indian/Mahe',
      'name': '(GMT +04:00) Mahé',
    },
    {
      'id': 'Indian/Reunion',
      'name': '(GMT +04:00) Reunión',
    },
    {
      'id': 'Europe/Samara',
      'name': '(GMT +04:00) Samara',
    },
    {
      'id': 'Europe/Saratov',
      'name': '(GMT +04:00) Sarátov',
    },
    {
      'id': 'Europe/Ulyanovsk',
      'name': '(GMT +04:00) Uliánovsk',
    },
    {
      'id': 'Asia/Tbilisi',
      'name': '(GMT +04:00) Tiflis',
    },
    {
      'id': 'Asia/Kabul',
      'name': '(GMT +04:30) Kabul',
    },
    {
      'id': 'Indian/Maldives',
      'name': '(GMT +05:00) Maldivas',
    },
    {
      'id': 'Indian/Kerguelen',
      'name': '(GMT +05:00) Kergüelen',
    },
    {
      'id': 'Asia/Aqtau',
      'name': '(GMT +05:00) Aktau',
    },
    {
      'id': 'Asia/Aqtobe',
      'name': '(GMT +05:00) Aktobe',
    },
    {
      'id': 'Asia/Ashgabat',
      'name': '(GMT +05:00) Asjabad',
    },
    {
      'id': 'Asia/Atyrau',
      'name': '(GMT +05:00) Atirau',
    },
    {
      'id': 'Asia/Samarkand',
      'name': '(GMT +05:00) Samarcanda',
    },
    {
      'id': 'Antarctica/Mawson',
      'name': '(GMT +05:00) Mawson',
    },
    {
      'id': 'Asia/Yekaterinburg',
      'name': '(GMT +05:00) Ekaterimburgo',
    },
    {
      'id': 'Asia/Qyzylorda',
      'name': '(GMT +05:00) Qyzylorda',
    },
    {
      'id': 'Asia/Karachi',
      'name': '(GMT +05:00) Karachi',
    },
    {
      'id': 'Asia/Dushanbe',
      'name': '(GMT +05:00) Dushanbe',
    },
    {
      'id': 'Asia/Oral',
      'name': '(GMT +05:00) Oral',
    },
    {
      'id': 'Asia/Tashkent',
      'name': '(GMT +05:00) Taskent',
    },
    {
      'id': 'Asia/Colombo',
      'name': '(GMT +05:30) Colombo',
    },
    {
      'id': 'Asia/Kolkata',
      'name': '(GMT +05:30) Calcuta',
    },
    {
      'id': 'Asia/Kathmandu',
      'name': '(GMT +05:45) Katmandú',
    },
    {
      'id': 'Asia/Qostanay',
      'name': '(GMT +06:00) Qostanay',
    },
    {
      'id': 'Antarctica/Vostok',
      'name': '(GMT +06:00) Vostok',
    },
    {
      'id': 'Asia/Urumqi',
      'name': '(GMT +06:00) Ürümqi',
    },
    {
      'id': 'Asia/Almaty',
      'name': '(GMT +06:00) Almatý',
    },
    {
      'id': 'Asia/Thimphu',
      'name': '(GMT +06:00) Timbu',
    },
    {
      'id': 'Asia/Bishkek',
      'name': '(GMT +06:00) Biskek',
    },
    {
      'id': 'Indian/Chagos',
      'name': '(GMT +06:00) Chagos',
    },
    {
      'id': 'Asia/Dhaka',
      'name': '(GMT +06:00) Dacca',
    },
    {
      'id': 'Asia/Omsk',
      'name': '(GMT +06:00) Omsk',
    },
    {
      'id': 'Asia/Yangon',
      'name': '(GMT +06:30) Rangún',
    },
    {
      'id': 'Indian/Cocos',
      'name': '(GMT +06:30) Cocos',
    },
    {
      'id': 'Asia/Jakarta',
      'name': '(GMT +07:00) Yakarta',
    },
    {
      'id': 'Asia/Bangkok',
      'name': '(GMT +07:00) Bangkok',
    },
    {
      'id': 'Antarctica/Davis',
      'name': '(GMT +07:00) Davis',
    },
    {
      'id': 'Asia/Barnaul',
      'name': '(GMT +07:00) Barnaúl',
    },
    {
      'id': 'Indian/Christmas',
      'name': '(GMT +07:00) Navidad',
    },
    {
      'id': 'Asia/Krasnoyarsk',
      'name': '(GMT +07:00) Krasnoyarsk',
    },
    {
      'id': 'Asia/Ho_Chi_Minh',
      'name': '(GMT +07:00) Ho Chi Minh',
    },
    {
      'id': 'Asia/Hovd',
      'name': '(GMT +07:00) Hoy',
    },
    {
      'id': 'Asia/Novokuznetsk',
      'name': '(GMT +07:00) Novokuznetsk',
    },
    {
      'id': 'Asia/Novosibirsk',
      'name': '(GMT +07:00) Novosibirsk',
    },
    {
      'id': 'Asia/Vientiane',
      'name': '(GMT +07:00) Vientián',
    },
    {
      'id': 'Asia/Phnom_Penh',
      'name': '(GMT +07:00) Phnom Penh',
    },
    {
      'id': 'Asia/Pontianak',
      'name': '(GMT +07:00) Pontianak',
    },
    {
      'id': 'Asia/Tomsk',
      'name': '(GMT +07:00) Asia/Tomsk',
    },
    {
      'id': 'Asia/Shanghai',
      'name': '(GMT +08:00) Shanghái',
    },
    {
      'id': 'Asia/Hong_Kong',
      'name': '(GMT +08:00) Hong Kong',
    },
    {
      'id': 'Australia/Perth',
      'name': '(GMT +08:00) Perth',
    },
    {
      'id': 'Asia/Choibalsan',
      'name': '(GMT +08:00) Choibalsan',
    },
    {
      'id': 'Asia/Brunei',
      'name': '(GMT +08:00) Brunéi',
    },
    {
      'id': 'Asia/Chungking',
      'name': '(GMT +08:00) Chongqing',
    },
    {
      'id': 'Asia/Ulaanbaatar',
      'name': '(GMT +08:00) Ulán Bataar',
    },
    {
      'id': 'Asia/Taipei',
      'name': '(GMT +08:00) Taipéi',
    },
    {
      'id': 'Asia/Singapore',
      'name': '(GMT +08:00) Singapur',
    },
    {
      'id': 'Asia/Manila',
      'name': '(GMT +08:00) Manila',
    },
    {
      'id': 'Asia/Makassar',
      'name': '(GMT +08:00) Makassar',
    },
    {
      'id': 'Asia/Kuching',
      'name': '(GMT +08:00) Kuching',
    },
    {
      'id': 'Asia/Macau',
      'name': '(GMT +08:00) Macao',
    },
    {
      'id': 'Asia/Kuala_Lumpur',
      'name': '(GMT +08:00) Kuala Lumpur',
    },
    {
      'id': 'Asia/Irkutsk',
      'name': '(GMT +08:00) Irkutsk',
    },
    {
      'id': 'Australia/Eucla',
      'name': '(GMT +08:45) Eucla',
    },
    {
      'id': 'Asia/Tokyo',
      'name': '(GMT +09:00) Tokio',
    },
    {
      'id': 'Asia/Chita',
      'name': '(GMT +09:00) Chitá',
    },
    {
      'id': 'Asia/Yakutsk',
      'name': '(GMT +09:00) Yakutsk',
    },
    {
      'id': 'Asia/Khandyga',
      'name': '(GMT +09:00) Khandyga',
    },
    {
      'id': 'Asia/Pyongyang',
      'name': '(GMT +09:00) Pionyang',
    },
    {
      'id': 'Asia/Dili',
      'name': '(GMT +09:00) Dili',
    },
    {
      'id': 'Asia/Jayapura',
      'name': '(GMT +09:00) Jayapura',
    },
    {
      'id': 'Asia/Seoul',
      'name': '(GMT +09:00) Seúl',
    },
    {
      'id': 'Pacific/Palau',
      'name': '(GMT +09:00) Palaos',
    },
    {
      'id': 'Australia/Darwin',
      'name': '(GMT +09:30) Darwin',
    },
    {
      'id': 'Australia/Brisbane',
      'name': '(GMT +10:00) Brisbane',
    },
    {
      'id': 'Pacific/Port_Moresby',
      'name': '(GMT +10:00) Puerto Moresby',
    },
    {
      'id': 'Australia/Lindeman',
      'name': '(GMT +10:00) Lindeman',
    },
    {
      'id': 'Pacific/Saipan',
      'name': '(GMT +10:00) Saipán',
    },
    {
      'id': 'Antarctica/DumontDUrville',
      'name': '(GMT +10:00) Dumont-d\'Urville',
    },
    {
      'id': 'Asia/Ust-Nera',
      'name': '(GMT +10:00) Ust-Nera',
    },
    {
      'id': 'Pacific/Guam',
      'name': '(GMT +10:00) Guam',
    },
    {
      'id': 'Asia/Vladivostok',
      'name': '(GMT +10:00) Vladivostok',
    },
    {
      'id': 'Pacific/Chuuk',
      'name': '(GMT +10:00) Chuuk',
    },
    {
      'id': 'Australia/Adelaide',
      'name': '(GMT +10:30) Adelaida',
    },
    {
      'id': 'Australia/Broken_Hill',
      'name': '(GMT +10:30) Colina Rota',
    },
    {
      'id': 'Asia/Sakhalin',
      'name': '(GMT +11:00) Sajalín',
    },
    {
      'id': 'Pacific/Kosrae',
      'name': '(GMT +11:00) Kosrae',
    },
    {
      'id': 'Australia/Sydney',
      'name': '(GMT +11:00) Sídney',
    },
    {
      'id': 'Pacific/Efate',
      'name': '(GMT +11:00) Éfate',
    },
    {
      'id': 'Australia/Melbourne',
      'name': '(GMT +11:00) Melbourne',
    },
    {
      'id': 'Australia/Lord_Howe',
      'name': '(GMT +11:00) Lord Howe',
    },
    {
      'id': 'Asia/Srednekolymsk',
      'name': '(GMT +11:00) Srednekolymsk',
    },
    {
      'id': 'Australia/Hobart',
      'name': '(GMT +11:00) Hobart',
    },
    {
      'id': 'Pacific/Bougainville',
      'name': '(GMT +11:00) Bougainville',
    },
    {
      'id': 'Pacific/Noumea',
      'name': '(GMT +11:00) Numea',
    },
    {
      'id': 'Asia/Magadan',
      'name': '(GMT +11:00) Magadán',
    },
    {
      'id': 'Pacific/Guadalcanal',
      'name': '(GMT +11:00) Guadalcanal',
    },
    {
      'id': 'Pacific/Pohnpei',
      'name': '(GMT +11:00) Pohnpei',
    },
    {
      'id': 'Australia/Canberra',
      'name': '(GMT +11:00) Canberra',
    },
    {
      'id': 'Antarctica/Macquarie',
      'name': '(GMT +11:00) Macquarie',
    },
    {
      'id': 'Antarctica/Casey',
      'name': '(GMT +11:00) Casey',
    },
    {
      'id': 'Asia/Anadyr',
      'name': '(GMT +12:00) Anádyr',
    },
    {
      'id': 'Pacific/Kwajalein',
      'name': '(GMT +12:00) Kwajalein',
    },
    {
      'id': 'Asia/Kamchatka',
      'name': '(GMT +12:00) Kamchatka',
    },
    {
      'id': 'Pacific/Wallis',
      'name': '(GMT +12:00) Wallis',
    },
    {
      'id': 'Pacific/Wake',
      'name': '(GMT +12:00) Despertar',
    },
    {
      'id': 'Pacific/Tarawa',
      'name': '(GMT +12:00) Tarawa',
    },
    {
      'id': 'Pacific/Fiji',
      'name': '(GMT +12:00) Fiyi',
    },
    {
      'id': 'Pacific/Funafuti',
      'name': '(GMT +12:00) Funafuti',
    },
    {
      'id': 'Pacific/Norfolk',
      'name': '(GMT +12:00) Norfolk',
    },
    {
      'id': 'Pacific/Nauru',
      'name': '(GMT +12:00) Nauru',
    },
    {
      'id': 'Pacific/Majuro',
      'name': '(GMT +12:00) Majuro',
    },
    {
      'id': 'Pacific/Apia',
      'name': '(GMT +13:00) Apia',
    },
    {
      'id': 'Pacific/Auckland',
      'name': '(GMT +13:00) Auckland',
    },
    {
      'id': 'Pacific/Kanton',
      'name': '(GMT +13:00) Kantón',
    },
    {
      'id': 'Pacific/Fakaofo',
      'name': '(GMT +13:00) Fakaofo',
    },
    {
      'id': 'Antarctica/McMurdo',
      'name': '(GMT +13:00) McMurdo',
    },
    {
      'id': 'Pacific/Tongatapu',
      'name': '(GMT +13:00) Tongatapu',
    },
    {
      'id': 'Pacific/Chatham',
      'name': '(GMT +13:45) Chatham',
    },
    {
      'id': 'Pacific/Kiritimati',
      'name': '(GMT +14:00) Kiritimati',
    },
    {
      'id': 'Atlantic/Azores',
      'name': '(GMT -01:00) Azores',
    },
    {
      'id': 'Atlantic/Cape_Verde',
      'name': '(GMT -01:00) Islas de Cabo Verde.',
    },
    {
      'id': 'America/Scoresbysund',
      'name': '(GMT -01:00) Puntuaciones por el sol',
    },
    {
      'id': 'Atlantic/South_Georgia',
      'name': '(GMT -02:00) Georgia del Sur',
    },
    {
      'id': 'America/Noronha',
      'name': '(GMT -02:00) Atlántico Medio',
    },
    {
      'id': 'America/Cayenne',
      'name': '(GMT -03:00) Cayena',
    },
    {
      'id': 'America/Fortaleza',
      'name': '(GMT -03:00) Fortaleza',
    },
    {
      'id': 'America/Maceio',
      'name': '(GMT -03:00) Maceió',
    },
    {
      'id': 'Antarctica/Palmer',
      'name': '(GMT -03:00) Palmer',
    },
    {
      'id': 'America/Bahia',
      'name': '(GMT -03:00) Bahía',
    },
    {
      'id': 'America/Miquelon',
      'name': '(GMT -03:00) Miquelón',
    },
    {
      'id': 'Atlantic/Stanley',
      'name': '(GMT -03:00) Stanley',
    },
    {
      'id': 'America/Montevideo',
      'name': '(GMT -03:00) Montevideo',
    },
    {
      'id': 'America/Belem',
      'name': '(GMT -03:00) Belén',
    },
    {
      'id': 'America/Argentina/Cordoba',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Asuncion',
      'name': '(GMT -03:00) Asunción',
    },
    {
      'id': 'America/Argentina/Ushuaia',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Tucuman',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/San_Juan',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Salta',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Rio_Gallegos',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Mendoza',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Araguaina',
      'name': '(GMT -03:00) Araguaina',
    },
    {
      'id': 'America/Argentina/Buenos_Aires',
      'name': '(GMT -03:00) Buenos Aires',
    },
    {
      'id': 'America/Argentina/La_Rioja',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Jujuy',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/Catamarca',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'America/Argentina/San_Luis',
      'name': '(GMT -03:00) Argentina',
    },
    {
      'id': 'Antarctica/Rothera',
      'name': '(GMT -03:00) Rothera',
    },
    {
      'id': 'America/Recife',
      'name': '(GMT -03:00) Recife',
    },
    {
      'id': 'America/Sao_Paulo',
      'name': '(GMT -03:00) São Paulo',
    },
    {
      'id': 'America/Santiago',
      'name': '(GMT -03:00) Santiago',
    },
    {
      'id': 'America/Nuuk',
      'name': '(GMT -03:00) América/Nuuk',
    },
    {
      'id': 'America/Paramaribo',
      'name': '(GMT -03:00) Paramaribo',
    },
    {
      'id': 'America/Santarem',
      'name': '(GMT -03:00) Santarém',
    },
    {
      'id': 'America/Punta_Arenas',
      'name': '(GMT -03:00) Punta_Arenas',
    },
    {
      'id': 'America/St_Johns',
      'name': '(GMT -03:30) St. Johns',
    },
    {
      'id': 'America/Curacao',
      'name': '(GMT -04:00) Curazao',
    },
    {
      'id': 'America/St_Lucia',
      'name': '(GMT -04:00) Santa Lucía',
    },
    {
      'id': 'America/La_Paz',
      'name': '(GMT -04:00) La Paz',
    },
    {
      'id': 'America/Kralendijk',
      'name': '(GMT -04:00) Kralendijk',
    },
    {
      'id': 'America/Aruba',
      'name': '(GMT -04:00) Aruba',
    },
    {
      'id': 'America/St_Kitts',
      'name': '(GMT -04:00) San Cristóbal',
    },
    {
      'id': 'America/Cuiaba',
      'name': '(GMT -04:00) Cuiabá',
    },
    {
      'id': 'America/Porto_Velho',
      'name': '(GMT -04:00) Porto Viejo',
    },
    {
      'id': 'America/Dominica',
      'name': '(GMT -04:00) Dominica',
    },
    {
      'id': 'America/Barbados',
      'name': '(GMT -04:00) Barbados',
    },
    {
      'id': 'America/St_Barthelemy',
      'name': '(GMT -04:00) San Bartolomé',
    },
    {
      'id': 'America/Santo_Domingo',
      'name': '(GMT -04:00) Santo Domingo',
    },
    {
      'id': 'America/Halifax',
      'name': '(GMT -04:00) Hora del Atlántico (Canadá)',
    },
    {
      'id': 'America/Guyana',
      'name': '(GMT -04:00) Guayana',
    },
    {
      'id': 'America/Glace_Bay',
      'name': '(GMT -04:00) Bahía Glace',
    },
    {
      'id': 'America/Guadeloupe',
      'name': '(GMT -04:00) Guadalupe',
    },
    {
      'id': 'America/Goose_Bay',
      'name': '(GMT -04:00) Bahía Ganso',
    },
    {
      'id': 'America/Lower_Princes',
      'name': '(GMT -04:00) Príncipes Inferiores',
    },
    {
      'id': 'America/St_Thomas',
      'name': '(GMT -04:00) Santo Tomás',
    },
    {
      'id': 'America/Campo_Grande',
      'name': '(GMT -04:00) Campo Grande',
    },
    {
      'id': 'America/Puerto_Rico',
      'name': '(GMT -04:00) Puerto Rico',
    },
    {
      'id': 'Atlantic/Bermuda',
      'name': '(GMT -04:00) Bermudas',
    },
    {
      'id': 'America/Blanc-Sablon',
      'name': '(GMT -04:00) Blanc-Sablón',
    },
    {
      'id': 'America/Anguilla',
      'name': '(GMT -04:00) Anguila',
    },
    {
      'id': 'America/Boa_Vista',
      'name': '(GMT -04:00) Boa Vista',
    },
    {
      'id': 'America/Montserrat',
      'name': '(GMT -04:00) Montserrat',
    },
    {
      'id': 'America/Antigua',
      'name': '(GMT -04:00) Antigua',
    },
    {
      'id': 'America/Tortola',
      'name': '(GMT -04:00) Tórtola',
    },
    {
      'id': 'America/Manaus',
      'name': '(GMT -04:00) Manaos',
    },
    {
      'id': 'America/Moncton',
      'name': '(GMT -04:00) Moncton',
    },
    {
      'id': 'America/Caracas',
      'name': '(GMT -04:00) Caracas',
    },
    {
      'id': 'America/Thule',
      'name': '(GMT -04:00) Thule',
    },
    {
      'id': 'America/Port_of_Spain',
      'name': '(GMT -04:00) Puerto España',
    },
    {
      'id': 'America/St_Vincent',
      'name': '(GMT -04:00) San Vicente',
    },
    {
      'id': 'America/Martinique',
      'name': '(GMT -04:00) Martinica',
    },
    {
      'id': 'America/Marigot',
      'name': '(GMT -04:00) Marigot',
    },
    {
      'id': 'America/Grenada',
      'name': '(GMT -04:00) Granada',
    },
    {
      'id': 'America/Detroit',
      'name': '(GMT -05:00) Detroit',
    },
    {
      'id': 'America/Rio_Branco',
      'name': '(GMT -05:00) Río Branco',
    },
    {
      'id': 'America/Cancun',
      'name': '(GMT -05:00) Cancún',
    },
    {
      'id': 'America/Eirunepe',
      'name': '(GMT -05:00) Eirunepe',
    },
    {
      'id': 'America/Atikokan',
      'name': '(GMT -05:00) Atikokan',
    },
    {
      'id': 'America/Bogota',
      'name': '(GMT -05:00) Bogotá',
    },
    {
      'id': 'America/Port-au-Prince',
      'name': '(GMT -05:00) Puerto Príncipe',
    },
    {
      'id': 'America/Cayman',
      'name': '(GMT -05:00) Caimán',
    },
    {
      'id': 'America/Indiana/Marengo',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Grand_Turk',
      'name': '(GMT -05:00) Gran Turca',
    },
    {
      'id': 'America/Iqaluit',
      'name': '(GMT -05:00) Iqaluit',
    },
    {
      'id': 'America/Pangnirtung',
      'name': '(GMT -05:00) Pangnirtung',
    },
    {
      'id': 'America/Panama',
      'name': '(GMT -05:00) Panamá',
    },
    {
      'id': 'America/Lima',
      'name': '(GMT -05:00) Lima',
    },
    {
      'id': 'America/Kentucky/Monticello',
      'name': '(GMT -05:00) Kentucky',
    },
    {
      'id': 'America/Nassau',
      'name': '(GMT -05:00) Nasáu',
    },
    {
      'id': 'Pacific/Easter',
      'name': '(GMT -05:00) Semana Santa',
    },
    {
      'id': 'America/Kentucky/Louisville',
      'name': '(GMT -05:00) Kentucky',
    },
    {
      'id': 'America/Jamaica',
      'name': '(GMT -05:00) Jamaica',
    },
    {
      'id': 'America/New_York',
      'name': '(GMT -05:00) Hora del Este (EE. UU. y Canadá)',
    },
    {
      'id': 'America/Toronto',
      'name': '(GMT -05:00) Toronto',
    },
    {
      'id': 'America/Nipigon',
      'name': '(GMT -05:00) Nipigón',
    },
    {
      'id': 'America/Indiana/Winamac',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Vincennes',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Vevay',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Petersburg',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Indiana/Indianapolis',
      'name': '(GMT -05:00) Indiana',
    },
    {
      'id': 'America/Havana',
      'name': '(GMT -05:00) La Habana',
    },
    {
      'id': 'America/Guayaquil',
      'name': '(GMT -05:00) Guayaquil',
    },
    {
      'id': 'America/Thunder_Bay',
      'name': '(GMT -05:00) Thunder Bay',
    },
    {
      'id': 'America/Winnipeg',
      'name': '(GMT -06:00) Winnipeg',
    },
    {
      'id': 'America/Rankin_Inlet',
      'name': '(GMT -06:00) Entrada Rankin',
    },
    {
      'id': 'America/Tegucigalpa',
      'name': '(GMT -06:00) Tegucigalpa',
    },
    {
      'id': 'America/Swift_Current',
      'name': '(GMT -06:00) Corriente rápida',
    },
    {
      'id': 'America/Rainy_River',
      'name': '(GMT -06:00) Río lluvioso',
    },
    {
      'id': 'America/Regina',
      'name': '(GMT -06:00) Regina',
    },
    {
      'id': 'America/Resolute',
      'name': '(GMT -06:00) Resuelto',
    },
    {
      'id': 'Pacific/Galapagos',
      'name': '(GMT -06:00) Galápagos',
    },
    {
      'id': 'America/North_Dakota/Beulah',
      'name': '(GMT -06:00) Dakota del Norte',
    },
    {
      'id': 'America/Indiana/Knox',
      'name': '(GMT -06:00) Indiana',
    },
    {
      'id': 'America/Bahia_Banderas',
      'name': '(GMT -06:00) Bahía Banderas',
    },
    {
      'id': 'America/Guatemala',
      'name': '(GMT -06:00) Guatemala',
    },
    {
      'id': 'America/North_Dakota/Center',
      'name': '(GMT -06:00) Dakota del Norte',
    },
    {
      'id': 'America/North_Dakota/New_Salem',
      'name': '(GMT -06:00) Dakota del Norte',
    },
    {
      'id': 'America/Monterrey',
      'name': '(GMT -06:00) Monterrey',
    },
    {
      'id': 'America/Mexico_City',
      'name': '(GMT -06:00) Hora central (EE. UU. y Canadá)',
    },
    {
      'id': 'America/Merida',
      'name': '(GMT -06:00) Mérida',
    },
    {
      'id': 'America/Menominee',
      'name': '(GMT -06:00) Menominee',
    },
    {
      'id': 'America/Belize',
      'name': '(GMT -06:00) Belice',
    },
    {
      'id': 'America/Managua',
      'name': '(GMT -06:00) Managua',
    },
    {
      'id': 'America/Indiana/Tell_City',
      'name': '(GMT -06:00) Indiana',
    },
    {
      'id': 'America/Matamoros',
      'name': '(GMT -06:00) Matamoros',
    },
    {
      'id': 'America/El_Salvador',
      'name': '(GMT -06:00) El_Salvador',
    },
    {
      'id': 'America/Chicago',
      'name': '(GMT -06:00) Chicago',
    },
    {
      'id': 'America/Costa_Rica',
      'name': '(GMT -06:00) Costa Rica',
    },
    {
      'id': 'America/Dawson',
      'name': '(GMT -07:00) Dawson',
    },
    {
      'id': 'America/Yellowknife',
      'name': '(GMT -07:00) Yellowknife',
    },
    {
      'id': 'America/Boise',
      'name': '(GMT -07:00) Boise',
    },
    {
      'id': 'America/Whitehorse',
      'name': '(GMT -07:00) Caballo Blanco',
    },
    {
      'id': 'America/Cambridge_Bay',
      'name': '(GMT -07:00) Bahía de Cambridge',
    },
    {
      'id': 'America/Ojinaga',
      'name': '(GMT -07:00) Ojinaga',
    },
    {
      'id': 'America/Chihuahua',
      'name': '(GMT -07:00) Chihuahua',
    },
    {
      'id': 'America/Fort_Nelson',
      'name': '(GMT -07:00) América/Fort_Nelson',
    },
    {
      'id': 'America/Mazatlan',
      'name': '(GMT -07:00) Mazatlán',
    },
    {
      'id': 'America/Creston',
      'name': '(GMT -07:00) Crestón',
    },
    {
      'id': 'America/Dawson_Creek',
      'name': '(GMT -07:00) Dawson Creek',
    },
    {
      'id': 'America/Inuvik',
      'name': '(GMT -07:00) Inuvik',
    },
    {
      'id': 'America/Edmonton',
      'name': '(GMT -07:00) Edmonton',
    },
    {
      'id': 'America/Hermosillo',
      'name': '(GMT -07:00) Hermosillo',
    },
    {
      'id': 'America/Phoenix',
      'name': '(GMT -07:00) Fénix',
    },
    {
      'id': 'America/Denver',
      'name': '(GMT -07:00) Hora de la montaña (EE. UU. y Canadá)',
    },
    {
      'id': 'Pacific/Pitcairn',
      'name': '(GMT -08:00) Pitcairn',
    },
    {
      'id': 'America/Vancouver',
      'name': '(GMT -08:00) Vancouver',
    },
    {
      'id': 'America/Tijuana',
      'name': '(GMT -08:00) Tijuana',
    },
    {
      'id': 'America/Los_Angeles',
      'name': '(GMT -08:00) Hora del Pacífico (EE. UU. y Canadá)',
    },
    {
      'id': 'Pacific/Gambier',
      'name': '(GMT -09:00) Gambier',
    },
    {
      'id': 'America/Anchorage',
      'name': '(GMT -09:00) Anchorage',
    },
    {
      'id': 'America/Nome',
      'name': '(GMT -09:00) Nome',
    },
    {
      'id': 'America/Yakutat',
      'name': '(GMT -09:00) Yakutat',
    },
    {
      'id': 'America/Sitka',
      'name': '(GMT -09:00) Sitka',
    },
    {
      'id': 'America/Metlakatla',
      'name': '(GMT -09:00) Metlakatla',
    },
    {
      'id': 'America/Juneau',
      'name': '(GMT -09:00) Juneau',
    },
    {
      'id': 'Pacific/Marquesas',
      'name': '(GMT -09:30) Marquesas',
    },
    {
      'id': 'Pacific/Rarotonga',
      'name': '(GMT -10:00) Rarotonga',
    },
    {
      'id': 'Pacific/Honolulu',
      'name': '(GMT -10:00) Honolulú',
    },
    {
      'id': 'America/Adak',
      'name': '(GMT -10:00) Adak',
    },
    {
      'id': 'Pacific/Tahiti',
      'name': '(GMT -10:00) Tahití',
    },
    {
      'id': 'Pacific/Midway',
      'name': '(GMT -11:00) Línea de fecha internacional Oeste',
    },
    {
      'id': 'Pacific/Niue',
      'name': '(GMT -11:00) Niue',
    },
    {
      'id': 'Pacific/Pago_Pago',
      'name': '(GMT -11:00) Pago Pago',
    },
    {
      'id': 'Pacific/Samoa',
      'name': '(GMT -11:00) Samoa',
    },
  ],
  'ru': [
    {
      'id': 'Africa/Abidjan',
      'name': '(GMT +00:00) Абиджан',
    },
    {
      'id': 'Europe/Lisbon',
      'name': '(GMT +00:00) Лиссабон',
    },
    {
      'id': 'Atlantic/Faroe',
      'name': '(GMT +00:00) Фарерские',
    },
    {
      'id': 'Atlantic/Madeira',
      'name': '(GMT +00:00) Мадейра',
    },
    {
      'id': 'Atlantic/Reykjavik',
      'name': '(GMT +00:00) Рейкьявик',
    },
    {
      'id': 'Africa/Lome',
      'name': '(GMT +00:00) Ломе',
    },
    {
      'id': 'Atlantic/St_Helena',
      'name': '(GMT +00:00) Остров Святой Елены',
    },
    {
      'id': 'Europe/London',
      'name': '(GMT +00:00) Лондон',
    },
    {
      'id': 'Europe/Jersey',
      'name': '(GMT +00:00) Джерси',
    },
    {
      'id': 'Africa/Freetown',
      'name': '(GMT +00:00) Фритаун',
    },
    {
      'id': 'Europe/Isle_of_Man',
      'name': '(GMT +00:00) Остров Мэн',
    },
    {
      'id': 'Europe/Guernsey',
      'name': '(GMT +00:00) Гернси',
    },
    {
      'id': 'Africa/Monrovia',
      'name': '(GMT +00:00) Монровия',
    },
    {
      'id': 'Europe/Dublin',
      'name': '(GMT +00:00) Дублин',
    },
    {
      'id': 'Africa/Nouakchott',
      'name': '(GMT +00:00) Нуакшот',
    },
    {
      'id': 'Africa/Ouagadougou',
      'name': '(GMT +00:00) Уагадугу',
    },
    {
      'id': 'Africa/Sao_Tome',
      'name': '(GMT +00:00) Сан-Томе и Принсипи',
    },
    {
      'id': 'Antarctica/Troll',
      'name': '(GMT +00:00) Троль',
    },
    {
      'id': 'Atlantic/Canary',
      'name': '(GMT +00:00) Канарейка',
    },
    {
      'id': 'UTC',
      'name': '(GMT +00:00) UTC',
    },
    {
      'id': 'Africa/Accra',
      'name': '(GMT +00:00) Аккра',
    },
    {
      'id': 'America/Danmarkshavn',
      'name': '(GMT +00:00) Данмарксхавн',
    },
    {
      'id': 'Africa/Dakar',
      'name': '(GMT +00:00) Дакар',
    },
    {
      'id': 'Africa/Conakry',
      'name': '(GMT +00:00) Конакри',
    },
    {
      'id': 'Africa/Bamako',
      'name': '(GMT +00:00) Бамако',
    },
    {
      'id': 'Africa/Banjul',
      'name': '(GMT +00:00) Банжул',
    },
    {
      'id': 'Africa/Bissau',
      'name': '(GMT +00:00) Бисау',
    },
    {
      'id': 'Europe/Vienna',
      'name': '(GMT +01:00) Вена',
    },
    {
      'id': 'Europe/Tirane',
      'name': '(GMT +01:00) Тирана',
    },
    {
      'id': 'Europe/Busingen',
      'name': '(GMT +01:00) Бюзинген',
    },
    {
      'id': 'Europe/Sarajevo',
      'name': '(GMT +01:00) Сараево',
    },
    {
      'id': 'Europe/Copenhagen',
      'name': '(GMT +01:00) Копенгаген',
    },
    {
      'id': 'Europe/Gibraltar',
      'name': '(GMT +01:00) Гибралтар',
    },
    {
      'id': 'Europe/Vaduz',
      'name': '(GMT +01:00) Вадуц',
    },
    {
      'id': 'Europe/Vatican',
      'name': '(GMT +01:00) Ватикан',
    },
    {
      'id': 'Europe/Prague',
      'name': '(GMT +01:00) Прага',
    },
    {
      'id': 'Europe/Ljubljana',
      'name': '(GMT +01:00) Любляна',
    },
    {
      'id': 'Europe/San_Marino',
      'name': '(GMT +01:00) Сан-Марино',
    },
    {
      'id': 'Europe/Podgorica',
      'name': '(GMT +01:00) Подгорица',
    },
    {
      'id': 'Europe/Luxembourg',
      'name': '(GMT +01:00) Люксембург',
    },
    {
      'id': 'Europe/Warsaw',
      'name': '(GMT +01:00) Варшава',
    },
    {
      'id': 'Europe/Madrid',
      'name': '(GMT +01:00) Мадрид',
    },
    {
      'id': 'Europe/Malta',
      'name': '(GMT +01:00) Мальта',
    },
    {
      'id': 'Europe/Monaco',
      'name': '(GMT +01:00) Монако',
    },
    {
      'id': 'Europe/Zagreb',
      'name': '(GMT +01:00) Загреб',
    },
    {
      'id': 'Europe/Budapest',
      'name': '(GMT +01:00) Будапешт',
    },
    {
      'id': 'Europe/Oslo',
      'name': '(GMT +01:00) Осло',
    },
    {
      'id': 'Europe/Paris',
      'name': '(GMT +01:00) Париж',
    },
    {
      'id': 'Europe/Zurich',
      'name': '(GMT +01:00) Цюрих',
    },
    {
      'id': 'Europe/Rome',
      'name': '(GMT +01:00) Рим',
    },
    {
      'id': 'Africa/Niamey',
      'name': '(GMT +01:00) Ниамей',
    },
    {
      'id': 'Europe/Skopje',
      'name': '(GMT +01:00) Скопье',
    },
    {
      'id': 'Africa/Casablanca',
      'name': '(GMT +01:00) Касабланка',
    },
    {
      'id': 'Arctic/Longyearbyen',
      'name': '(GMT +01:00) Лонгйир',
    },
    {
      'id': 'Africa/Douala',
      'name': '(GMT +01:00) Дуала',
    },
    {
      'id': 'Africa/El_Aaiun',
      'name': '(GMT +01:00) Ель Аюн',
    },
    {
      'id': 'Africa/Brazzaville',
      'name': '(GMT +01:00) Браззавиль',
    },
    {
      'id': 'Africa/Kinshasa',
      'name': '(GMT +01:00) Киншаса',
    },
    {
      'id': 'Africa/Tunis',
      'name': '(GMT +01:00) Тунис',
    },
    {
      'id': 'Africa/Lagos',
      'name': '(GMT +01:00) Лагос',
    },
    {
      'id': 'Africa/Libreville',
      'name': '(GMT +01:00) Либревиль',
    },
    {
      'id': 'Africa/Luanda',
      'name': '(GMT +01:00) Луанда',
    },
    {
      'id': 'Europe/Amsterdam',
      'name': '(GMT +01:00) Амстердам',
    },
    {
      'id': 'Africa/Malabo',
      'name': '(GMT +01:00) Малабо',
    },
    {
      'id': 'Europe/Andorra',
      'name': '(GMT +01:00) Андорра',
    },
    {
      'id': 'Africa/Bangui',
      'name': '(GMT +01:00) Банги',
    },
    {
      'id': 'Africa/Algiers',
      'name': '(GMT +01:00) Алта',
    },
    {
      'id': 'Europe/Belgrade',
      'name': '(GMT +01:00) Белград',
    },
    {
      'id': 'Europe/Berlin',
      'name': '(GMT +01:00) Берлин',
    },
    {
      'id': 'Europe/Bratislava',
      'name': '(GMT +01:00) Братислава',
    },
    {
      'id': 'Africa/Ndjamena',
      'name': '(GMT +01:00) Нджамена',
    },
    {
      'id': 'Europe/Stockholm',
      'name': '(GMT +01:00) Стокгольм',
    },
    {
      'id': 'Africa/Porto-Novo',
      'name': '(GMT +01:00) Порто-Ново',
    },
    {
      'id': 'Europe/Brussels',
      'name': '(GMT +01:00) Брюссель',
    },
    {
      'id': 'Africa/Ceuta',
      'name': '(GMT +01:00) Сеута',
    },
    {
      'id': 'Asia/Gaza',
      'name': '(GMT +02:00) Газа',
    },
    {
      'id': 'Asia/Famagusta',
      'name': '(GMT +02:00) Asia/Famagusta',
    },
    {
      'id': 'Asia/Hebron',
      'name': '(GMT +02:00) Хеврон',
    },
    {
      'id': 'Asia/Damascus',
      'name': '(GMT +02:00) Дамаск',
    },
    {
      'id': 'Europe/Bucharest',
      'name': '(GMT +02:00) Бухарест',
    },
    {
      'id': 'Asia/Beirut',
      'name': '(GMT +02:00) Бейрут',
    },
    {
      'id': 'Europe/Riga',
      'name': '(GMT +02:00) Рига',
    },
    {
      'id': 'Asia/Jerusalem',
      'name': '(GMT +02:00) Иерусалим',
    },
    {
      'id': 'Asia/Nicosia',
      'name': '(GMT +02:00) Никозия',
    },
    {
      'id': 'Europe/Kiev',
      'name': '(GMT +02:00) Киев',
    },
    {
      'id': 'Europe/Kaliningrad',
      'name': '(GMT +02:00) Калининград',
    },
    {
      'id': 'Europe/Athens',
      'name': '(GMT +02:00) Афины',
    },
    {
      'id': 'Europe/Chisinau',
      'name': '(GMT +02:00) Кишинев',
    },
    {
      'id': 'Europe/Mariehamn',
      'name': '(GMT +02:00) Мариехамн',
    },
    {
      'id': 'Europe/Helsinki',
      'name': '(GMT +02:00) Хельсинки',
    },
    {
      'id': 'Europe/Sofia',
      'name': '(GMT +02:00) София',
    },
    {
      'id': 'Africa/Maputo',
      'name': '(GMT +02:00) Мапуту',
    },
    {
      'id': 'Africa/Maseru',
      'name': '(GMT +02:00) Масеру',
    },
    {
      'id': 'Africa/Mbabane',
      'name': '(GMT +02:00) Мбабане',
    },
    {
      'id': 'Africa/Tripoli',
      'name': '(GMT +02:00) Триполи',
    },
    {
      'id': 'Africa/Windhoek',
      'name': '(GMT +02:00) Виндхук',
    },
    {
      'id': 'Africa/Lubumbashi',
      'name': '(GMT +02:00) Лубумбаши',
    },
    {
      'id': 'Africa/Kigali',
      'name': '(GMT +02:00) Кигали',
    },
    {
      'id': 'Africa/Khartoum',
      'name': '(GMT +02:00) Хартум',
    },
    {
      'id': 'Africa/Juba',
      'name': '(GMT +02:00) Джуба',
    },
    {
      'id': 'Europe/Tallinn',
      'name': '(GMT +02:00) Таллин',
    },
    {
      'id': 'Africa/Johannesburg',
      'name': '(GMT +02:00) Йоханнесбург',
    },
    {
      'id': 'Africa/Harare',
      'name': '(GMT +02:00) Хараре',
    },
    {
      'id': 'Africa/Gaborone',
      'name': '(GMT +02:00) Габороне',
    },
    {
      'id': 'Africa/Lusaka',
      'name': '(GMT +02:00) Лусака',
    },
    {
      'id': 'Europe/Vilnius',
      'name': '(GMT +02:00) Вильнюс',
    },
    {
      'id': 'Europe/Uzhgorod',
      'name': '(GMT +02:00) Ужгород',
    },
    {
      'id': 'Africa/Blantyre',
      'name': '(GMT +02:00) Блантайр',
    },
    {
      'id': 'Africa/Cairo',
      'name': '(GMT +02:00) Каир',
    },
    {
      'id': 'Europe/Zaporozhye',
      'name': '(GMT +02:00) Запорожье',
    },
    {
      'id': 'Africa/Bujumbura',
      'name': '(GMT +02:00) Бужумбура',
    },
    {
      'id': 'Indian/Comoro',
      'name': '(GMT +03:00) Коморо',
    },
    {
      'id': 'Indian/Antananarivo',
      'name': '(GMT +03:00) Антананариву',
    },
    {
      'id': 'Europe/Simferopol',
      'name': '(GMT +03:00) Симферополь',
    },
    {
      'id': 'Africa/Addis_Ababa',
      'name': '(GMT +03:00) Аддис-Абеба',
    },
    {
      'id': 'Africa/Mogadishu',
      'name': '(GMT +03:00) Могадишо',
    },
    {
      'id': 'Africa/Nairobi',
      'name': '(GMT +03:00) Найроби',
    },
    {
      'id': 'Africa/Asmara',
      'name': '(GMT +03:00) Асмэре',
    },
    {
      'id': 'Europe/Volgograd',
      'name': '(GMT +03:00) Волгоград',
    },
    {
      'id': 'Africa/Kampala',
      'name': '(GMT +03:00) Кампала',
    },
    {
      'id': 'Asia/Qatar',
      'name': '(GMT +03:00) Катар',
    },
    {
      'id': 'Asia/Riyadh',
      'name': '(GMT +03:00) Эр-Рияд',
    },
    {
      'id': 'Europe/Minsk',
      'name': '(GMT +03:00) Минск',
    },
    {
      'id': 'Europe/Istanbul',
      'name': '(GMT +03:00) Стамбул',
    },
    {
      'id': 'Asia/Kuwait',
      'name': '(GMT +03:00) Кувейт',
    },
    {
      'id': 'Antarctica/Syowa',
      'name': '(GMT +03:00) Сева',
    },
    {
      'id': 'Europe/Kirov',
      'name': '(GMT +03:00) Киров',
    },
    {
      'id': 'Asia/Bahrain',
      'name': '(GMT +03:00) Бахрейн',
    },
    {
      'id': 'Europe/Moscow',
      'name': '(GMT +03:00) Москва',
    },
    {
      'id': 'Indian/Mayotte',
      'name': '(GMT +03:00) Майотта',
    },
    {
      'id': 'Asia/Aden',
      'name': '(GMT +03:00) Аден',
    },
    {
      'id': 'Asia/Amman',
      'name': '(GMT +03:00) Амман',
    },
    {
      'id': 'Africa/Dar_es_Salaam',
      'name': '(GMT +03:00) Дар-эс-Салам',
    },
    {
      'id': 'Asia/Baghdad',
      'name': '(GMT +03:00) Багдад',
    },
    {
      'id': 'Africa/Djibouti',
      'name': '(GMT +03:00) Джибути',
    },
    {
      'id': 'Asia/Tehran',
      'name': '(GMT +03:30) Тегеран',
    },
    {
      'id': 'Europe/Astrakhan',
      'name': '(GMT +04:00) Астрахань',
    },
    {
      'id': 'Asia/Dubai',
      'name': '(GMT +04:00) Дубай',
    },
    {
      'id': 'Asia/Yerevan',
      'name': '(GMT +04:00) Ереван',
    },
    {
      'id': 'Indian/Mauritius',
      'name': '(GMT +04:00) Маврикий',
    },
    {
      'id': 'Asia/Baku',
      'name': '(GMT +04:00) Баку',
    },
    {
      'id': 'Asia/Muscat',
      'name': '(GMT +04:00) Мускат',
    },
    {
      'id': 'Indian/Mahe',
      'name': '(GMT +04:00) Маэ',
    },
    {
      'id': 'Indian/Reunion',
      'name': '(GMT +04:00) Реюньон',
    },
    {
      'id': 'Europe/Samara',
      'name': '(GMT +04:00) Самара',
    },
    {
      'id': 'Europe/Saratov',
      'name': '(GMT +04:00) Саратов',
    },
    {
      'id': 'Europe/Ulyanovsk',
      'name': '(GMT +04:00) Ульяновск',
    },
    {
      'id': 'Asia/Tbilisi',
      'name': '(GMT +04:00) Тбилиси',
    },
    {
      'id': 'Asia/Kabul',
      'name': '(GMT +04:30) Кабул',
    },
    {
      'id': 'Indian/Maldives',
      'name': '(GMT +05:00) Мальдивы',
    },
    {
      'id': 'Indian/Kerguelen',
      'name': '(GMT +05:00) Кергелен',
    },
    {
      'id': 'Asia/Aqtau',
      'name': '(GMT +05:00) Актау',
    },
    {
      'id': 'Asia/Aqtobe',
      'name': '(GMT +05:00) Актобе',
    },
    {
      'id': 'Asia/Ashgabat',
      'name': '(GMT +05:00) Ашхабад',
    },
    {
      'id': 'Asia/Atyrau',
      'name': '(GMT +05:00) Атырау',
    },
    {
      'id': 'Asia/Samarkand',
      'name': '(GMT +05:00) Самарканд',
    },
    {
      'id': 'Antarctica/Mawson',
      'name': '(GMT +05:00) Моусон',
    },
    {
      'id': 'Asia/Yekaterinburg',
      'name': '(GMT +05:00) Екатеринбург',
    },
    {
      'id': 'Asia/Qyzylorda',
      'name': '(GMT +05:00) Кызылорда',
    },
    {
      'id': 'Asia/Karachi',
      'name': '(GMT +05:00) Карачи',
    },
    {
      'id': 'Asia/Dushanbe',
      'name': '(GMT +05:00) Душанбе',
    },
    {
      'id': 'Asia/Oral',
      'name': '(GMT +05:00) Оральный',
    },
    {
      'id': 'Asia/Tashkent',
      'name': '(GMT +05:00) Ташкент',
    },
    {
      'id': 'Asia/Colombo',
      'name': '(GMT +05:30) Коломбо',
    },
    {
      'id': 'Asia/Kolkata',
      'name': '(GMT +05:30) Калькутта',
    },
    {
      'id': 'Asia/Kathmandu',
      'name': '(GMT +05:45) Катманду',
    },
    {
      'id': 'Asia/Qostanay',
      'name': '(GMT +06:00) Костанай',
    },
    {
      'id': 'Antarctica/Vostok',
      'name': '(GMT +06:00) Восток',
    },
    {
      'id': 'Asia/Urumqi',
      'name': '(GMT +06:00) Урумчи',
    },
    {
      'id': 'Asia/Almaty',
      'name': '(GMT +06:00) Алматы',
    },
    {
      'id': 'Asia/Thimphu',
      'name': '(GMT +06:00) Тхимпху',
    },
    {
      'id': 'Asia/Bishkek',
      'name': '(GMT +06:00) Бишкек',
    },
    {
      'id': 'Indian/Chagos',
      'name': '(GMT +06:00) Чагос',
    },
    {
      'id': 'Asia/Dhaka',
      'name': '(GMT +06:00) Дакка',
    },
    {
      'id': 'Asia/Omsk',
      'name': '(GMT +06:00) Омск',
    },
    {
      'id': 'Asia/Yangon',
      'name': '(GMT +06:30) Янгон',
    },
    {
      'id': 'Indian/Cocos',
      'name': '(GMT +06:30) Кокосовые',
    },
    {
      'id': 'Asia/Jakarta',
      'name': '(GMT +07:00) Джакарта',
    },
    {
      'id': 'Asia/Bangkok',
      'name': '(GMT +07:00) Бангкок',
    },
    {
      'id': 'Antarctica/Davis',
      'name': '(GMT +07:00) Дэвис',
    },
    {
      'id': 'Asia/Barnaul',
      'name': '(GMT +07:00) Барнаул',
    },
    {
      'id': 'Indian/Christmas',
      'name': '(GMT +07:00) Остров Рождества',
    },
    {
      'id': 'Asia/Krasnoyarsk',
      'name': '(GMT +07:00) Красноярск',
    },
    {
      'id': 'Asia/Ho_Chi_Minh',
      'name': '(GMT +07:00) Хошимин',
    },
    {
      'id': 'Asia/Hovd',
      'name': '(GMT +07:00) Ховд',
    },
    {
      'id': 'Asia/Novokuznetsk',
      'name': '(GMT +07:00) Новокузнецк',
    },
    {
      'id': 'Asia/Novosibirsk',
      'name': '(GMT +07:00) Новосибирск',
    },
    {
      'id': 'Asia/Vientiane',
      'name': '(GMT +07:00) Вьентьян',
    },
    {
      'id': 'Asia/Phnom_Penh',
      'name': '(GMT +07:00) Пномпень',
    },
    {
      'id': 'Asia/Pontianak',
      'name': '(GMT +07:00) Понтианак',
    },
    {
      'id': 'Asia/Tomsk',
      'name': '(GMT +07:00) Томск',
    },
    {
      'id': 'Asia/Shanghai',
      'name': '(GMT +08:00) Шанхай',
    },
    {
      'id': 'Asia/Hong_Kong',
      'name': '(GMT +08:00) Гонконг',
    },
    {
      'id': 'Australia/Perth',
      'name': '(GMT +08:00) Перт',
    },
    {
      'id': 'Asia/Choibalsan',
      'name': '(GMT +08:00) Чойбалсан',
    },
    {
      'id': 'Asia/Brunei',
      'name': '(GMT +08:00) Бруней',
    },
    {
      'id': 'Asia/Chungking',
      'name': '(GMT +08:00) Чунцин',
    },
    {
      'id': 'Asia/Ulaanbaatar',
      'name': '(GMT +08:00) Улан-Батор',
    },
    {
      'id': 'Asia/Taipei',
      'name': '(GMT +08:00) Тайбэй',
    },
    {
      'id': 'Asia/Singapore',
      'name': '(GMT +08:00) Сингапур',
    },
    {
      'id': 'Asia/Manila',
      'name': '(GMT +08:00) Манила',
    },
    {
      'id': 'Asia/Makassar',
      'name': '(GMT +08:00) Макассар',
    },
    {
      'id': 'Asia/Kuching',
      'name': '(GMT +08:00) Кучинг',
    },
    {
      'id': 'Asia/Macau',
      'name': '(GMT +08:00) Макао',
    },
    {
      'id': 'Asia/Kuala_Lumpur',
      'name': '(GMT +08:00) Куала-Лумпур',
    },
    {
      'id': 'Asia/Irkutsk',
      'name': '(GMT +08:00) Иркутск',
    },
    {
      'id': 'Australia/Eucla',
      'name': '(GMT +08:45) Еукла',
    },
    {
      'id': 'Asia/Tokyo',
      'name': '(GMT +09:00) Токио',
    },
    {
      'id': 'Asia/Chita',
      'name': '(GMT +09:00) Чита',
    },
    {
      'id': 'Asia/Yakutsk',
      'name': '(GMT +09:00) Якутск',
    },
    {
      'id': 'Asia/Khandyga',
      'name': '(GMT +09:00) Хандыга',
    },
    {
      'id': 'Asia/Pyongyang',
      'name': '(GMT +09:00) Пхеньян',
    },
    {
      'id': 'Asia/Dili',
      'name': '(GMT +09:00) Дили',
    },
    {
      'id': 'Asia/Jayapura',
      'name': '(GMT +09:00) Джаяпура',
    },
    {
      'id': 'Asia/Seoul',
      'name': '(GMT +09:00) Сеул',
    },
    {
      'id': 'Pacific/Palau',
      'name': '(GMT +09:00) Палау',
    },
    {
      'id': 'Australia/Darwin',
      'name': '(GMT +09:30) Дарвин',
    },
    {
      'id': 'Australia/Brisbane',
      'name': '(GMT +10:00) Брисбен',
    },
    {
      'id': 'Pacific/Port_Moresby',
      'name': '(GMT +10:00) Порт-Морсби',
    },
    {
      'id': 'Australia/Lindeman',
      'name': '(GMT +10:00) Линдеман',
    },
    {
      'id': 'Pacific/Saipan',
      'name': '(GMT +10:00) Сайпан',
    },
    {
      'id': 'Antarctica/DumontDUrville',
      'name': '(GMT +10:00) Станция Дюмон-д’Юрвиль',
    },
    {
      'id': 'Asia/Ust-Nera',
      'name': '(GMT +10:00) Усть-Нера',
    },
    {
      'id': 'Pacific/Guam',
      'name': '(GMT +10:00) Гуам',
    },
    {
      'id': 'Asia/Vladivostok',
      'name': '(GMT +10:00) Владивосток',
    },
    {
      'id': 'Pacific/Chuuk',
      'name': '(GMT +10:00) Чуук',
    },
    {
      'id': 'Australia/Adelaide',
      'name': '(GMT +10:30) Аделаида',
    },
    {
      'id': 'Australia/Broken_Hill',
      'name': '(GMT +10:30) Брокен-Хилл',
    },
    {
      'id': 'Asia/Sakhalin',
      'name': '(GMT +11:00) Сахалин',
    },
    {
      'id': 'Pacific/Kosrae',
      'name': '(GMT +11:00) Косраэ',
    },
    {
      'id': 'Australia/Sydney',
      'name': '(GMT +11:00) Сидней',
    },
    {
      'id': 'Pacific/Efate',
      'name': '(GMT +11:00) Эфате',
    },
    {
      'id': 'Australia/Melbourne',
      'name': '(GMT +11:00) Мельбурн',
    },
    {
      'id': 'Australia/Lord_Howe',
      'name': '(GMT +11:00) Лорд-Хау',
    },
    {
      'id': 'Asia/Srednekolymsk',
      'name': '(GMT +11:00) Среднеколымск',
    },
    {
      'id': 'Australia/Hobart',
      'name': '(GMT +11:00) Хобарт',
    },
    {
      'id': 'Pacific/Bougainville',
      'name': '(GMT +11:00) Бугенвиль',
    },
    {
      'id': 'Pacific/Noumea',
      'name': '(GMT +11:00) Нумеа',
    },
    {
      'id': 'Asia/Magadan',
      'name': '(GMT +11:00) Магадан',
    },
    {
      'id': 'Pacific/Guadalcanal',
      'name': '(GMT +11:00) Гуадалканал',
    },
    {
      'id': 'Pacific/Pohnpei',
      'name': '(GMT +11:00) Понпеи',
    },
    {
      'id': 'Australia/Canberra',
      'name': '(GMT +11:00) Канберра',
    },
    {
      'id': 'Antarctica/Macquarie',
      'name': '(GMT +11:00) Маккуори',
    },
    {
      'id': 'Antarctica/Casey',
      'name': '(GMT +11:00) Кейси',
    },
    {
      'id': 'Asia/Anadyr',
      'name': '(GMT +12:00) Анадырь',
    },
    {
      'id': 'Pacific/Kwajalein',
      'name': '(GMT +12:00) Кваджалейн',
    },
    {
      'id': 'Asia/Kamchatka',
      'name': '(GMT +12:00) Камчатский',
    },
    {
      'id': 'Pacific/Wallis',
      'name': '(GMT +12:00) Уоллис',
    },
    {
      'id': 'Pacific/Wake',
      'name': '(GMT +12:00) Вейк',
    },
    {
      'id': 'Pacific/Tarawa',
      'name': '(GMT +12:00) Тарава',
    },
    {
      'id': 'Pacific/Fiji',
      'name': '(GMT +12:00) Фиджи',
    },
    {
      'id': 'Pacific/Funafuti',
      'name': '(GMT +12:00) Фунафути',
    },
    {
      'id': 'Pacific/Norfolk',
      'name': '(GMT +12:00) Норфолк',
    },
    {
      'id': 'Pacific/Nauru',
      'name': '(GMT +12:00) Науру',
    },
    {
      'id': 'Pacific/Majuro',
      'name': '(GMT +12:00) Маджуро',
    },
    {
      'id': 'Pacific/Apia',
      'name': '(GMT +13:00) Апиа',
    },
    {
      'id': 'Pacific/Auckland',
      'name': '(GMT +13:00) Окленд',
    },
    {
      'id': 'Pacific/Kanton',
      'name': '(GMT +13:00) Кантон',
    },
    {
      'id': 'Pacific/Fakaofo',
      'name': '(GMT +13:00) Факаофо',
    },
    {
      'id': 'Antarctica/McMurdo',
      'name': '(GMT +13:00) Макмердо',
    },
    {
      'id': 'Pacific/Tongatapu',
      'name': '(GMT +13:00) Тонгатапу',
    },
    {
      'id': 'Pacific/Chatham',
      'name': '(GMT +13:45) Чатем',
    },
    {
      'id': 'Pacific/Kiritimati',
      'name': '(GMT +14:00) Карибати',
    },
    {
      'id': 'Atlantic/Azores',
      'name': '(GMT -01:00) Азорские острова',
    },
    {
      'id': 'Atlantic/Cape_Verde',
      'name': '(GMT -01:00) Кабо-Верде',
    },
    {
      'id': 'America/Scoresbysund',
      'name': '(GMT -01:00) Скорсби',
    },
    {
      'id': 'Atlantic/South_Georgia',
      'name': '(GMT -02:00) Южная Георгия и Южные Сандвичевы Острова',
    },
    {
      'id': 'America/Noronha',
      'name': '(GMT -02:00) Норонья',
    },
    {
      'id': 'America/Cayenne',
      'name': '(GMT -03:00) Кайен',
    },
    {
      'id': 'America/Fortaleza',
      'name': '(GMT -03:00) Форталеза',
    },
    {
      'id': 'America/Maceio',
      'name': '(GMT -03:00) Масейо',
    },
    {
      'id': 'Antarctica/Palmer',
      'name': '(GMT -03:00) Палмер',
    },
    {
      'id': 'America/Bahia',
      'name': '(GMT -03:00) Баия',
    },
    {
      'id': 'America/Miquelon',
      'name': '(GMT -03:00) Микелон',
    },
    {
      'id': 'Atlantic/Stanley',
      'name': '(GMT -03:00) Стэнли',
    },
    {
      'id': 'America/Montevideo',
      'name': '(GMT -03:00) Монтевидео',
    },
    {
      'id': 'America/Belem',
      'name': '(GMT -03:00) Белем',
    },
    {
      'id': 'America/Argentina/Cordoba',
      'name': '(GMT -03:00) Кордова',
    },
    {
      'id': 'America/Asuncion',
      'name': '(GMT -03:00) Асунсьон',
    },
    {
      'id': 'America/Argentina/Ushuaia',
      'name': '(GMT -03:00) Ушуая',
    },
    {
      'id': 'America/Argentina/Tucuman',
      'name': '(GMT -03:00) Тукуман',
    },
    {
      'id': 'America/Argentina/San_Juan',
      'name': '(GMT -03:00) Сан-Хуан',
    },
    {
      'id': 'America/Argentina/Salta',
      'name': '(GMT -03:00) Сальта',
    },
    {
      'id': 'America/Argentina/Rio_Gallegos',
      'name': '(GMT -03:00) Рио-Гальегос',
    },
    {
      'id': 'America/Argentina/Mendoza',
      'name': '(GMT -03:00) Мендоса',
    },
    {
      'id': 'America/Araguaina',
      'name': '(GMT -03:00) Арагуаина',
    },
    {
      'id': 'America/Argentina/Buenos_Aires',
      'name': '(GMT -03:00) Буэнос-Айрес',
    },
    {
      'id': 'America/Argentina/La_Rioja',
      'name': '(GMT -03:00) Риоха',
    },
    {
      'id': 'America/Argentina/Jujuy',
      'name': '(GMT -03:00) Жужуй',
    },
    {
      'id': 'America/Argentina/Catamarca',
      'name': '(GMT -03:00) Катамарка',
    },
    {
      'id': 'America/Argentina/San_Luis',
      'name': '(GMT -03:00) Сан-Луис',
    },
    {
      'id': 'Antarctica/Rothera',
      'name': '(GMT -03:00) Ротера',
    },
    {
      'id': 'America/Recife',
      'name': '(GMT -03:00) Ресифи',
    },
    {
      'id': 'America/Sao_Paulo',
      'name': '(GMT -03:00) Сан-Паулу',
    },
    {
      'id': 'America/Santiago',
      'name': '(GMT -03:00) Сантьяго',
    },
    {
      'id': 'America/Nuuk',
      'name': '(GMT -03:00) America/Nuuk',
    },
    {
      'id': 'America/Paramaribo',
      'name': '(GMT -03:00) Парамарибо',
    },
    {
      'id': 'America/Santarem',
      'name': '(GMT -03:00) Сантарем',
    },
    {
      'id': 'America/Punta_Arenas',
      'name': '(GMT -03:00) Пунта-Аренас',
    },
    {
      'id': 'America/St_Johns',
      'name': '(GMT -03:30) Сан-Джонс',
    },
    {
      'id': 'America/Curacao',
      'name': '(GMT -04:00) Кюрасао',
    },
    {
      'id': 'America/St_Lucia',
      'name': '(GMT -04:00) Сан-Лучия',
    },
    {
      'id': 'America/La_Paz',
      'name': '(GMT -04:00) Ла-Пас',
    },
    {
      'id': 'America/Kralendijk',
      'name': '(GMT -04:00) Кралендейк',
    },
    {
      'id': 'America/Aruba',
      'name': '(GMT -04:00) Аруба',
    },
    {
      'id': 'America/St_Kitts',
      'name': '(GMT -04:00) Сан-Киттс',
    },
    {
      'id': 'America/Cuiaba',
      'name': '(GMT -04:00) Куяба',
    },
    {
      'id': 'America/Porto_Velho',
      'name': '(GMT -04:00) Порту-Велью',
    },
    {
      'id': 'America/Dominica',
      'name': '(GMT -04:00) Доминика',
    },
    {
      'id': 'America/Barbados',
      'name': '(GMT -04:00) Барбадос',
    },
    {
      'id': 'America/St_Barthelemy',
      'name': '(GMT -04:00) Сен-Бартелеми',
    },
    {
      'id': 'America/Santo_Domingo',
      'name': '(GMT -04:00) Санто-Доминго',
    },
    {
      'id': 'America/Halifax',
      'name': '(GMT -04:00) Атлантическое время (Канада)',
    },
    {
      'id': 'America/Guyana',
      'name': '(GMT -04:00) Гайана',
    },
    {
      'id': 'America/Glace_Bay',
      'name': '(GMT -04:00) Глас Бэй',
    },
    {
      'id': 'America/Guadeloupe',
      'name': '(GMT -04:00) Гваделупа',
    },
    {
      'id': 'America/Goose_Bay',
      'name': '(GMT -04:00) Гуз Бэй',
    },
    {
      'id': 'America/Lower_Princes',
      'name': '(GMT -04:00) Лоуер Принсес',
    },
    {
      'id': 'America/St_Thomas',
      'name': '(GMT -04:00) Сан-Томас',
    },
    {
      'id': 'America/Campo_Grande',
      'name': '(GMT -04:00) Кампу-Гранди',
    },
    {
      'id': 'America/Puerto_Rico',
      'name': '(GMT -04:00) Пуэрто-Рико',
    },
    {
      'id': 'Atlantic/Bermuda',
      'name': '(GMT -04:00) Бермудские острова',
    },
    {
      'id': 'America/Blanc-Sablon',
      'name': '(GMT -04:00) Блан-Саблон',
    },
    {
      'id': 'America/Anguilla',
      'name': '(GMT -04:00) Ангилья',
    },
    {
      'id': 'America/Boa_Vista',
      'name': '(GMT -04:00) Боа-Виста',
    },
    {
      'id': 'America/Montserrat',
      'name': '(GMT -04:00) Монсеррат',
    },
    {
      'id': 'America/Antigua',
      'name': '(GMT -04:00) Антигуа',
    },
    {
      'id': 'America/Tortola',
      'name': '(GMT -04:00) Тортола',
    },
    {
      'id': 'America/Manaus',
      'name': '(GMT -04:00) Манаус',
    },
    {
      'id': 'America/Moncton',
      'name': '(GMT -04:00) Монктон',
    },
    {
      'id': 'America/Caracas',
      'name': '(GMT -04:00) Каракас',
    },
    {
      'id': 'America/Thule',
      'name': '(GMT -04:00) Туле',
    },
    {
      'id': 'America/Port_of_Spain',
      'name': '(GMT -04:00) Порт-оф-Спейн',
    },
    {
      'id': 'America/St_Vincent',
      'name': '(GMT -04:00) Сан-Винсент',
    },
    {
      'id': 'America/Martinique',
      'name': '(GMT -04:00) Мартиника',
    },
    {
      'id': 'America/Marigot',
      'name': '(GMT -04:00) Мариго',
    },
    {
      'id': 'America/Grenada',
      'name': '(GMT -04:00) Гренада',
    },
    {
      'id': 'America/Detroit',
      'name': '(GMT -05:00) Детройт',
    },
    {
      'id': 'America/Rio_Branco',
      'name': '(GMT -05:00) Риу-Бранку',
    },
    {
      'id': 'America/Cancun',
      'name': '(GMT -05:00) Канкун',
    },
    {
      'id': 'America/Eirunepe',
      'name': '(GMT -05:00) Еирунепе',
    },
    {
      'id': 'America/Atikokan',
      'name': '(GMT -05:00) Атикокан',
    },
    {
      'id': 'America/Bogota',
      'name': '(GMT -05:00) Богота',
    },
    {
      'id': 'America/Port-au-Prince',
      'name': '(GMT -05:00) Порт-ау-Принс',
    },
    {
      'id': 'America/Cayman',
      'name': '(GMT -05:00) Кайман',
    },
    {
      'id': 'America/Indiana/Marengo',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Grand_Turk',
      'name': '(GMT -05:00) Гранд-Терк',
    },
    {
      'id': 'America/Iqaluit',
      'name': '(GMT -05:00) Икалуит',
    },
    {
      'id': 'America/Pangnirtung',
      'name': '(GMT -05:00) Панглао',
    },
    {
      'id': 'America/Panama',
      'name': '(GMT -05:00) Панама',
    },
    {
      'id': 'America/Lima',
      'name': '(GMT -05:00) Лима',
    },
    {
      'id': 'America/Kentucky/Monticello',
      'name': '(GMT -05:00) Кентукки',
    },
    {
      'id': 'America/Nassau',
      'name': '(GMT -05:00) Нассау',
    },
    {
      'id': 'Pacific/Easter',
      'name': '(GMT -05:00) Остров Пасхи',
    },
    {
      'id': 'America/Kentucky/Louisville',
      'name': '(GMT -05:00) Кентукки',
    },
    {
      'id': 'America/Jamaica',
      'name': '(GMT -05:00) Ямайка',
    },
    {
      'id': 'America/New_York',
      'name': '(GMT -05:00) Восточное время (США & Канада)',
    },
    {
      'id': 'America/Toronto',
      'name': '(GMT -05:00) Торонто',
    },
    {
      'id': 'America/Nipigon',
      'name': '(GMT -05:00) Нипигон',
    },
    {
      'id': 'America/Indiana/Winamac',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Indiana/Vincennes',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Indiana/Vevay',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Indiana/Petersburg',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Indiana/Indianapolis',
      'name': '(GMT -05:00) Индиана',
    },
    {
      'id': 'America/Havana',
      'name': '(GMT -05:00) Гавана',
    },
    {
      'id': 'America/Guayaquil',
      'name': '(GMT -05:00) Гуаякиль',
    },
    {
      'id': 'America/Thunder_Bay',
      'name': '(GMT -05:00) Тандер-Бей',
    },
    {
      'id': 'America/Winnipeg',
      'name': '(GMT -06:00) Виннипег',
    },
    {
      'id': 'America/Rankin_Inlet',
      'name': '(GMT -06:00) Ранкин-Инлет',
    },
    {
      'id': 'America/Tegucigalpa',
      'name': '(GMT -06:00) Тегусигальпа',
    },
    {
      'id': 'America/Swift_Current',
      'name': '(GMT -06:00) Свифт-Керрент',
    },
    {
      'id': 'America/Rainy_River',
      'name': '(GMT -06:00) Рейни Ривер',
    },
    {
      'id': 'America/Regina',
      'name': '(GMT -06:00) Реджина',
    },
    {
      'id': 'America/Resolute',
      'name': '(GMT -06:00) Резолюто',
    },
    {
      'id': 'Pacific/Galapagos',
      'name': '(GMT -06:00) Галапагосы',
    },
    {
      'id': 'America/North_Dakota/Beulah',
      'name': '(GMT -06:00) Северная Дакота',
    },
    {
      'id': 'America/Indiana/Knox',
      'name': '(GMT -06:00) Индиана',
    },
    {
      'id': 'America/Bahia_Banderas',
      'name': '(GMT -06:00) Мексиканское центральное время',
    },
    {
      'id': 'America/Guatemala',
      'name': '(GMT -06:00) Гватемала',
    },
    {
      'id': 'America/North_Dakota/Center',
      'name': '(GMT -06:00) Северная Дакота',
    },
    {
      'id': 'America/North_Dakota/New_Salem',
      'name': '(GMT -06:00) Северная Дакота',
    },
    {
      'id': 'America/Monterrey',
      'name': '(GMT -06:00) Монтеррей',
    },
    {
      'id': 'America/Mexico_City',
      'name': '(GMT -06:00) Центральное время (США & Канада)',
    },
    {
      'id': 'America/Merida',
      'name': '(GMT -06:00) Мерида',
    },
    {
      'id': 'America/Menominee',
      'name': '(GMT -06:00) Меномини',
    },
    {
      'id': 'America/Belize',
      'name': '(GMT -06:00) Белиз',
    },
    {
      'id': 'America/Managua',
      'name': '(GMT -06:00) Манагуа',
    },
    {
      'id': 'America/Indiana/Tell_City',
      'name': '(GMT -06:00) Индиана',
    },
    {
      'id': 'America/Matamoros',
      'name': '(GMT -06:00) Матаморос',
    },
    {
      'id': 'America/El_Salvador',
      'name': '(GMT -06:00) Сальвадор',
    },
    {
      'id': 'America/Chicago',
      'name': '(GMT -06:00) Чикаго',
    },
    {
      'id': 'America/Costa_Rica',
      'name': '(GMT -06:00) Коста-Рика',
    },
    {
      'id': 'America/Dawson',
      'name': '(GMT -07:00) Доусон',
    },
    {
      'id': 'America/Yellowknife',
      'name': '(GMT -07:00) Йеллоунайф',
    },
    {
      'id': 'America/Boise',
      'name': '(GMT -07:00) Бойсе',
    },
    {
      'id': 'America/Whitehorse',
      'name': '(GMT -07:00) Уайтхорс',
    },
    {
      'id': 'America/Cambridge_Bay',
      'name': '(GMT -07:00) Кеймбридж-Бей',
    },
    {
      'id': 'America/Ojinaga',
      'name': '(GMT -07:00) Охинага',
    },
    {
      'id': 'America/Chihuahua',
      'name': '(GMT -07:00) Чихуахуа',
    },
    {
      'id': 'America/Fort_Nelson',
      'name': '(GMT -07:00) Форт Нельсон',
    },
    {
      'id': 'America/Mazatlan',
      'name': '(GMT -07:00) Масатлан',
    },
    {
      'id': 'America/Creston',
      'name': '(GMT -07:00) Крестона',
    },
    {
      'id': 'America/Dawson_Creek',
      'name': '(GMT -07:00) Досон-Крик',
    },
    {
      'id': 'America/Inuvik',
      'name': '(GMT -07:00) Инувик',
    },
    {
      'id': 'America/Edmonton',
      'name': '(GMT -07:00) Эдмонтон',
    },
    {
      'id': 'America/Hermosillo',
      'name': '(GMT -07:00) Эрмосильо',
    },
    {
      'id': 'America/Phoenix',
      'name': '(GMT -07:00) Феникс',
    },
    {
      'id': 'America/Denver',
      'name': '(GMT -07:00) Денвер',
    },
    {
      'id': 'Pacific/Pitcairn',
      'name': '(GMT -08:00) Питкэрн',
    },
    {
      'id': 'America/Vancouver',
      'name': '(GMT -08:00) Ванкувер',
    },
    {
      'id': 'America/Tijuana',
      'name': '(GMT -08:00) Тихуана',
    },
    {
      'id': 'America/Los_Angeles',
      'name': '(GMT -08:00) Тихоокеанское время (США & Канада)',
    },
    {
      'id': 'Pacific/Gambier',
      'name': '(GMT -09:00) Гамбье',
    },
    {
      'id': 'America/Anchorage',
      'name': '(GMT -09:00) Анкоридж',
    },
    {
      'id': 'America/Nome',
      'name': '(GMT -09:00) Ноум',
    },
    {
      'id': 'America/Yakutat',
      'name': '(GMT -09:00) Якутат',
    },
    {
      'id': 'America/Sitka',
      'name': '(GMT -09:00) Ситка',
    },
    {
      'id': 'America/Metlakatla',
      'name': '(GMT -09:00) Метлакатла',
    },
    {
      'id': 'America/Juneau',
      'name': '(GMT -09:00) Джуно',
    },
    {
      'id': 'Pacific/Marquesas',
      'name': '(GMT -09:30) Маркизские',
    },
    {
      'id': 'Pacific/Rarotonga',
      'name': '(GMT -10:00) Раротонга',
    },
    {
      'id': 'Pacific/Honolulu',
      'name': '(GMT -10:00) Гонолулу',
    },
    {
      'id': 'America/Adak',
      'name': '(GMT -10:00) Адак',
    },
    {
      'id': 'Pacific/Tahiti',
      'name': '(GMT -10:00) Таити',
    },
    {
      'id': 'Pacific/Midway',
      'name': '(GMT -11:00) Международная западная линия времени',
    },
    {
      'id': 'Pacific/Niue',
      'name': '(GMT -11:00) Ниуэ',
    },
    {
      'id': 'Pacific/Pago_Pago',
      'name': '(GMT -11:00) Паго-Паго',
    },
    {
      'id': 'Pacific/Samoa',
      'name': '(GMT -11:00) Самоа',
    },
  ],
};
