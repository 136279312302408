import React, { useState } from 'react';
import './CustomTemplates.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { customTemplatesSelector } from '../../../Chat/templatesSelector';
import CustomTemplateCard from '../CustomTemplateCard/CustomTemplateCard';
import { filterCustomTemplates } from './helpers';
import { CustomTemplate } from '../../../../api/types';
import Search from '../../../../components/Search/Search';
import PlusIcon from '../../../../assets/plus-icon.svg';
import {
  WrapperPermission
} from '../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../components/Button/Button';

type Props = {
  setTemplateEdit: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>>;
};

const CustomTemplates: React.FC<Props> = (setTemplateEdit) => {
  const [inputValue, setInputValue] = useState<string>('');
  const customTemplates = useSelector(customTemplatesSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { url } = useRouteMatch();

  return (
    <div className="customTemplates">
      <div className="customTemplates__header">
        <div className="customTemplates__header_title">
          <h1>{t('templates.title')}</h1>
          <WrapperPermission permission='templatesCreate'>
            <Button type='button' color='orange' textType='regular' text={t('templates.create_templates')}
              onClick={() => dispatch(push(`${url}/add`))} image={PlusIcon} />
          </WrapperPermission>
        </div>
        <Search onChangeSearchInput={setInputValue} value={inputValue} />
      </div>
      <div className="customTemplates__content">
        {inputValue
          ? filterCustomTemplates(inputValue, customTemplates)
          : customTemplates.map((template) => (
            <CustomTemplateCard key={template.id} template={template}
              setTemplateEdit={setTemplateEdit.setTemplateEdit} />
            ))}
      </div>
    </div>
  );
};

export default CustomTemplates;
