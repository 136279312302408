import React, { FC, useRef } from 'react';
import './RoleModal.scss';
import { useTranslation } from 'react-i18next';
import Botton from '../../../../components/Button/Button';
import { ReactComponent as CloseIcon } from '../../../../assets/close-icon-black.svg';
import { useOutsideClick } from '../../../../components/utils/hooks';



type Props = {
  handlerCloseModal: () => void
  handlerSubmitModal: () => void
  customText?: string
  title: string
  disabled: boolean
  error: boolean
  errorText: string
  textSubmit: string
  titleMain: string
  style?: React.CSSProperties | undefined
}

const RoleModal: FC<Props> = ({
                                handlerCloseModal,
                                handlerSubmitModal,
                                customText,
                                children,
                                title,
                                disabled,
                                error,
                                errorText,
                                textSubmit,
                                titleMain,
  style
                              }) => {

  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isTitle = title.length > 0

  useOutsideClick(contentRef, (e) => {
    // @ts-ignore
    if (e.target?.id !== 'ignoreClick') {
      handlerCloseModal();
    }
  }, triggerRef);

  return (
    <div className='addRoleModalWrapper'>
      <div className='addRoleModalWrapper__addRoleModal'>
        <div ref={contentRef} className='addRoleModalWrapper__addRoleModal__item' style={style}>
          <div ref={triggerRef} className='addRoleModalWrapper__addRoleModal__item__title'>
            <h2>{titleMain}</h2>
            <CloseIcon onClick={handlerCloseModal} />
          </div>
          {customText}
          <div>
            <div className='addRoleModalWrapper__addRoleModal__item__input'>
              {isTitle && (
                <h3 className={`addRoleModalWrapper__addRoleModal__item__input ${error ? 'error' : ''}`}
                >{title}</h3>
              )}
              {children}
              {error && <div className='addRoleModalWrapper__addRoleModal__item__input__errorText'>{errorText}</div>}
            </div>
          </div>
          <div className='addRoleModalWrapper__addRoleModal__item__buttons'>
            <Botton
              disabled={disabled}
              text={textSubmit}
              type='button'
              color='orange'
              textType='small'
              onClick={handlerSubmitModal}
            />
            <Botton
              text={t('cancel')}
              type='button'
              color='white'
              textType='small'
              onClick={handlerCloseModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default RoleModal;
