import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { broadcastsLogTotalSelector, broadcastsStatusLogSelector } from '../../BroadcastsSelector';
import { getBroadcastsLog } from '../../BroadcactsSlice';
import { broadcastLogPageLimit } from '../../utils';
import { getPageLimit } from '../../../../components/Paginator/utils';
import Paginator from '../../../../components/Paginator';
import LogList from '../LogList';
import loader from '../../../../assets/grid.svg';
import './BroadcastsLog.scss';


function BroadcastsLog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const total = useSelector(broadcastsLogTotalSelector);
  const status = useSelector(broadcastsStatusLogSelector);

  const onUpdate = (page: number, limit: number) => {
    dispatch(getBroadcastsLog({
      companyId: currentCompany.id, limit, offset: (limit * (page - 1)) }));
  };

  return (
    <div className='broadcastsLog'>
      <h5>{t('broadcasts.log.title')}</h5>
      <table className='log_list'>
        <thead>
          <tr className='log_list__header'>
            <th>{t('broadcasts.params.name_date')}</th>
            <th>{t('broadcasts.params.connection')}</th>
            <th>{t('broadcasts.params.recipients')}</th>
            <th>{t('broadcasts.params.delivered')}</th>
            <th>{t('broadcasts.params.read')}</th>
            <th>{t('broadcasts.params.status')}</th>
          </tr>
        </thead>
        <tbody>
          {status === 'load'
            ? <div className="load"><img src={loader} alt='loading...' /></div>
            : <LogList />}
        </tbody>
      </table>
      {total > getPageLimit(broadcastLogPageLimit) && 
        <Paginator totalCount={total} pageSelect={onUpdate} pageLimitStoreIndex={broadcastLogPageLimit} />}
    </div>
  );
}

export default BroadcastsLog;
