import './Notes.scss';
import { useSelector } from 'react-redux';
import { ContactNotesType } from '../../../../api/ContactsAPI';
import { membersSelector } from '../../../Chat/companiesSelector';
import { selectContactNotesSelector } from "../../contactsSlice/contactsSelector";
import { Note } from './Note';


const Notes = () => {
  const members = useSelector(membersSelector);
  const selectContactNotes = useSelector(selectContactNotesSelector);

  if (!selectContactNotes) {
    return <></>;
  }

  return (
    <div className={`notesList ${selectContactNotes.length === 0 ? 'empty' : ''}`}>
      {selectContactNotes?.map((n: ContactNotesType) => <Note key={n.id} note={n} members={members} />)}
    </div>
  );
};

export default Notes;
