import { ReactElement } from 'react';
import './NotItems.scss';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from "../../../../utils/WrapperPermission/WrapperPermission";
import Button from '../../../../components/Button/Button';
import { ReactComponent as PlusIcon } from '../../../../assets/plus-icon.svg';


type NotItemsProps = {
  image?: string | ReactElement;
  title: string;
  subsection: string;
  cb: () => void;
  buttonName: string;
};

function NotItems({ subsection, cb, image, title, buttonName }: NotItemsProps) {
  const { t } = useTranslation();
  const permissionCreate = useHasPermission('salesShopCreate');

  const getImage = () => {
    if (typeof image === 'string') {
      return (<img className='GlobalButton_img' alt='img' src={image} />);
    }
    if (typeof image === 'object') {
      return { ...image };
    }
    return <></>;
  };

  return (
    <div className='notItems'>
      <div className='notItems__icon'>
        {getImage()}
      </div>
      <h1>{t(title)}</h1>
      {permissionCreate 
        ? (<>
          <p className='regularText'>{t('sales.shops.noShopsText')}</p>
          <Button type='button' color='orange' textType='regular' text={t(buttonName)} 
            image={<PlusIcon />} onClick={cb} />
          </>)
        : (
          <p>{t('sales.shops.noShopCreate')}</p>)
      }
    </div>
  );
}

export default NotItems;
