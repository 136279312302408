import React, { useEffect, useState } from 'react';
import { Currency, InvoicesProduct, Terminal, TypePaymentMethods } from '../../../../../api/BillAPI';
import { ReactComponent as TinkoffIcon } from '../../../../../assets/logo-tinkoff.svg';
import { ReactComponent as SberIcon } from '../../../../../assets/sberbank-logo.svg';
import { ReactComponent as PaykeeperIcon } from '../../../../../assets/paykeeper-logo.svg';
import { ReactComponent as ModulbankIcon } from '../../../../../assets/modulbank-logo.svg';
import { ReactComponent as BePaidIcon } from '../../../../../assets/bePaid-logo.svg';
import { ReactComponent as YookassaIcon } from '../../../../../assets/logo-yookassa.svg';


type AmountProps = {
  price: number;
  arrItems: InvoicesProduct[];
};

export const useAmount = ({ price, arrItems }: AmountProps) => {
  const [totalSum, setTotalSum] = useState<number>(0);

  useEffect(() => {
    let totalPrice = 0;
    for (let i = 0; i < arrItems.length; i++) {
      totalPrice += Number(arrItems[i].price) * Number(arrItems[i].quantity);
    }
    setTotalSum(totalPrice || 0);
  }, [arrItems, price]);

  return { totalSum };
};

export const currencyCodeToSymbol: Record<number, string> = {
  643: 'RUB',
  840: 'USD',
  978: 'EUR',
  944: 'AZN',
  933: 'BYN',
  156: 'CNY',
  417: 'KGS',
  398: 'KZT',
  498: 'MDL',
  980: 'UAH'
};

export const paymentMethodsList: TypePaymentMethods[] = [
  { id: 'all', name: 'Все доступные' },
  { id: 'card', name: 'Оплата только картой' },
  { id: 'sbp', name: 'СБП (только QR-код)' }
];

export type SVGElementType = React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;

export type KeyListSVGElementsType = Record<string, SVGElementType>;

const terminalLogos: KeyListSVGElementsType = {
  tinkoff: TinkoffIcon,
  sberbank: SberIcon,
  paykeeper: PaykeeperIcon,
  modulbank: ModulbankIcon,
  bepaid: BePaidIcon,
  yookassa: YookassaIcon
};

export type ItemWithLogo<T> = T & { logo?: SVGElementType; };

export const addLogosToTerminals = (terminals: Terminal[]): Array<ItemWithLogo<Terminal>> =>
  terminals.map((terminal) => ({ ...terminal, logo: terminalLogos[terminal.type] }));

export const currenciesListToItems = (currencies: Currency[]) =>
  currencies.map((currency) => ({ id: currency.numberCode, name: currency.name }));
