import React from 'react';
import { useTranslation } from 'react-i18next';
import { stepsAnchorsUris } from '../OnboardingPopup/stepsAnchorsUris';

const FourthStep = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="fourthStep">
      <p className="secondStep__title">{t('onboarding.fourthStep.title')}</p>
      <p className="secondStep__description">{t('onboarding.fourthStep.description')}</p>
      <a className="secondStep__more" href={stepsAnchorsUris[4][i18n.language]} target="_blank" rel="noreferrer">
        {t('onboarding.more_anchor')}
      </a>
    </div>
  );
};

export default FourthStep;
