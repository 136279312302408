import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExclamationIcon } from '../../../../../assets/exclamation-point-icon.svg';
import BlackListAdd from './BlackListAdd';
import BlackListContent from './BlackListContent';
import './BlackList.scss';


const BlackList = () => {
  const { t } = useTranslation();
  
  return (
    <div className="blackList">
      <p className="blackList_title">{t('connections.black_list.title')}</p>
      <div className="blackList_addPhone">
        <BlackListAdd />
        <div className="blackList_description">
          <ExclamationIcon />
          <p>{t('connections.black_list.description')}</p>
        </div>
      </div>
      <BlackListContent />
    </div>
  );
};

export default BlackList;
