import React, { useEffect, useRef, useState } from 'react';
import { BaseMessageComponent } from './types';
import { ReactComponent as FileLoader } from '../../../../assets/fileLoader.svg';
import CustomAudioPlayer from './CustomAudioPlayer';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';
import MessageWabaProduct from './MessageWabaProduct';


const MessageAudioType: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const [audioUrl, setAudioUrl] = useState<string>();
  const audioContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (message.messageType === 'audio') {
      setAudioUrl(message.audio.url);
    } else setAudioUrl(message.voice.url);
  }, [message]);

  useEffect(() => {
    if (audioContainerRef.current && handleSizeChange) {
      handleSizeChange(audioContainerRef.current.offsetHeight);
    }
  }, [audioContainerRef.current, audioUrl]);

  const audioComponent = () => {
    if (audioUrl) {
      return (
        <>
          <div className="customAudioPlayer" ref={audioContainerRef}>
            <CustomAudioPlayer url={audioUrl} />
          </div>
          {message.audio && <LinkifyText text={message.audio.caption} />}
        </>
      );
    }
    return <FileLoader width={40} height={40} />;
  };

  return message.messageType === 'audio' && message.audio.wabaReferredProduct ?
    <MessageWabaProduct type='audio' text={message.audio.url} message={message.audio.wabaReferredProduct}/> : audioComponent();
};

export default MessageAudioType;
