import { addMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../Chat/companiesSelector';
import { timezone } from '../../../Settings/CompanySettings/timezone';
import Checkbox from '../../../../components/Checkbox';
import DateTimePicker from '../../../../components/DateTimePicker';
import './DelayForm.scss';


function getTimezone(language: string, timezoneId: string) {
  const timezoneItems = timezone[language] ? timezone[language] : timezone.en;
  const timezoneItem = timezoneItems.find((item) => item.id === timezoneId);
  if (timezoneItem) return timezoneItem.name;
  return null;
}

type DelayFormProps = {
  name: string;
};

function DelayForm({ name }: DelayFormProps) {
  const { t, i18n } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const [isDelay, setIsDelay] = useState<boolean>(false);
  const { watch, getValues, setValue } = useFormContext();
  const date = watch(name);

  useEffect(() => {
    if (getValues(name)) setIsDelay(true);
  }, [date]);

  const onDelayCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDelay(e.currentTarget.checked);
    if (!e.currentTarget.checked) setValue(name, null, { shouldDirty: true });
  };

  return (
    <div className='delayForm'>
      <Checkbox name='delay' label={t('broadcasts.form.delay')}
        value={isDelay} onCheckedHandler={onDelayCheck} />
      {isDelay &&
        <div className='delayForm__date'>
          <DateTimePicker name={name} withTime todayButton dateStart={new Date()} dateEnd={addMonths(new Date(), 1)} />
          <span>{getTimezone(i18n.language, currentCompany.timezone)}</span>
        </div>
      }
    </div>
  );
}

export default DelayForm;
