import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/cancel.svg';
import { ReactComponent as DawIcon } from '../../../assets/daw.svg';
import { putUpdateTag, postCreateTags, getTagsList } from '../tagsSlice';
import { currentCompanySelector } from '../../Chat/companiesSelector';
import { Tag } from '../../../api/types';
import { useOutsideClick } from '../../../components/utils/hooks';
import { tagsSelector } from '../tagsSelector';
import { addAlertWithCustomText } from '../../../components/Alert/alertSlice';
import Button from '../../../components/Button/Button';

type Props = {
  isDisable: boolean;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  tagItem: Tag | undefined;
  setTagItem: React.Dispatch<React.SetStateAction<Tag | undefined>>;
};

const CreateAndEditTag: React.FC<Props> = ({ isDisable, setIsDisable,
                                             isEdit, setIsEdit,
                                             tagItem, setTagItem }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const tags = useSelector(tagsSelector);
  const [isStateChange, setStateChange] = useState(false);
  const [selectColor, setSelectColor] = useState('#FFFFFF');
  const [newTagsData, setNewTagsData] = useState({
    name: '',
    description: '',
  });
  const [errors, setErrors] = useState(false);

  const arrColors = [
    { id: 1, color: '#FFFFFF' },
    { id: 2, color: '#FABBBC' },
    { id: 3, color: '#99D7CC' },
    { id: 4, color: '#FFB7D6' },
    { id: 5, color: '#FFD5AB' },
    { id: 6, color: '#D2D2D2' },
    { id: 7, color: '#D3E5F4' },
    { id: 8, color: '#9FE2EB' },
    { id: 9, color: '#F6F8C5' },
    { id: 10, color: '#E6F9D4' },
    { id: 11, color: '#FFCED4' },
    { id: 12, color: '#D0C0E3' },
    { id: 13, color: '#ECECEC' },
  ];
  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);


  useEffect(() => {
    if (isEdit && tagItem) {
      setSelectColor(tagItem.backgroundColor);
      setNewTagsData({
        name: tagItem.name,
        description: tagItem.description,
      });
    } else setSelectColor('#FFFFFF');
  }, [isEdit, tagItem]);

  useEffect(() => {
    if (
      (isEdit && newTagsData.name !== tagItem?.name) ||
      selectColor !== tagItem?.backgroundColor ||
      newTagsData.description !== tagItem?.description
    ) {
      setStateChange(true);
    } else setStateChange(false);
  }, [newTagsData, tagItem, selectColor]);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(getTagsList({ company_id: currentCompany.id }));
    }
  }, [currentCompany]);

  const closeModalWindows = () => {
    setIsDisable(false);
    setIsEdit(false);
    setErrors(false);
    setStateChange(false);
    setNewTagsData({
      name: '',
      description: '',
    });
  };

  const onChangeNewTagsInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputId = e.target.id;

    if (e.target.id === 'name') {
      setErrors(false);
    }

    setNewTagsData((prevState) => ({
      ...prevState,
      [inputId]: e.target.value,
    }));
  };

  const onEditAndCreateTag = () => {
    if (newTagsData.name.length < 1) {
      setErrors(true);
      return;
    }
    if (tags && !isEdit) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].name === newTagsData.name) {
          dispatch(addAlertWithCustomText({
            message: t('settings.tag.error_tag'),
            color: '#F54242',
          }));
          return;
        }
      }
    }
    if (isEdit && tagItem) {
      dispatch(
        putUpdateTag({
          tag_id: tagItem?.id,
          company_id: currentCompany.id,
          requestOption: {
            name: newTagsData.name,
            description: newTagsData.description.length > 0 ? newTagsData.description : '',
            backgroundColor: selectColor,
          },
        })
      );
      setTagItem({
        backgroundColor: '',
        companyId: 0,
        description: '',
        id: 0,
        name: '',
      });
      setStateChange(false);
    } else {
      dispatch(
        postCreateTags({
          company_id: currentCompany.id,
          name: newTagsData.name,
          description: newTagsData.description.length > 0 ? newTagsData.description : undefined,
          backgroundColor: selectColor,
        })
      );
      setNewTagsData({
        name: '',
        description: '',
      });
      setSelectColor('#FFFFFF');
    }
    setIsEdit(false);
    setIsDisable(false);
  };

  const onCloseDropdown = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsDisable(false);
    setIsEdit(false);
    setErrors(false);
    setStateChange(false);
    setNewTagsData({
      name: '',
      description: '',
    });
  };

  useOutsideClick(wrapperRef, onCloseDropdown, triggerButtonRef);

  return (
    <div className={`createAndEditTag ${isDisable && 'open'}`} ref={wrapperRef}>
      <div className="createAndEditTag_header">
        <h2>{isEdit ? t('settings.tag.edit.title') : t('settings.tag.create.title')}</h2>
        <img src={closeIcon} alt="close" onClick={closeModalWindows}
             ref={triggerButtonRef}/>
      </div>
      <div className="createAndEditTag_name">
        <div className="createAndEditTag_name_text">
          <h5>{t('settings.tag.create.subtitle1')}</h5>
          <h6>{t('settings.tag.create.limitText1')}</h6>
        </div>
        <textarea
          id="name"
          className={errors ? 'error' : ''}
          value={isEdit ? newTagsData.name : newTagsData.name}
          onChange={onChangeNewTagsInput}
          maxLength={15}
        />
        {errors ? <p
          className="createAndEditTag_name-error">{t('registration.create.empty_field')}</p> : null}
      </div>
      <div className="createAndEditTag_description">
        <div className="createAndEditTag_description_text">
          <h5>{t('settings.tag.create.subtitle2')}</h5>
          <h6>{t('settings.tag.create.limitText2')}</h6>
        </div>
        <textarea
          id="description"
          value={isEdit ? newTagsData.description : newTagsData.description}
          onChange={onChangeNewTagsInput}
          maxLength={256}
        />
      </div>
      <div className="createAndEditTag_colors">
        <h5>{t('settings.tag.create.subtitle3')}</h5>
        <div className="colorItems">
          {arrColors.map((item) => (
            <div
              key={item.id}
              className="colorItems_item"
              style={
                item.color === '#FFFFFF'
                  ? { background: item.color, border: '1px solid #EBEBEB' }
                  : { background: item.color }
              }
              onClick={() => setSelectColor(item.color)}
            >
              {selectColor === item.color ? (
                <div className="dawIcon">
                  <DawIcon/>
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="createAndEditTag_footer">
        <h2>{t('settings.tag.create.preview')}</h2>
        <div className="createAndEditTag_footer_preview"
             style={{ background: selectColor }}>
          {newTagsData.name.length > 0 ? newTagsData.name : t('settings.tag.create.name_tag')}
        </div>
      </div>
      <div className="createAndEditTag_button">
        <Button text={isEdit ? t('save') : t('settings.tag.create_button')} color='orange' disabled={!isStateChange}
                onClick={onEditAndCreateTag} textType='small' style={{ padding: '6px 12px', color: '#FFFFFF' }} type='button' />
        <Button text={t('settings.tag.create.cancel')} color='white'
                onClick={closeModalWindows} textType='small' style={{ padding: '6px 12px' }} type='button' />
      </div>
    </div>
  );
};

export default CreateAndEditTag;
