import { useEffect, useState } from 'react';
import './PayKeeperEdit.scss';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { useParams, useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { updateConnection } from '../../../connectionsSlice';
import { PaykeeperConnection } from '../../../../../../api/CompanyAPI';
import { transformEmptyStringToUndefined } from '../../../utils/connections';
import { DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME } from "../../../utils/terminals";
import Input from '../../../../../../components/Input/Input';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Button from '../../../../../../components/Button/Button';
import LifetimePayment from "../../../components/LifetimePayment";


type SubmitSchema = {
  name: string;
  params?: {
    user_name?: string;
    password: string;
    invoice_lifetime_minutes: number;
  };
};

const PayKeeperEdit = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.paykeeper);
  const [connection, setConnection] = useState<PaykeeperConnection>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      password: yup.string().transform(transformEmptyStringToUndefined),
      invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    })
      .transform((value) => {
        if (value && Object.values(value).some((v) => v !== '')) {
          return value;
        }
        return undefined;
      })
      .default(undefined),
  });


  const methods = useForm<SubmitSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        password: '',
        invoice_lifetime_minutes: DEFAULT_LIFETIME
      }
    }
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as PaykeeperConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect?.name,
        params: {
          password: '',
          invoice_lifetime_minutes: connect.params.invoiceLifetimeMinutes
        }
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<SubmitSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }
    if (currentCompanyId?.params.companyId){
      dispatch(updateConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        itemId: Number(connectionId),
        name: data.name,
        type: 'paykeeper',
        params: data.params,
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/payKeeper`));
        }
      });
    }
  };

  return connection ? (
    <GoBackWrapper title={t('edit')}>
      <div className="payKeeperEditPage">
        <p className="payKeeperEditPage__connectionNumber">Номер подключения: #{connection.id}</p>
        <p className="payKeeperEditPage__terminalKey">Сервер PayKeeper: {connection.params.serverUrl}</p>
        <p className="payKeeperEditPage__login">Логин: {connection.params.userName}</p>
        <FormProvider {...methods}>
          <div className="payKeeperEditPage__form">
            <Input label="Название подключения" name="name" />
            <Input label="Обновить пароль" name="params.password" inputType="password" />
            <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
          </div>
        </FormProvider>
        <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
          onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
      </div>
    </GoBackWrapper>
  ) : null;
};

export default PayKeeperEdit;
