import React, { useEffect, useState } from 'react';
import './TemplateMessage.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import TemplateList from './TemplateList';
import TemplateView from './TemplateView';
import { fetchWabaTemplates } from '../../../Chat/templatesSlice';
import { useAppDispatch } from '../../../../store/store';
import { WabaTemplate } from '../../../../api/types';
import { SourceType, TextMessageType } from '../../utils';
import { useBroadcastItem } from '../../api';
import { wabaTemplatesSelector } from '../../../Chat/templatesSelector';
import { ErrorStateType } from '../../api/types';

type TemplateMessageProps = {
  setMessageText: React.Dispatch<React.SetStateAction<TextMessageType | undefined>>;
  selectedSource?: SourceType | null;
  errorState?: ErrorStateType;
};

function TemplateMessage({ setMessageText, selectedSource, errorState }: TemplateMessageProps) {
  const { t } = useTranslation();
  const currentCompany = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const params = useRouteMatch<{ broadcastId: string }>('/companies/:companyId/broadcasts/edit/:broadcastId');
  const location = useLocation<number>();
  const { state } = location;
  const broadcastItem = useBroadcastItem();
  const dispatch = useAppDispatch();
  const wabaTemplates = useSelector(wabaTemplatesSelector);
  const [selectTemplate, setSelectTemplate] = useState<WabaTemplate>();
  const [isEdit, setIsEdit] = useState<boolean>();
  const { watch } = useFormContext();

  const selectConnection = watch('connection_id');

  useEffect(() => {
    if (selectedSource?.type === 'waba') {
      setSelectTemplate(undefined);
      dispatch(
        fetchWabaTemplates({
          companyId: Number(currentCompany?.params.companyId),
          connectionId: selectConnection,
        })
      );
    }
  }, [selectedSource?.id]);

  useEffect(() => {
    if (params?.params.broadcastId || state) {
      if (broadcastItem) {
        const tempalateEdit = wabaTemplates.find(
          (template) => template.name === broadcastItem.message.wabaTemplate?.templateName
        );
        setSelectTemplate(tempalateEdit);
        setIsEdit(true);
      }
    } else setSelectTemplate(undefined);
  }, [wabaTemplates, state, params?.params.broadcastId]);

  useEffect(() => {
    if(!selectTemplate){
      setMessageText(undefined);
    }
  },[selectTemplate]);


  return (
    <div className="templateMessage">
      <h4>{t('broadcasts.form.message_waba')}</h4>
      {selectTemplate ? (
        <TemplateView selectTemplate={selectTemplate} setSelectTemplate={setSelectTemplate}
          setMessageText={setMessageText} isEdit={isEdit} errorState={errorState} />
      ) : (
        <TemplateList setSelectTemplate={setSelectTemplate} errorState={errorState} />
      )}
    </div>
  );
}

export default TemplateMessage;
