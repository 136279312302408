import React, { useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-down.svg';
import './ConnectionAddon.scss';


type ConnectionAddonProps = {
  children?: React.ReactNode;
};

const ConnectionAddon = ({ children }: ConnectionAddonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="connectionAddon">
      <ArrowIcon className={`connectionAddon_arrowIcon ${isOpen ? 'active' : ''}`}
        onClick={() => setIsOpen(!isOpen)} />
      {isOpen && <>{children}</>}
    </div>
  );
};

export default ConnectionAddon;
