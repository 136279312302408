import React from 'react';
import './InteractiveMessageTemplateCard.scss';
import InteractiveMessageTemplateCardBadges 
  from '../InteractiveMessageTemplateCardBadges/InteractiveMessageTemplateCardBadges';
import { CustomTemplate } from '../../../../api/types';
import CardButtons from '../CardButtons/CardButtons';


type Props = {
  template: CustomTemplate;
  setTemplateEdit: (value: unknown) => void;
};

const InteractiveMessageTemplateCard: React.FC<Props> = ({ template, setTemplateEdit }) => (
  <div className="interactiveMessageTemplateCard">
    <div className="interactiveMessageTemplateCard__container">
      <h3>{template.name}</h3>
      {template.params?.header?.text && <p className="regularText semibold">{template.params.header.text}</p>}
      <p className="regularText">{template.params.body}</p>
      {template.params?.footer && <p className="monospaceSmText">{template.params.footer}</p>}
      {template.params?.header?.type && <InteractiveMessageTemplateCardBadges type={template.params.header.type} />}
    </div>
    <CardButtons setTemplateEdit={setTemplateEdit} template={template} />
  </div>
);

export default InteractiveMessageTemplateCard;
