import React, { useState } from 'react';
import './NoConnectionsVideo.scss';
import { Trans, useTranslation } from 'react-i18next';
import { ConnectionServiceType } from '../../utils/connections';
import { LinksWabaWorkRules } from '../../ConnectionsViews/Waba/utils';
import { ConnectionsType } from '../../ConnectionsTypes';
import Checkbox from '../../../../../components/Checkbox';
import Button from '../../../../../components/Button/Button';
import CircleVideo from '../../../../../components/CircleVideo';


type Props = {
  video: string;
  service: ConnectionsType;
  connection: ConnectionServiceType;
  onAddConnect: () => void;
};

const NoConnectionsVideo = ({ video, service, connection, onAddConnect }: Props) => {
  const { t, i18n } = useTranslation();
  const [check, setCheck] = useState<boolean>(false);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(e.currentTarget.checked);
  };

  return (
    <div className="NoConnectionsVideo">
      <div className="NoConnectionsVideo__video">
        <CircleVideo video={video} />
      </div>
      <div className="NoConnectionsVideo__content">
        <h1>{t(connection.name)}</h1>
        <p className="regularText">{t(`connections.no-connections.${service}.description`)}</p>
        <p className="regularText">
          <Trans i18nKey={`connections.no-connections.${service}.instruction`}>
            text<a target="_blank" rel="noreferrer" href={LinksWabaWorkRules[i18n.language]}>link</a>instruction
          </Trans>
        </p>
        <Checkbox
          name="yes"
          label={t(`connections.no-connections.${service}.checkbox`)}
          value={check}
          onCheckedHandler={onCheck}
        />
        <Button color="orange" textType="regular" text={t('continue')} disabled={!check} onClick={onAddConnect} />
      </div>
    </div>
  );
};

export default NoConnectionsVideo;
