import React from 'react';
import './Checkbox.scss';
import { useFormContext } from 'react-hook-form';

type Props = {
  label: string;
  name: string;
  value: string;
  disabled?:boolean
};

const Checkbox: React.FC<Props> = ({ label, name, value,disabled }) => {
  const {
    register,
  } = useFormContext();

  return(
    <label className="checkbox" >
      <input {...register(name)} type="checkbox" value={value} disabled={disabled}/>
      <span className="checkmark"/>
      {/* eslint-disable-next-line no-irregular-whitespace */}
      <p>      </p>
      {label}
    </label>
  )
}

export default Checkbox;
