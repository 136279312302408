import React, { useEffect, useState } from 'react';
import './SubscriptionsInvoicePay.scss';
import { useDebounce } from 'use-debounce';
import * as yup from 'yup';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useAppDispatch } from '../../../../../store/store';
import { InvoiceSelector } from '../../SubscriptionsSelector';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { addSubscriptionInvoice, clearSubscriptionPayInvoice } from '../../SubscriptionsSlice';
import { SubscriptionInvoiceRequestDialog, SubscriptionInvoiceRequestItem } from '../../SubscriptionsAPI';
import { SubscriptionInvoiceSchema } from '../../schema/schema';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { useCurrencyBill } from '../../utils';
import { tarifName, TariffType } from "../../../../../utils/dialogs";
import UserSubscription from '../../models/UserSubscription';
import Button from '../../../../../components/Button/Button';
import PeriodSelector from './PeriodSelector/PeriodSelector';
import PromoCode from "./PromoCode/PromoCode";
import ItemsList from './ItemsList/ItemsList';
import DialogsInput from './DialogsInput/DialogsInput';
import loader from '../../../../../assets/grid.svg';
import PaymentMethod from './PaymentMethod';
import Requisites from "./Requisites/Requisites";


const DIALOGS_LIMITS = { min: 1, max: 25000 };

type Props = {
  setOnPay: React.Dispatch<React.SetStateAction<boolean>>;
  subscriptionsList?: UserSubscription[];
  setSwitchPosition: React.Dispatch<React.SetStateAction<string>>;
  dialogOnly?: boolean;
};

function SubscriptionsInvoicePay({ setOnPay, subscriptionsList, setSwitchPosition, dialogOnly = false }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isVisiblePromoCode, setIsVisiblePromoCode] = useState(false);
  const currentCompany = useSelector(currentCompanySelector);
  const invoiceReview = useSelector(InvoiceSelector)
  const [promoCodeTest, setPromoCodeTest] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const isBillAvailable = useCurrencyBill();

  const schema = yup.object().shape({
    period_months: yup.number(),
    company_requisite_id: yup.number().when('payment_method', {
      is: 'BANK_TRANSFER',
      then: yup.number().required(t('error.form.empty_field')).typeError(t('error.form.empty_field')),
      otherwise: yup.number().nullable(),
    }),
    promo_code: yup.string().nullable(),
    payment_method: yup.string().nullable(),
    dialogs: yup.number().integer(t('error.form.integer_field')).nullable(true)
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .min(DIALOGS_LIMITS.min, t('error.form.min_value', { limit: DIALOGS_LIMITS.min }))
      .max(DIALOGS_LIMITS.max, t('error.form.max_value', { limit: DIALOGS_LIMITS.max }))
  });

  const methods = useForm<SubscriptionInvoiceSchema>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      period_months: 1,
      company_requisite_id: null,
      promo_code: '',
      payment_method: 'CARD_RU',
    },
  });

  const selectedSubscriptions = dialogOnly
    ? undefined
    : subscriptionsList?.map((sub) => sub.id);
  const hasWaba = tarifName(currentCompany?.waba360DialogPartnerId as TariffType) === 'russia' &&
    (subscriptionsList?.some((item) => item.type === 'waba') || dialogOnly);
  const period = useWatch({ control: methods.control, name: 'period_months' });
  const payment = useWatch({ control: methods.control, name: 'payment_method' });
  const promo = useWatch({ control: methods.control, name: 'promo_code' });
  const dialogsInput = useWatch( { control: methods.control, name: 'dialogs' });
  const [dialogs] = useDebounce(dialogsInput, 400);
  const showSubscriptionsParams = !hasWaba || (selectedSubscriptions && selectedSubscriptions?.length > 0);
  const isPaymentMethodRequisite = payment === 'BANK_TRANSFER';

  useEffect(() => () => {
    dispatch(clearSubscriptionPayInvoice());
  }, []);

 useEffect(() => {
  if (methods.formState.errors.dialogs === undefined) getInvoice(methods.getValues(), true);
  }, [period, dialogs]);

  useEffect(() => {
    if (promo) checkPromo();
  }, [promo]);

  const checkPromo = async () => {
    const invoiceUrl = await getInvoice(methods.getValues(), true);
    setPromoCodeTest(!!invoiceUrl);
    if (!invoiceUrl) methods.setValue('promo_code', '');
  };

  const onClickPromoCode = (promoCodeValue: string) => {
    methods.setValue('promo_code', promoCodeValue);
  };

  const getInvoice = async (data: SubscriptionInvoiceSchema, preview: boolean) => {
    let invoiceUrl = '';
    const items: Array<SubscriptionInvoiceRequestItem | SubscriptionInvoiceRequestDialog> = [];
    if (selectedSubscriptions) {
      items.push(...selectedSubscriptions!.map(
        (item) => ({ type: 'SUBSCRIPTION', id: item } as SubscriptionInvoiceRequestItem)));
    }
    if (data.dialogs && data.dialogs > 0) {
      items.push({ type: 'WABA_DIALOGS', count: data.dialogs } as SubscriptionInvoiceRequestDialog);
    }
    if (items.length > 0) {
      await dispatch(addSubscriptionInvoice({
        company_id: currentCompany.id, preview, items,
        payment_method: currentCompany.paymentCurrency === 'USD' ? "CARD_OTHER" : data.payment_method,
        period_months: data.period_months,
        ...(data.promo_code ? { promo_code: data.promo_code } : {}),
        ...(data.company_requisite_id && isPaymentMethodRequisite ? { company_requisite_id: data.company_requisite_id } : {})
      })).then((res) => {
        if (typeof res.payload === 'object') {
          invoiceUrl = res.payload?.previewUrl;
        }
      });
    }
    return invoiceUrl;
  };

  const onSubmit = async (data: SubscriptionInvoiceSchema) => {
    setClicked(true);
    const invoiceUrl = await getInvoice(data, false);
    if (invoiceUrl) {
      dispatch(addAlertWithCustomText({ message: t('subscriptions.invoice.submit_fulfilled'), color: '#2CBA5F' }));
      setOnPay(false);
      window.open(invoiceUrl, '_blank');
    } else {
      dispatch(addAlertWithCustomText({ message: t('subscriptions.invoice.submit_rejected'), color: '#F54242' }));
    }
    setClicked(false);
  };

  const onAddNewRequisites = () => {
    setOnPay(false);
    setSwitchPosition('requisites');
  };

  const showPromoInput = () => {
    setIsVisiblePromoCode(true);
  };

  if (!invoiceReview && !dialogOnly) {
    return (
      <>
        <div className='subscriptionsInvoicePay'>
          <div className='loaderContainer'>
            <img src={loader} alt='loader' />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='subscriptionsInvoicePay'>
        <div className='subscriptionsInvoicePay__header'>
          <h1>{t('subscriptions.pay')}</h1>
        </div>
        <FormProvider {...methods}>
          {showSubscriptionsParams && <PeriodSelector />}
          {hasWaba && <DialogsInput />}
          {invoiceReview && <ItemsList invoice={invoiceReview} subscriptions={subscriptionsList} />}
          {showSubscriptionsParams && <div className='subscriptionsInvoicePay__promoCode'>
            {isVisiblePromoCode
              ? (
                <div className='subscriptionsInvoicePay__promoCode_content'>
                  <PromoCode setPromo={onClickPromoCode} showInfo={hasWaba} />
                  {promoCodeTest && <span>{t('subscriptions.invoice.success_promo_code')}</span>}
                </div>
              ): <p onClick={showPromoInput}>{t('subscriptions.invoice.have_promo_code')}</p>}
          </div>}
          {currentCompany.paymentCurrency === 'RUB' && <PaymentMethod />}
          {isBillAvailable && isPaymentMethodRequisite && <Requisites onAddNewRequisites={onAddNewRequisites} />}
          <div className={`subscriptionsInvoicePay__bottom ${clicked ? 'clicked' : ''}`}>
            <Button type='submit' text={t('next')} color='orange' textType='regular'
              onClick={methods.handleSubmit(onSubmit)} />
          </div>
        </FormProvider>
      </div>
    </>
  );
}

export default SubscriptionsInvoicePay;
