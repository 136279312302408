import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useKeycloak } from '@react-keycloak/web';
import { history } from '../store/store';
import { PrivateRoute } from './PrivateRoute';
import Home from '../pages/Home/Home';
import ScrollToTop from '../components/ScrollToTop';
import loader from '../assets/grid.svg';
import { WindowContextProvider } from '../utils/WindowsContext';


const AppRouter = () => {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div className="loaderContainer">
        <img src={loader} alt="" />
      </div>
    );
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <WindowContextProvider>
          <ScrollToTop />
          <PrivateRoute path="/" component={Home} />
        </WindowContextProvider>
      </Switch>
    </ConnectedRouter>
  );
};

export default AppRouter;
