import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as VoiceRecordingIcon } from '../../../../assets/microphone-icon.svg';
import { ReactComponent as VoiceRecordEndIcon } from '../../../../assets/voiceRecordEnd-icon.svg';
import { ReactComponent as VoiceRecordSendIcon } from '../../../../assets/sendVoice-icon.svg';
import voiceRecordingGif from '../../../../assets/voice-recording.gif';


type Props = {
  isRecorded: boolean;
  setIsRecorded: React.Dispatch<React.SetStateAction<boolean>>;
  textAreaIsBlocked: boolean;
  setVoiceFile: React.Dispatch<React.SetStateAction<File | null>>;
};

const VoiceRecorder: React.FC<Props> = React.memo(({ isRecorded, setIsRecorded, textAreaIsBlocked, setVoiceFile }) => {
  const [voiceChunks, setVoiceChunks] = useState<Blob[] | null>(null);
  const timerId = useRef<NodeJS.Timer>();
  const [voiceRecordingTime, setVoiceRecordingTime] = useState<string>('00:00');
  const [voiceRecordIsStopped, setVoiceRecordIsStopped] = useState(true);
  const mediaRecorder = useRef<MediaRecorder>();


  useEffect(() => {
    if (!voiceRecordIsStopped && voiceChunks) {
      // @ts-ignore
      const voiceFile = new File([voiceChunks], `voice.oga`);
      setVoiceFile(voiceFile);
    } else {
      setVoiceFile(null);
      setVoiceChunks(null);
    }
  }, [voiceRecordIsStopped, voiceChunks]);

  const onRecord = () => {
    if (!isRecorded) {
      // @ts-ignore
      const { Recorder } = window;
      mediaRecorder.current = new Recorder({
        encoderPath: `${process.env.PUBLIC_URL}/OpusMediaRecorder/encoderWorker.min.js`,
      });
      // @ts-ignore
      mediaRecorder.current.onstart = () => {
        let time = 0;
        timerId.current = setInterval(() => {
          time += 1;
          setVoiceRecordingTime(
            `${Math.floor(time / 60)
              .toString()
              .padStart(2, '0')}:${
              time < 60 ? time.toString().padStart(2, '0') : `${(time % 60).toString().padStart(2, '0')}`
            }`
          );
        }, 1000);
        setVoiceRecordIsStopped(false);
      };
      // @ts-ignore
      mediaRecorder.current.onstop = () => {
        setIsRecorded(false);
        setVoiceChunks(null);
        if (timerId.current) {
          clearInterval(timerId.current);
        }
      };
      // @ts-ignore
      mediaRecorder.current.ondataavailable = (array) => {
        // @ts-ignore
        const blob = new Blob([array], { type: 'audio/ogg; codecs=opus' });
        // @ts-ignore
        setVoiceChunks(blob);
      };
      // @ts-ignore
      mediaRecorder.current.start();
    }
  };

  return isRecorded ? (
    <div className="iconsContainer__recordGroup">
      <button
        type="button"
        className="iconsContainer__recordGroup_stopButton"
        onClick={() => {
          if (mediaRecorder.current) {
            setIsRecorded(false);
            setVoiceRecordingTime('00:00');
            mediaRecorder.current.stop();
          }
          setVoiceRecordIsStopped(true);
          setVoiceChunks(null);
        }}
      >
        <VoiceRecordEndIcon />
      </button>
      <span className="iconsContainer__recordGroup_timerText">
        {voiceRecordingTime}
      </span>
      <img
        src={voiceRecordingGif}
        alt="voice gif"
        className="iconsContainer__recordGroup_recordGif"
      />
      <button
        type="button"
        className="iconsContainer__recordGroup_sendButton"
        onClick={() => {
          if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            // @ts-ignore
            setVoiceRecordIsStopped(false);
            setVoiceRecordingTime('00:00');
            setVoiceFile(null);
          }
        }}
      >
        <VoiceRecordSendIcon />
      </button>
    </div>
  ) : (
    <VoiceRecordingIcon
      className={`${textAreaIsBlocked && 'blocked'}`}
      onClick={() => {
        if (!textAreaIsBlocked) {
          setIsRecorded(true);
          onRecord();
        }
      }}
    />
  );
});

export default VoiceRecorder;
