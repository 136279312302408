import axiosInstance from "../../../api/AxiosInstance";
import { Connections, NewConnection } from "../../../api/CompanyAPI";
import * as con from './ConnectionsTypes';

export const postConnectionState = async (companyId: number): Promise<{state: string}> =>
  axiosInstance.post(`companies/${companyId}/connections/state`);

export const patchConnection = async (requestOptions: con.ConnectionsUpdateRequest): Promise<NewConnection> => {
  const { companyId, itemId, ...rest } = requestOptions;
  return axiosInstance.patch(`companies/${companyId}/connections/${itemId}`, { ...rest });
};

export const postConnection = async (requestOptions: con.ConnectionsCreateRequest): Promise<NewConnection> => {
  const { companyId, ...rest } = requestOptions;
  return axiosInstance.post(`/companies/${companyId}/connections/`, { ...rest });
};

export const getConnectionsList = async (requestOptions: con.ConnectionsListRequest): Promise<Connections> =>
  axiosInstance.get(`/companies/${requestOptions.companyId}/connections/`, {
    params: {
      offset: requestOptions.offset,
      limit: requestOptions.limit,
      types: requestOptions.types
    }
  });

export const getConnectionById = async (requestOption: con.CompanyConnectionType ): Promise<NewConnection> =>
  axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connectionId}`);

export const getConnectionStatus = async (
  requestOption: {companyId: number, connectionId: number}): Promise<con.ConnectionStatusType> =>
  axiosInstance.get(`companies/${requestOption.companyId}/connections/${requestOption.connectionId}/instance/status`);

export const getChatsBlackList = async (requestOptions: con.BlackListChatsRequest): Promise<con.BlockedChatType[]> =>
  axiosInstance.get(
    `/companies/${requestOptions.companyId}/connections/${requestOptions.connectionId}/chats_blacklist/`,
    { params: { offset: requestOptions.offset, limit: requestOptions.limit } });

export const postChatToBlackList = async (requestOptions: con.BlackListChatAddRequest): Promise<con.BlockedChatType> =>
  axiosInstance.post(
    `companies/${requestOptions.companyId}/connections/${requestOptions.connectionId}/chats_blacklist/`,
    { phone: requestOptions.phone });

export const deleteChatFromBlackList = async (requestOptions: con.BlackListChatDeleteRequest): Promise<void> => {
  const { companyId, connectionId, itemId } = requestOptions;
  return axiosInstance.delete(`companies/${companyId}/connections/${connectionId}/chats_blacklist/${itemId}`);
};

export const linkFacebook = async (requestOptions: con.linkFacebookRequest): Promise<void> => {
  const { company_id, ...rest } = requestOptions;
  return axiosInstance.post(`/companies/${company_id}/facebook/link_facebook`, { ...rest });
};

export const getFacebookCatalog = async (requestOptions: con.WabaConnectionRequest): Promise<con.FacebookCatalog> => 
  axiosInstance.get(`/companies/${requestOptions.company_id}/facebook/catalog/${requestOptions.waba_connection_id}`);

export const disableFacebookCatalog = async (requestOptions: con.WabaConnectionRequest): Promise<void> =>
  axiosInstance.post(
    `/companies/${requestOptions.company_id}/facebook/catalog/${requestOptions.waba_connection_id}/disable`);
