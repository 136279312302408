import React from 'react';
import './TemplateItem.scss';
import { WabaTemplate } from "../../../../../api/types";


type TemplateItemProps = {
  templates: WabaTemplate,
  setSelectTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | undefined>>
}

const TemplateItem = ({ templates, setSelectTemplate }: TemplateItemProps) => {

  const onTemplate = () => {
    setSelectTemplate(templates);
  }

  return (
    <div className='templateItem' onClick={onTemplate}>
      {templates.components.body.text}
    </div>
  );
};

export default TemplateItem;
