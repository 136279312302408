import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectionLimits, getConnectionLimits } from '../api/CompanyAPI';
import { addAlertWithCustomText } from '../components/Alert/alertSlice';
import { currentCompanySelector } from '../views/Chat/companiesSelector';
import { ConnectionsType } from '../views/Settings/Connections/ConnectionsTypes';


export function useIsConnectMaxLimit(service: ConnectionsType) {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const [connectLimit, setConnectLimit] = useState<ConnectionLimits>();

  useEffect(() => {
    const getLimit = () => {
      getConnectionLimits({ companyId: currentCompany.id, limitType: `connections_${service}` })
        .then(res => setConnectLimit(res));
    };

    getLimit();

  }, [service]);

  const isMaxLimit = useCallback(() => {
    if (connectLimit && connectLimit.currentValue >= connectLimit.maxValue) {
      dispatch(addAlertWithCustomText({ message: `connections.${service}.limit`, type: 'alarm' }));
      return true;
    }

    return false;
  }, [service, connectLimit]);

  return isMaxLimit;  
}
