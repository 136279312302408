import React from 'react';
import './AddActionButton.scss';
import { ReactComponent as PlusIcon } from '../../../../assets/plus-icon.svg';

type Props = {
  onAddAction: () => void;
  subtext: string;
};

const AddActionButton: React.FC<Props> = ({ onAddAction, subtext }) => (
  <button type="button" className="addActionButton" onClick={onAddAction}>
    <PlusIcon width={12} height={12} />
    {subtext}
  </button>
);

export default AddActionButton;
