import axiosInstance from '../../../api/AxiosInstance';
import { CurrenciesType } from '../CompanySettings/currency';
import { MethodsPaymentType } from "./components/SubscriptionsInvoicePay/helper";


const subscriptionServices = [
  'whatsapp', 'waba', 'bank', 'telegram', 'telegram_bot', 'odnoklassniki',
  'tinkoff', 'modulbank', 'sberbank', 'paykeeper', 'paypal', 'bepaid', 'yookassa'
] as const; // , 'payselection'

export type SubscriptionServiceType = typeof subscriptionServices[number];

type UServiceType<Str extends string> = `CONNECTION_${Uppercase<Str>}`;

export type SubscriptionType = UServiceType<SubscriptionServiceType>;

export const subscriptionServiceMap = Object.fromEntries(subscriptionServices.map((item) => [
  `CONNECTION_${item.toUpperCase()}`, item]));

export type SubscriptionItem = 'SUBSCRIPTION';
export type WabaDialogItem = 'WABA_DIALOGS';

export const invoiceItemTypes: Record<SubscriptionItem | WabaDialogItem, string> = {
  SUBSCRIPTION: 'subscription',
  WABA_DIALOGS: 'dialog'
};

export type Subscription = {
  id: number;
  connections: number[];
  subscriptionType: SubscriptionType;
  currentSize: number;
  testUntil: string;
  paidUntil: string;
  pausedAt: string | null;
};

export type PatchSubscription = {
  connections: number[];
  preview: boolean;
};

export type PaymentRequisite = {
  id: number;
  tin: string;
  name: string;
  approvalStatus: 'APPROVED' | 'DECLINED' | 'PENDING';
};

export type PostPaymentRequisite = {
  company_id: number;
  tin: string;
  name: string;
};

export type UpdatePaymentRequisite = {
  requisite_id: number;
  company_id: number;
  tin: string;
  name: string;
};

export type SubscriptionInvoiceItem = {
  type: SubscriptionItem;
  subscriptionId: number;
  oldSize: number;
  products: ProductType[];
};

export type SubscriptionInvoiceDialog = {
  type: WabaDialogItem;
  count: number;
  price: number;
};

export function checkItemSubscription(
  item: SubscriptionInvoiceItem | SubscriptionInvoiceDialog): item is SubscriptionInvoiceItem {
  if (item && (item as SubscriptionInvoiceItem).type === 'SUBSCRIPTION') {
    return true;
  }
  return false;
}

export function checkItemDialog
  (item: SubscriptionInvoiceItem | SubscriptionInvoiceDialog): item is SubscriptionInvoiceDialog {
  if (item && (item as SubscriptionInvoiceDialog).type === 'WABA_DIALOGS') {
    return true;
  }
  return false;
}

export type SubscriptionReceiptItem = {
  name: string;
  quantity: number;
  price: number;
};

export type UpsellRecommendation = {
  type: WabaDialogItem;
  value: {
    previousCount: number;
    recommendedCount: number;
  };
};

export type ProductType = {
  connectionId: number;
  amount: number;
  discount: number;
};

export type SubscriptionInvoice = {
  id: number;
  preview: boolean;
  status: string;
  lifetime: string;
  summary: {
    items: Array<SubscriptionInvoiceItem | SubscriptionInvoiceDialog>;
    periodMonths: number;
    paymentMethod: MethodsPaymentType;
    promoCode: string;
    discount: number;
    companyRequisite: {
      id: number;
      tin: string;
      name: string;
      approvalStatus: string;
    };
  };
  amount: number;
  currency: CurrenciesType;
  paymentUrl: string;
  previewUrl: string;
  actUrl: string;
  receipt: {
    items: SubscriptionReceiptItem[];
  };
  createdAt: string;
};

export type SubscriptionDialogs = {
  type: WabaDialogItem;
  value: number;
};

export type SubscriptionInvoiceRequestItem = {
  type: SubscriptionItem;
  id: number;
};

export type SubscriptionInvoiceRequestDialog = {
  type: WabaDialogItem;
  count: number;
};

export type PostSubscriptionInvoice = {
  company_id: number;
  preview: boolean;
  items: Array<SubscriptionInvoiceRequestItem | SubscriptionInvoiceRequestDialog>;
  payment_method: MethodsPaymentType;
  period_months: number;
  company_requisite_id?: number;
  promo_code?: string;
};

export type SubscriptionsInvoicesRequest = {
  companyId: number;
  limit?: number;
  offset?: number;
};

export type SubscriptionDialogsRequest = {
  companyId: number;
};

export const getSubscriptions = async (company_id: number): Promise<Subscription[]> =>
  axiosInstance.get(`/companies/${company_id}/subscriptions/`, {
    params: {
      company_id,
    },
  });

export const patchSubscriptions = async (
  subscriptionId: number,
  companyId: number,
  data: PatchSubscription): Promise<Subscription> =>
  axiosInstance.patch(`/companies/${companyId}/subscriptions/${subscriptionId}`, data);

export const getPaymentRequisite = async (company_id: number, only_approved?: boolean): Promise<PaymentRequisite[]> =>
  axiosInstance.get(`/companies/${company_id}/subscriptions/requisites/`, {
    params: {
      company_id,
      only_approved,
    },
  });

export const postPaymentRequisite = async (requestOption: PostPaymentRequisite): Promise<PaymentRequisite> =>
  axiosInstance.post(`/companies/${requestOption.company_id}/subscriptions/requisites/`, {
    company_id: requestOption.company_id,
    tin: requestOption.tin,
    name: requestOption.name,
  });

export const patchPaymentRequisite = async (requestOption: UpdatePaymentRequisite): Promise<PaymentRequisite> =>
  axiosInstance.put(`/companies/${requestOption.company_id}/subscriptions/requisites/${requestOption.requisite_id}`, {
    requisite_id: requestOption.requisite_id,
    company_id: requestOption.company_id,
    tin: requestOption.tin,
    name: requestOption.name,
  });

export const deletePaymentRequisite = async (company_id: number, requisite_id: number):
  Promise<PaymentRequisite> => axiosInstance.delete(`/companies/${company_id}/subscriptions/requisites/${requisite_id}`);

export const getSubscriptionInvoice = async (
  requestOption: SubscriptionsInvoicesRequest): Promise<SubscriptionInvoice[]> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/subscriptions/invoices/`,
    { params: { limit: requestOption.limit, offset: requestOption.offset } }
  );

export const postSubscriptionInvoice = async (
  requestOption: PostSubscriptionInvoice): Promise<SubscriptionInvoice> =>
  axiosInstance.post(`/companies/${requestOption.company_id}/subscriptions/invoices/`, {
    preview: requestOption.preview,
    items: requestOption.items,
    payment_method: requestOption.payment_method,
    period_months: requestOption.period_months,
    company_requisite_id: requestOption.company_requisite_id,
    promo_code: requestOption.promo_code,
  });

export const getSubscriptionDialogs = async (
  requestOption: SubscriptionDialogsRequest): Promise<SubscriptionDialogs[]> =>
  axiosInstance.get(`/companies/${requestOption.companyId}/subscriptions/dialogs/`);

export const getUpsellRecommendations = async (companyId: number): Promise<UpsellRecommendation[]> =>
  axiosInstance.get(`/companies/${companyId}/subscriptions/invoices/upsell_recommendations/`);
