import React, { FC } from 'react';
import './InvoiceItem.scss';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { SubscriptionInvoiceItemType } from '../helper';


type Props = {
  item: SubscriptionInvoiceItemType;
};

const InvoiceItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <tr className='subscriptionsItem' key={nanoid()}>
      <td data-title={t('subscriptions.invoice.sub_list.name')}>
        {t(`subscriptions.invoice.title_item.${item.itemType}`)} {t(`subscriptions.types.${item.type}`)}
        <h6>{`${t(`subscriptions.invoice.sub_list.${item.serviceType}`)}${item.volume}`}</h6>
      </td>
      <td data-title={t('subscriptions.invoice.sub_list.price')} className='leftStyle'>{item.amount}</td>
      <td data-title={t('subscriptions.invoice.sub_list.discount')} className='leftStyle'>{item.discount}</td>
      <td data-title={t('subscriptions.invoice.sub_list.amount')} className='amountStyle'>{item.total}</td>
    </tr>
  );
};

export default InvoiceItem;
