import axiosInstance from './AxiosInstance';
import {
  ChatsRequest,
  ChatType,
  ContactChatsWithMetaData,
  CreateChatResponse,
  CreateCustomTemplateRequestOptions,
  CreateInteractiveTemplateRequestData,
  CustomTemplate,
  ImageUrl,
  ImageUrlRequest, DialogsCountsType,
  MessageModeType,
  MessagePOSTRequest,
  MessageRequest,
  MessagesRequest,
  MessageTemplateRequestType,
  PatchCustomTemplateRequestOptions,
  RequestInteractiveTemplate,
  WabaTemplate,
  ChatAvailabilityRequest,
  ChatAvailability,
} from './types';

export const getMessages = async (requestOption: MessagesRequest): Promise<MessageRequest[]> =>
  axiosInstance.get(`companies/${requestOption.companyId}/messaging/messages/`, {
    params: {
      connection_id: requestOption.connectionId,
      chat_id: requestOption.chatId,
      limit: requestOption.limit,
      until: requestOption.until,
    },
  });

export const getChats = async (requestOption: ChatsRequest): Promise<ChatType[]> =>
  axiosInstance.get(`companies/${requestOption.companyId}/messaging/chats/`, {
    params: {
      connection_id: requestOption.connectionId,
      offset: 0,
      query: requestOption.query,
    },
  });

export const getChatById = async (
  requestOptions: { connectionId: number, chatId: number, companyId:number }): Promise<ChatType> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/${requestOptions.connectionId}/${requestOptions.chatId}`);

export const getUrl = async (requestOptions: ImageUrlRequest): Promise<ImageUrl> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/files/`, {
    params: {
      connection_id: requestOptions.connectionId,
      chat_id: requestOptions.chatId,
      message_id: requestOptions.messageId,
    },
  });

export const uploadFile = async (requestOptions: {companyId:number, file:File}): Promise<{ url: string }> => {
  const formData = new FormData();
  formData.append('file', requestOptions.file);
  return axiosInstance.post(`companies/${requestOptions.companyId}/messaging/files/`, formData);
};

export const postMessage = async (requestOptions: MessagePOSTRequest ): Promise<MessageModeType> =>
  axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`, requestOptions);

export const getChatAvailability = async (requestOptions: ChatAvailabilityRequest): Promise<ChatAvailability> => {
  const { companyId, chatId, connectionId } = requestOptions;
  return axiosInstance.get(`/companies/${companyId}/messaging/chats/${connectionId}/${chatId}/availability`);
};

export const getDialogsCounters = async (companyId: number): Promise<DialogsCountsType[]> =>
  axiosInstance.get(`/companies/${companyId}/messaging/dialogs/counters/`);

export const postWabaTemplate = async (
  requestOptions: MessageTemplateRequestType,
  currentChat: ChatType
): Promise<MessageModeType> => axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`,
    {
    connection_id: currentChat.connectionId,
    chat_id: currentChat.chatId,
    message_type: 'waba_template',
      waba_template:requestOptions.waba_template
  });


export const mailInteractiveTemplate = async (requestOptions: RequestInteractiveTemplate, currentChat: ChatType) =>
  axiosInstance.post(`companies/${requestOptions.companyId}/messaging/messages/`, {
    connection_id: currentChat.connectionId,
    chat_id: currentChat.chatId,
    message_type: 'waba_interactive',
    waba_interactive: { ...requestOptions },
  });

export const getListMessageTimestamp = (requestOptions: {
  connectionId: number;
  chatId: number;
  companyId:number
}): Promise<{ createdAt: string }> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/last_inbound_message_created_at`, {
    params: {
      connection_id: requestOptions.connectionId,
      chat_id: requestOptions.chatId,
    },
  });

export const getCustomTemplates = async (requestOptions: { companyId: number }): Promise<Array<CustomTemplate>> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/templates/`, {
    params: {
      company_id: requestOptions.companyId,
    },
  });

export const deleteCustomTemplate = async (requestOption:{ companyId: number,templateId:number }) =>
  axiosInstance.delete(`companies/${requestOption.companyId}/messaging/templates/${requestOption.templateId}`);

export const getWabaTemplates = async (
  requestOptions: {companyId: number, connectionId: number }): Promise<Array<WabaTemplate>> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/waba_templates/`, {
    params: {
      connection_id: requestOptions.connectionId,
      only_approved: true,
    },
  });

export const patchCustomTemplate = async (requestOptions: PatchCustomTemplateRequestOptions): Promise<CustomTemplate> =>
  axiosInstance.patch(`companies/${requestOptions.companyId}/messaging/templates/${requestOptions.templateId}`, {
    name: requestOptions.name,
    text: requestOptions.text,
    file_url: requestOptions.fileUrl,
  });

export const postCustomTemplate = async (requestOptions: CreateCustomTemplateRequestOptions): Promise<CustomTemplate> =>
  axiosInstance.post(`companies/${requestOptions.companyId}/messaging/templates/`, {
    name: requestOptions.name,
    text: requestOptions.text,
    company_id: requestOptions.companyId,
    type: requestOptions.type,
    file_url: requestOptions.fileUrl,
  });

export const getAllCompanyChats = async (requestOptions: { companyId: number; query?: string }): Promise<ChatType[]> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/all/`, {
    params: {
      company_id: requestOptions.companyId,
      query: requestOptions.query,
    },
  });

export const postInteractiveTemplate = async (
  requestOptions: CreateInteractiveTemplateRequestData
): Promise<CustomTemplate> => axiosInstance.post(`companies/${requestOptions.company_id}/messaging/templates/`, requestOptions);

export const patchInteractiveTemplate = async (
  requestOptions: CreateInteractiveTemplateRequestData,
  templateId: number
): Promise<CustomTemplate> =>
  axiosInstance.patch(`companies/${requestOptions.company_id}/messaging/templates/${templateId}`, {
    name: requestOptions.name,
    waba_interactive: requestOptions.waba_interactive,
  });

export type ChatsWithContactsParamType={
  companyId: number;
  contactId?: number;
  limit?: number;
  cursor?: string;
  searchedValue?: string;
}

export const getChatsWithContacts = async (requestOptions: ChatsWithContactsParamType)
  : Promise<ContactChatsWithMetaData> =>
  axiosInstance.get(`companies/${requestOptions.companyId}/messaging/chats/with_contacts/`, {
    params: {
      contact_id: requestOptions.contactId ?? undefined,
      query: requestOptions.searchedValue,
      cursor: requestOptions.cursor ?? undefined,
      limit: requestOptions.limit ?? undefined,
    },
  });

export const postChat = async (
  requestOption: { companyId: number, connectionId: number, contactId?: number; phone?: string, username?: string }
  ): Promise<CreateChatResponse> =>
  axiosInstance.post(`companies/${requestOption.companyId}/messaging/chats/`, {
    connection_id: requestOption.connectionId,
    contact_id: requestOption.contactId,
    phone: requestOption.phone,
    user_name: requestOption.username });
