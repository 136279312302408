import { useTranslation } from 'react-i18next';
import { ReactComponent as GiftIcon } from '../../../../../../assets/xmass-tree.svg';
import './Gift.scss';


type GiftProps = {
  giftValue: number
};

function Gift({ giftValue }: GiftProps) {
  const { t } = useTranslation();

  return (
    <div className='gift'>
      <p className='smallText'>{`+${giftValue} ${t('days')}`}</p><GiftIcon />
    </div>
  );
}

export default Gift;
