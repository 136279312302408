import React, { useEffect, useState } from 'react';
import './NavbarDesktop.scss';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../../assets/close-icon.svg';
import { ReactComponent as TailPopupIcon } from '../../../../assets/dropdown-after-triangle.svg';
import { ReactComponent as DoubleArrowIcon } from '../../../../assets/doubleArrow-icon.svg';
import { getCurrentCompanyRoleSelector } from '../../../../views/Chat/companiesSelector';
import { currentStepSelector } from '../../../Onboarding/onboardingSelectors';
import { cancelOnboarding } from '../../../Onboarding/onboardingSlice';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { UpdateRolledUp } from '../../../../components/utils/hooks';
import CompaniesList from '../components/CompaniesList';
import SalesList from './SalesList';
import LinkItem from '../components/LinkItem';
import CompanyLogo from "../components/CompanyLogo";
import SupportButton from "../components/SupportButton";
import ReferenceButton from "../components/ReferenceButton";
import PromotionBanner from "../components/PromotionBanner";
import HelpTooltipPopup from "./HelpTooltipPopup";


const NavbarDesktop = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ id: string }>('/companies/:companyId');
  const currentTab = useRouteMatch<{ tab: string }>('/companies/:companyId/:tab');
  const [selectedTab, setSelectedTab] = useState<string>();
  const currentOnboardingStep = useSelector(currentStepSelector);
  const { isRolledUp, setIsRolledUp } = UpdateRolledUp();
  const [isShowingHelpTooltip, setIsShowingHelpTooltip] = useState(false);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const isManagerRole = currentRole && currentRole.name === 'MANAGER';

  const onSelectTab = (e: React.MouseEvent<HTMLDivElement>) => {
    setSelectedTab(e.currentTarget.id);
  };

  useEffect(() => {
    if (currentTab) {
      setSelectedTab(currentTab.params.tab);
    } else {
      setSelectedTab('chats');
    }
  }, [url]);

  const onShowingRolledUp = () => {
    setIsRolledUp(!isRolledUp);
  };

  const onClickHelp = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isManagerRole) {
      return;
    }
    e.preventDefault();
    setIsShowingHelpTooltip(true);
  };

  return (
    <>
      <div id='navbarContainer' className={`navbarContainer ${isRolledUp ? 'rolledUp': ''}`}>
        <div className="navbarContainer__closeTrigger" onClick={onShowingRolledUp}>
          <DoubleArrowIcon style={{ transform: isRolledUp ? 'rotateY(180deg)' : 'none' }} />
        </div>
        <div className={`navbarContainer__header ${isRolledUp ? 'rolledUp' : ''}`}>
          <CompanyLogo isRolledUp={isRolledUp} />
        </div>
        <div className={`navbarContainer__tabs ${isRolledUp ? 'rolledUp' : ''}`}>
          <WrapperPermission permission='messagingChatView'>
            <LinkItem location="chats" isRolledUp={isRolledUp} />
          </WrapperPermission>
          <WrapperPermission
            permissions={['salesShopView','salesCatalogView','salesProductView','banksInvoiceView']}>
            <SalesList selectedTab={selectedTab} onSelectTab={onSelectTab} isRolledUp={isRolledUp} />
          </WrapperPermission>
          <WrapperPermission permission='templatesView'>
            <LinkItem location="templates" isRolledUp={isRolledUp} />
          </WrapperPermission>
          <WrapperPermission permission='contactsView'>
            <LinkItem location="contacts" isRolledUp={isRolledUp} />
          </WrapperPermission>
          <WrapperPermission permission='broadcastsView'>
            <LinkItem location='broadcasts' isRolledUp={isRolledUp} />
          </WrapperPermission>
          <LinkItem location="settings" isRolledUp={isRolledUp} />
        </div>
        <div className={`navbarContainer__footer ${isRolledUp ? 'rolledUp' : ''}`}>
          <PromotionBanner isRolledUp={isRolledUp} />
          {(currentOnboardingStep === 7 || currentOnboardingStep === 100 || isShowingHelpTooltip) && (
            <HelpTooltipPopup setIsShowingHelpTooltip={setIsShowingHelpTooltip} />
          )}
          {currentOnboardingStep === 100 && (
            <div className="onboardingHelpTooltip">
              <div className="wrapper">
                <p>{t('onboarding.onboadring__additional_popup')}</p>
                <TailPopupIcon className="tailIcon" />
                <CloseIcon className="closeIcon" onClick={() => dispatch(cancelOnboarding())} />
              </div>
            </div>
          )}
          <CompaniesList isRolledUp={isRolledUp} position="top" />
          <div className="supportMenu">
            <ReferenceButton isRolledUp={isRolledUp} onClickHelp={onClickHelp} />
            <SupportButton isRolledUp={isRolledUp} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarDesktop;
