import { useEffect, useState } from 'react';
import './InvoiceChat.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentContactSelector } from '../../../../contactsSlice/contactsSelector';
import { 
  getInvoiceDate, getInvoiceStatus, getSymbolCurrency
} from '../../../../../Sales/Orders/components/OrderItem/helpers';
import { fetchInvoices } from '../../../../../Chat/billSlice';
import { invoicesSelector } from '../../../../../Chat/billSelector';


const InvoiceChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contact = useSelector(currentContactSelector);
  const listInvoice = useSelector(invoicesSelector);
  const [limit, setLimit] = useState(1);

  useEffect(() => {
    if (contact) dispatch(fetchInvoices({
      companyId: contact.companyId, contactId: contact.id, offset: 0, limit: limit + 1 
    }));
  }, [limit]);

  if (listInvoice) {
    return (
      <div className='InvoiceItemChat'>
        {listInvoice.slice(0, limit).map((invoice) => (
          <div className="InvoiceItemChat__item" key={invoice.id}>
            <div className='InvoiceItemChat__item_content'>
              <div className='InvoiceItemChat__item_content_date'>
                <h6>{t('contacts.detailContact.date_order')}</h6>
                <p>{getInvoiceDate(invoice.createdAt, t)}</p>
              </div>
              <div className='InvoiceItemChat__item_content_status'>
                <h6>{t('orders.status_title')}</h6>
                {getInvoiceStatus(invoice, t)}
              </div>
              <div className='InvoiceItemChat__item_content_amount'>
                <h6>{t('orders.amount_title')}</h6>
                <p>{invoice.amount} {getSymbolCurrency(invoice)}</p>
              </div>
              <div className='InvoiceItemChat__item_content_client'>
                <h6>{t('contacts.detailContact.employee')}</h6>
                <p>{invoice.receipt.clientName}</p>
              </div>
            </div>
          </div>
        ))}
        {(listInvoice.length > listInvoice.slice(0, limit).length) && (
          <div className='InvoiceItemChat__item_button' onClick={() => setLimit(limit + 1)}>
            <p>{t('contacts.detailContact.show_more')}</p>
          </div>
        )}
      </div>
    );
  }
  return <></>
};

export default InvoiceChat;
