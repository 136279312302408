import React, { useEffect, useState } from 'react';
import { convertAudioTime } from './helpers';

type Props = {
  wavesurfer: React.MutableRefObject<WaveSurfer | undefined>;
  isPlaying: boolean;
  duration: number;
};

const CustomAudioPlayerTimer: React.FC<Props> = ({ wavesurfer, isPlaying, duration }) => {
  const [timerTime, setTimerTime] = useState<number>(duration);
  
  const updateTimerTimer = () => {
    if (isPlaying) setTimerTime(wavesurfer.current!.getCurrentTime());
  };

  useEffect(() => {
    setTimerTime(duration);
  }, [duration]);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.on('audioprocess', updateTimerTimer);
    }

    return () => {
      if (wavesurfer.current) wavesurfer.current.un('audioprocess', updateTimerTimer);
    };
  }, [isPlaying]);

  return <div className="customAudioPlayer__time">{convertAudioTime(timerTime)}</div>;
};

export default CustomAudioPlayerTimer;
