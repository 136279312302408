import React from 'react';
import { nanoid } from 'nanoid';
import './ListItems.scss';


type ListStepsProps = {
  typeList: 'ol' | 'ul';
  items: string[];
};

function ListItems({ typeList, items }: ListStepsProps) {
  
  if (items.length === 0) return null;

  const listItems = () => items.map(item => <li key={nanoid()}>{item}</li>);

  if (typeList === 'ol') {
    return (<ol>{listItems()}</ol>);
  } else {
    return (<ul>{listItems()}</ul>);
  }
}

export default ListItems;
