import React, { useEffect, useRef } from 'react';
import './ProfileInvoice.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Invoice } from '../../../../../api/BillAPI';
import closeIcon from '../../../../../assets/close-icon-black.svg';
import { updateIsVisibleProfileInvoice } from '../../../../Chat/billSlice';
import {
  getInvoiceDate,
  getInvoiceStatus,
  getSymbolCurrency, getTerminalAndLogo,
} from '../OrderItem/helpers';
import ProfileInvoiceItem from './ProfileInvoiceItem/ProfileInvoiceItem';
import { terminalsSelector } from '../../../../Chat/billSelector';
import { useOutsideClick } from '../../../../../components/utils/hooks';
import { currentContactSelector } from '../../../../Contacts/contactsSlice/contactsSelector';
import { fetchContact } from '../../../../Contacts/contactsSlice/contactsSlice';


type Props = {
  invoice: Invoice
}

const ProfileInvoice: React.FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);

  const terminals = useSelector(terminalsSelector);
  const currentContact = useSelector(currentContactSelector);

  useEffect(() => {
    if (invoice.contactId) {
      dispatch(fetchContact({
        contactId: invoice.contactId,
        companyId: Number(url?.params.companyId),
      }));
    }
  }, [invoice.contactId, url?.params.companyId]);

  const onCloseProfile = () => {
    dispatch(updateIsVisibleProfileInvoice({
      isVisibleProfileInvoice: false,
      profileInvoice: null,
    }));
  };

  const onClickContact = () => {
    if (invoice.contactId) {
      history.push(`/companies/${url?.params.companyId}/contacts/${invoice.contactId}`);
    }
  };

  const onCloseDropdown = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    dispatch(updateIsVisibleProfileInvoice({
      isVisibleProfileInvoice: false,
      profileInvoice: null,
    }));
  };

  useOutsideClick(wrapperRef, onCloseDropdown, triggerButtonRef);

  const buyerText = () => {
    if (!invoice.contactId && !invoice.receipt.clientName) {
      return t('orders.amo_buyer');
    }
    if (invoice.contactId && currentContact && currentContact.name) {
      return currentContact.name;
    }
    return t('orders.amo_buyer');
  }

  return (
    <>
      <div className='container'>
        {}
      </div>
      <div className='profileInvoice' ref={wrapperRef}>
        <div className='profileInvoice__left'>
          <div className='profileInvoice__left_order'>
            <h6>{t('orders.id_order_title')}</h6>
            <p>{t('orders.order')} №{invoice.orderId}</p>
          </div>
          <div className='profileInvoice__left_buyer'>
            <h6>{t('orders.buyer')}</h6>
            <p className='profileInvoice__left_buyer_text'
               onClick={onClickContact}
               style={invoice.contactId && currentContact && currentContact.name ? { color: '#FE6D04', cursor: 'pointer' } : {}}>
              {buyerText()}
            </p>
          </div>
          <div className='profileInvoice__left_container'>
            <div className='profileInvoice__left_container_left'>
              <div className='profileInvoice__left_container_left_date'>
                <h6>{t('orders.date_title')}</h6>
                <p>{getInvoiceDate(invoice.createdAt, t, false, true)}</p>
              </div>
              <div className='profileInvoice__left_container_left_terminal'>
                <h6>{t('orders.terminal')}</h6>
                {getTerminalAndLogo(invoice, terminals)}
              </div>
            </div>
            <div className='profileInvoice__left_container_right'>
              <div className='profileInvoice__left_container_right_amount'>
                <h6>{t('orders.amount_title')}</h6>
                <p>{invoice.amount} {getSymbolCurrency(invoice)}</p>
              </div>
              <div className='profileInvoice__left_container_right_status'>
                <h6>{t('orders.status_title')}</h6>
                {getInvoiceStatus(invoice, t)}
              </div>
            </div>
          </div>
          <div className='profileInvoice__left_client'>
            <h6>{t('orders.client_name')}</h6>
            <p>{invoice.responsibleMemberId ? invoice.receipt.clientName : t('orders.amo_client')}</p>
          </div>
          <div className='profileInvoice__left_url'>
            <h6>{t('orders.link')}</h6>
            <a href={invoice.url} target='_blank' rel='noreferrer'>
              <p>{invoice.url}</p>
            </a>
          </div>
          <div className='profileInvoice__left_products'>
            <h4>{t('orders.products.title')}</h4>
            <div className='profileInvoice__left_products_title'>
              <div className='profileInvoice__left_products_title_product'>{t('orders.products.product')}</div>
              <div
                className='profileInvoice__left_products_title_count'>{t('orders.products.count')}</div>
              <div
                className='profileInvoice__left_products_title_price'>{t('orders.products.amount')}</div>
            </div>
            <div className='profileInvoice__left_products_list'>
              {invoice.receipt.items.map(item => (
                <ProfileInvoiceItem product={item} forCurrency={invoice}
                                    key={item.name} />
              ))}
            </div>
          </div>
        </div>
        <div className='profileInvoice__right'>
          <img alt='close img' src={closeIcon} onClick={onCloseProfile}
               ref={triggerButtonRef} />
        </div>
      </div>
    </>
  );
};

export default ProfileInvoice;
