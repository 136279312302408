import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon-black.svg';
import { useMobile, useOutsideClick } from '../../../components/utils/hooks';
import Button from "../../../components/Button/Button";


type PropsUseModal = {
  title: string;
  propsNameButton: string;
  cancelButton?: () => void;
  reset?: () => void;
  onSubmit: () => void
  disabled?: boolean
};

const UseModal: FC<PropsUseModal> = ({ title, children, propsNameButton, cancelButton, onSubmit, disabled }) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<SVGSVGElement>(null);

  const cancel = () => cancelButton && cancelButton();

  useOutsideClick(contentRef, cancel, triggerRef);

  return (
    <div ref={contentRef} className="useModalContact">
      <div className="useModalContact__header">
        <h3>{title}</h3>
        <CloseIcon ref={triggerRef} onClick={cancel} />
      </div>
      {children}
      <div className="useModalContact__footer">
        <Button type="submit" color='orange' textType={isMobile ? 'regular' : 'small'} text={propsNameButton}
          disabled={!disabled} onClick={onSubmit} data-testid='submit' />
        {!isMobile &&
          <Button type='button' color='white' textType={isMobile ? 'regular' : 'small'}
            text={t('contacts.detailContact.cancel')} onClick={cancel} data-testid='cancel' />
        }
      </div>
    </div>
  );
};

export default UseModal;
