import { ConnectionsType } from "../ConnectionsTypes";
import ConnectionOuterLink from "./ConnectionOuterLink";
import SubscriptionStatus from "./SubscriptionStatus";
import TelegramStatus from "./TelegramStatus";
import Statistics from "./Statistics";
import BlackList from "./BlackList";
import ConnectionStatus from "./ConnectionStatus";
import ConnectionStatusQr from "./ConnectionStatusQr";
import CatalogStatus from "./CatalogStatus";


type ConnectionCardInnerType = {
  editName?: boolean;
  status?: Array<() => JSX.Element>;
  addon?: Array<() => JSX.Element>;
  children?: Array<() => JSX.Element>;
};

export const connectionCardInner: Partial<Record<ConnectionsType, ConnectionCardInnerType>> = {
  'telegram': {
    status: [TelegramStatus, SubscriptionStatus]
  },
  'telegram_bot': {
    status: [SubscriptionStatus],
    children: [ConnectionOuterLink]
  },
  'waba': {
    status: [SubscriptionStatus],
    addon: [Statistics, BlackList],
    children: [CatalogStatus]
  },
  'whatsapp': {
    status: [ConnectionStatusQr, SubscriptionStatus],
    addon: [BlackList],
    children: [ConnectionStatus]
  },
  'odnoklassniki': {
    status: [SubscriptionStatus],
    children: [ConnectionOuterLink]
  },
  'bepaid': {
    status: [SubscriptionStatus]
  },
  'tinkoff': {
    status: [SubscriptionStatus]
  },
  'sberbank': {
    status: [SubscriptionStatus]
  },
  'modulbank': {
    status: [SubscriptionStatus]
  },
  'paykeeper': {
    status: [SubscriptionStatus]
  },
  'yookassa': {
    status: [SubscriptionStatus]
  }
};
