import React from "react";
import { ReactComponent as ShopsIcon } from "../../../assets/shops.svg";
import { ReactComponent as ProductsIcon } from "../../../assets/products.svg";
import { ReactComponent as CatalogsIcon } from "../../../assets/catalogs.svg";
import { ReactComponent as OrdersIcon } from "../../../assets/orders.svg";
import { listOfTabsInSettings } from "../../../views/Settings/utility/listOfTabsInSettings";


export type SubMenuTabType = 'settings' | 'sales';

export type SubMenuTabsType = {
  tab: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {title?: string | undefined}>;
  title: string;
  permission: string;
  url: string;
};

export type TabsListType = {
  title: string;
  tabs: SubMenuTabsType[];
};

export const tabsSales: SubMenuTabsType[] = [
  {
    tab: 'shops',
    Icon: ShopsIcon,
    title: 'navbar.shops',
    permission: 'sales_shop_view',
    url: 'shops'
  },
  {
    tab: 'products',
    Icon: ProductsIcon,
    title: 'navbar.products',
    permission: 'sales_product_view',
    url: 'products'
  },
  {
    tab: 'catalogs',
    Icon: CatalogsIcon,
    title: 'navbar.catalogs',
    permission: 'sales_catalog_view',
    url: 'catalogs'
  },
  {
    tab: 'orders',
    Icon: OrdersIcon,
    title: 'navbar.orders',
    permission: 'banks_invoice_view',
    url: 'orders'
  },
];

export const tabsSettings: SubMenuTabsType[] = listOfTabsInSettings.map((item) => ({
  tab: item.tab, Icon: item.ComponentIcon, permission: item.permission, title: item.title, url: item.url
}));
