import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useAppSelector } from '../../../../../store/store';
import { connectionItemContext } from '../../utils/connections';
import { WhatsAppConnection } from '../../../../../api/CompanyAPI';
import { ConnectionStatusType } from '../../ConnectionsTypes';
import Button from '../../../../../components/Button/Button';
import ImageQRModal from '../../ConnectionsViews/WhatsApp/ImageQRModal';
import './ConnectionStatusQr.scss';


const ConnectionStatusQr = () => {
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const connectionStatuses = useAppSelector((state) => state.connections.whatsappStatus);
  const { connection: connectionItem } = useContext(connectionItemContext);
  const connection = connectionItem as WhatsAppConnection;
  const [status, setStatus] = useState<ConnectionStatusType>();
  const [isShownQrCode, setIsShownQrCode] = useState<boolean>(false);

    useEffect(() => {
    if (connectionStatuses) {
      setStatus(connectionStatuses[connection.id]);
    }
  }, [connectionStatuses]);

   const onTriggerQrCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (currentCompanyId?.params.companyId) {
      e.stopPropagation();
      setIsShownQrCode(!isShownQrCode);
    }
  };

  return (
    <div className="connectionStatusQr">
      {status?.status === 'qr' && (
        <>
          <Button type='button' textType='small' color='white'
            text={t('connections.whatsapp.main-view.show_qrcode')}
            disabled={isShownQrCode} onClick={onTriggerQrCode} />
          {isShownQrCode && (
            <ImageQRModal
              isOpen={isShownQrCode}
              imageSrc={status.qrCode || ''}
              setIsOpen={setIsShownQrCode}
              qrUrl={connection.params?.qrUrl || ''}
              connection={connection}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ConnectionStatusQr;
