import React from 'react';
import UserSubscription from '../../models/UserSubscription';
import SubscriptionCardTyped from '../SubscriptionCard/SubscriptionCard';


type Props = {
  subscriptions: UserSubscription[];
};

const SubscriptionsList = ({ subscriptions }: Props) => (
  <div className="subscriptions__list">
    {subscriptions && subscriptions.map((item) => (
      <SubscriptionCardTyped subscription={item} key={item.id} />
    ))}
  </div>
);

export default SubscriptionsList;
