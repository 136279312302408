import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTruncateString } from '../../../../utils/utils';
import useDropdown from '../../../../components/PopupWrapper/usePopupWrapper';
import PopupWrapper from '../../../../components/PopupWrapper/PopupWrapper';
import Button from "../../../../components/Button/Button";
import { useMobile } from "../../../../components/utils/hooks";


type Props = {
  attachmentFile: File;
  captionFromTextarea: string;
  onConfirmSendMessage: (caption: string) => void;
  inputFileRef: React.RefObject<HTMLInputElement>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  setAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

const AttachmentPreview: React.FC<Props> = ({
  attachmentFile,
  captionFromTextarea,
  onConfirmSendMessage,
  inputFileRef,
  setText,
  setAttachedFile,
}) => {
  const { t } = useTranslation();
  const { isVisible, setIsVisible } = useDropdown();
  const [caption, setCaption] = useState(captionFromTextarea);
  const [fileUrl, setFileUrl] = useState<string>();
  const mediaPreviewRef = useRef<HTMLVideoElement>(null);
  const isMobile = useMobile();

  useEffect(() => {
    setFileUrl(URL.createObjectURL(attachmentFile));
    setIsVisible(true);
  }, [attachmentFile]);

  useEffect(() => {
    setCaption(captionFromTextarea);
  }, [captionFromTextarea]);

  const messagesPreviewTypes = useMemo(() => {
    const fileType = attachmentFile.type.split('/')[0];

    switch (fileType) {
      case 'audio':
        return (
          <audio src={fileUrl} controls ref={mediaPreviewRef}>
            <track src="#" kind="captions" />
          </audio>
        );
      case 'video':
        return (
          <video src={fileUrl} controls ref={mediaPreviewRef}>
            <track src="#" kind="captions" />
          </video>
        );
      case 'image':
        return <img src={fileUrl} alt="file preview" />;
      default:
        return <p>{getTruncateString(attachmentFile.name,40)}</p>;
    }
  }, [attachmentFile, fileUrl]);

  const onChangeCaption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCaption(e.currentTarget.value);
  };

  const onClosePopupWrapper = () => {
    if (inputFileRef.current) {
      setIsVisible(false);
      inputFileRef.current.value = '';
      if (mediaPreviewRef.current) {
        mediaPreviewRef.current.pause();
      }
    }
  };

  const onSendAttachment = () => {
    onClosePopupWrapper();
    setCaption('');
    setAttachedFile(undefined);
    onConfirmSendMessage(caption);
    setText('');
  };

  return (
    <PopupWrapper
      direction="top"
      title={t('chats.preview.title')}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      onClosePopupWrapper={onClosePopupWrapper}
    >
      <div className="preview">
        <div className="preview__fileContainer">{fileUrl && messagesPreviewTypes}</div>
        <div className="preview__bottomBlock">
          <input
            placeholder={t('chats.preview.text_placeholder')}
            onChange={onChangeCaption}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                onSendAttachment();
              }
            }}
            value={caption}
          />
          <Button text={t('send')} color='orange' textType={isMobile ? 'regular' : 'small'} onClick={onSendAttachment} />
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AttachmentPreview;
