import React from 'react';
import './Comment.scss';
import CustomAudioPlayer from "../MessageTypes/CustomAudioPlayer";
import { useTranslation } from 'react-i18next';


type Props = {
  type?: string;
  text: string;
}

const Comment = ({ type = 'text', text }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="comment">
      <p className='smallText bold'>{t('chats.order.comment')}</p>
      {type === 'audio' ?
        <div className='audioPlayer'>
          <CustomAudioPlayer url={text} />
        </div> : <p className='smallText'>{text}</p>}
    </div>
  );
};

export default Comment;
