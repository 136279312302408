import './ModulBankCreate.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../../store/store';
import { createConnection } from '../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { ModulbankPaymentMethods, RuTaxation } from '../../../ConnectionsTypes';
import { PaymentTypes } from '../../../../../../api/CompanyAPI';
import { settingConnections } from '../../../utils/connections';
import { 
  DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME, getMethodPaymentModulbank, switchItems, TaxationListType, taxationTypes
} from '../../../utils/terminals';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import Button from '../../../../../../components/Button/Button';
import Dropdown from '../../../../Dropdown/Dropdown';
import Input from '../../../../../../components/Input/Input';
import Switch from '../../../../Switch/Switch';
import GoBackIcon from '../../../../../../assets/template-backward-icon.svg';
import LifetimePayment from "../../../components/LifetimePayment";


type ModulBankSchema = {
  name: string;
  params: {
    merchant_id: string;
    merchant_password: string;
    is_testing: boolean;
    taxation: RuTaxation;
    payment_methods: PaymentTypes;
    invoice_lifetime_minutes: number;
  }
};

const PaymentMethodTypes: TaxationListType = [
  { id: 'card_sbp', option: 'Все доступные ' },
  { id: 'card', option: 'Оплата только картой' },
  { id: 'sbp', option: 'СБП (только QR-код) ' },
];

const ModulBankCreate = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      merchant_id: yup.string().required(t('error.form.empty_field'))
        .max(128, t('error.form.max_length', { limit: 128 })),
      merchant_password: yup.string().required(t('error.form.empty_field'))
        .max(128, t('error.form.max_length', { limit: 128 })),
      is_testing: yup.boolean().required().default(false),
      taxation: yup.string().default('osn'),
      invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<ModulBankSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      params: {
        is_testing: false,
        taxation: 'osn',
        payment_methods: 'card_sbp',
        invoice_lifetime_minutes: DEFAULT_LIFETIME,
      },
    },
  });

  const gotoBack = () => goBack();

  const onSubmit = (data: ModulBankSchema) => {
    dispatch(createConnection({
      companyId: currentCompany.id,
      name: data.name,
      type: 'modulbank',
      params: {
        ...data.params,
        payment_methods: getMethodPaymentModulbank(data.params.payment_methods) as ModulbankPaymentMethods[]
      }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        gotoBack();
      }
    });
  };

  return (
    <div className="modulBankCreate">
      <div className="modulBankCreate__header">
        <Button color='white' textType='regular' text={t('back')} onClick={gotoBack} image={GoBackIcon} />
        <h3>{t(`${settingConnections.modulbank?.name}`)}</h3>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input name="name" label={t('connections.connection_name')} />
          <Input name="params.merchant_id" label="Merchant ID" />
          <Input name="params.merchant_password" label="Secret" inputType="password" />
          <Dropdown
            items={taxationTypes}
            label="Система налогообложения"
            name="params.taxation"
            labelKey="option"
            valueKey="id"
          />
          <Dropdown id="payment"
            items={PaymentMethodTypes}
            label="Способ оплаты по умолчанию"
            name="params.payment_methods"
            labelKey="option"
            valueKey="id"
          />
          <Switch name="params.is_testing" items={switchItems} />
          <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={DEFAULT_LIFETIME} />
          <Button type='submit' color='orange' textType='regular' text={t('connections.modulbank.add_button')}
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
};

export default ModulBankCreate;
