import React, {useEffect, useState} from 'react';
import './Onboarding.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import OnboardingPopup from './OnboardingPopup/OnboardingPopup';
import { currentStepSelector } from './onboardingSelectors';
import {
  currentCompanySelector,
  getCurrentCompanyRoleSelector,
  profileSettingsInfo
} from '../../views/Chat/companiesSelector';
import { updateFirstLogin } from '../../views/Chat/companiesSlice';
import { startOnboarding } from './onboardingSlice';

const Onboarding = () => {
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const currentRole = useSelector(getCurrentCompanyRoleSelector);
  const profileSettings = useSelector(profileSettingsInfo);
  const currentStep = useSelector(currentStepSelector);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const isAdministrator = currentRole && currentRole.name === 'ADMINISTRATOR';

  useEffect(() => {
    if(currentCompany && profileSettings && profileSettings.userId === currentCompany.ownerId){
      setIsOwner(true);
    } else setIsOwner(false);
    if (currentCompany && profileSettings.firstLogin && currentCompany.id && (isOwner || isAdministrator) ) {
      dispatch(push(`/companies/${currentCompany.id}/chats`));
    }
  }, [profileSettings, currentCompany]);

  useEffect(() => {
    if ( profileSettings && profileSettings.firstLogin) {
      dispatch(startOnboarding())
      dispatch(updateFirstLogin());
    }
  }, [profileSettings]);

  if (!currentStep || (!isOwner && !isAdministrator)) return null;

  return (
    <div className="onboarding">
      <OnboardingPopup />
    </div>
  );
};

export default Onboarding;
