import { useEffect } from 'react';
import './ContactCard.scss';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { currentContactSelector, selectContactNotesSelector } from '../../contactsSlice/contactsSelector';
import {
  clearContacts, fetchAllTags, fetchContact, fetchContactNotes, setSelectContactNotes
} from '../../contactsSlice/contactsSlice';
import { allConnectionsForChatSelector } from '../../../Chat/companiesSelector';
import { ChatItemContact } from '../../components/ChatItem/ChatItemContact';
import { useAppDispatch } from '../../../../store/store';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../components/Button/Button';
import InvoiceList from "./components/InvoiceList";
import Avatar from "../../../../components/Avatar";
import AddNote from '../AddNote';
import AddTags from '../AddTags';
import Notes from '../Notes';
import TagsList from '../TagsList';
import { ReactComponent as SmallEmail } from '../../../../assets/email.svg';
import { ReactComponent as EditIcon } from '../../../../assets/editIconButton.svg';
import { ReactComponent as SmallPhone } from '../../../../assets/smart.svg';
import loader from '../../../../assets/grid.svg';


type RouteMatchCompanyIdContactIdType = {
  companyId: string;
  contactId: string;
}

const ContactCard = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentContact = useSelector(currentContactSelector);
  const selectContactNotes = useSelector(selectContactNotesSelector);
  const url = useRouteMatch<RouteMatchCompanyIdContactIdType>('/companies/:companyId/contacts/:contactId');
  const connections = useSelector(allConnectionsForChatSelector);

  useEffect(() => {
    if (url?.params.companyId && url.params.contactId) {
      dispatch(fetchContact({
        contactId: Number(url.params.contactId),
        companyId: Number(url?.params.companyId),
      }));
      dispatch(fetchContactNotes({
        contactId: Number(url.params.contactId),
        companyId: Number(url?.params.companyId),
      }));
      dispatch(fetchAllTags({ companyId: Number(url?.params.companyId) }));
    }
    return () => { dispatch(setSelectContactNotes()) }
  }, [url?.params.companyId, url?.params.contactId]);

  useEffect(() => () => {
    dispatch(clearContacts());
  }, []);

  const handlerEditContactCard = () => {
    if (url?.params.companyId && url?.params.contactId) {
      dispatch(push(`/companies/${url?.params.companyId}/contacts/${url?.params.contactId}/edit`))
    }
  }

  if (!currentContact || !selectContactNotes ) {
    return (
      <div className="loaderContainer">
        <img src={loader} alt=""/>
      </div>
    );
  }

  return (
    <div className="contactCard__wrapper">
      <div className="contactCard__photoContainer">
        <div className="contactCard__photo">
          <Avatar image={currentContact.avatarUrl} name={currentContact.name} />
        </div>
        <WrapperPermission permission='contactsEdit'>
          <Button type='button' color='white' textType='small' text={t('edit')} image={<EditIcon />}
            onClick={handlerEditContactCard} data-testid='edit-contact' />
        </WrapperPermission>
      </div>
      <div className="contactCard">
        <div className="contactCard__name">
          <h1>{currentContact.name}</h1>
        </div>
        <div className="contactCard__wrap">
          {currentContact.phones.length > 0 && (
            <div className="contactCard__phone">
              <div className="contactCard__phone__icon">
                <SmallPhone/>
              </div>
              <div className="contactCard__phone__numbers">
                {currentContact.phones.map((p) => (
                    <p key={p.id}>{p.value}</p>
                  ))}
              </div>
            </div>
          )}
          {currentContact.emails.length > 0 && (
            <div className="contactCard__emails">
              <div className="contactCard__emails__icon">
                <SmallEmail/>
              </div>
              <div className="contactCard__emails__list">
                {currentContact.emails.map((c) => (
                    <p key={c.id}>{c.value}</p>
                  ))}
              </div>
            </div>
          )}
          <div className="contactCard__tags">
            <h4>{t('contacts.contactsList.tags')}</h4>
            <TagsList />
            <AddTags currentContact={currentContact} contactId={url?.params.contactId || '0'} />
          </div>
          <div className="contactCard__notes">
            <h4>{t('contacts.detailContact.note')}</h4>
            <Notes />
            <div className="contactCard__notes__wrapperAddNote">
              <WrapperPermission permission='contactsEdit'>
                <AddNote contactId={url?.params.contactId || '0'} />
              </WrapperPermission>
            </div>
          </div>
          <div className="contactCard__listChats">
            {currentContact.chats.length > 0 && (
              <>
                <h4>{t('contacts.detailContact.chatList')}</h4>
                <div className="contactCard__listChats__list">
                  {currentContact.chats.map((item) => (
                    <ChatItemContact
                      connections={connections}
                      contactId={currentContact.id}
                      companyId={Number(url?.params.companyId)}
                      key={item.chatId} item={item} />
                  ))}
                </div>
              </>
            )}
          </div>
          <WrapperPermission permission='banksInvoiceView'>
            <InvoiceList />
          </WrapperPermission>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
