import { useContext } from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../../../../store/store';
import { setAlertWithClosing } from '../../../../../components/Alert/alertSlice';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { ConnectionsType } from '../../ConnectionsTypes';
import { useIsConnectMaxLimit } from "../../../../../utils";
import { settingConnections, connectionsUploadContext } from '../../utils/connections';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../components/Button/Button';
import ConnectionList from '../ConnectionList';
import { ReactComponent as PlusIcon } from '../../../../../assets/plus-white.svg';
import './ConnectionView.scss';


const ConnectionView = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const { service } = useContext(connectionsUploadContext);
  const currentCompany = useSelector(currentCompanySelector);
  const connections = useAppSelector((state) => state.connections.connectionsStore[service]);
  const isMaxLimit = useIsConnectMaxLimit(service);
  const isViewTariff = service === 'waba' &&
    currentCompany.paymentCurrency === 'RUB' && !currentCompany.waba360DialogPartnerId;

  const createConnection = () => {
    if (!isMaxLimit()) {
      if (isViewTariff) {
        dispatch(push(`${url}/tariff`));
      } else dispatch(push(`${url}/create`));
    }
  };

  const onDelete = () => {
    dispatch(setAlertWithClosing({ color: '#F54242', payload: {
      title: t(`connections.${service}.delete_confirm`),
      text: t(`connections.${service}.delete_text`)
    } }));
  };

  if (service) {
    return (
      <div className="connectionView">
        <div className="connectionView__header">
          <h1>{t(`${settingConnections[service as ConnectionsType]?.name}`)}</h1>
          <WrapperPermission permission='connectionsCreate'>
          <Button type='button' color='orange' image={<PlusIcon />} textType='regular'
            text={t(`connections.${service}.create`)} onClick={createConnection} />
          </WrapperPermission>
        </div>
        {connections &&
          <>
            <h3>{t(`connections.${service}.subtitle`)}</h3>
            <ConnectionList connections={connections} onDelete={onDelete} />
          </>
        }
      </div>
    );
  }
  return null;
};

export default ConnectionView;
