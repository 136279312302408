import { useEffect, useState } from 'react';
import './Templates.scss';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../Chat/companiesSelector';
import { fetchCustomTemplates } from '../Chat/templatesSlice';
import { CustomTemplate } from '../../api/types';
import CustomTemplates from './Components/CustomTemplates/CustomTemplates';
import CustomTemplateSettings from './Components/CustomTemplateSettings/CustomTemplateSettings';
import InteractiveMessages from './Components/InteractiveMessages/InteractiveMessages';
import InteractiveMessageSettings from './Components/InteractiveMessageSettings/InteractiveMessageSettings';
import TemplatesList from './Components/TemplatesList/TemplatesList';


const TemplatesRoute = () => {
  const [templateEdit, setTemplateEdit] = useState<CustomTemplate>();
  const params = useRouteMatch();
  const dispatch = useDispatch();
  const currentCompanyId = useSelector(currentCompanySelector);
  
  useEffect(() => {
    if (currentCompanyId.id) {
      dispatch(fetchCustomTemplates({ companyId: currentCompanyId.id }));
    }
  }, [currentCompanyId]);

  return (
    <>
      <TemplatesList />
      <div className="templatesContent">
        <Switch>
          <Route path={params.url} exact>
            <CustomTemplates setTemplateEdit={setTemplateEdit} />
          </Route>
          <Route path={`${params.path}/add`} exact>
            <CustomTemplateSettings />
          </Route>
          <Route path={`${params.path}/edit`} exact>
            <CustomTemplateSettings editTemplateData={templateEdit} />
          </Route>
          <Route path={`${params.path}/interactive-messages`} exact>
            <InteractiveMessages setTemplateEdit={setTemplateEdit} />
          </Route>
          <Route path={`${params.path}/interactive-messages/add`} exact>
            <InteractiveMessageSettings />
          </Route>
          <Route path={`${params.path}/interactive-messages/edit`} exact>
            <InteractiveMessageSettings editTemplateData={templateEdit} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default TemplatesRoute;
