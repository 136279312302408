import { ChatType, MessagePOSTRequest } from '../../api/types';


export const messageRequestBodyFactory = (
  messageType: string,
  caption: string,
  templateName: string | undefined,
  currentChat: ChatType,
  url: string,
  companyId:number
): MessagePOSTRequest => {
  const baseMessageType = {
    connection_id: currentChat.connectionId,
    chat_id: currentChat.chatId,
    message_type: messageType,
  };

  if (messageType === 'text') {
    return {
      ...baseMessageType,
      companyId,
      text: {
        text: caption,
      },
    };
  }

  return {
    ...baseMessageType,
    companyId,
    [messageType]: {
      caption,
      url,
    },
  } as MessagePOSTRequest;
};
