import React from 'react';
import './RadioButton.scss';
import { useFormContext } from 'react-hook-form';


type Props = {
  label: string;
  value: number | string;
  name: string;
  defaultChecked?: boolean;
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLInputElement>) => void);
};

function RadioControl({ label, value, name, defaultChecked = false, onClick }: Props) {
  const { register } = useFormContext();

  return (
    <div className='radioButton'>
      {label}
      <input {...register(name)} type="radio" defaultChecked={defaultChecked}
        name={name} value={value} onClick={onClick} />
      <span className="checkmark" />
    </div>
  );
}

export default RadioControl;
