import React, { useEffect, useState } from 'react';
import './TemplateView.scss';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { WabaTemplate } from '../../../../../api/types';
import GoBackIcon from '../../../../../assets/template-backward-icon.svg';
import Button from '../../../../../components/Button/Button';
import WabaTemplateBase from '../../../../Chat/components/WabaTemplateBase/WabaTemplateBase';
import {
  IHeaderTemplate,
  InputCollection,
  TextConvertHeaderType,
  TextConvertHeaderWithUrlType,
} from '../../../BroadcastsTypes';
import { TextMessageType } from '../../../utils';
import { uploadFile } from '../../../../../api/ChatAPI';
import { useBroadcastItem } from '../../../api';
import { ErrorStateType } from "../../../api/types";


type TemplateViewProps = {
  selectTemplate: WabaTemplate;
  setSelectTemplate: React.Dispatch<React.SetStateAction<WabaTemplate | undefined>>;
  setMessageText: React.Dispatch<React.SetStateAction<TextMessageType | undefined>>;
  isEdit: boolean | undefined;
  errorState?: ErrorStateType;
};

type InputHeader = Array<IHeaderTemplate> | Array<TextConvertHeaderType>;

const TemplateView = ({ selectTemplate, setSelectTemplate, setMessageText, isEdit, errorState }: TemplateViewProps) => {
  const { t } = useTranslation();
  const currentCompany = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [inputCollection, setInputCollection] = useState<InputCollection>([]);
  const [inputCollectionHeader, setInputCollectionHeader] = useState<InputCollection>([]);
  const [attachedFiles, setAttachedFiles] = useState<FileList>(([] as unknown) as FileList);
  const broadcastItem = useBroadcastItem();
  const [inputCollectionEdit, setInputCollectionEdit] = useState<InputCollection>([]);
  const [inputCollectionHeaderEdit, setInputCollectionHeaderEdit] = useState<InputHeader>([]);

  const isError = errorState?.template && inputCollection.filter((text) => text.length > 0).length
    !== selectTemplate.components.body.paramsCount;

  const setMessage = (header: Array<TextConvertHeaderWithUrlType> | Array<TextConvertHeaderType>) => {
    setMessageText({
      type: 'waba_template', text: '',
      wabaTemplate: {
        template_locale: selectTemplate.locale,
        template_name: selectTemplate.name,
        header_params: header,
        body_params: inputCollection,
        params_count: selectTemplate.components.body.paramsCount
      },
    });
  };

  useEffect(() => {
    if (attachedFiles[0]) {
      const textConvertHeaderWithUrl: Array<TextConvertHeaderWithUrlType> = [];
      uploadFile({ file: attachedFiles[0], companyId: Number(currentCompany?.params.companyId) }).then(({ url }) => {
        if (inputCollectionHeader.length > 0) {
          inputCollectionHeader.forEach((i) => textConvertHeaderWithUrl.push({ text: i, url }));
        } else {
          textConvertHeaderWithUrl.push({ url });
        }
      setMessage(textConvertHeaderWithUrl);
      });
    } else if (!attachedFiles[0] &&
      inputCollectionHeaderEdit.length &&
      broadcastItem?.message.wabaTemplate?.headerParams) {
      setMessage(broadcastItem.message.wabaTemplate?.headerParams);
    } else {
      const textConvertHeader = inputCollectionHeader.map((i) => ({ text: i }));
      setMessage(textConvertHeader);
    }
  }, [inputCollectionHeaderEdit, inputCollection, inputCollectionHeader, attachedFiles]);

  useEffect(() => {
    if (isEdit && broadcastItem?.message.wabaTemplate?.templateName === selectTemplate.name) {
      if (broadcastItem.message.wabaTemplate.bodyParams) {
        setInputCollection(broadcastItem.message.wabaTemplate.bodyParams);
        setInputCollectionEdit(broadcastItem.message.wabaTemplate.bodyParams);
      }
      if (broadcastItem.message.wabaTemplate.headerParams) {
        setInputCollectionHeaderEdit(broadcastItem.message.wabaTemplate.headerParams);
        setMessage(broadcastItem.message.wabaTemplate.headerParams);
      }
    }
  }, []);

  const onBack = () => {
    setSelectTemplate(undefined);
    setInputCollectionEdit([]);
    setInputCollectionHeaderEdit([]);
  };

  return (
    <>
      <div className={`templateView ${isError ? 'error' : ''}`}>
        <WabaTemplateBase template={selectTemplate}
          setAttachedFiles={setAttachedFiles} attachedFiles={attachedFiles}
          setInputCollection={setInputCollection} setInputCollectionHeader={setInputCollectionHeader}
          inputCollectionEdit={inputCollectionEdit} inputCollectionHeaderEdit={inputCollectionHeaderEdit}
          setInputCollectionHeaderEdit={setInputCollectionHeaderEdit} />
      </div>
      {isError && <p className="errorMessage">{t('broadcasts.error.not_filled_template')}</p>}
      <Button color="white" textType="small" text={t('broadcasts.form.other_template')}
        onClick={onBack} image={GoBackIcon} />
    </>
  );
};

export default TemplateView;
