import { createContext } from "react";
import { IntegrationsItem } from "./IntegrationTypes";
import { Areas, IntegrationService } from "./types";


export function getArea(itegrationType: string): Areas | undefined {
  // get CRM name from integration type
  if (itegrationType) {
    const types = itegrationType.split('_');
    return types[types.length - 1] as Areas;
  }
  return undefined;
}

export function getService(itegrationType: string) {
  // get service name from integration type
  if (itegrationType) {
    const types = itegrationType.split('_');
    return types.slice(0, types.length - 1).join('_');
  }
  return '';
}

export const hasFirstOptionMessaging: Partial<Record<IntegrationService, boolean>> = {
  odnoklassniki: false,
  retie_whatsapp: true,
  retie_whatsappchatapi: true,
  waba: true,
  whatsapp: true,
  telegram: true,
  telegram_bot: false
};

export const optionsListAmo: Partial<Record<IntegrationService, string[]>> = {
  retie_whatsapp: ['handleAmoDialogEvents', 'checkPhonesEnabled'],
  retie_whatsappchatapi: ['handleAmoDialogEvents', 'checkPhonesEnabled'],
  waba: ['handleAmoDialogEvents', 'checkPhonesEnabled', 'createInvoices'],
  whatsapp: ['handleAmoDialogEvents', 'checkPhonesEnabled'],
  odnoklassniki: [],
  telegram: ['handleAmoDialogEvents'],
  telegram_bot: ['createInvoices']
};

export const optionsListBitrix: Partial<Record<IntegrationService, string[]>> = {
  whatsapp: ['smsCreateChatEnabled']
};

type IntegrationContext = {
  area: Areas;
  service?: IntegrationService;
  integration: IntegrationsItem;
  setIntegration: (item: IntegrationsItem) => void;
};

export const integrationContext = createContext<IntegrationContext>({} as IntegrationContext);
