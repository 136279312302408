import React from 'react';
import './MessageWabaProduct.scss';
import { ReactComponent as Plug } from '../../../../../assets/plug-product.svg';
import { currencyFormat } from '../../../../Settings/CompanySettings/currency';
import { useTranslation } from 'react-i18next';
import { ReferredProductType } from '../../../../../api/types';
import Comment from "../../Comment";


type Props = {
  type: string;
  text: string;
  message: ReferredProductType;
};

const MessageWabaProduct = ({ type, text, message }: Props) => {
  const { i18n } = useTranslation();

  return (
    <div className="wabaProductContent">
      <div className={`wabaProductContent_image ${!message.imageUrl ? 'plug' : ''}`}>
        {message.imageUrl ? <img alt="image" src={message.imageUrl} /> : <Plug />}
      </div>
      <div className="wabaProductContent_content">
        <p className='regularText bold'>{message.name}</p>
        <p className='monospaceRegText middle'>{currencyFormat(message.price, message.currency, i18n.language)}</p>
        <p className='smallText'>{message.description}</p>
        <a href={message.url} target="_blank" rel="noreferrer">
          <p className="smallText">{message.url}</p>
        </a>
        {text && <Comment type={type} text={text} />}
      </div>
    </div>
  );
};

export default MessageWabaProduct;
