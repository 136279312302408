import React, { useEffect } from 'react';
import './Alert.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alertsSelector, alertWithClosingSelector } from './alertSelectors';
import {
  removeAlert, setAlertWithClosing, AlertType, AlertWithClosingType, AlertInner, AlertStyle
} from './alertSlice';
import AlertMessage from '../AlertMessage';
import DialogsWarn from '../../views/Broadcasts/components/DialogsWarn';
import WabaNoCatalog from '../../views/Settings/Connections/ConnectionsViews/Waba/WabaNoCatalog';
import CallSupport from '../CallSupport';
import { deleteWabaLinkError } from '../../views/Settings/Connections/connectionsSlice';


const innerComponents: Record<AlertInner, React.FunctionComponent> = {
  broadcasts_waba_dialog: DialogsWarn,
  connections_waba_catalogs: WabaNoCatalog,
  error_call_support: CallSupport
};

const Alert = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alerts = useSelector(alertsSelector);
  const alertWithCustom = useSelector(alertWithClosingSelector);

  const getMessageText = (alert: AlertType | AlertWithClosingType) => {
    if (alert.code) {
      return t(`errors.${alert.code}`);
    }
    if (alert.payload) {
      if (typeof(alert.payload) === 'string') {
        return t(alert.payload);
      }
      return t(alert.payload.text)
    }
    return '';
  };
  const getMessageTitle = (alert: AlertWithClosingType) => {
    if (typeof(alert.payload) === 'object') {
      return t(alert.payload.title);
    }
    return undefined;
  };
  useEffect(() => {
    if (alerts.length > 0) {
      setTimeout(() => {
        dispatch(removeAlert(alerts[alerts.length - 1]));
      }, 3000);
    }
  }, [alerts]);

  const getStyles = (alert: AlertType | AlertWithClosingType): AlertStyle => {
    if (alert.code) {
      return alert.code < 100000 ? 'alarm' : 'info';
    }
    if (alert.type) {
      return alert.type;
    }
    if (alert.color) {
      return alert.color === '#F54242' ? 'alarm' : 'info';
    }
    return 'info';
  };

  const onHandlerClosedAlert = () => {
    dispatch(setAlertWithClosing(null));
    dispatch(deleteWabaLinkError());
  };

  return (
    <div className="alertContainer">
      {alerts.map(alert =>
        <AlertMessage key={alert.identificator} type={getStyles(alert)} message={getMessageText(alert)} />
      )} 
      
      {alertWithCustom &&
        <AlertMessage type={getStyles(alertWithCustom)} title={getMessageTitle(alertWithCustom)}
          message={getMessageText(alertWithCustom)} close={true} onClose={onHandlerClosedAlert}>
          {alertWithCustom.inner && React.createElement(innerComponents[alertWithCustom?.inner])}
        </AlertMessage>
      }
      
    </div>
  );
};

export default Alert;
