import { ReactElement } from 'react';
import './Avatar.scss';


type Props = {
  name: string;
  image?: string | ReactElement;
};

function Avatar({ name, image }: Props) {
  
  const getImage = () => {
    if(typeof image === 'string'){
      return(
        <img alt='avatar' src={image} />
      )
    } if(typeof image === 'object'){
      return { ...image }
    }
    return <></>
  };

  return (
    <div className='avatar'>
      {image
        ? getImage()
        : <p>{name.charAt(0)}</p>}
  </div>);
}

export default Avatar;
