import React, { useState } from 'react';
import { MessageLoading } from './types';
import UploadProgressCard from '../UploadProgressCard/UploadProgressCard';
import { useFileUpload } from '../../../../components/utils/hooks';

const MessageLoadingType: MessageLoading = ({ message, handleSizeChange, setIsFileLoaded }) => {
  const [uploadStatusData, setUploadStatusData] = useState<{ loaded: number; total: number | undefined; }>({
    loaded: 0, total: 1 });

  useFileUpload(message, setUploadStatusData, setIsFileLoaded);

  return (
    <UploadProgressCard
      uploadStatusData={uploadStatusData}
      fileName={message.fileName}
      handleSizeChange={handleSizeChange}
    />
  );
};

export default MessageLoadingType;
