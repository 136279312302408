export const keysPermission = [
  'chat',
  'shop',
  'product',
  'catalog',
  'invoice',
  'contacts',
  'broadcast',
  'templates',
  'connections',
  'members',
  'subscriptions',
  'integrations',
  'tags',
  'company_settings',
  'notifications',
];

// arrange the correct order of operation(CRUD): 1.view 2.create/add 3.edit 4.delete
export const PERMISSIONS = {
  salesShopView: 'sales_shop_view',
  salesShopCreate: 'sales_shop_create',
  salesShopEdit: 'sales_shop_edit',
  salesShopDelete: 'sales_shop_delete',
  salesCatalogView: 'sales_catalog_view',
  salesCatalogCreate: 'sales_catalog_create',
  salesCatalogEdit: 'sales_catalog_edit',
  salesCatalogDelete: 'sales_catalog_delete',
  salesProductView: 'sales_product_view',
  salesProductCreate: 'sales_product_create',
  salesProductEdit: 'sales_product_edit',
  salesProductDelete: 'sales_product_delete',
  messagingChatView: 'messaging_chat_view',
  messagingChatCreate: 'messaging_chat_create',
  contactsView: 'contacts_view',
  contactsCreate: 'contacts_create',
  contactsEdit: 'contacts_edit',
  templatesView: 'templates_view',
  templatesCreate: 'templates_create',
  templatesEdit: 'templates_edit',
  templatesDelete: 'templates_delete',
  connectionsView: 'connections_view',
  connectionsCreate: 'connections_create',
  connectionsEdit: 'connections_edit',
  integrationsView: 'integrations_view',
  integrationsCreate: 'integrations_create',
  integrationsEdit: 'integrations_edit',
  integrationsDelete: 'integrations_delete',
  membersView: 'members_view',
  membersAdd: 'members_add',
  membersEdit: 'members_edit',
  membersDelete: 'members_delete',
  subscriptionsView: 'subscriptions_view',
  subscriptionsEdit: 'subscriptions_edit',
  tagsView: 'tags_view',
  tagsCreate: 'tags_create',
  tagsEdit: 'tags_edit',
  notificationsView: 'notifications_view',
  notificationsCreate: 'notifications_create',
  notificationsEdit: 'notifications_edit',
  notificationsDelete: 'notifications_delete',
  banksInvoiceView: 'banks_invoice_view',
  banksInvoiceCreate: 'banks_invoice_create',
  companySettingsView:'company_settings_view',
  companySettingsEdit:'company_settings_edit',
  broadcastsView: 'broadcasts_view',
  broadcastsCreate: 'broadcasts_create',
  broadcastsEdit: 'broadcasts_edit',
  broadcastsDelete: 'broadcasts_delete',
};

export type PermissionsType = keyof typeof PERMISSIONS;

type ValidationsPermissionsValueType = {
  necessary:Array<string>,
  uncheckWhenDisable?:Array<string>
}
type ValidationsPermissionsType = Record<string, ValidationsPermissionsValueType>

export const VALIDATIONS_PERMISSIONS: ValidationsPermissionsType = {
  [PERMISSIONS.messagingChatView]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.contactsView,
      PERMISSIONS.tagsView,
      PERMISSIONS.templatesView
    ],
    uncheckWhenDisable:[PERMISSIONS.messagingChatCreate,PERMISSIONS.banksInvoiceCreate],
  },
  [PERMISSIONS.messagingChatCreate]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView,
      PERMISSIONS.tagsView,
      PERMISSIONS.templatesView,
    ],
    uncheckWhenDisable:[PERMISSIONS.messagingChatView,PERMISSIONS.banksInvoiceCreate],
  },
  [PERMISSIONS.salesShopView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[
      PERMISSIONS.salesShopCreate,
      PERMISSIONS.salesShopEdit,
      PERMISSIONS.salesShopDelete,
    ]
  },
  [PERMISSIONS.salesShopEdit]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.salesShopView],
  },
  [PERMISSIONS.salesShopCreate]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.salesShopView],
  },
  [PERMISSIONS.salesShopDelete]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.salesShopView],
  },
  [PERMISSIONS.salesCatalogView]:{
    necessary: [PERMISSIONS.membersView, PERMISSIONS.salesProductView],
    uncheckWhenDisable:[
      PERMISSIONS.salesCatalogCreate,
      PERMISSIONS.salesCatalogEdit,
      PERMISSIONS.salesCatalogDelete,
    ]
  },
  [PERMISSIONS.salesCatalogCreate]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesShopView,
      PERMISSIONS.salesProductView
    ]
  },
  [PERMISSIONS.salesCatalogDelete]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesProductView,
    ],
  },
  [PERMISSIONS.salesCatalogEdit]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesShopView,
      PERMISSIONS.salesProductView
    ],
  },
  [PERMISSIONS.salesProductView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[
      PERMISSIONS.salesProductCreate,
      PERMISSIONS.salesProductEdit,
      PERMISSIONS.salesProductDelete,
    ]
  },
  [PERMISSIONS.salesProductEdit]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.salesProductView,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesCatalogEdit,
      PERMISSIONS.salesShopView
    ],
  },
  [PERMISSIONS.salesProductCreate]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.salesShopView,
      PERMISSIONS.salesProductView,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesCatalogEdit,
    ],
  },
  [PERMISSIONS.banksInvoiceView]:{
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.messagingChatView,

    ],
    uncheckWhenDisable:[PERMISSIONS.banksInvoiceCreate]
  },
  [PERMISSIONS.banksInvoiceCreate]:{
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.banksInvoiceView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView,
      PERMISSIONS.tagsView,
      PERMISSIONS.templatesView
    ],
  },
  [PERMISSIONS.salesProductDelete]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.salesProductView,
      PERMISSIONS.salesCatalogEdit,
      PERMISSIONS.salesCatalogView,
      PERMISSIONS.salesShopView,
    ],
  },
  [PERMISSIONS.contactsView]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.tagsView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.templatesView
    ],
    uncheckWhenDisable:
      [
        PERMISSIONS.contactsCreate,
        PERMISSIONS.contactsEdit,
        PERMISSIONS.messagingChatView,
        PERMISSIONS.messagingChatCreate,
        PERMISSIONS.banksInvoiceView,
        PERMISSIONS.banksInvoiceCreate,
        PERMISSIONS.tagsView,
        PERMISSIONS.tagsCreate,
        PERMISSIONS.tagsEdit,
        PERMISSIONS.salesProductView,
        PERMISSIONS.salesProductCreate,
        PERMISSIONS.salesProductEdit,
        PERMISSIONS.salesProductDelete,
        PERMISSIONS.salesCatalogView,
        PERMISSIONS.salesCatalogCreate,
        PERMISSIONS.salesCatalogEdit,
        PERMISSIONS.salesCatalogDelete,
        PERMISSIONS.salesShopView,
        PERMISSIONS.salesShopCreate,
        PERMISSIONS.salesShopEdit,
        PERMISSIONS.salesShopDelete,
        PERMISSIONS.templatesView,
        PERMISSIONS.templatesCreate,
        PERMISSIONS.templatesEdit,
        PERMISSIONS.templatesDelete,
        PERMISSIONS.broadcastsView,
        PERMISSIONS.broadcastsCreate,
        PERMISSIONS.broadcastsEdit,
        PERMISSIONS.broadcastsDelete,
      ]
  },
  [PERMISSIONS.contactsEdit]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.contactsView,
      PERMISSIONS.tagsView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.templatesView,
    ],
  },
  [PERMISSIONS.contactsCreate]:{
    necessary: [
      PERMISSIONS.membersView,
      PERMISSIONS.contactsView,
      PERMISSIONS.tagsView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.templatesView
    ],
  },
  [PERMISSIONS.templatesView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[
      PERMISSIONS.templatesCreate,
      PERMISSIONS.templatesEdit,
      PERMISSIONS.templatesDelete,
    ]
  },
  [PERMISSIONS.templatesEdit]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.templatesView]
  },
  [PERMISSIONS.templatesCreate]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.templatesView]
  },
  [PERMISSIONS.templatesDelete]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.templatesView]
  },
  [PERMISSIONS.connectionsView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[PERMISSIONS.connectionsCreate, PERMISSIONS.connectionsEdit]
  },
  [PERMISSIONS.connectionsEdit]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.connectionsView],
  },
  [PERMISSIONS.connectionsCreate]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.connectionsView]
  },
  [PERMISSIONS.integrationsView]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.integrationsView,
      PERMISSIONS.connectionsView
    ],
    uncheckWhenDisable:[
      PERMISSIONS.integrationsCreate,
      PERMISSIONS.integrationsEdit,
      PERMISSIONS.integrationsDelete,
    ]
  },
  [PERMISSIONS.integrationsEdit]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.integrationsView,
      PERMISSIONS.connectionsView
    ]
  },
  [PERMISSIONS.integrationsCreate]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.integrationsView,
      PERMISSIONS.connectionsView,
    ]
  },
  [PERMISSIONS.integrationsDelete]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.integrationsView,
      PERMISSIONS.connectionsView
    ]
  },
  [PERMISSIONS.membersView]: {
    necessary:[PERMISSIONS.membersView],
  },
  [PERMISSIONS.membersAdd]: {
    necessary:[PERMISSIONS.membersView]
  },
  [PERMISSIONS.membersEdit]: {
    necessary:[PERMISSIONS.membersView]
  },
  [PERMISSIONS.membersDelete]: {
    necessary:[PERMISSIONS.membersView]
  },
  [PERMISSIONS.subscriptionsView]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.connectionsView],
    uncheckWhenDisable:[PERMISSIONS.subscriptionsEdit]
  },
  [PERMISSIONS.subscriptionsEdit]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.subscriptionsView, PERMISSIONS.connectionsView]
  },
  [PERMISSIONS.tagsView]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.contactsView,
      PERMISSIONS.templatesView,
      PERMISSIONS.messagingChatView
    ],
    uncheckWhenDisable:[
      PERMISSIONS.tagsCreate,
      PERMISSIONS.tagsEdit
    ]
  },
  [PERMISSIONS.tagsEdit]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.tagsView,
      PERMISSIONS.contactsView,
      PERMISSIONS.templatesView,
      PERMISSIONS.messagingChatView
    ]
  },
  [PERMISSIONS.tagsCreate]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.tagsView,
      PERMISSIONS.contactsView,
      PERMISSIONS.messagingChatView,
      PERMISSIONS.templatesView
    ]
  },
  [PERMISSIONS.notificationsView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[
      PERMISSIONS.notificationsCreate,
      PERMISSIONS.notificationsEdit,
      PERMISSIONS.notificationsDelete,
    ]
  },
  [PERMISSIONS.notificationsEdit]: {
    necessary:[
      PERMISSIONS.membersView,
      PERMISSIONS.notificationsView,
    ]
  },
  [PERMISSIONS.notificationsCreate]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.notificationsView]
  },
  [PERMISSIONS.notificationsDelete]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.notificationsView]
  },
  [PERMISSIONS.companySettingsView]: {
    necessary:[PERMISSIONS.membersView],
    uncheckWhenDisable:[ PERMISSIONS.companySettingsEdit]
  },
  [PERMISSIONS.companySettingsEdit]: {
    necessary:[PERMISSIONS.membersView, PERMISSIONS.companySettingsView]
  },
  [PERMISSIONS.broadcastsView]: {
    necessary: [PERMISSIONS.messagingChatCreate, PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView, PERMISSIONS.tagsView, PERMISSIONS.templatesView],
    uncheckWhenDisable: [
      PERMISSIONS.broadcastsCreate,
      PERMISSIONS.broadcastsDelete,
      PERMISSIONS.broadcastsEdit
    ]
  },
  [PERMISSIONS.broadcastsCreate]: {
    necessary: [PERMISSIONS.broadcastsView, PERMISSIONS.messagingChatCreate, PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView, PERMISSIONS.tagsView, PERMISSIONS.templatesView]
  },
  [PERMISSIONS.broadcastsEdit]: {
    necessary: [PERMISSIONS.broadcastsView, PERMISSIONS.messagingChatCreate, PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView, PERMISSIONS.tagsView, PERMISSIONS.templatesView]
  },
  [PERMISSIONS.broadcastsDelete]: {
    necessary: [PERMISSIONS.broadcastsView, PERMISSIONS.messagingChatCreate, PERMISSIONS.messagingChatView,
      PERMISSIONS.contactsView, PERMISSIONS.tagsView, PERMISSIONS.templatesView]
  }
};
