import React, { useEffect } from 'react';
import './OnboardingPopup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { currentStepSelector } from '../onboardingSelectors';
import { onboardingStepsConfig } from './onboardingStepsConfig';
import { ReactComponent as ArrowIcon } from '../../../assets/stepper-arrow.svg';
import { ReactComponent as TailIcon } from '../../../assets/dropdown-after-triangle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import {
  currentCompanySelector,
  profileSettingsInfo,
} from '../../../views/Chat/companiesSelector';
import { cancelOnboarding, decrementStep, incrementStep, selectStep } from '../onboardingSlice';
import { currentChatSelector } from '../../../views/Chat/chatsSelector';
import { updateFirstLogin } from '../../../views/Chat/companiesSlice';

const OnboardingPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentStep = useSelector(currentStepSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const currentChat = useSelector(currentChatSelector);
  const profileSettings = useSelector(profileSettingsInfo);
  const StepComponent = onboardingStepsConfig[currentStep].component;

  useEffect(() => {
    if (currentCompany && currentCompany.id) {
      switch (currentStep) {
        case 2:
          dispatch(push(`/companies/${currentCompany.id}/settings/members`));
          break;
        case 3:
          dispatch(push(`/companies/${currentCompany.id}/settings`));
          break;
        case 4:
          dispatch(push(`/companies/${currentCompany.id}/settings/integrations`));
          break;
        default:
          if (!currentChat.connectionId) {
            dispatch(push(`/companies/${currentCompany.id}/chats`));
          }
      }
    }
  }, [currentStep, currentCompany, currentChat]);

  const onClickNextStep = () => {
    dispatch(incrementStep());
  };

  const onClickPreviousStep = () => {
    dispatch(decrementStep());
  };

  const onSelectStep = (e: React.MouseEvent<HTMLDivElement>) => {
    const step = e.currentTarget.id;
    dispatch(selectStep(parseInt(step, 10)));
  };

  const onCloseOnboarding = () => {
    dispatch(cancelOnboarding());
    if (profileSettings && profileSettings.firstLogin) {
      dispatch(updateFirstLogin());
    }
  };

  return (
    <div className="onboardingPopup" style={onboardingStepsConfig[currentStep].styles}>
      <div className="wrapper">
        <StepComponent />
        {currentStep > 1 && (
          <div className="onboardingPopup__stepper">
            <div className="onboardingPopup__stepper_stepsWrapper">
              <div className="onboardingPopup__stepper_steps">
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 2 ? 'active' : ''}`}
                  id="2"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 3 ? 'active' : ''}`}
                  id="3"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 4 ? 'active' : ''}`}
                  id="4"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 5 ? 'active' : ''}`}
                  id="5"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 6 ? 'active' : ''}`}
                  id="6"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 7 ? 'active' : ''}`}
                  id="7"
                  onClick={onSelectStep}
                />
                <div
                  className={`onboardingPopup__stepper_steps__step ${currentStep === 8 ? 'active' : ''}`}
                  id="8"
                  onClick={onSelectStep}
                />
              </div>
              <p className="onboardingPopup__stepper_count">
                {currentStep - 1} {t('onboarding.steps_delimiter')} 7
              </p>
            </div>
            <div className="onboardingPopup__stepper_arrowGroup">
              {currentStep > 2 && (
                <button className="onboardingPopup__stepper_arrowGroup_left" onClick={onClickPreviousStep}>
                  <ArrowIcon />
                </button>
              )}
              <button
                className={`onboardingPopup__stepper_arrowGroup_right ${currentStep === 8 ? 'disabled' : ''}`}
                disabled={currentStep === 8}
                onClick={onClickNextStep}
              >
                <ArrowIcon />
              </button>
            </div>
          </div>
        )}
        <CloseIcon className="closeIcon" onClick={onCloseOnboarding} />
        <TailIcon className="tailIcon" style={onboardingStepsConfig[currentStep].tailPosition} />
      </div>
    </div>
  );
};

export default OnboardingPopup;
