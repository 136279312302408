import axiosInstance from './AxiosInstance';
import { Tag, CreateTag, UpdateTag, TagsListRequestType } from './types';

export const getTags = ({ company_id, limit, offset }: TagsListRequestType): Promise<Tag[]> =>
  axiosInstance.get(`/companies/${company_id}/tags/`, { params: { limit, offset } });

export const getTag = (tag_id: number, company_id: number): Promise<Tag> =>
  axiosInstance.get(`/companies/${company_id}/tags/${tag_id}`, {
    params: {
      tag_id,
      company_id,
    },
  });

export const createTag = (requestOption: CreateTag): Promise<Tag> =>
  axiosInstance.post(`/companies/${requestOption.company_id}/tags/`, {
    name: requestOption.name,
    description: requestOption.description,
    background_color: requestOption.backgroundColor,
  });

export const updateTag = (tag_id: number, company_id: number, requestOption: UpdateTag): Promise<Tag> =>
  axiosInstance.put(`/companies/${company_id}/tags/${tag_id}`, {
    name: requestOption.name,
    description: requestOption.description,
    background_color: requestOption.backgroundColor,
  });

export const deleteTag = (tag_id: number, company_id: number): Promise<Tag> =>
  axiosInstance.delete(`/companies/${company_id}/tags/${tag_id}`, {
    params: {
      tag_id,
      company_id,
    },
  });
