import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import { removeChatFromBlackList } from '../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { BlockedChatType } from '../../../ConnectionsTypes';
import { connectionItemContext } from '../../../utils/connections';
import Button from '../../../../../../components/Button/Button';
import './BlackListItem.scss';


type BlackListItemProps = {
  item: BlockedChatType;
};

const BlackListItem = ({ item }: BlackListItemProps ) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const { connection } = useContext(connectionItemContext);

  const removeItem = () => {
    dispatch(removeChatFromBlackList({ companyId: currentCompany.id, connectionId: connection.id, itemId: item.id }));
  };

  return (
    <div className="blackListItem">
      <p>{parsePhoneNumberFromString(item.phone)?.formatInternational()}</p>
      <Button color='white' textType='small' text={t('connections.black_list.button_unlock')} onClick={removeItem} />
    </div>
  );
};

export default BlackListItem;
