import React, { useEffect, useRef, useState } from 'react';
import { BaseMessageComponent } from './types';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';

const MessageWabaType: BaseMessageComponent = ({ displayMessage }) => {
  const textElementRef = useRef<HTMLDivElement>(null);
  const [mediaUrl, setMediaUrl] = useState<string>();

  const getMediaType = (format: string) => {
    switch (format) {
      case 'image':
        return <img src={mediaUrl} alt="message media" />;
      case 'video':
        return (
          <video src={mediaUrl} controls>
            <track src="#" kind="captions" />
          </video>
        );
      default:
        return (
          <a href={mediaUrl} target="_blank" download rel="noreferrer">
            {displayMessage.wabaTemplate?.mediaHeader!.fileName}
          </a>
        );
    }
  };

  useEffect(() => {
    if (displayMessage.wabaTemplate?.mediaHeader?.fileUrl) {
      setMediaUrl(displayMessage.wabaTemplate.mediaHeader.fileUrl);
    }
  }, [displayMessage]);

  return (
    <div ref={textElementRef} className="messageWabaTemplate">
      {displayMessage.wabaTemplate?.mediaHeader && (
        <div className="messageWabaTemplate__media">{getMediaType(displayMessage.wabaTemplate.mediaHeader.format)}</div>
      )}
      <div className="messageWabaTemplate__text">
        {displayMessage.wabaTemplate?.text && <LinkifyText text={displayMessage.wabaTemplate?.text} />}
      </div>
      <div className="messageWabaTemplate__buttons">
        {displayMessage.wabaTemplate?.buttons &&
          displayMessage.wabaTemplate.buttons.map((button) => (
            <button type="button" key={button.text}>
              {button.text}
            </button>
          ))}
      </div>
    </div>
  );
};

export default MessageWabaType;
