import React from 'react';
import './InteractiveTempalteComponent.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CustomTemplate } from '../../../../../api/types';
import { ReactComponent as BackwardIcon } from '../../../../../assets/template-backward-icon.svg';
import getInteractiveAttachmentType from './InteractiveTemplateComponentTypes';
import { useAppDispatch } from '../../../../../store/store';
import { sendInteractiveTemplate } from '../../../chatsSlice';
import Button from "../../../../../components/Button/Button";


type Props = {
  template: CustomTemplate;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>>;
  onCloseDropdown: (e: React.MouseEvent<unknown, MouseEvent>) => void;
};

const InteractiveTemplateComponent: React.FC<Props> = ({ template, setSelectedTemplate, onCloseDropdown }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const onSendInteractiveTemplate = (e: React.MouseEvent<unknown, MouseEvent>) => {
    let header:
      | {
          type: string;
          text?: string;
          url?: string;
          filename?: string;
        }
      | undefined;

    let footer: string | undefined;

    if (template.params.header) {
      if (template.params.header.filename) {
        header = {
          type: template.params.header.type,
          url: template.params.header.url,
          filename: template.params.header.filename,
        };
      } else {
        header = {
          type: template.params.header.type,
          text: template.params.header.text as string | undefined,
        };
      }
    } else {
      header = undefined;
    }

    if (template.params.footer) {
      footer = template.params.footer;
    } else {
      footer = undefined;
    }
    if (currentCompanyId?.params.companyId){
      dispatch(
        sendInteractiveTemplate({
          companyId:Number(currentCompanyId?.params.companyId),
          type: template.params.type,
          header,
          // @ts-ignore
          action: template.params.action,
          body: template.params.body,
          footer,
        })
      ).then(() => onCloseDropdown(e));
    }
  };

  return (
    <div className="interactiveTemplateComponent">
      <div className="wrapper">
        <div className="interactiveTemplateComponent__attachmentContainer">
          {template.params?.header && (
            <div className="interactiveTemplateComponent__attachmentContainer_attachment">
              {getInteractiveAttachmentType(
                template.params?.header?.type,
                template.params.header.url,
                template.params.header.filename as string
              )}
            </div>
          )}
        </div>
        <div className="interactiveTemplateComponent__textSection">
          {template.params?.header?.text && (
            <p className="interactiveTemplateComponent__textSection_title">{template.params?.header?.text}</p>
          )}
          <p className="interactiveTemplateComponent__textSection_body">{template.params.body}</p>
          {template.params.footer && (
            <p className="interactiveTemplateComponent__textSection_footer">{template.params.footer}</p>
          )}
        </div>
        <div className="interactiveTemplateComponent__actionSection">
          {template.params.action.buttons && (
            <div className="interactiveTemplateComponent__actionSection_buttonSection">
              {template.params.action.buttons.map((button) => (
                <div
                  className="interactiveTemplateComponent__actionSection_buttonSection__button"
                  key={button.reply.id}
                >
                  {button.reply.title}
                </div>
              ))}
            </div>
          )}
          {template.params.action.sections?.sections &&
            template.params.action.sections?.sections.map((section) => (
              <div className="interactiveTemplateComponent__actionSection_listSection" key={section.rows.length}>
                <p className="interactiveTemplateComponent__actionSection_listSection__title">
                  {template.params.action.sections?.button}
                </p>
                {section.rows.map((row) => (
                  <div className="interactiveTemplateComponent__actionSection_listSection__row" key={row.id}>
                    <p className="interactiveTemplateComponent__actionSection_listSection__row_title">{row.title}</p>
                    <p className="interactiveTemplateComponent__actionSection_listSection__row_description">
                      {row.description}
                    </p>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
      <div className="interactiveTemplateComponent__buttonsContainer">
        <Button text={t('back')} image={<BackwardIcon />} color='white' textType='small' onClick={() => setSelectedTemplate(undefined)} />
        <Button text={t('send')} color='orange' textType='small' onClick={onSendInteractiveTemplate} />
      </div>
    </div>
  );
};

export default InteractiveTemplateComponent;
