import { TFunction } from 'i18next';
import { getDateTime } from './getDateTime/getDateTime';


export const getCustomMonth = (date:string, t: TFunction) => {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => t(`chats.month.month${i}`));
  const month = Number(getDateTime().moment(date).format('MM')) - 1;
  return {
    months,
    month,
  };
}
