import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Pagination, Stack } from '@material-ui/core';
import { ReactComponent as HideIcon } from '../../assets/arrow-up.svg';
import { ReactComponent as ShowIcon } from '../../assets/arrow-down.svg';
import './Paginator.scss';
import { ITEMS_ONE_PAGE, usePageLimit } from "./utils";


const useStyles = makeStyles({
  ul: {
    '& .MuiPaginationItem-text': {
      fontFamily: 'Inter, sans-serif',
      color: '#333333',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      fontFeatureSettings: "'tnum' on, 'lnum' on, 'ordn' on, 'cv01' on, 'cv02' on, 'cv05' on, 'cv09' on, 'case' on, 'cpsp' on",
    },
    '& .Mui-selected': {
      color: '#fff',
      background: '#FE6D04 !important',
      boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.15)',
    },
  },
});

type PaginatorProps = {
  totalCount: number;
  pageLimitStoreIndex: string;
  pageSelect: (page: number, limit: number) => void;
};

const Paginator = ({ totalCount, pageSelect, pageLimitStoreIndex }: PaginatorProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { limit, setLimit } = usePageLimit(pageLimitStoreIndex);
  const [openListVolume, setOpenListVolume] = useState<boolean>(false);
  const pagesCount = Math.ceil( totalCount / limit);

  const onChangePage = (page: number) => {
    if (page) {
      setCurrentPage(page);
      pageSelect(page, limit);
    }
  };

  const onIconClick = () => {
    setOpenListVolume(!openListVolume);
  };

  const onPageVolume = (pageVolume: number) => {
    setCurrentPage(1);
    pageSelect(1, pageVolume);
    setLimit(pageVolume)
    onIconClick();
  };

  if (!totalCount || totalCount <= ITEMS_ONE_PAGE[0]) return null;

  return (
    <div className="paginator">
      <div>
      {pagesCount > 1 ? (
        <Stack spacing={2}>
        <Pagination
          shape="rounded"
          className={classes.ul}
          page={currentPage}
          onChange={(event, page) => onChangePage(page)}
          count={pagesCount}
          size="large"
        />
      </Stack>) : null }
      </div>
      <div className="paginator__settings">
        <div className="paginator__settings_text">{t('contacts.contactsList.showOnPageBy')}</div>
        {openListVolume && (
          <div className="paginator__settings_list">
          {ITEMS_ONE_PAGE.map((item) => (
            <div className="paginator__settings_item" key={item} onClick={() => onPageVolume(item)}>
              {item}
            </div>
          ))}
          </div>
        )}
        <div className="paginator__settings_field" onClick={onIconClick}>
          <div>{limit}</div>
          <div>{openListVolume ? <HideIcon /> : <ShowIcon />}</div>
        </div>
      </div>
    </div>
  );
};

export default Paginator;
