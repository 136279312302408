import React, { useState } from 'react';
import './CardButtons.scss';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { removeCustomTemplate } from '../../../Chat/templatesSlice';
import { CustomTemplate } from '../../../../api/types';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import ActionDialog from '../../../../components/Modals/ActionDialog';
import { ReactComponent as PenIcon } from '../../../../assets/pen-icon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/trash-icon.svg';


type Props = {
  template: CustomTemplate;
  setTemplateEdit: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>> | undefined;
};

function CardButtons({ template, setTemplateEdit }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const onClickEditButton = () => {
    if (setTemplateEdit) {
      dispatch(push(`${url}/edit`));
      setTemplateEdit(template);
    }
  };

  const onHandlerDelete = () => setDeleteOpen(true);

  const deleteCatalog = () => {
    if (currentCompanyId?.params.companyId) {
      dispatch(removeCustomTemplate({
        companyId: Number(currentCompanyId?.params.companyId),
        templateId: template.id,
      }));
    }
  };

  const getDeleteConfimMessage = () => t('templates.delete_confirm.warning');

  return (
    <div className='cardButtons'>
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')} background='light'
          title={t('templates.delete_confirm.head')} message={getDeleteConfimMessage()}
          onAction={deleteCatalog} setIsOpen={setDeleteOpen} />}
      <WrapperPermission permission='templatesEdit'>
        <button className='cardButtons_editButton' onClick={onClickEditButton}>
          <PenIcon />
          {t('edit')}
        </button>
      </WrapperPermission>
      <WrapperPermission permission='templatesDelete'>
        <button className='cardButtons_deleteButton' onClick={onHandlerDelete}>
          <TrashIcon />
          {t('delete')}
        </button>
      </WrapperPermission>
    </div>
  );
};

export default CardButtons;
