import React, {useEffect, useState} from 'react';
import './InvoiceList.scss';
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useAppDispatch } from "../../../../../../store/store";
import { fetchInvoices } from "../../../../../Chat/billSlice";
import { useSelector } from 'react-redux';
import { invoicesSelector } from '../../../../../Chat/billSelector';
import InvoiceItem from "../InvoiceItem";


const InvoiceList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const url = useRouteMatch<{ companyId: string; contactId: string }>('/companies/:companyId/contacts/:contactId');
  const listInvoice = useSelector(invoicesSelector);
  const [limitInvoice, setLimitInvoice] = useState(10);

  useEffect(() => {
    if (url?.params) {
      dispatch(fetchInvoices({
        companyId: Number(url.params.companyId),
        contactId: Number(url.params.contactId),
        offset: 0,
        limit: limitInvoice + 1,
      }));
    }
  }, [url?.params.companyId, url?.params.contactId, limitInvoice]);

  if(!listInvoice || listInvoice && listInvoice.length === 0){
   return <></>
  }

  return (
    <div className="invoiceList">
      <h4>{t('contacts.detailContact.history_orders')}</h4>
      <div className='invoiceList_titleList'>
        <div>{t('contacts.detailContact.date_order')}</div>
        <div>{t('orders.status_title')}</div>
        <div>{t('orders.amount_title')}</div>
        <div>{t('contacts.detailContact.employee')}</div>
      </div>
      <div className='invoiceList_list'>
        {listInvoice && listInvoice.slice(0, limitInvoice).map((invoice) =>
          <InvoiceItem key={invoice.id} invoice={invoice}/>)}
        {(listInvoice.length > listInvoice.slice(0, limitInvoice).length) && (
          <div className='invoiceList_list_button'>
            <p onClick={() => setLimitInvoice(limitInvoice + 10)}>{t('contacts.detailContact.show_more')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceList;
