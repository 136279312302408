import React from 'react';
import { useTranslation } from 'react-i18next';
import PlanList from '../PlanList';
import './BroadcastsPlan.scss';


function BroadcastsPlan() {
  const { t } = useTranslation();
  
  return (
    <div className='broadcastPlan'>
      <h5>{t('broadcasts.plan.title')}</h5>
      <table className='plan_list'>
        <thead>
          <tr className='plan_list__header'>
            <th>{t('broadcasts.params.name_date')}</th>
            <th>{t('broadcasts.params.connection')}</th>
            <th>{t('broadcasts.params.change_last')}</th>
          </tr>
        </thead>
        <tbody><PlanList /></tbody>
      </table>
    </div>
  );
}

export default BroadcastsPlan;
