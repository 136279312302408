
export enum SubscriptionStatusEnum {
  expired = 0,
  test = 4,
  testEnd = 3,  // end of test period
  paid = 2,
  warn = 1,
  unpaid = 5,
  paused = 6,
  unknown = 7
};
