import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAlertWithCustomText } from "../../../../components/Alert/alertSlice";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { createAmoInvoiceStatuses, getAmoInvoiceStatusesFromIntegration } from "../IntegrationAPI";
import { IntegrationsItem, InvoiceStatuses, PostStatuses } from "../IntegrationTypes";


export function useAmoInvoicesStatuses(integration: IntegrationsItem) {
  const dispatch = useDispatch();
  const company = useSelector(currentCompanySelector);
  const [invoiceStatuses, setInvoiceStatuses] = useState<InvoiceStatuses>();
  const [amoStatus, setAmoStatus] = useState<boolean>(true);
  const [fetched, setFetched] = useState<boolean>(false);
  
  const fetchAmoInvoicesStatuses = useCallback(async () => {
    let result: boolean = false;
    try {
      const response = await getAmoInvoiceStatusesFromIntegration({
        companyId: company.id,
        integrationId: integration.id
      });
      setInvoiceStatuses(response);
      setFetched(true);
      result = true;
    } catch (e) {
      if (e.response) {
        setAmoStatus(false);
        dispatch(addAlertWithCustomText({ code: e.response.data.error_code, type: 'alarm' }));
      }
      setFetched(false);
    }
    return result;
  }, [company, integration]);

  useEffect(() => {
    if (integration.params.amoInvoices) fetchAmoInvoicesStatuses();
  }, [integration, fetchAmoInvoicesStatuses]);

  const createInvoiceStatuses = useCallback(async (statuses: PostStatuses[]) => {
    const result = await createAmoInvoiceStatuses({
      companyId: company.id,
      integrationId: integration.id,
      data: { statuses }
    });
    setInvoiceStatuses(result);
  }, [company, integration]);

  return { amoStatus, fetched, invoiceStatuses, createInvoiceStatuses, fetchAmoInvoicesStatuses };
}
