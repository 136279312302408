import React, { useEffect, useState } from 'react';
import './TelegramBotCreateUpdateConnection.scss';
import { useRouteMatch } from 'react-router-dom';
import { goBack, push } from 'connected-react-router';
import * as yup from 'yup';
import { useParams } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ReactComponent as ComeBackIcon } from '../../../../../../../assets/template-backward-icon.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/store';
import { TelegramBotConnection } from '../../../../../../../api/CompanyAPI';
import { createConnection, updateConnection } from '../../../../connectionsSlice';
import { addAlertWithCustomText } from '../../../../../../../components/Alert/alertSlice';
import { listOfNumbers } from '../../../../../../../utils/listOfNumber';
import { YandexMetrikaCall } from '../../../../../../../utils/utils';
import { setHadConnections } from "../../../../../../Chat/companiesSlice";
import Button from "../../../../../../../components/Button/Button";
import Input from '../../../../../../../components/Input/Input';
import {useSelector} from "react-redux";
import {currentCompanySelector} from "../../../../../../Chat/companiesSelector";


type SubmitSchema = {
  name: string;
  token: string;
};

const Instruction = (t: TFunction) =>
  <div className='TelegramBotCreateUpdateConnection__editMode__instruction'>
    <h3>{t('settings.integrations.telegram_bot.addTelegramBotText.title')}</h3>
    <div
      className='TelegramBotCreateUpdateConnection__editMode__instruction__list'>
      {listOfNumbers.map(({ id, Component }) => {
        if (id === 1) {
          return (
            <div
              className='TelegramBotCreateUpdateConnection__editMode__instruction__list__item'
              key={id}>
              <Component />
              <span>{t('settings.integrations.telegram_bot.addTelegramBotText.redirectBotFather1')}
                <a target='_blank'
                   rel='noreferrer' href='https://t.me/BotFather'>@BotFather</a>
                {t('settings.integrations.telegram_bot.addTelegramBotText.redirectBotFather2')}
             </span>
            </div>
          );
        }
        return <div
          className='TelegramBotCreateUpdateConnection__editMode__instruction__list__item'
          key={id}>
          <Component />
          {t(`settings.integrations.telegram_bot.addTelegramBotText.${id}`)}
        </div>;
      })}
    </div>
  </div>;

const TelegramBotCreateUpdateConnection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const [connection, setConnection] = useState<TelegramBotConnection>();
  const telegramBotsConnections = useAppSelector(
    (state) => state.connections.connectionsStore.telegram_bot as Array<TelegramBotConnection>);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')),
    token: yup.string(),
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name || '',
      token: '',
    },
  });

  useEffect(() => {
    if (connectionId) {
      const itemConnection = telegramBotsConnections.find((item) => item.id === Number(connectionId));
      if (itemConnection) {
        setConnection(itemConnection  as TelegramBotConnection);
        methods.setValue('name', itemConnection.name);
      }
    }
  }, [telegramBotsConnections]);

  const onSubmit = (data: SubmitSchema) => {
    if (currentCompanyId?.params.companyId) {
      if (connection) {
        dispatch(updateConnection({
          itemId: connection.id,
          name: data.name,
          ...(data?.token.length > 0 ? { params: { bot_token: data.token } } : {}),
          type: 'telegram_bot',
          companyId: Number(currentCompanyId?.params.companyId),
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(push(`${currentCompanyId.url}/settings/connections/telegram_bot`));
          }
        });
        return;
      }

      if (data.token.length > 1 && !connection) {
        dispatch(createConnection({
          name: data.name,
          params: {
            bot_token: data.token,
          },
          type: 'telegram_bot',
          companyId: Number(currentCompanyId?.params.companyId),
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            if (currentCompany.hadConnections === false) {
              YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
              dispatch(setHadConnections(true));
            }
            dispatch(
              addAlertWithCustomText({
                message: t('settings.integrations.telegram_bot.botSuccessConnected'),
                color: '#2CBA5F',
              })
            );
            dispatch(push(`${currentCompanyId.url}/settings/connections/telegram_bot`));
          }
        });
      } else {
        methods.setError('token', {
          type: 'required',
          message: t('error.form.empty_field'),
        });
      }
    }
  };

  const gotoBack = () => {
    dispatch(goBack());
  };

  return (
    <FormProvider {...methods}>
      <form className={`TelegramBotCreateUpdateConnection ${connection ? 'editMode' : ''}`}
        onSubmit={methods.handleSubmit(onSubmit)}>
        <div className='TelegramBotCreateUpdateConnection__title'>
          <div className='TelegramBotCreateUpdateConnection__title__button'>
            <Button type='reset' text={t('back')} color='white' textType='small'
              image={<ComeBackIcon />}  onClick={gotoBack} />
          </div>
          <div className='TelegramBotCreateUpdateConnection__title__text'>
            {connection && connection.name
              ? t('edit')
              : t('settings.integrations.telegram_bot.AddTelegramBot')
            }
          </div>
        </div>
        {connection && (
          <div className='TelegramBotCreateUpdateConnection__editMode'>
            <div>{t('settings.integrations.odnoklassniki.numberConnect')}:
              #{connection.id}</div>
            <div>@{connection.params.botUsername}</div>
          </div>
        )}
        <div className='TelegramBotCreateUpdateConnection__name'>
          <Input type='text' label={t('connections.whatsapp.add-view.input_title')} name='name' />
        </div>
        <div className='TelegramBotCreateUpdateConnection__token'>
          <Input type='text' name='token' label={t('settings.integrations.telegram_bot.TelegramBotToken')} inputType="password" />
        </div>
        <div className={`TelegramBotCreateUpdateConnection__submit ${connection ? 'editMode' : ''}`}>
          <Button type='submit' color='orange' textType='regular' text={connection ? t('save_edit') : t('connect')}
            disabled={!methods.formState.isDirty} />
        </div>
        {!connection && Instruction(t)}
      </form>
    </FormProvider>
  );
};

export default TelegramBotCreateUpdateConnection;
