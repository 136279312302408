import React, { FC } from 'react';
import './ChatItemContact.scss';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { ChatType } from '../../../../api/ContactsAPI';
import { ResGetAllConnForChatType } from '../../../../api/CompanyAPI';
import { listLogosChatsContact } from '../../utility/Logos';


type Props = {
  item: ChatType;
  contactId: number;
  companyId: number;
  connections: ResGetAllConnForChatType[];
};

export const ChatItemContact: FC<Props> = (
  { item: { phone, chatId, connectionId }, contactId, companyId, connections }
) => {
  const dispatch = useDispatch();
  const connection = connections.find((c) => c.connectionId === connectionId);
  const name = connection?.name || '';

  const onRouteCardChat = ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(push(`/companies/${companyId}/chats/${contactId}/${chatId}`));
  });

  return (
    <div onClick={onRouteCardChat} className="ChatItemContact" data-testid='chat' >
      <div>{connection && listLogosChatsContact[connection.type]}</div>
      <div>
        <p className='smallText middle'>{name}</p>
        <p className='phoneText'>{phone}</p>
      </div>
    </div>
  );
};
