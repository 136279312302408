import React from 'react';
import './SettingsList.scss';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentCompanyRoleSelector } from '../../Chat/companiesSelector';
import { listOfTabsInSettings } from '../utility/listOfTabsInSettings';
import { settingConnections } from '../Connections/utils/connections';

const firstStyle = { borderRadius: '25px 0px 0px 0px' };

const getStyleForConnections = (currentTab: string, selectedTab: string) => {
  if (currentTab === 'connections') {
    return Object.values(settingConnections)
      .map((conn) => conn.url).includes(selectedTab)
      ? firstStyle
      : undefined;
  }
  return {};
};

type Props = {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
};

const SettingsList: React.FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const currentRole = useSelector(getCurrentCompanyRoleSelector);

  const onSelectTab = (tabTitle: string) => {
    setSelectedTab(tabTitle);
  };

  const tabsWithOther = currentRole ? [...currentRole.permissions, 'profile'] : [];
  const tabs = listOfTabsInSettings.filter(i => tabsWithOther.includes(i.permission));

  return (
    <div className='settingListContainer'>
      <div className='settingList'>
        <div className='settingListContent'>
          {currentRole && tabs.map(({ tab, ComponentIcon, title }) =>
            <Link key={tab} to={`${url}/${tab}`}>
              <div
                className={`tabShadow ${selectedTab === tab ? 'active' : ''}`}
                style={getStyleForConnections(tab, selectedTab)}
                onClick={() => onSelectTab(tab)}
              >
                <div className='tab'>
                  <div className='settingListMiddle'>
                    <ComponentIcon />
                    <p>{t(`${title}`)}</p>
                  </div>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsList;
