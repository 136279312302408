import React, { FC, useEffect, useRef, useState } from 'react';


type Props = {
  timeLimit: number;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  customText?: string;
};

const formatMinuteSeconds = (seconds: number) =>
  `${Math.floor(seconds / 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

export const TimerDisabled: FC<Props> = ({ timeLimit, setDisabled, customText }) => {
  const [elapsed, setElapsed] = useState<number>(timeLimit);
  const timerId = useRef<NodeJS.Timer>();

  useEffect(() => {
    setDisabled(elapsed === 0);
  }, [elapsed]);

  useEffect(() => {
    if (timeLimit > 0) {
      setElapsed(prev => timeLimit);

      const testFunc = () => {
        setElapsed(prev => {
          if (prev > 0) return prev -1;
          return prev;
        });
      };

      testFunc();
      timerId.current = setInterval(testFunc, 1000);
    }

    return () => {
      if (timerId.current) {
        clearInterval(timerId.current);
      }
    };
  }, [timeLimit]);

  return (
    <div>
      <span>{customText} {formatMinuteSeconds(elapsed)}</span>
    </div>
  );
};
