import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContactChatsWithMetaData, MessagesRequest } from '../../api/types';
import { ChatsWithContactsParamType, getChatsWithContacts, getMessages } from '../../api/ChatAPI';
import { generateChatSortWeight, generateChatsWithContactSortWeight, sortChats } from './utils';


export const fetchMessages = createAsyncThunk(
  'messages/fetchedMessages', async (requestOption: MessagesRequest) => await getMessages(requestOption)
);

export const fetchChatsWithContact = createAsyncThunk<ContactChatsWithMetaData, ChatsWithContactsParamType>(
  'chat/fetchChatsWithContact', async (requestOption) => {
  const contactChatsWithMetaData = await getChatsWithContacts(requestOption);

  contactChatsWithMetaData.data.forEach((chatWithContact) => {
    const { chats } = chatWithContact;

    if (chats && chats.length) {
      for (let i = 0; i < chats.length; i++) {
        chats[i].sortWeight = generateChatSortWeight(chats[i]);
      }

      sortChats(chats);

      chatWithContact.lastMessageInfo = {
        chatId: chats[0].chatId,
        connectionId: chats[0].connectionId,
        lastMessage: chats[0].lastMessage,
      };
    } else {
      chatWithContact.lastMessageInfo = undefined;
    }

    chatWithContact.sortWeight = generateChatsWithContactSortWeight(chatWithContact);
  });

  sortChats(contactChatsWithMetaData.data);

  return contactChatsWithMetaData;
});
