import React from 'react';
import { Anchorme } from 'react-anchorme';

type Props = {
  text: string;
};

const LinkifyText: React.FC<Props> = ({ text }) => (
  <Anchorme target="_blank" truncate={50}>
    {text}
  </Anchorme>
);

export default LinkifyText;
