import { useEffect } from 'react';
import './ChatContent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { messagesSelector } from '../../messagesSelector';
import { updateChatWithContactOrder } from '../../chatsSlice';
import { StickyViewport } from './StickyViewport/StickyViewport';
import { getSortMessages } from './utils/getSortMessages';


const ChatContent = () => {
  const dispatch = useDispatch();
  const messages = useSelector(messagesSelector);
  const list = getSortMessages(messages)

  useEffect(() => () => {
    dispatch(updateChatWithContactOrder());
  }, []);

  return <StickyViewport list={list} messages={messages} />
};

export default ChatContent;
