import React, { useContext } from 'react';
import { connectionItemContext, useConnectionOuterLink } from '../../utils/connections';
import './ConnectionOuterLink.scss';


const ConnectionOuterLink = () => {
  const { connection } = useContext(connectionItemContext);
  const { link, href } = useConnectionOuterLink(connection)
  return <a className="connectionOuterLink" target='_blank' rel='noreferrer' href={href}>{link}</a>;
};

export default ConnectionOuterLink;
