import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { useAppSelector } from '../../../../../store/store';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { WhatsAppConnection } from '../../../../../api/CompanyAPI';
import { ConnectionStatusType } from '../../ConnectionsTypes';
import { updateConnectionStatus } from '../../connectionsSlice';
import WhatsAppCopyQr from './WhatsAppCopyQr';
import closeIcon from '../../../../../assets/close-icon-black.svg';


type Props = {
  isOpen: boolean;
  imageSrc: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  qrUrl: string
  connection: WhatsAppConnection;
};

const ImageQRModal: React.FC<Props> = ({ isOpen, imageSrc, setIsOpen, qrUrl, connection }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const connectionStatuses = useAppSelector((state) => state.connections.whatsappStatus);
  const [status, setStatus] = useState<ConnectionStatusType>();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (connectionStatuses) {
      setStatus(connectionStatuses[connection.id]);
    }
  }, [connectionStatuses]);

  useEffect(() => {
    const id = setInterval(async () => {
      dispatch(updateConnectionStatus({
        connectionId: connection.id,
        companyId: currentCompany.id
      }))
    }, 15000);
    setIntervalId(id);
  }, []);

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId]
  );

  useEffect(() => {
    if (intervalId && status && status.status === 'ok') {
      clearInterval(intervalId);
    }
  }, [intervalId, status]);

  useEffect(() => {
    if (status && status.status === 'ok' && isOpen) {
      dispatch(
        addAlertWithCustomText({
          message: `${t('connections.whatsapp.add-view.number')} ${connection.params.phone || ''} ${t(
            'connections.whatsapp.add-view.connected'
          )}`,
        })
      );
    }
  }, [status]);

  const onClickHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(false);
  };
  
  if (!isOpen) return null;

  return (
    <div className="imageQRModal_wrapper" onClick={onClickHandler}>
      <img className="imageQRModal__close"
        src={closeIcon}  width={20} height={20} alt='x' onClick={onClickHandler}/>
      <div className="imageQRModal">
        <div className="imageQRModal__title" onClick={(e) => e.stopPropagation()}>
          <span style={{ width: 248 }}>{t('connections.whatsapp.main-view.modal_title_first')}</span>
          <br/>
          <span style={{ width: 248 }}>{t('connections.whatsapp.main-view.modal_title_second')}</span>
        </div>
        <img className="imageQRModal__image"
          src={status?.qrCode} alt='qr-code...' onClick={(e) => e.stopPropagation()}/>
        <div className="copyQR">
          <WhatsAppCopyQr codeQr={connection.params.qrUrl || ''}/>
        </div>
      </div>
    </div>
  );
};

export default ImageQRModal;
