import React from 'react';
import './ButtonAction.scss';
import { UseFieldArrayReturn, useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Textarea from '../Textarea/Textarea';
import RemoveActionButton from '../RemoveActionButton/RemoveActionButton';
import { CreateInteractiveTemplateData } from '../../../../api/types';

type Props = {
  id: number;
};

const ButtonAction: React.FC<
  Props & UseFormReturn<CreateInteractiveTemplateData> & UseFieldArrayReturn<CreateInteractiveTemplateData>
> = ({ id, register, remove, formState }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<CreateInteractiveTemplateData>();

  const onRemoveButtonAction = () => {
    remove(id);
  };

  const validateButtonActionTitle = () => {
    const buttons = getValues('waba_interactive.action.buttons');
    const titles = new Set(buttons.map((button) => button.reply.title));
    const message = t('templates.add_interactive_templates.template_actions.buttons.errors.unique_titles');
    return titles.size === buttons.length || message;
  }

  return (
    <div className="buttonAction">
      <Textarea
        title={`${t('templates.add_interactive_templates.template_actions.buttons.button_title')} ${id + 1}`}
        subtitle={t('templates.add_interactive_templates.template_actions.buttons.button_subtitle')}
        minRows={1}
        maxRows={2}
        width="400px"
        {...register(`waba_interactive.action.buttons.${id}.reply.title`, {
          minLength: { value: 1, message: 'Заполните это поле' },
          maxLength: { value: 20, message: 'Не более 20 символов' },
          required: true,
          validate: () => validateButtonActionTitle()
        })}
        maxLength={20}
        errorMessage={
          (formState.errors.waba_interactive?.action?.buttons?.[id]?.reply?.title?.type === 'required' && (
            <span className="errorMessage">
              {t('templates.add_interactive_templates.template_actions.buttons.errors.add')}
            </span>
          )) ||
          (formState.errors.waba_interactive?.action?.buttons?.[id]?.reply?.title && (
            <span className="errorMessage">
              {formState.errors.waba_interactive?.action?.buttons?.[id]?.reply?.title?.message}
            </span>
          ))
        }
      />
      {id > 0 && <RemoveActionButton onRemoveAction={onRemoveButtonAction} />}
    </div>
  );
};

export default ButtonAction;
