import React from 'react';
import './InteractiveMessageSettingsLIstActions.scss';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Textarea from '../Textarea/Textarea';
import ListAction from './ListAction';
import AddActionButton from '../AddActionButton/AddActionButton';
import { CreateInteractiveTemplateData } from '../../../../api/types';

const InteractiveMessageSettingsListActions = () => {
  const { t } = useTranslation();
  const methods = useFormContext<CreateInteractiveTemplateData>();
  const fieldArrayMethods = useFieldArray<CreateInteractiveTemplateData>({
    name: 'waba_interactive.action.sections.sections.0.rows',
    control: methods.control,
  });

  const onAddListAction = () => {
    if (fieldArrayMethods.fields.length < 10) {
      fieldArrayMethods.append({
        title: '',
        description: '',
      });
    }
  };

  return (
    <div className="interactiveMessageSettingsListActions">
      <div className="interactiveMessageSettingsListActions__heading">
        <Textarea
          title={t('templates.add_interactive_templates.template_actions.list.title')}
          subtitle={t('templates.add_interactive_templates.template_actions.list.subtitle')}
          minRows={2}
          maxRows={2}
          {...methods.register('waba_interactive.action.sections.button', {
            minLength: { value: 1, message: 'Заполните это поле' },
            maxLength: { value: 20, message: 'Не более 20 символов' },
            required: true,
          })}
          maxLength={20}
          errorMessage={
            (methods.formState.errors.waba_interactive?.action?.sections?.button?.type === 'required' && (
              <span className="errorMessage">
                {t('templates.add_interactive_templates.template_actions.list.errors.title')}
              </span>
            )) ||
            (methods.formState.errors.waba_interactive?.action?.sections?.button && (
              <span className="errorMessage">
                {methods.formState.errors.waba_interactive?.action.sections.button.message}
              </span>
            ))
          }
        />
      </div>
      <div className="interactiveMessageSettingsListActions__actions">
        {fieldArrayMethods.fields.map((listAction, id) => (
          <ListAction id={id} key={listAction.id} {...methods} {...fieldArrayMethods} />
        ))}
      </div>
      {fieldArrayMethods.fields.length < 10 && (
        <AddActionButton
          onAddAction={onAddListAction}
          subtext={t('templates.add_interactive_templates.template_actions.list.buttons.add_paragraph')}
        />
      )}
    </div>
  );
};

export default InteractiveMessageSettingsListActions;
