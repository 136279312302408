
export const ALL_TARIFF = ['4xX6E3PA', 'LcgcDgPA'] as const;
export const NAMES_TARIFF = ['russia', 'intl'] as const;

export type TariffType = typeof ALL_TARIFF[number];
export type NamesTariffType = typeof NAMES_TARIFF[number];
export const DEFALUT_TARIFF: TariffType = '4xX6E3PA';

export const tariffsType: Record<NamesTariffType, TariffType> = {
  'russia': '4xX6E3PA',
  'intl': 'LcgcDgPA'
};

export function tarifName(tarifId: TariffType | undefined) {
  if (tarifId !== undefined) {
    return (Object.keys(tariffsType) as NamesTariffType[]).find(tarif => tariffsType[tarif] === tarifId);
  }
  return '';
}
