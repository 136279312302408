import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone-icon.svg';

const MyTemplatesLink = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const urlData = useRouteMatch<{ tab: string }>('/companies/:companyId/templates/:tab');
  const { t } = useTranslation();

  return (
    <div
      className={`templatesList__item ${urlData?.params.tab !== 'interactive-messages' && 'active'}`}
      onClick={() => history.push(url)}
    >
      <div className="templatesList__item_content">
        <PhoneIcon width={18} />
        <p className="templatesList__item_content__name">{t('templates.my_templates')}</p>
      </div>
    </div>
  );
};

export default MyTemplatesLink;
