import { useEffect, useState } from 'react';
import './Users.scss';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getInviteLink, getMember } from '../../Chat/companiesSlice';
import { currentCompanySelector, membersSelector } from '../../Chat/companiesSelector';
import { UsersType } from '../../../api/CompanyAPI';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../components/Button/Button';
import User from './User/User';
import SidePanel from '../../../components/Modals/SidePanel';
import AddUser from './AddUser/AddUser';
import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg';


const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const membersArr = useSelector(membersSelector);
  const currentCompany = useSelector(currentCompanySelector);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isRoleEditable, setIsRoleEditable] = useState<UsersType | null>(null);
  const [openAddUserForm, setOpenAddUserForm] = useState<boolean>(false);
  const findOwner = membersArr?.find(i => i.userId === currentCompany.ownerId);

  // owner - first in list
  const correctMembers = membersArr && findOwner
    ? [findOwner, ...membersArr.filter(i => i.userId !== findOwner.userId)]
    : membersArr;

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const activeSetIsRoleEditable = (user: UsersType) => {
    setIsRoleEditable(user);
  };

  const onCloseEditCard = () => {
    setIsRoleEditable(null);
  };

  const onAddUser = () => {
    setOpenAddUserForm(!openAddUserForm);
  };

  useEffect(() => {
    if (currentCompanyId?.params.companyId) {
      dispatch(getMember(Number(currentCompanyId?.params.companyId)));
      dispatch(getInviteLink(Number(currentCompanyId?.params.companyId)));
    }
  }, [currentCompanyId?.params.companyId]);


  useEffect(() => {
    if (isOpenModal) {
      setTimeout(closeModal, 6000);
    } else setTimeout(closeModal, 0);
  }, [isOpenModal]);

  return (
    <>
      {openAddUserForm &&
        <SidePanel background='light' setIsOpen={setOpenAddUserForm}>
          <AddUser />
        </SidePanel>
      }
      <div className='users'>
        <div className='users__header'>
          <h1>{t('settings.settings_list.users')}</h1>
          <WrapperPermission permission='membersAdd'>
            <Button type='button' color='orange' textType='regular' text={t('settings.settings_list.addUser')}
              image={<PlusIcon />} onClick={onAddUser}/>
          </WrapperPermission>
        </div>
        <div className='users__list'>
          {correctMembers && correctMembers.map((user) =>
            <User key={user.id} user={user} isRoleEditable={isRoleEditable} onCloseEditCard={onCloseEditCard}
              activeSetIsRoleEditable={activeSetIsRoleEditable} />
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
