import React, { useRef } from 'react';
import './ModalPaymentInvoiceLink.scss';
import '../../ChatBill.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import * as clipboard from 'clipboard-polyfill/text';
import { useTranslation } from 'react-i18next';
import { push } from "connected-react-router";
import CloseIcon from '../../../../../../assets/cancel.svg';
import CheckIcon from '../../../../../../assets/check-green-big.svg';
import { updateIsPageBill, updateIsVisibleLinkInvoice } from '../../../../billSlice';
import { isVisibleLinkInvoiceSelector, urlSelector } from '../../../../billSelector';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import Button from '../../../../../../components/Button/Button';
import { useOutsideClick } from "../../../../../../components/utils/hooks";

const ModalPaymentInvoiceLink = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const IsVisibleLinkInvoice = useSelector(isVisibleLinkInvoiceSelector);
  const urlInvoices = useSelector(urlSelector);
  const url = useRouteMatch<{ companyId: string; contactId: string; chatId: string }>(
    '/companies/:companyId/chats/:contactId/:chatId'
  );

  const onClickClose = () => {
    dispatch(updateIsVisibleLinkInvoice(false));
  };
  const onClickNewInvoice = () => {
    dispatch(updateIsVisibleLinkInvoice(false));
    dispatch(push(`${url?.url}/bill`));
    dispatch(updateIsPageBill(true));
  };
  const copyPaste = () => {
    if(urlInvoices){
      clipboard.writeText(urlInvoices).then();
      dispatch(addAlertWithCustomText({ message: t('settings.users.modal_text') }));
    }
  }

  const wrapperRef = useRef(null);
  const triggerButtonRef = useRef(null);
  useOutsideClick(wrapperRef, onClickClose, triggerButtonRef);

  if (IsVisibleLinkInvoice && urlInvoices) {
    return (
      <div className='ModalPaymentInvoiceLink' ref={wrapperRef}>
        <div className='ModalPaymentInvoiceLink_header'>
          <div className='ModalPaymentInvoiceLink_header_close'>
            <img src={CloseIcon} alt='close icon' ref={triggerButtonRef} onClick={onClickClose}/>
          </div>
          <div className='ModalPaymentInvoiceLink_header_checkGreen'>
            <img src={CheckIcon} alt='check icon'/>
          </div>
        </div>
        <div className='ModalPaymentInvoiceLink_content'>
          <h5>{t('chats.bill.modal_invoice.link_title')}</h5>
          <div className="inputContainer">
            <div className="inputContainer__wrapper">
              <input value={urlInvoices} disabled
                     className="inputContainer__input"/>
            </div>
          </div>
        </div>
        <div className='ModalPaymentInvoiceLink_bottom'>
          <Button text={t('chats.bill.modal_invoice.copy_button')} color='orange' onClick={copyPaste} textType='small' style={{ padding: '6px 12px' }} />
          <Link to={`${url?.url}/bill`}>
            <Button text={t('chats.bill.modal_invoice.new_bill_button')} color='white' onClick={onClickNewInvoice} textType='small' style={{ padding: '6px 12px' }} />
          </Link>
        </div>
      </div>
    );
  } return <></>
};

export default ModalPaymentInvoiceLink;
