import './TerminalItem.scss';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { terminalsContext } from '../../../api/terminals';
import { Named } from '../../../types';
import { WrapperPermission } from '../../../../../../utils/WrapperPermission/WrapperPermission';
import SwitchControl from '../../../../../../components/SwitchControl';
import ActionDialog from '../../../../../../components/Modals/ActionDialog';
import { ReactComponent as StopIcon } from '../../../../../../assets/stop-icon.svg';


type TerminalItemProps = {
  terminal: Named;
};

function TerminalItem({ terminal }: TerminalItemProps) {
  const { t } = useTranslation();
  const { onDelete } = useContext(terminalsContext);
  const { control } = useFormContext();
  const defaultConnection = useWatch({ control, name: 'defaultConnection' });
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);

  const onDeleteClick = () => setDeleteConfirm(true);

  const onDeleteHandler = () => {
    onDelete(terminal);
    setDeleteConfirm(false);
  };

  return (
    <>
      {deleteConfirm &&
        <ActionDialog onAction={onDeleteHandler} setIsOpen={setDeleteConfirm}
          action={t('disable')} cancel={t('cancel')}
          background='light' title={t('settings.integrations.titleDisableIntegration')}
          message={t('settings.integrations.disableIntegration')} />
      }
      <div className='terminalItem'>
        <p className='terminalItem__name'>{terminal.name}</p>
        <p className='terminalItem__number'>#{terminal.id}</p>
        <WrapperPermission permission='integrationsEdit' disabledProps={{ disabled: true }}>
          <SwitchControl name='defaultConnection' value={terminal.id} type='radio'
            label={t('settings.integrations.banking.checkbox2')}
            defaultChecked={defaultConnection === terminal.id} />
        </WrapperPermission>
        <WrapperPermission permission='integrationsDelete'>
          <div className="terminalItem__buttonsGroup">
            <button className="terminalItem__buttonsGroup_disableButton" type="button" onClick={onDeleteClick}>
              <StopIcon />
              {t('disable')}
            </button>
          </div>
        </WrapperPermission>
      </div>
    </>
  );
}

export default TerminalItem;
