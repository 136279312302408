import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductChooseType } from '../../../types';
import CatalogsChooseProductCard from '../CatalogsChooseProductCard';
import './CatalogsChooseProductList.scss';


type CatalogsChooseProductListProps = {
  productList: ProductChooseType[];
  onUpload: () => void;
  more: boolean;
};

const CatalogsChooseProductList = ({ productList, onUpload, more }: CatalogsChooseProductListProps) => {
  const { t } = useTranslation();
  const [productSorted, setProdustSorted] = useState<ProductChooseType[]>(
    productList.sort((a, b) => a.selected && !b.selected ? -1 : 1));
  const [scrollPosition, setScrollPosition] = useState<number | null>(null);
  const refList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setProdustSorted(productList.sort((a, b) => a.selected && !b.selected ? -1 : 1));
  }, [productList]);

  const onCheckHandler = (product: ProductChooseType) => {
    setProdustSorted([...productList.sort((a, b) => a.selected && !b.selected ? -1 : 1)]);
    if (refList.current) setScrollPosition(refList.current.scrollTop);
  };

  useEffect(() => {
    if (refList.current && scrollPosition) refList.current.scroll({ top: scrollPosition });
    setScrollPosition(null);
  }, [scrollPosition]);
  
  if (productList.length === 0) {
    return (<div className="catalogsChooseProductList">{t('sales.catalogs.no_products_found')}</div>);
  }

  return (
    <InfiniteScroll
      next={onUpload}
      hasMore={more}
      inverse={false}
      scrollableTarget='scrollableDiv'
      loader={null}
      dataLength={productList.length}
    >
      <div className="catalogsChooseProductList" id='scrollableDiv' ref={refList}>
        {productSorted.map((product, index, products) => {
          let setSplitter = false;
          if (index > 0 && products[index - 1].selected) {
            setSplitter = !product.selected;
          }
          return (
            <div className={`catalogsChooseProductList__product ${setSplitter ? 'splitter' : ''}`} key={product.id}>
              <CatalogsChooseProductCard key={product.id} product={product} onCheck={onCheckHandler} />
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default CatalogsChooseProductList;
