import React from 'react';
import { CustomTemplate } from '../../../../api/types';
import CustomTemplateCard from '../CustomTemplateCard/CustomTemplateCard';

export const filterCustomTemplates = (searchedValue: string, templates: CustomTemplate[]) => {
  const findedTemplatesByText = templates.filter((template) =>
    template.text.toLowerCase().includes(searchedValue.toLowerCase())
  );
  const findedTemplatesByName = templates.filter((template) =>
    template.name.toLowerCase().includes(searchedValue.toLowerCase())
  );
  // @ts-ignore
  const mergedArrayWithoutDuplicates = [...new Set([...findedTemplatesByText, ...findedTemplatesByName])];
  return mergedArrayWithoutDuplicates.map((template) => (
    <CustomTemplateCard template={template} key={template.id} setTemplateEdit={undefined} />
  ));
};
