import React, { useState } from 'react';

const usePopupWrapper = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onTriggerPopupWrapper = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsVisible(!isVisible);
  };

  const onClosePopupWrapper = (e: React.MouseEvent<unknown>) => {
    e.stopPropagation();
    setIsVisible(false);
  };

  return { isVisible, setIsVisible, onTriggerPopupWrapper, onClosePopupWrapper };
};

export default usePopupWrapper;
