import React from 'react';
import './TitleComponent.scss';


type Props = {
  title: string;
  children: React.ReactNode;
}

const TitleComponent = ({ title, children }: Props) => (
    <div className='titleComponent'>
      <h6>{title}</h6>
      {children}
    </div>
  );

export default TitleComponent;
