import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { removeMember } from '../../../Chat/companiesSlice';
import { currentCompanySelector, getRolesCompanySelector, profileSettingsInfo } from '../../../Chat/companiesSelector';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { RoleType, UsersType } from '../../../../api/CompanyAPI';
import { getNameRole } from '../UserEdit/utlity';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import RoleModal from '../RoleModal/RoleModal';
import UserEdit from '../UserEdit/UserEdit';
import { ReactComponent as CloseIcon } from '../../../../assets/close-icon.svg';
import edit from '../../../../assets/edit.svg';
import trash from '../../../../assets/trash.svg';


const trashStyle = {
  color: '#F54242',
};

type Props = {
  user: UsersType;
  isRoleEditable: UsersType | null;
  onCloseEditCard: () => void;
  activeSetIsRoleEditable(user: UsersType): void;
};

const User: FC<Props> = ({ user, isRoleEditable, onCloseEditCard, activeSetIsRoleEditable }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const roles = useSelector(getRolesCompanySelector);
  const currentCompany = useSelector(currentCompanySelector);
  const { userId: currentUserId } = useSelector(profileSettingsInfo);
  const [deleteMember, setDeleteMember] = useState<UsersType|null>(null);
  const isOwner = user.userId === currentCompany.ownerId;
  const isCurrentUser = user.userId === currentUserId;

  const handlerCloseDeleteMember = () => {
    setDeleteMember(null);
  };

  const submitDeleteMember = () => {
    if (currentCompanyId?.params.companyId) dispatch(
       removeMember({
         companyId: Number(currentCompanyId?.params.companyId),
         memberId: user.id,
       })
     ).then(r => {
      if (r.meta.requestStatus === 'fulfilled'){
        dispatch(addAlertWithCustomText({
          message: t('settings.users.user.successDeleteMember'),
          color: '#2CBA5F'
        }));
      }
    })
  }

  const [role, setRole] = useState<RoleType|null>(null);

  const activeDeleteMember = () => {
    setDeleteMember(user);
  };

  const initialStateCurrentRoleEdit:RoleType|undefined = roles.find(r => r.id === user.roleId);

  useEffect(() => {
    const isRole = roles.find(r => r.id === user.roleId)
    if (isRole) {
      setRole(isRole);
    }
  }, [user]);

  return (
      <div className={`infoUsers ${isRoleEditable && isRoleEditable?.id === user.id ? 'pb' : ''}
      ${ role && role?.name.length > 50 ? 'minHeight':''}
      `}>
        <div className="infoUsers__content">
          <div className={`infoUsers__text ${isRoleEditable && isRoleEditable?.id === user.id ? 'edit' : ''}`}>
            {!(isRoleEditable && isRoleEditable?.id === user.id) &&
              <div className=
                {`infoUsers__role ${isOwner ? "OWNER" : role && role.name} ${role && role.name.length > 50 ? 'longWord' : ''}`}>
              {role && getNameRole(role.name, isOwner, t)}
            </div>}
            <div className="infoUsers__text_name" >{user.displayName}</div>
            {isRoleEditable && isRoleEditable?.id === user.id && <CloseIcon onClick={onCloseEditCard} />}
          </div>
          <p>{user.email}</p>
        </div>
        {isRoleEditable && isRoleEditable?.id === user.id && initialStateCurrentRoleEdit
          ? <UserEdit isOwner={isOwner} roles={roles} user={isRoleEditable}
              onCloseEditCard={onCloseEditCard} initialStateCurrentRoleEdit={initialStateCurrentRoleEdit} />
          : (
            (!isOwner && !isCurrentUser) && <div className="infoUsers__button">
              <div className='UsersButton'>
                <WrapperPermission permission='membersEdit'>
                  <button className='optionallyButton' type='button' onClick={() => activeSetIsRoleEditable(user)} >
                    <img src={edit} alt='optionally btn' />
                    <span>{t('edit')}</span>
                  </button>
                </WrapperPermission>
                <WrapperPermission permission='membersDelete'>
                  <button className='optionallyButton' type='button' disabled={!!deleteMember || isOwner}
                    style={trashStyle} onClick={activeDeleteMember} >
                    <img src={trash} alt='optionally btn' /> {t('delete')}
                  </button>
                </WrapperPermission>
              </div>
            </div>
          )
        }
        {deleteMember &&
          <RoleModal
            handlerCloseModal={handlerCloseDeleteMember}
            handlerSubmitModal={submitDeleteMember}
            title=""
            disabled={false}
            error={false}
            errorText=''
            textSubmit={t('settings.users.user.alertButton')}
            titleMain={t('settings.users.user.alertTitle')}
          >
            <div>
              {t('settings.users.user.areYouSure')}
              <br/>
              {t('settings.users.user.willBeRemoved_start')} {deleteMember?.displayName}{t('settings.users.user.willBeRemoved')}
            </div>
          </RoleModal>
        }
      </div>
  );
};

export default User;
