import React from 'react';
import './RemoveActionButton.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../../../assets/trash-icon.svg';

type Props = {
  onRemoveAction: () => void;
};

const RemoveActionButton: React.FC<Props> = ({ onRemoveAction }) => {
  const { t } = useTranslation();

  return (
    <div className="removeActionButton" onClick={onRemoveAction}>
      <div className="removeActionButton__trashIcon">
        <TrashIcon />
        <span>{t('templates.add_interactive_templates.template_actions.buttons.buttons.delete_button')}</span>
      </div>
    </div>
  );
};

export default RemoveActionButton;
