import React, { useEffect, useRef, useState } from 'react';
import { BaseMessageComponent } from './types';
import ImageModal from '../../../../components/Modals/ImageModal';
import { ReactComponent as FileLoader } from '../../../../assets/fileLoader.svg';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';


const MessageImageType: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const [imageModal, setImageModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imageContainerRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isLoaded && imageContainerRef.current && handleSizeChange) {
      handleSizeChange(imageContainerRef.current.offsetHeight);
    }
  }, [isLoaded]);

  return (
    <>
      {message.image.url ? (
        <>
          <img
            style={isLoaded ? { display: 'block' } : { display: 'none' }}
            ref={imageContainerRef}
            onClick={() => setImageModal(!imageModal)}
            src={message.image.url}
            alt="chat message"
            onLoad={() => { setIsLoaded(true) }}
          />
          <p className='imageText'>{message.image.caption && isLoaded && <LinkifyText text={message.image.caption} />}</p>
          <ImageModal isOpen={imageModal} imageUrl={message.image.url} setImageModal={setImageModal} />
          {!isLoaded && <FileLoader width={40} height={40} />}
        </>
      ) : (
        <FileLoader width={40} height={40} />
      )}
    </>
  );
};

export default MessageImageType;
