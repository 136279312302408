import { RootState } from '../../store/store';

export const invoicesSelector = (state: RootState) => state.bill.invoices;
export const terminalsSelector = (state: RootState) => state.bill.terminal;
export const urlSelector = (state: RootState) => state.bill.urlInvoice;
export const isVisibleLinkInvoiceSelector = (state: RootState) => state.bill.isVisibleLinkInvoice;
export const isActionBillSelector = (state: RootState) => state.bill.isActionBill;
export const isPageBillSelector = (state: RootState) => state.bill.isPageBill;
export const isLoadingAddInvoiceSelector = (state: RootState) => state.bill.isLoadingAddInvoice;
export const totalCountSelector = (state: RootState) => state.bill.totalCountAndCount;
export const isVisibleProfileInvoiceSelector = (state: RootState) => state.bill.isVisibleProfileInvoice;
export const profileInvoiceSelector = (state: RootState) => state.bill.profileInvoice;
export const RadistOrderSelector = (state: RootState) => state.bill.radistOrder;
