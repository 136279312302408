import React from 'react';
import './Requisites.scss';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHasPermission } from "../../../../../../utils/WrapperPermission/WrapperPermission";
import { RequisitesSelector } from "../../../SubscriptionsSelector";
import Dropdown from "../../../../Dropdown/Dropdown";
import DropdownEmptyLink from "../../../../../../components/DropdownEmptyLink/DropdownEmptyLink";


type Props = {
  onAddNewRequisites: () => void;
}

const Requisites = ({ onAddNewRequisites }: Props) => {
  const { t } = useTranslation();
  const requisites = useSelector(RequisitesSelector);
  const requisitesList = requisites.filter((item) => item.approvalStatus === 'APPROVED');
  const permissionEdit = useHasPermission('subscriptionsEdit');

  return (
    <div className="requisites">
        {requisitesList.length > 0 ? (
          <Dropdown
            label={t('subscriptions.invoice.requisite_title')}
            items={requisitesList}
            placeholder={t('select')}
            labelKey="name"
            valueKey="id"
            name="company_requisite_id"
          />
        ) : (
          <DropdownEmptyLink
            label={t('subscriptions.invoice.requisite_title')}
            link="settings/subscriptions"
            options={onAddNewRequisites}
            linkText={t('subscriptions.requisites.add_requisites')}
            placeholder={t('subscriptions.invoice.requisite_none')}
            visibleButton={permissionEdit}
          />
        )}
    </div>
  );
};

export default Requisites;
