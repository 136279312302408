import axios from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import keycloak from "../../../keycloak";
import { dateTimeFormat } from "../utils";


type BroadcastsUrlType = {
  companyId: string | number | undefined;
  broadcastId: string | number | undefined;
  name: string | undefined;
 };

export function useExportStatistic({ companyId, broadcastId, name }: BroadcastsUrlType) {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const ref = useRef<HTMLAnchorElement>(null);

  const getFileName = () => {
    const now = new Date();
    return `${t('broadcasts.statistic', {
      name,
      date: dateTimeFormat(now.toISOString(), 'shortDate', i18n.language),
      time: dateTimeFormat(now.toISOString(), 'fullTime', i18n.language)
    })}.xlsx`;
  }

  const download = async () => {
    if (companyId && broadcastId && name) {
      await axios({
        url: `${process.env.REACT_APP_BASE_API_URL}/companies/${companyId}/broadcasts/${broadcastId}/statistics/export`,
        method: "GET",
        headers: { 'Authorization': `Bearer ${keycloak.token}` },
        responseType: "blob" // important
      }).then(response => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        setFile(fileUrl);
        setFileName(getFileName());
        ref.current?.click();
        URL.revokeObjectURL(fileUrl);
      });
    }
  };

  return { download, ref, file, fileName }

}
