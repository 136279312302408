import './PayKeeperCreate.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import GoBackIcon from '../../../../../../assets/template-backward-icon.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../../store/store';
import { PaykeeperCreateParams } from '../../../ConnectionsTypes';
import { createConnection } from '../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { settingConnections } from '../../../utils/connections';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import { DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME } from '../../../utils/terminals';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import LifetimePayment from "../../../components/LifetimePayment";


type PayKeeperSchema = {
  name: string;
  params: PaykeeperCreateParams;
};

const PayKeeperCreate = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      server_url: yup.string().required(t('error.form.empty_field'))
        .max(2083, t('error.form.max_length', { limit: 2083 })),
      user_name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      password: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
      invoice_lifetime_minutes: yup.number()
        .min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<PayKeeperSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      params: {
        invoice_lifetime_minutes: DEFAULT_LIFETIME
      }
    }
  });

  const gotoBack = () => goBack();

  const onSubmit = (data: PayKeeperSchema) => {
    dispatch(createConnection({
      companyId: currentCompany.id,
      name: data.name,
      type: 'paykeeper',
      params: { ...data.params }
    })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        gotoBack()
      }
    });
  };

  return (
    <div className="payKeeperCreate">
      <div className="modulBankCreate__header">
        <Button color='white' textType='regular' text={t('back')} onClick={gotoBack} image={GoBackIcon} />
        <h3>{t(`${settingConnections.paykeeper?.name}`)}</h3>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input name="name" label={t('connections.connection_name')} />
          <Input name="params.server_url" label="Сервер PayKeeper (в формате https://test.server.paykeeper.ru/)" />
          <Input name="params.user_name" label="Логин" />
          <Input name="params.password" label="Пароль" inputType="password" />
          <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={DEFAULT_LIFETIME} />
          <Button type='submit' color='orange' textType='regular' text={t('connections.paykeeper.add_button')}
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
};

export default PayKeeperCreate;
