export const convertUrl = (url: string, id: number) => {
  const match = /(\/(\d)\/)/g.exec(url);
  const matchLastInteger = /\/(\d+)/g.exec(url);

  if (match && match[0]) {
    const trimmedMatch = match[0].substring(1, match[0].length - 1);
    return url.replace(trimmedMatch, id.toString());
  }
  if (matchLastInteger && matchLastInteger[0]) {
    const trimmedMatchLastInteger = matchLastInteger[0].substring(1, matchLastInteger[0].length);
    return url.replace(trimmedMatchLastInteger, id.toString());
  }

  return url;
};
