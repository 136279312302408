import React from 'react';
import { SelectedServiceContactType } from './types';


export const LIST_DELIMETER = ';';

export const regexContactsBroadcasts = {
  returnArrStr: /(.+)/g,
  telegramUsernameValidator: /^@?[a-zA-Z0-9_]{2,63}$/,
  phoneValidator: /^\+\d+$/,
  linesTrimmer: /^([^;]+);\s*(.+?)\s*$/gm
};

export type ServicesContactsValidateType = {
  telegram: 'phone_username';
  telegram_bot: 'username';
  waba: 'phone'
  whatsapp: 'phone';
}

export const servicesContactsValidate:ServicesContactsValidateType = {
  telegram: 'phone_username', telegram_bot: 'username', waba: 'phone', whatsapp: 'phone'
}

export const examplesContactsForm: Record<SelectedServiceContactType, JSX.Element> = {
  phone: <div>
    <div>+79234567890</div>
    <div>+79236567890;Анна</div>
  </div>,
  username: <div>
    <div>@testtesttest1123</div>
    <div>@testtesttest112345;Анна</div>
  </div>,
  phone_username: <div>
    <div>@testtesttest1123</div>
    <div>+79234567890;Анна</div>
    <div>+79854567890</div>
  </div>
};
