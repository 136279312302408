import React, {useEffect} from 'react';
import './LifetimePayment.scss';
import * as yup from "yup";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { getLifetime, setLifetime } from "./utils";
import LifetimeInput from "./LifetimeInput";


type Props = {
  name: string;
  lifetime: number;
}

const LifetimePayment = ({ name, lifetime }: Props) => {
  const { t } = useTranslation();
  const lifetimeParams = getLifetime(lifetime);

  const { formState, setValue } = useFormContext();

  const schema = yup.object().shape({
    days: yup.number().min(0).max(30),
    hours: yup.number().min(0).max(23),
    minutes: yup.number().min(0).max(59),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { days: lifetimeParams.days, hours: lifetimeParams.hours, minutes: lifetimeParams.minutes },
    resolver: yupResolver(schema),
  });

  const daysWatch = methods.watch('days');
  const hoursWatch = methods.watch('hours');
  const minutesWatch = methods.watch('minutes');

  useEffect(() => {
    const days = Number(methods.getValues('days'));
    const hours = Number(methods.getValues('hours'));
    const minutes = Number(methods.getValues('minutes'));

    if (lifetime !== setLifetime(days, hours, minutes)) {
      setValue(name, setLifetime(days, hours, minutes), { shouldDirty: true });
    }
  }, [daysWatch, hoursWatch, minutesWatch]);

  return (
    <div className="lifespanPayment">
      <div className="lifespanPayment__header">
        <h3>{t('connections.lifetime.title')}</h3>
        <p className="smallText">{t('connections.lifetime.description')}</p>
      </div>
      <FormProvider {...methods}>
        <div className="lifespanPayment__content">
          <LifetimeInput name="days" label={t('connections.lifetime.days')} max={30} value={lifetimeParams.days} />
          <LifetimeInput name="hours" label={t('connections.lifetime.hours')} max={23} value={lifetimeParams.hours} />
          <LifetimeInput name="minutes" label={t('connections.lifetime.minutes')} max={59} value={lifetimeParams.minutes} />
        </div>
      </FormProvider>
      <ErrorMessage
        errors={formState.errors || methods.formState.errors}
        name={name}
        render={({ message }) => <p className="lifespanPayment__errorMessage">{message}</p>}
      />
    </div>
  );
};

export default LifetimePayment;
