import React from 'react';
import { useSelector } from 'react-redux';
import { tagsSelector } from '../../../../Settings/tagsSelector';
import './TagItemName.scss';


type TagItemNameProps = {
  value: number;
};

function TagItemName({ value }: TagItemNameProps) {
  const items = useSelector(tagsSelector);
  const tag = items?.find((item) => item.id === value);

  if (tag) {
    return (
      <div className='conditionItem__name' style={{ backgroundColor: tag.backgroundColor }}>
        {tag.name}
      </div>
    );
  }

  return (<div className='conditionItem__name'>{value}</div>);
}

export default TagItemName;
