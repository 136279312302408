import React, { FC } from 'react';
import './ProductItemEdit.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { paymentArr, taxArr, taxBepaidArr, typeArr } from '../../../../../../utils/payments';
import Button from '../../../../../../components/Button/Button';
import { Terminal } from '../../../../../../api/BillAPI';
import Dropdown from '../../../../../Settings/Dropdown/Dropdown';
import DeleteIcon from '../../../../../../assets/deleteIcon.svg';
import PlusIcon from '../../../../../../assets/plusOrange.svg';
import { ProductsSelector } from "../../../../../Sales/SalesSelector";
import { Product, ProductsList } from "../../../../../../api/SalesAPI";
import { currencyFormat } from "../../../../../Settings/CompanySettings/currency";
import { currencyCodeToSymbol } from "../helpers";


type Props = {
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  watchCurrencyTerminal: Terminal;
};

const ProductItemEdit: FC<Props> = ({ setPrice, watchCurrencyTerminal }) => {
  const {
    control,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const products = useFieldArray({ control, name: 'receipt.items' });
  const watchCurrency = watch('currency');
  const productsForOrder = useSelector(ProductsSelector);
  const { t, i18n } = useTranslation();

  const addNewProduct = () => {
    products.append({
      name: '',
      quantity: undefined,
      price: undefined,
      vat: 'none',
      type: 'another',
      payment_type: 'full_prepayment',
      tax_name: 'НДС',
      tax_value: null,
    });
  };

  const onSelect = (product: Product, index: number) => {
    products.update(index, {
      name: product.name,
      quantity: 1,
      price: product.prices[0].price,
      vat: product.vat,
      type: product.paymentObjectType,
      payment_type: 'full_prepayment',
      tax_name: 'НДС',
      tax_value: null,
    });
    clearErrors();
  };

  const getAmount = (index: number) => {
    if (Number(getValues(`receipt.items.${index}.quantity`)) && Number(getValues(`receipt.items.${index}.price`))) {
      return Math.round((Number(getValues(`receipt.items.${index}.quantity`)) * Number(getValues(`receipt.items.${index}.price`))) * 100) / 100
    }
    return 0;
  }

  const getListProductsForOrder = (productsOrder: ProductsList, index: number) => {
    if (productsOrder) {
      const watchName = watch(`receipt.items.${index}.name`);
      const filterProduct = productsOrder.items.filter((product) => product.name.includes(watchName));
      return (
        <>
          {filterProduct && filterProduct.length >= 1 && (
            <div className='contentList'>
              {filterProduct.map((product) => (
                <div className='contentList_item'
                     onClick={() => onSelect(product, index)}
                     key={product.id}>{product.name}</div>
              ))}
            </div>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <div className='chatBillContainer__content__productContainer'>
      {products.fields.map(({ id }, index: number) => (
        <div key={id} className='chatBillContainer__content__productContainer_content'>
          <div className='chatBillContainer__content__productContainer_content_title'>
            <h5>{t('chats.bill.product.product_title')} {index + 1}.</h5>
            {products.fields.length === 1 ? null :
              (<p onClick={() => products.remove(index)}>
                <img src={DeleteIcon} alt='delete icon' />
                {t('delete')}
              </p>)}
          </div>
          <Controller name={`receipt.items.${index}.name`} control={control}
                      render={({ field: { value, onChange } }) => (
                        <div className="inputContainer" style={{ width: '60%' }}>
                          <p className="inputContainer__label">{t('chats.bill.product.name')}</p>
                          <div className="inputContainer__wrapper">
                            <input className="inputContainer__input name" value={value}
                                   onChange={(event) => onChange(event)} />
                            {getListProductsForOrder(productsForOrder!, index)}
                          </div>
                          <ErrorMessage
                            errors={errors}
                            name={`receipt.items.${index}.name`}
                            render={({ message }) =>
                              <p
                                className="chatBillContainer__content__productContainer_content_errorName">{message}</p>}
                          />
                        </div>
                      )} />
          <div
            className='chatBillContainer__content__productContainer_content_inputs'>
            <Controller name={`receipt.items.${index}.type`} control={control} render={() => (
              <Dropdown
                items={typeArr}
                label={t('chats.bill.product.type')}
                name={`receipt.items.${index}.type`}
                labelKey="name"
                valueKey="id"
                style={{ width: '60%' }}
              />
            )} />
            <Controller name={`receipt.items.${index}.vat`} control={control} render={() => (
              <Dropdown
                items={watchCurrencyTerminal.type === 'bepaid' ? taxBepaidArr : taxArr}
                label={t('chats.bill.product.vat')}
                name={`receipt.items.${index}.vat`}
                labelKey="name"
                valueKey="id"
                style={{ width: '37%' }}
              />
            )} />
          </div>
          <div
            className='chatBillContainer__content__productContainer_content_inputs'>
            <Controller name={`receipt.items.${index}.payment_type`} control={control} render={() => (
              <Dropdown
                items={paymentArr}
                label={t('chats.bill.product.payment')}
                name={`receipt.items.${index}.payment_type`}
                labelKey="name"
                valueKey="id"
                style={{ width: '40%' }}
              />
            )} />
            <Controller name={`receipt.items.${index}.quantity`} control={control}
                        render={({ field: { value, onChange } }) => (
                          <div className="inputContainer" style={{ width: '26%' }}>
                            <p className="inputContainer__label">{t('chats.bill.product.quantity')}</p>
                            <div className="inputContainer__wrapper">
                              <input
                                className="inputContainer__input"
                                value={value}
                                type='number'
                                placeholder='0'
                                onWheel={(e) => e.currentTarget.blur()}
                                onChange={(event) => {
                                  if (event.target.value.slice(0, 1) === '0') {
                                    onChange(event);
                                    if (Number(event.target.value) / 10 && event.target.value.length >= 3) {
                                      onChange(event);
                                    } else if (event.target.value.length > 1) {
                                      onChange(event.target.value.slice(1));
                                    }
                                    setPrice(Number(event.target.value.slice(1)));
                                  } else {
                                    onChange(event);
                                    setPrice(Number(event.target.value));
                                  }
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name={`receipt.items.${index}.quantity`}
                                render={({ message }) =>
                                  <p
                                    className="chatBillContainer__content__productContainer_content_error">{message}</p>}
                              />
                            </div>
                          </div>
                        )} />
            <Controller name={`receipt.items.${index}.price`} control={control}
                        render={({ field: { value, onChange } }) => (
                          <div className="inputContainer" style={{ width: '28%' }}>
                            <p className="inputContainer__label">{t('chats.bill.product.price')}</p>
                            <div className="inputContainer__wrapper">
                              <input
                                className="inputContainer__input"
                                value={value}
                                type='number'
                                placeholder='0'
                                onWheel={(e) => e.currentTarget.blur()}
                                onChange={(event) => {
                                  if (event.target.value.slice(0, 1) === '0') {
                                    onChange(event);
                                    if (Number(event.target.value) / 10 && event.target.value.length >= 3) {
                                      onChange(event);
                                    } else if (event.target.value.length > 1) {
                                      onChange(event.target.value.slice(1));
                                    }
                                    setPrice(Number(event.target.value.slice(1)));
                                  } else {
                                    onChange(event);
                                    setPrice(Number(event.target.value));
                                  }
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name={`receipt.items.${index}.price`}
                                render={({ message }) =>
                                  <p
                                    className="chatBillContainer__content__productContainer_content_error">{message}</p>}
                              />
                            </div>
                          </div>
                        )} />
          </div>
          <h4>{t('chats.bill.product.summary')}: {currencyFormat(getAmount(index), currencyCodeToSymbol[watchCurrency], i18n.language)}</h4>
        </div>
      ))}
      <Button text={t('chats.bill.add_product')} style={{ padding: '6px 12px 6px 8px', marginTop: '48px' }} type='button'
              onClick={addNewProduct} image={PlusIcon} color='white' textType='small' />
    </div>
  );
};

export default ProductItemEdit;
