import { useEffect, useState } from 'react';
import './TelegramCreate.scss';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import * as yup from 'yup';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  authTelegramSelector,
  currentCompanySelector,
  errorConnectionSelector
} from '../../../../../../Chat/companiesSelector';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/store';
import {
  authLoginTelegramConnection, authTelegramConnection, loginTelegramConnection,
  setClearAuthTelegram, setClearErrorConnection, setHadConnections
} from '../../../../../../Chat/companiesSlice';
import { createConnection } from '../../../../connectionsSlice';
import { TimerDisabled } from '../timer/TimerDisabled';
import { addAlertWithCustomText } from '../../../../../../../components/Alert/alertSlice';
import { TelegramConnection } from '../../../../../../../api/CompanyAPI';
import { usePhoneSchema } from '../../../../../../../components/PhoneInput/utils';
import { YandexMetrikaCall } from '../../../../../../../utils/utils';
import Button from '../../../../../../../components/Button/Button';
import Input from '../../../../../../../components/Input';
import GoBackWrapper from '../../../../../../../components/GoBackWrapper';
import PhoneField from '../../../../../../../components/PhoneInput/PhoneField';
import { ReactComponent as NotificationIcon } from '../../../../../../../assets/info.svg';


type SubmitSchema = {
  name: string;
  phone: string;
  code: string;
  password: string;
};

type LocationState = {
  name: string;
  phone: string
  connectionId: string;
} | undefined;

const CODE_INTERVAL = 60;  // wait time interval between send code requests in seconds

const TelegramCreate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation<LocationState>();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { state } = location;

  const errorConnection = useSelector(errorConnectionSelector);
  const authTelegram = useSelector(authTelegramSelector);
  const connectedNumber =
    useAppSelector((store) => store.connections.connectionsStore.telegram) as Array<TelegramConnection>;

  const [timer, setTimer] = useState<number>(0);
  const [disabled, setDisabled] = useState(false);
  const [isCodeSuccess, setIsCodeSuccess] = useState(false);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);

  const phoneSchema = usePhoneSchema({ required: true, nonGeo: true });

  const schema = yup.object().shape({
    ...{
      name: yup.string().required(t('error.form.empty_field')),
      code: yup.string().when({
        is: (code: string) => code.length > 0,
        then: yup.string().min(5, t('error.form.empty_field')),
      }),
      password: yup.string().when({
        is: (password: string) => password.length > 0,
        then: yup.string().min(1, t('error.form.empty_field')),
      })
    }, ...phoneSchema
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      name: state?.name || '',
      phone: state?.phone || '',
      code: '',
      password: '',
    },
  });

  const activeTimer = () => setTimer(CODE_INTERVAL);

  useEffect(() => {
    // clear wait code timer
    if (disabled) setTimer(0);
  }, [disabled]);

  const getCodeAgain = () => {
    if (disabled) {
      setTimer(CODE_INTERVAL);
      dispatch(authTelegramConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        phone: methods.getValues('phone'),
        force_sms: false,
      })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          activeTimer();
        }
        if (res.meta.requestStatus === 'rejected') {
          setIsLoginError(true);
        }
      });
    }
  };

  const watchPassword = methods.watch('password');
  const watchCode = methods.watch('code');

  const nextDisabled =() => {
    if (isLoginError) {
      dispatch(setClearAuthTelegram());
      setIsLoginError(false);
      return false;
    }
    if (errorConnection === 17003) {
      return watchPassword.length > 1;
    }
    if (authTelegram) {
      return watchCode.length === 5;
    }
    return methods.formState.isDirty;
  }

// проверяем если такой номер существует , то ошибка
  const isPhoneConnected = connectedNumber?.find(({ params: { phone } }) => methods.getValues('phone') === phone);

  const onSubmit = (data: SubmitSchema) => {
    if (currentCompanyId?.params.companyId){
      // реавторизация
      if (state?.phone){
        if (authTelegram && isCodeSuccess && errorConnection === 17003 ) {
          if (data.password.length === 0 && isCodeSuccess) {
            methods.setError('password', {
              type: 'min',
              message: t('error.form.empty_field'),
            });
          } else {
            dispatch(loginTelegramConnection({
              companyId: Number(currentCompanyId.params.companyId),
              connection_id: Number(state.connectionId),
                code: Number(data.code),
                phone_code_hash: authTelegram.phoneCodeHash,
                password: data.password,
            })).then(res => {
              if (res.meta.requestStatus === 'fulfilled') {
                if (currentCompany.hadConnections === false) {
                  YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
                  dispatch(setHadConnections(true));
                }
                dispatch(addAlertWithCustomText({
                  message: t('settings.integrations.telegram.successAddedNumber'),
                  color: '#2CBA5F',
                }));
                if (currentCompanyId.url) dispatch(push(`${currentCompanyId.url}/settings/connections/telegram`))
                dispatch(setClearErrorConnection(null));
                dispatch(setClearAuthTelegram());
              }

              if (res.meta.requestStatus === 'rejected') {
                dispatch(setClearErrorConnection(17003));
              }
            });
          }
        }

        if (authTelegram && !isCodeSuccess) {
          dispatch(loginTelegramConnection({
            companyId: Number(currentCompanyId.params.companyId),
            connection_id: Number(state.connectionId),
            code: Number(data.code),
            phone_code_hash: authTelegram.phoneCodeHash,
          })).then(res => {
            if (res.meta.requestStatus === 'fulfilled') {
              methods.reset(data);
              dispatch(setClearAuthTelegram());
              if (currentCompanyId.url) dispatch(push(`${currentCompanyId.url}/settings/connections/telegram`));
              dispatch(addAlertWithCustomText({
                message: t('settings.integrations.telegram.successAddedNumber'),
                color: '#2CBA5F',
              }));
            }
            if (res.meta.requestStatus === 'rejected') {
              switch (res.payload) {
                case 17003:
                  methods.reset(data);
                  setIsCodeSuccess(true);
                break;
                case 17004:
                  setIsLoginError(true);
                  break;
              }
            }
          });
        }
      }
      // новое подключения
      if (!state?.phone) {
        if (isPhoneConnected) {
          methods.setError('phone', {
            type: 'min',
            message: t('settings.integrations.telegram.isPhoneConnected'),
          });
        } else {
          if (authTelegram && isCodeSuccess && errorConnection === 17003 ) {
            // запрос с паролям
            if (data.password.length === 0 && isCodeSuccess) {
              methods.setError('password', {
                type: 'min',
                message: t('error.form.empty_field'),
              });
            } else {
              dispatch(createConnection({
                companyId: Number(currentCompanyId.params.companyId),
                type: 'telegram',
                name: data.name,
                params: {
                  code: Number(data.code),
                  phone: data.phone,
                  session_id: authTelegram.sessionId,
                  phone_code_hash: authTelegram.phoneCodeHash,
                  password: data.password,
                },
              })).then(res => {
                if (res.meta.requestStatus === 'fulfilled') {
                  dispatch(setClearErrorConnection(null));
                  dispatch(setClearAuthTelegram());
                  dispatch(push(`${currentCompanyId.url}/settings/connections/telegram`));
                  dispatch(addAlertWithCustomText({
                    message: t('settings.integrations.telegram.successAddedNumber'),
                    color: '#2CBA5F',
                  }));
                }

                if (res.meta.requestStatus === 'rejected') {
                  dispatch(setClearErrorConnection(17003));
                }
              });
            }
          }
          // запрос с кодом
          if (authTelegram && !isCodeSuccess) {
            dispatch(createConnection({
              companyId: Number(currentCompanyId.params.companyId),
              type: 'telegram',
              name: data.name,
              params: {
                code: Number(data.code),
                phone: data.phone,
                session_id: authTelegram.sessionId,
                phone_code_hash: authTelegram.phoneCodeHash,
              },
            })).then(res => {
              if (res.meta.requestStatus === 'fulfilled') {
                methods.reset(data);
                dispatch(setClearAuthTelegram());
                if (currentCompanyId.url) dispatch(push(`${currentCompanyId.url}/settings/connections/telegram`));
                dispatch(addAlertWithCustomText({
                  message: t('settings.integrations.telegram.successAddedNumber'),
                  color: '#2CBA5F',
                }));
              }
              if (res.meta.requestStatus === 'rejected') {
                if (res.payload === 17003) {
                  methods.reset(data);
                  setIsCodeSuccess(true);
                }
              }
            });
          }

          // запрос за кодом
          if (!authTelegram && !errorConnection && data.phone) {
            dispatch(authTelegramConnection({
              companyId:Number(currentCompanyId?.params.companyId),
              phone: data.phone,
              force_sms: false,
            })).then(res => {
              if (res.meta.requestStatus === 'fulfilled') {
                methods.reset((data));
                activeTimer();
              }
              if (res.meta.requestStatus === 'rejected') {
                setIsLoginError(true);
              }
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    const data = methods.getValues();
    if (state?.phone && state.connectionId && !authTelegram) {
      dispatch(authLoginTelegramConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        phone: state?.phone,
        connection_id: Number(state.connectionId),
        force_sms: false,
      })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          methods.reset(data);
          activeTimer();
        }
      });
    }
  }, []);

  useEffect(() => () => {
    dispatch(setClearErrorConnection(null));
    dispatch(setClearAuthTelegram());
  }, []);

  const onRepaidCode = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    getCodeAgain();
  };

  return (
    <GoBackWrapper title={state?.phone
      ? t('settings.integrations.telegram.ConnectNumberTelegram')
      : t('settings.integrations.telegram.AddNumberTelegram')
    }>
      <div className='TelegramCreateConnectionView'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input name='name' label={t('connections.connection_name')} />
            <div className='TelegramCreateConnectionView__phone'>
              <div className='TelegramCreateConnectionView__phone_lable'>
                <p>{t('contacts.contactsList.phone')}</p>
                <span className='info__icon'><NotificationIcon /></span>
                <div className='info__message'>{t('settings.integrations.telegram.textInfo1')}</div>
              </div>
              <PhoneField name='phone' />
            </div>
            {authTelegram &&
              <div className='TelegramCreateConnectionView__blockCode'>
                {!isCodeSuccess && <>
                  <p>{t('settings.integrations.telegram.sentCode')}</p>
                  <p>
                    <TimerDisabled timeLimit={timer} setDisabled={setDisabled}
                      customText={t('settings.integrations.telegram.repaidCodeTimer')}/>
                  </p>
                </>}
                <div className='TelegramCreateConnectionView__blockCode__code'>
                  <Input name='code' label={t('settings.integrations.telegram.code')} />
                  {!isCodeSuccess &&
                    <Button type='button' color='white' textType='regular'
                      text={t('settings.integrations.telegram.repaidCode')} disabled= {!disabled} onClick={onRepaidCode}
                  />}
                </div>
              </div>
            }
            {errorConnection && errorConnection === 17003 &&
            <div className='TelegramCreateConnectionView__blockPassword'>
              <p>{t('settings.integrations.telegram.2FA')}</p>
              <Input name='password' label={t('settings.integrations.telegram.password')} />
            </div>
            }
            <Button type='submit' color='orange' textType='regular' disabled={!nextDisabled()}
              text={state?.phone
                ? t('settings.integrations.telegram.connectNumber')
                : t('settings.integrations.telegram.continue')}/>
          </form>
        </FormProvider>
      </div>
    </GoBackWrapper>
  );
};

export default TelegramCreate;
