import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../../../store/store';
import { InvoicesSelector, MoreInvoicesSelector } from '../../SubscriptionsSelector';
import { fetchSubscriptionInvoice } from '../../SubscriptionsSlice';
import InvoicesList from '../InvoicesList';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow.svg';
import './SubscriptionsInvoices.scss';


const SubscriptionsInvoices = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const invoices = useSelector(InvoicesSelector);
  const hasMoreInvoices = useSelector(MoreInvoicesSelector);

  const getMore = () => {
    dispatch(fetchSubscriptionInvoice({
      companyId: Number(params?.params.companyId),
      offset: invoices?.length }))
  };

if (invoices && invoices.length > 0 ) {
  return (
    <>
      <div className="bills__title">{t('subscriptions.bills.title')}</div>
      <table className="bills__list">
        <thead >
          <tr className="bills__list_header">
            <th>{t('subscriptions.bills.number')}</th>
            <th>{t('subscriptions.bills.amount')}</th>
            <th>{t('subscriptions.bills.status')}</th>
          </tr>
        </thead>
        <tbody><InvoicesList /></tbody>
      </table>
      {hasMoreInvoices &&
        <div className="showMore" onClick={getMore}>{t('subscriptions.bills.show_more')}<ArrowIcon /></div>}
    </>
  );
} 

return null;

};

export default SubscriptionsInvoices;
