import React, {useEffect, useRef} from 'react';
import {BaseMessageComponent} from './types';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';
import MessageWabaProduct from './MessageWabaProduct';


const MessageTextType: BaseMessageComponent = ({message, handleSizeChange}) => {
  const textElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textElementRef.current && handleSizeChange) {
      handleSizeChange(textElementRef.current.offsetHeight);
    }
  }, [message, handleSizeChange, textElementRef.current]);

  return message.text.wabaReferredProduct ? <MessageWabaProduct type='text' text={message.text.text} message={message.text.wabaReferredProduct}/> :
    <div className="textContent" ref={textElementRef}>
      <LinkifyText text={message.text.text} />
    </div>
};

export default MessageTextType;
