import React, { useEffect, useRef, useState } from 'react';
import './DropdownTemplate.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TemplatesIcon } from '../../../../../assets/templates-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow.svg';
import { CustomTemplate, WabaTemplate } from '../../../../../api/types';
import { useMobile } from '../../../../../components/utils/hooks';
import PopupWrapper from '../../../../../components/PopupWrapper/PopupWrapper';
import usePopupWrapper from '../../../../../components/PopupWrapper/usePopupWrapper';
import WabaTemplateComponent from '../WabaTemplateComponent/WabaTemplateComponent';
import InteractiveTemplateComponent from '../InteractiveTemplateComponent/InteractiveTemplateComponent';
import Templates from '../Templates';


type Props = {
  subscriptionIsBan: boolean;
  inputFileRef: React.RefObject<HTMLInputElement>;
  textAreaIsBlocked: boolean;
  setSelectedCustomTemplate: React.Dispatch<React.SetStateAction<CustomTemplate | undefined>>;
  setAttachedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
}

const DropdownTemplate = ({ subscriptionIsBan, inputFileRef, textAreaIsBlocked, setSelectedCustomTemplate, setAttachedFile, setText }: Props) => {
  const { t } = useTranslation();
  const { isVisible, setIsVisible, onTriggerPopupWrapper, onClosePopupWrapper } = usePopupWrapper();
  const triggerButtonRef = useRef<HTMLDivElement>(null);
  const [selectedWabaTemplate, setSelectedWabaTemplate] = useState<WabaTemplate | null>();
  const [selectedInteractiveTemplate, setSelectedInteractiveTemplate] = useState<CustomTemplate>();
  const [activeTab, setActiveTab] = useState('whatsApp');
  const [searchedValueOfTemplate, setSearchedValueOfTemplate] = useState<string>('');
  const isMobile = useMobile();

  useEffect(() => {
    setAttachedFile(undefined);
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
    setSelectedWabaTemplate(null);
    setSelectedInteractiveTemplate(undefined);
  }, [isVisible]);

  const getDropdownContent = () => {
    if (selectedWabaTemplate) {
      return (
        <WabaTemplateComponent
          template={selectedWabaTemplate}
          setSelectedTemplate={setSelectedWabaTemplate}
          onCloseDropdown={onClosePopupWrapper}
        />
      );
    }
    if (selectedInteractiveTemplate) {
      return (
        <InteractiveTemplateComponent
          template={selectedInteractiveTemplate}
          setSelectedTemplate={setSelectedInteractiveTemplate}
          onCloseDropdown={onClosePopupWrapper}
        />
      );
    }
    return (
      <Templates
        setActiveTab={setActiveTab}
        textAreaIsBlocked={textAreaIsBlocked}
        setSelectedWabaTemplate={setSelectedWabaTemplate}
        setSelectedCustomTemplate={setSelectedCustomTemplate}
        setSelectedInteractiveTemplate={setSelectedInteractiveTemplate}
        activeTab={activeTab}
        onSelectTab={onSelectTab}
        setText={setText}
        onCloseDropdown={onClosePopupWrapper}
        setSearchedValueOfTemplate={setSearchedValueOfTemplate}
        searchedValueOfTemplate={searchedValueOfTemplate}
      />
    );
  };

  const onSelectTab = (e: React.MouseEvent<HTMLDivElement>) => {
    setActiveTab(e.currentTarget.id);
  };

  const getDropdownTitle = () => {
    if (selectedWabaTemplate) {
      return selectedWabaTemplate.name;
    }
    if (selectedInteractiveTemplate) {
      return selectedInteractiveTemplate.name;
    }
    return t('chats.templates.title');
  };

  return (
    <div className={`dropdownTemplate ${subscriptionIsBan ? 'blocked' : ''}`}
         onClick={subscriptionIsBan ? () => {} : onTriggerPopupWrapper}
         ref={triggerButtonRef}>
      <TemplatesIcon className={`${subscriptionIsBan && 'blocked'}`} />
      {!isMobile && <span>{t('chats.chat.templates')}</span>}
      <ArrowIcon className={`${subscriptionIsBan ? 'blocked' : ''}`}
                 style={{
                   transform: isVisible ? 'rotateX(180deg)' : 'inherit',
                   transition: 'transform 0.2s',
                 }} />
      <PopupWrapper
        direction='top'
        title={getDropdownTitle()}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        onClosePopupWrapper={onClosePopupWrapper}
        triggerButton={triggerButtonRef}>
        {getDropdownContent()}
      </PopupWrapper>
    </div>
  );
};

export default DropdownTemplate;
