import React from 'react';
import { BroadcastsServicesType, servicesIcons } from '../../utils';
import './ItemConnection.scss';


type ItemConnectionProps = {
  service: BroadcastsServicesType;
  name?: string;
};

const getIcon = (service: BroadcastsServicesType) => {
  const Icon = servicesIcons[service];
  if (Icon) return <Icon />;
  return null;
};

function ItemConnection({ service, name }: ItemConnectionProps) {
  return (
    <div className='itemConnection' data-info={name}>
      <div className='itemConnection__icon'>{getIcon(service)}</div><p>{name}</p>
    </div>
  );
}

export default React.memo(ItemConnection);
