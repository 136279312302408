import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../store/store";
import { currentCompanySelector } from "../../../Chat/companiesSelector";
import { setWhatsappStatusGood, updateConnectionStatus } from "../connectionsSlice";
import { ConnectionStatusType, ConnectionsType, connectionTypes } from "../ConnectionsTypes";
import { NewConnection } from "../../../../api/CompanyAPI";
import { ReactComponent as WhatsAppIcon } from '../../../../assets/whatsApp-connectionLogo.svg';
import { ReactComponent as WhatsAppBigIcon } from '../../../../assets/logo-whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/TelegramIcon.svg';
import { ReactComponent as TelegramBot } from '../../../../assets/icon-telegram-bot.svg';
import { ReactComponent as TelegramBigIcon } from '../../../../assets/logo-telegram.svg';
import { ReactComponent as WhatsAppBusinessIcon  } from '../../../../assets/whatsAppBusinessIcon.svg';
import { ReactComponent as WhatsAppBusinessBigIcon } from '../../../../assets/logo-whatsapp-buiness.svg';
import { ReactComponent as OdnoklassnikiIcon } from '../../../../assets/odnoklassnikiIcon.svg';
import { ReactComponent as OdnoklassnikiBigIcon } from '../../../../assets/logo-odnoklassniki.svg';
import { ReactComponent as bePaidIcon } from '../../../../assets/logo-bePaid.svg';
import { ReactComponent as TinkoffIcon } from '../../../../assets/tinkoffNameIcon.svg';
import { ReactComponent as TinkoffBigIcon } from '../../../../assets/logo-tinkoff.svg';
import { ReactComponent as SberIcon } from '../../../../assets/logo-sber.svg';
import { ReactComponent as SberBigIcon } from '../../../../assets/sberbank-logo.svg';
import { ReactComponent as ModulbankIcon } from '../../../../assets/ModulBankIcon.svg';
import { ReactComponent as ModulbankBigIcon } from '../../../../assets/modulbank-logo.svg';
import { ReactComponent as PayKeeperIcon } from '../../../../assets/logo-paykeeper.svg';
import { ReactComponent as PayKeeperBigIcon } from '../../../../assets/paykeeper-logo.svg';
import { ReactComponent as YookassaIcon } from '../../../../assets/logo-yookassa.svg';
import { ReactComponent as YookassaBigIcon } from '../../../../assets/logo-yookassa-b.svg';


export type ConnectionServiceTypes = 'mobile' | 'social' | 'banking' | 'bot';

export type ConnectionServiceType = {
  type: ConnectionServiceTypes;
  url: string;
  ComponentIcon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  bigIcon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
  bigIconSize: React.CSSProperties;
  name: string;
  background: string;
};

export type ConnectionButtonsType = Partial<Record<ConnectionsType, ConnectionServiceType>>;

export const connectionsComposeMap = Object.fromEntries(connectionTypes.map((item) => [item, `connections_${item}`]));

export const settingConnections: ConnectionButtonsType = {
  'waba': {
    type: 'mobile',
    url:'waba',
    ComponentIcon: WhatsAppBusinessIcon,
    bigIcon: WhatsAppBusinessBigIcon,
    bigIconSize: { width: 212, height: 212 },
    name:'connections.title.waba',
    background: '#075E54' },
  'whatsapp': {
    type: 'mobile',
    url:'whatsapp',
    ComponentIcon: WhatsAppIcon,
    bigIcon: WhatsAppBigIcon,
    bigIconSize: { width: 212, height: 212 },
    name: 'connections.title.whatsapp',
    background: '#25D366' },
  'telegram': {
    type: 'mobile',
    url:'telegram',
    ComponentIcon: TelegramIcon,
    bigIcon: TelegramBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.telegram',
    background: '#59A3D6' },
  'telegram_bot': {
    type: 'bot',
    url:'telegram_bot',
    ComponentIcon: TelegramBot,
    bigIcon: TelegramBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.telegram_bot',
    background: '#F2F2F2' },
  'odnoklassniki': {
    type: 'social',
    url: 'odnoklassniki',
    ComponentIcon: OdnoklassnikiIcon,
    bigIcon: OdnoklassnikiBigIcon,
    bigIconSize: { width: 214, height: 212 },
    name: 'connections.title.odnoklassniki',
    background: '#F7931E' },
  'bepaid': {
    type: 'banking',
    url: 'bePaid',
    ComponentIcon: bePaidIcon,
    bigIcon: bePaidIcon,
    bigIconSize: { width: 369, height: 90 },
    name: 'connections.title.bePaid',
    background: '#F2F2F2' },
  'tinkoff': {
    type: 'banking',
    url: 'tinkoff',
    ComponentIcon: TinkoffIcon,
    bigIcon: TinkoffBigIcon,
    bigIconSize: { width: 217.5, height: 191.61 },
    name: 'connections.title.tinkoff',
    background: '#FFDD2D' },
  'sberbank': {
    type: 'banking',
    url: 'sber',
    ComponentIcon: SberIcon,
    bigIcon: SberBigIcon,
    bigIconSize: { width: 165, height: 165 },
    name: 'connections.title.sber',
    background: '#F5F4F3' },
  'modulbank': {
    type: 'banking',
    url: 'modulbank',
    ComponentIcon: ModulbankIcon,
    bigIcon: ModulbankBigIcon,
    bigIconSize: { width: 176.07, height: 176.07 },
    name: 'connections.title.modulbank',
    background: '#0F1A7C' },
  'paykeeper': {
    type: 'banking',
    url: 'paykeeper',
    ComponentIcon: PayKeeperIcon,
    bigIcon: PayKeeperBigIcon,
    bigIconSize: { width: 186.43, height: 124.29 },
    name: 'connections.title.paykeeper',
    background: '#CC1626' },
  'yookassa': {
    type: 'banking',
    url: 'yookassa',
    ComponentIcon: YookassaIcon,
    bigIcon: YookassaBigIcon,
    bigIconSize: { width: 214, height: 214 },
    name: 'connections.title.yookassa',
    background: '#F5F4F3' }
};

export const getServiceNameByUrl = (serviceUrl: string | undefined) => {
  if (serviceUrl) {
    const filterArray = Object.entries(settingConnections).find(([_, value]) => value.url === serviceUrl);
    if (filterArray && filterArray[0]) return filterArray[0] as ConnectionsType;
  }
  return null;
};

export const useConnectionOuterLink = ( connection: NewConnection): { link: string, href: string } => {
  let link: string = '';
  let href: string = '';
  switch (connection.type) {
    case 'odnoklassniki':
      href = `https://ok.ru/group/${connection.params.groupId.replace(/[^0-9]/g, '')}`;
      link = href;
      return { link, href };
    case 'telegram_bot':
      href = `https://t.me/${connection.params.botUsername}`;
      link = `@${connection.params.botUsername}`;
      return { link, href };
    default:
      return { link, href };
  }
};

export type ConnectionItemContextType = {
  connection: NewConnection;
  onEdit?: (connection: NewConnection) => void;
  onDelete?: (connection: NewConnection) => void;
  onPreview?: (connection: NewConnection) => void;
};

export const connectionItemContext = createContext<ConnectionItemContextType>({ connection: {} as NewConnection });

export type ConnectionsUploadContextType = {
  hasMore: boolean;
  onUpload: () => void;
  service: ConnectionsType;
};

export const connectionsUploadContext = createContext<ConnectionsUploadContextType>(
  { service: '' as ConnectionsType, hasMore: false, onUpload: () => { } }
);

export const useConnectionStatus = () => {
  const dispatch = useDispatch();
  const { connection } = useContext(connectionItemContext);
  const company = useSelector(currentCompanySelector);
  const connectionStatuses = useAppSelector((state) => state.connections.whatsappStatus);
  const [status, setStatus] = useState<ConnectionStatusType>();

  useEffect(() => {
    /* const getStatus = async () => {
      const connectionStatus = await getConnectionStatus({
        connectionId: connection.id,
        companyId: Number(currentCompanyId?.params.companyId)
      });
      setStatus(connectionStatus);
    }; */
    if (company.id && !status) {
      if (connection.lastStatus?.statusType === 'bad') {
        dispatch(updateConnectionStatus({
          connectionId: connection.id,
          companyId: company.id
        }));
      } else if (connection.lastStatus?.statusType === 'good') {
        dispatch(setWhatsappStatusGood(connection.id));
      }
    }
  }, [company]);

  useEffect(() => {
    if (connectionStatuses) {
      setStatus(connectionStatuses[connection.id]);
    }
  }, [connectionStatuses]);

  return status;
};

export const transformEmptyStringToUndefined = (value: string) => {
  if (value === '') {
    return undefined;
  }
  return value;
};

export const useHasConnections = () => {
  const currentCompany = useSelector(currentCompanySelector);
  const connections = useAppSelector((state) => state.connections.connectionsStore);
  const countConnectionTypes = connectionTypes.length;

  let total = 0;

  for (let i = 0; i < countConnectionTypes; i++) {
    const type = connectionTypes[i];
    connections[type].forEach(() => {
      if (total === 0) {
        total++;
      }
    });
    if (total > 0) break;
  }

  return currentCompany.hadConnections === true && total > 0;
};
