import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { getIntegrationsList, getIntegrationsTypes } from './integrationSlice';
import { integrationsListSelector, integrationsTypesSelector } from './integrationSelectors';
import { WrapperPermission } from '../../../utils/WrapperPermission/WrapperPermission';


const IntegrationsList = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const matchRoute = useRouteMatch();
  const currentCompanyId = useRouteMatch<{ companyId: string; }>('/companies/:companyId');
  const integrationsTypesArr = useSelector(integrationsTypesSelector);
  const integrationsArr = useSelector(integrationsListSelector);
  const { t } = useTranslation();

  useEffect(() => {
    if (currentCompanyId?.params.companyId) {
      dispatch(getIntegrationsList(Number(currentCompanyId.params.companyId)));
      dispatch(getIntegrationsTypes({
        companyId: Number(currentCompanyId.params.companyId),
        language: i18n.language
      }));
    }

  }, [currentCompanyId?.params.companyId]);

  const onIntegration = (e: React.MouseEvent<HTMLDivElement>, name: string) => {
    const path = name === 'api' ? '/setup/' : '/description/';
    history.push(`${matchRoute.url}${path}${name}`);
  };

  const onIntegrationClick = (id: number) => dispatch(push(`${matchRoute.url}/${id}`));

  const listIntegrationsTypes = () =>
    integrationsTypesArr.map((item) => (
      <div className="integrationDiv" key={item.type}
        onClick={item.published ? (e) => onIntegration(e, item.type) : () => { }}>
        <div className="integrationImg">
          <img src={item.logoUrl} alt="integration icon" />
        </div>
        <p>{item.name}</p>
      </div>
    ));

  const listIntegrations = () =>
    integrationsArr?.map((item) => {
      const integration = integrationsTypesArr.find((i) => item.type === i.type);

      return (
        <div className="integrationDiv" key={item.id} onClick={() => onIntegrationClick(item.id)}>
          <div className="integrationImg">
            <img src={integration?.logoUrl} alt="integration img" />
          </div>
          <p>{item?.name}</p>
        </div>
      );
    });

  return (
    <div className="integrations">
      <div className="integrationMainContent">
        {integrationsArr?.length > 0
          ?
            <div className="integrationInstalledContainer">
              <h1>{t('settings.integrations.title1')}</h1>
              <div className="integrationContent">{listIntegrations()}</div>
            </div>
          : <></>
        }
        <WrapperPermission permission='integrationsCreate'>
          <div className='integrationAvailableContainer'>
            <h1>{t('settings.integrations.title2')}</h1>
            <div className='integrationContent'>{listIntegrationsTypes()}</div>
          </div>
        </WrapperPermission>
      </div>
    </div>
  );
};

export default IntegrationsList;
