import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import closeIcon from "../../../../../assets/close-icon-black.svg";
import './PaidWarn.scss';


type Props = {
  handleConfirm: () => void;
};

export function PaidWarn({ handleConfirm }: Props) {
  const { t } = useTranslation();

  return (
    <div className="paidWarn__wrapper">
      <div className="paidWarn">
        <div className="paidWarn__close"><img alt='close' src={closeIcon} onClick={handleConfirm} /></div>
        <h4>{t('subscriptions.paid_warn.head')}</h4>
        <div className="paidWarn__content">
          <p>{t('subscriptions.paid_warn.line_1')}</p>
          <p>{t('subscriptions.paid_warn.line_2')}</p>
        </div>
        <Button color='orange' textType='regular' text={t('confirm')} onClick={handleConfirm} />
      </div>
    </div>
  );
};
