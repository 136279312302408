import React from 'react';
import './OdnoklassnikiConnectGroup.scss';
import { push } from 'connected-react-router';
import { useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../store/store';
import { createConnection } from '../../../connectionsSlice';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { useHasConnections } from '../../../utils/connections';
import { setCurrentCompany } from "../../../../../Chat/companiesSlice";
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import ListItems from '../../../../../../components/ListItems';
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../../../../Chat/companiesSelector";

type SubmitType = {
  name: string;
  group: string;
};

const OdnoklassnikiConnectGroup = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, 'Не более 128 символов'),
    group: yup.string().required(t('error.form.empty_field')).max(256, 'Не более 256 символов'),
  });

  const methods = useForm<SubmitType>({
    mode: 'onSubmit',
    defaultValues: { group: '', name: '' },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: SubmitType) => {
    if (currentCompanyId?.params.companyId) {
      dispatch(
        createConnection({
          companyId: Number(currentCompanyId?.params.companyId),
          type: 'odnoklassniki',
          name: data.name,
          params: { ok_api_key: data.group },
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          if (currentCompany.hadConnections === false) {
            YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
            dispatch(setHadConnections(true));
          }
          dispatch(
            addAlertWithCustomText({
              message: t('connections.odnoklassniki.created'),
              color: '#2CBA5F',
            })
          );
          dispatch(push(`${currentCompanyId.url}/settings/connections/odnoklassniki`));
        }
      });
    }
  };

  return (
    <GoBackWrapper title={t('settings.integrations.odnoklassniki.networkGroup')}>
      <FormProvider {...methods}>
        <div className="odnoklassnikiConnectionGroup">
          <div className="odnoklassnikiConnectionGroup__api">
            <Input name="name" label={t('settings.number_phone.bePaid.name_connections')} />
            <Input name="group" label={t('settings.integrations.odnoklassniki.apiKeyGroup')} inputType="password" />
            <Button
                text={t('settings.integrations.odnoklassniki.connectGroup')}
                color="orange"
                textType="regular"
                onClick={methods.handleSubmit(onSubmit)}
                disabled={!methods.formState.isDirty}
              />
          </div>
          <div className="odnoklassnikiConnectionGroup__text">
            <h3>{t('settings.integrations.odnoklassniki.titleManual')}</h3>
            <ListItems typeList='ol' items={t('settings.integrations.odnoklassniki.manual').split('\n')} />
          </div>
        </div>
      </FormProvider>
    </GoBackWrapper>
  );
};
export default OdnoklassnikiConnectGroup;
