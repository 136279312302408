import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../../../../../store/store';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { clearBlackListChats, fetchChatsBlackList } from '../../../connectionsSlice';
import { connectionItemContext } from '../../../utils/connections';
import BlackListItem from '../BlackListItem';
import './BlackListContent.scss';


const BlackListContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { connection } = useContext(connectionItemContext);
  const currentCompany = useSelector(currentCompanySelector);
  const blackList = useAppSelector((state) => state.connections.blackListChats[connection.id]);

  const updateList = () => {
    dispatch(fetchChatsBlackList({
      companyId: currentCompany.id,
      connectionId: connection.id,
      offset: blackList?.blockedChats.length,
    }))
  };

  useEffect(() => {
    updateList();

    return () => {dispatch(clearBlackListChats(connection.id))};
  }, []);
  
  return (
    <div className="blackListContent">
      <p className="blackListContent_title">{t('connections.black_list.title_list')}</p>
      {blackList && blackList.blockedChats
        ? (blackList.blockedChats.map((item) => <BlackListItem key={item.id} item={item} />)) 
        : (<p className="blackListContent_empty">{t('connections.black_list.no_block')}</p>)
      }
      {blackList && blackList.hasMore && (
        <p className="blackListContent_more" onClick={updateList}>{t('show_more')}</p>
      )}
    </div>
  );
};

export default BlackListContent;
