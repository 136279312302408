import React from 'react';
import { nanoid } from 'nanoid';
import { ConnectionsType } from '../ConnectionsTypes';
import { connectionCardInner } from './ConnectionInner';
import ConnectionAddon from './ConnectionAddon';
import ConnectionCard from './ConnectionCard/ConnectionCard';
import StatusBar from './StatusBar';


type ConnectionCardInnerProps = {
  service: ConnectionsType;
};

const ConnectionCardInner = React.memo(({ service }: ConnectionCardInnerProps) => {
    const inner = connectionCardInner[service];
    return (inner ? 
      <ConnectionCard editNameOnly={inner.editName}>
        {inner.status &&
          <StatusBar>
            {inner.status.map((Item) => <Item key={nanoid()} />)}
          </StatusBar>
        }
        {inner.children && inner.children.map((Item) => <Item key={nanoid()} />)}
        {inner.addon && 
          <ConnectionAddon>
            {inner.addon.map((Item) => <Item key={nanoid()} />)}
          </ConnectionAddon>
        }
      </ConnectionCard> : null
    );
  });

export default ConnectionCardInner;
