import React, { ReactElement, useContext } from 'react';
import './SubscriptionStatus.scss'
import { useTranslation } from "react-i18next";
import { useConnectSubscriptionStatus } from "../../../../../components/utils/hooks";
import { SubscriptionStatusEnum } from "../../../Subscriptions/types";
import { connectionItemContext } from '../../utils/connections';
import statusOK from '../../../../../assets/status-ok.svg';
import statusNoPaid from '../../../../../assets/status-no-paid.svg';
import statusNope from '../../../../../assets/status-nope.svg';
import statusTest from '../../../../../assets/status-test.svg';


const SubscriptionStatus = () => {
  const { t } = useTranslation();
  const { connection } = useContext(connectionItemContext);
  const status = useConnectSubscriptionStatus({
    connectionId: connection.id,
    subscriptionId: connection.subscriptionId
  });

  const statusName = SubscriptionStatusEnum[status];
  const statusTypes: { [key: string]: ReactElement } = {
    expired: <img alt='status img' src={statusNoPaid} />,
    warn: <img alt='status img' src={statusOK} />,
    paid: <img alt='status img' src={statusOK} />,
    testEnd: <img alt='status img' src={statusTest} />,
    test: <img alt='status img' src={statusTest} />,
    unpaid: <img alt='status img' src={statusNoPaid} />,
    paused: <img alt='status img' src={statusNope} />,
    unknown: <img alt='status img' src={statusNope} />,
  };

  return (
    <div className={`subscriptionStatus ${statusName}`}>
      {statusTypes[statusName]}
      <p>{t(`connections.sub-status.${statusName}`)}</p>
    </div>
  );
};

export default SubscriptionStatus;
