import React, { useEffect, useState } from 'react';
import './OdnoklassnikiEdit.scss';
import { push } from 'connected-react-router';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OdnoklassnikiConnection } from '../../../../../../api/CompanyAPI';
import { updateConnection } from '../../../connectionsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Input from '../../../../../../components/Input/Input';
import Button from '../../../../../../components/Button/Button';


type SubmitType = {
  group: string;
  name: string;
};

const OdnoklassnikiEdit = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { connectionId } = useParams<{ connectionId: string }>();
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.odnoklassniki);
  const [currentConnect, setCurrentConnect] = useState<OdnoklassnikiConnection | null>(null);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    group: yup.string(),
  });

  const methods = useForm<SubmitType>({
    mode: 'onChange', defaultValues: { name: '', group: '' }, resolver: yupResolver(schema) });

  const getIdRef = () => `https://ok.ru/group/${currentConnect?.params.groupId.replace(/[^0-9]/g, '')}`;

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as OdnoklassnikiConnection;
    if (connect) {
      setCurrentConnect(connect);
      methods.reset({
        name: connect?.name
      });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmit = (data: SubmitType) => {
    if (currentConnect && currentCompanyId?.params.companyId) {
      dispatch(updateConnection({
        itemId: currentConnect.id,
        type: 'odnoklassniki',
        companyId: Number(currentCompanyId.params.companyId),
        name: data.name,
        ...(data.group?.length > 0 ? { params: { ok_api_key: data.group } } : {})
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(push(`${currentCompanyId.url}/settings/connections/odnoklassniki`))
        }
      })
    }
  };

  if (currentConnect) {
    return (
      <GoBackWrapper title={t('edit')}>
        <FormProvider {...methods}>
          <div className='odnoklassnikiEdit'>
          <p>{t('connections.connection_number')}: #{currentConnect.id}</p>
          <a className='odnoklassnikiEdit__link' href={getIdRef()}>{getIdRef()}</a>
          <Input name='name' label={t('connections.connection_name')} />
          <Input name='group' label={t('connections.odnoklassniki.update_key')} inputType="password" />
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
            onClick={methods.handleSubmit(onSubmit)} disabled={!methods.formState.isDirty} />
          </div>
        </FormProvider>
      </GoBackWrapper>
    );
  }

  return null;
};

export default OdnoklassnikiEdit;
