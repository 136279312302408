import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createTag, deleteTag, getTag, getTags, updateTag } from '../../api/TagsAPI';
import { CreateTag, Tag, TagsListRequestType, UpdateTag } from '../../api/types';


export type InitialState = {
  tags: Array<Tag> | null;
  tag: Tag;
  companyId: number;
  hasMore: boolean;
};

const initialState: InitialState = {
  tags: null,
  tag: {} as Tag,
  companyId: 0,
  hasMore: true
};

type TagType = {
  tag_id: number;
  company_id: number;
};

export const getTagsList = createAsyncThunk('tags/getTagsList', async (requestOptions: TagsListRequestType) => 
  getTags(requestOptions)
);

export const getItemTag = createAsyncThunk('tags/getItemTag', async (requestOptions: TagType) => 
  getTag(requestOptions.tag_id, requestOptions.company_id)
);

export const postCreateTags = createAsyncThunk('tags/createTags', async (requestOptions: CreateTag) =>
  createTag(requestOptions)
);

type TagsUpdateType = {
  tag_id: number;
  company_id: number;
  requestOption: UpdateTag;
};

export const putUpdateTag = createAsyncThunk('tags/patchUpdateTag', async (requestOptions: TagsUpdateType) =>
  updateTag(requestOptions.tag_id, requestOptions.company_id, requestOptions.requestOption)
);

export const deleteTags = createAsyncThunk(
  'tags/deleteTags', async (requestOptions: TagType, { dispatch, rejectWithValue }) => {
  try {
    await deleteTag(requestOptions.tag_id, requestOptions.company_id);
    return dispatch(deleteTagSlice({ id: requestOptions.tag_id }));
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    deleteTagSlice: (state, action: PayloadAction<{ id: number; }>) => {
      if (state.tags) {
        const idIndex = state.tags.findIndex((tag) => tag.id === action.payload.id);
        state.tags.splice(idIndex, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTagsList.fulfilled, (state, action) => {
        if (state.companyId !== action.meta.arg.company_id || !state.tags ) {
          state.tags = action.payload;
          state.companyId = action.meta.arg.company_id;
        } else {
          const ids = state.tags.map((item) => item.id);
          state.tags.push(...action.payload.filter((item) => !ids.includes(item.id)));
        }
        if (action.meta.arg.limit) {
          state.hasMore = action.meta.arg.limit === action.payload.length;
        }
      })
      .addCase(getItemTag.fulfilled, (state, action: PayloadAction<Tag>) => {
        state.tag = action.payload;
      })
      .addCase(postCreateTags.fulfilled, (state, action) => {
        if (state.tags) {
          state.tags.push(action.payload);
        }
      })
      .addCase(putUpdateTag.fulfilled, (state, action) => {
        if (state.tags) {
          const idIndex = state.tags.findIndex((tag) => tag.id === action.payload.id);
          state.tags[idIndex] = action.payload;
        }
      });
  },
});

export const { deleteTagSlice } = tagsSlice.actions;

export default tagsSlice.reducer;
