import React, { useEffect, useState } from 'react';
import './Checkbox.scss';

type Props = {
  name: string;
  value: boolean;
  onCheckedHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
};

const Checkbox: React.FC<Props> = ({ name, onCheckedHandler, value, label, disabled = false }) => {
  const [checked, setChecked] = useState<boolean>(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    onCheckedHandler(e);
  };

  return (
    <label className="checkbox">
      <input type="checkbox" name={name} checked={checked} onChange={onChangeHandler} disabled={disabled} />
      <span className="checkmark" />
      {label}
    </label>
  );
};

export default Checkbox;
