import React, { useEffect } from 'react';
import './Orders.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  invoicesSelector, isLoadingAddInvoiceSelector,
  isVisibleProfileInvoiceSelector, profileInvoiceSelector,
  totalCountSelector,
} from '../../../Chat/billSelector';
import { fetchInvoices, fetchTerminals, updateIsVisibleProfileInvoice } from '../../../Chat/billSlice';
import { fetchProducts } from "../../SalesSlice";
import { getPageLimit } from "../../../../components/Paginator/utils";
import OrderPlug from './OrderPlug/OrderPlug';
import OrderItem from './OrderItem/OrderItem';
import ProfileInvoice from './ProfileInvoice/ProfileInvoice';
import Paginator from "../../../../components/Paginator";
import loader from '../../../../assets/grid.svg';


const Orders = () => {
  const dispatch = useDispatch();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const { t } = useTranslation();
  const listInvoice = useSelector(invoicesSelector);
  const totalCount = useSelector(totalCountSelector);
  const isVisibleProfileInvoice = useSelector(isVisibleProfileInvoiceSelector);
  const profileInvoice = useSelector(profileInvoiceSelector);
  const isLoadingInvoice = useSelector(isLoadingAddInvoiceSelector);

  const ordersListPageLimitIndex = 'limitInvoice'

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(fetchProducts({ companyId: Number(params?.params.companyId) }));
      dispatch(fetchTerminals(Number(params?.params.companyId)));
      onUpdate(1, getPageLimit(ordersListPageLimitIndex));
    }
  }, [params?.params.companyId]);

  useEffect(() => () => {
    dispatch(updateIsVisibleProfileInvoice({ isVisibleProfileInvoice: false, profileInvoice: null }));
  }, []);

  const onUpdate = (page: number, limit: number) => {
    dispatch(fetchInvoices({
      companyId: Number(params?.params.companyId), limit, offset: (limit * (page - 1))
    }));
  };

  if (!listInvoice) {
    return (
      <div className='orders'>
        <div className='loaderContainer'>
          <img src={loader} alt='loader' />
        </div>
      </div>
    );
  }

  if (listInvoice.length <= 0) {
    return <OrderPlug />;
  }

  return (
    <div className='orders'>
      {isVisibleProfileInvoice && profileInvoice && <ProfileInvoice invoice={profileInvoice} />}
      <div className='orders__wrapper_header'>
        <h1>{t('orders.title')}</h1>
        <div className='orders__wrapper_header_list'>
          <div className='orders__wrapper_header_list_order'>{t('orders.id_order_title')}</div>
          <div>{t('orders.date_title')}</div>
          <div>{t('orders.status_title')}</div>
          <div className='orders__wrapper_header_list_amount'>{t('orders.amount_title')}</div>
        </div>
      </div>
      <div className='orders__wrapper_list'>
        {isLoadingInvoice 
          ? <div className="load"><img src={loader} alt='loading...' /></div> 
          : listInvoice.map((invoice) => (<OrderItem key={invoice.id} invoice={invoice} />))
        }
      </div>
      <Paginator totalCount={totalCount.totalCount} pageSelect={onUpdate}
        pageLimitStoreIndex={ordersListPageLimitIndex} />
    </div>
  );
};

export default Orders;
