import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Contacts from './components/Contacts';
import ContactCard from './components/ContactCard/ContactCard';
import ContactCardEdit from './components/ContactCardEdit/ContactCardEdit';
import CreateContact from './components/CreateContact/CreateContact';


const ContactsRoute = () => {
  const currentCompany = useRouteMatch<{ companyId: string }>('/companies/:companyId/contacts');

  return (
    <Switch>
      <Route path={`${currentCompany?.url}/add`} exact>
        <CreateContact />
      </Route>
      <Route path={`${currentCompany?.url}/:contactId/edit`} exact>
        <ContactCardEdit />
      </Route>
      <Route path={`${currentCompany?.url}/:contactId`} exact>
        <ContactCard />
      </Route>
      <Route path={`${currentCompany?.url}`} >
        <Contacts />
      </Route>
    </Switch>
  );
};

export default ContactsRoute;
