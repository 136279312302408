import { useCallback } from 'react';
import './Shop.scss';
import { useTranslation } from 'react-i18next';
import { ShopType } from '../../../../../api/ShopsAPI';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { useAppDispatch } from '../../../../../store/store';
import { WrapperPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';
import { ReactComponent as EditIconButton } from '../../../../../assets/black-icon-edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon-black.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/copy.svg';


type ShopProps = {
  item: ShopType;
  handlerModeCreateEdit: (editShop?: ShopType) => void;
  handlerDeleteShop: (shopId: number) => void;
};

function Shop({ item, handlerModeCreateEdit, handlerDeleteShop }: ShopProps ) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlerEdit = () => {
    handlerModeCreateEdit(item);
  };

  const onCopyRefHandler = useCallback(() => {
    navigator.clipboard.writeText(item.link);
    dispatch(addAlertWithCustomText({
      color: '#2CBA5F',
      message: t('sales.shops.refSuccessCopied'),
    }));
  }, [item.link]);

  return (
    <div className='shop'>
      <div className='shop__nameLink'>
        <div className='shop__nameLink_name'>{item.name}</div>
        <div className='shop__nameLink__link'>
          <div className='shop__nameLink__link__copy'>
            {item.link}
          </div>
          <div className='shop__nameLink__link__icon'>
            <CopyIcon onClick={onCopyRefHandler} />
          </div>
        </div>
      </div>
      <div className='shop__buttons'>
          <WrapperPermission permission='salesShopEdit'>
            <button className='shop__buttons_edit' onClick={handlerEdit}>
              <EditIconButton />
              <span>{t('edit')}</span>
            </button>
          </WrapperPermission>
          <WrapperPermission permission='salesShopDelete'>
            <button className='shop__buttons_delete' onClick={() => handlerDeleteShop(item.id)}>
              <DeleteIcon />
              <span>{t('delete')}</span>
            </button>
          </WrapperPermission>
      </div>
    </div>
  );
};

export default Shop;
