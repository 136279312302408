import React, { useEffect, useRef, useState } from 'react';
import { BaseMessageComponent } from './types';
import { ReactComponent as FileLoader } from '../../../../assets/fileLoader.svg';
import LinkifyText from '../../../../components/LinkifyText/LinkifyText';

const MessageVideoType: BaseMessageComponent = ({ message, handleSizeChange }) => {
  const videoContainerRef = useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded && videoContainerRef.current && handleSizeChange) {
      handleSizeChange(videoContainerRef.current.offsetHeight);
    }
  }, [isLoaded]);

  return (
    <>
      {message.video.url ? (
        <>
          <video
            style={isLoaded ? { display: 'block', width: '100%' } : { display: 'none' }}
            onLoadedData={() => {
              setIsLoaded(true);
            }}
            ref={videoContainerRef}
            src={message.video.url}
            controls
            controlsList="nodownload"
          >
            <track src="#" kind="captions" />
          </video>
          <LinkifyText text={message.video.caption} />
          {!isLoaded && <FileLoader width={40} height={40} />}
        </>
      ) : (
        <FileLoader width={40} height={40} />
      )}
    </>
  );
};

export default MessageVideoType;
