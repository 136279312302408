import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import plugImage from '../../../../../assets/Saly-17.png';
import './CatalogsPlug.scss';
import { useHasPermission } from "../../../../../utils/WrapperPermission/WrapperPermission";

type CatalogsPlugType = { createCatalog: () => void };

const CatalogsPlug = ({ createCatalog }: CatalogsPlugType ) => {
  const { t } = useTranslation();
  const permissionCreate = useHasPermission('salesCatalogCreate');

  return (
    <div className="catalogsPlug">
      <img alt='Catalogs' src={plugImage} />
      {permissionCreate ? (
        <>
          <h3>{t('sales.catalogs.title')}</h3>
          <p>{t('sales.catalogs.no_catalogs')}</p>
          <Button color='orange' textType='regular' text={t('sales.catalogs.create_catalog')} onClick={createCatalog} />
        </>) : (
        <p>{t('sales.catalogs.noCatalogCreate')}</p>
      )}
    </div>
  );
};

export default CatalogsPlug;
