 import { useEffect } from 'react';
 import './CreateContact.scss';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import { createContact, fetchAllTags, setCurrentContact, setStatusReject } from '../../contactsSlice/contactsSlice';
import { allTagsContactSelector, statusRejectSelector } from '../../contactsSlice/contactsSelector';
import { useAppDispatch } from '../../../../store/store';
import { usePhoneSchema } from '../../../../components/PhoneInput/utils';
import Button from "../../../../components/Button/Button";
import Input from '../../../../components/Input';
import InputsEmails from '../../components/InputsEmails';
import InputsPhones from '../../components/InputsPhones';
import SelectTags from './components/SelectTags';
import CreateNotes from './components/CreateNotes';
 

type SubmitSchemaCreateContact = {
  name: string;
  emails: Array<{ value: string }>;
  phones: Array<{ value: string }>;
  tags: number[];
  note?: string;
};

const CreateContact = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const statusReject = useSelector(statusRejectSelector);
  const allTags = useSelector(allTagsContactSelector);

  const nameRegex = new RegExp('^(?!\\s*$)[-\\/\'" а-яА-ЯёЁa-zA-Z0-9]+$');

  const emailSchema = {
    value: yup.string().email(t('contacts.errorMessage.incorrectEmail')),
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field'))
      .matches(nameRegex, t('contacts.errorMessage.incorrectData'))
      .max(200, `${t('error.form.max_length', { limit: 200 })}`),
    phones: yup.array().of(yup.object().shape(usePhoneSchema({ fieldName: 'value' }))),
    emails: yup.array().of(yup.object().shape(emailSchema)),
    note: yup.string(),
  });

  const methods = useForm<SubmitSchemaCreateContact>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      phones: [{ value: '' }],
      emails: [{ value: '' }],
      tags: [],
      note: '',
    },
  });

  useEffect(() => {
    dispatch(fetchAllTags({ companyId: Number(url?.params.companyId) }));
  }, [url?.params.companyId]);

  useEffect(() => {
    if (statusReject) {
      dispatch(addAlertWithCustomText({
        message: t('contacts.errorMessage.duplicateEmailOrPhone'),
        type: 'alarm',
      }));
      dispatch(setStatusReject(false));
    }
  }, [statusReject]);

  const onSubmit = (data: SubmitSchemaCreateContact) => {
    const { name, emails = [], phones = [], tags, note } = data;
    dispatch(createContact({
      companyId: Number(url?.params.companyId),
      name,
      phones: phones.filter(phone => phone.value),
      emails: emails.filter(email => email.value),
      tags,
      notes: note ? [{ text: note }] : [],
    })).then(res => {
      if (res.meta.requestStatus=== 'fulfilled' && typeof res.payload !== "number") {
        if (res.payload) setCurrentContact(res.payload);
        dispatch(push(`/companies/${url?.params.companyId}/contacts/${res.payload?.id}`));
      }
    });
  };

  const onClose = () => {
    if (url?.params.companyId) dispatch(push(`/companies/${url.params.companyId}/contacts`));
  };

  return (
    <div className='createContact__wrapper'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='createContact'>
            <h1>{t('contacts.contactsList.addNewContact')}</h1>
            <div className='createContact__content'>
              <Input name='name' label={t('contacts.contactsList.nameSurname')} data-testid='name' />
              <InputsPhones />
              <InputsEmails />
              <SelectTags name='tags' allTags={allTags} />
              <CreateNotes />
            </div>
            <div className='createContact__footer'>
              <Button type='submit' color='orange' textType='regular' text={t('contacts.contactsList.addNewContact')} 
                disabled={!methods.formState.isDirty} data-testid='add' />
              <Button text={t('cancel')} onClick={onClose} color='white' textType='regular' data-testid='cancel' />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateContact;
