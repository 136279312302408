import React, { useState, useEffect } from 'react';

import { useStickyState } from './Context';
import { EventsStickyType, RefType } from './types';

function useSentinelOffsets(topSentinelRef: RefType) {
  const { stickyRefs } = useStickyState();
  const [bottomSentinelHeight, setBottomSentinelHeight] = useState('');
  const [topSentinelMarginTop, setTopSentinelMarginTop] = useState('');

  // Move the sentinel up by the top margin of the sticky component
  useEffect(() => {
    if (topSentinelRef?.current) {
      const stickyNode = stickyRefs.get(topSentinelRef?.current);


      if (stickyNode) {

        const topStyle = window.getComputedStyle(stickyNode);
        const getProp = (name: string) => topStyle.getPropertyValue(name);
        // const paddingtop = getProp('padding-top');
        // const paddingBottom = getProp('padding-bottom');
        // const height = getProp('height');
        const marginTop = getProp('margin-top');
        // const BottomSentinelHeight = `calc(${marginTop} +
        // ${paddingtop} +
        // ${height} +
        // ${paddingBottom})`;

        const BottomSentinelHeight = 'calc(20) ';
        setBottomSentinelHeight(BottomSentinelHeight);
        setTopSentinelMarginTop(marginTop);
      }
    }
  }, [stickyRefs, topSentinelRef]);

  return { bottomSentinelHeight, topSentinelMarginTop };
}

// https://developers.google.com/web/updates/2017/09/sticky-headers
function useObserveTopSentinels(topSentinelRef: React.RefObject<HTMLDivElement>, events: EventsStickyType) {
  const { onStuck, onUnstuck, onChange } = events;
  const { stickyRefs, containerRef } = useStickyState();

  useEffect(() => {
    if (!containerRef) return;
    if (!containerRef.current) return;

    const options = {
      threshold: [0],
      root: containerRef.current,
    };

    const observer = new IntersectionObserver(entries => {

      entries.forEach(entry => {
        if (entry.target) {
          const target = stickyRefs.get(entry.target);
          const targetInfo = entry.boundingClientRect;
          const rootBoundsInfo = entry.rootBounds;

          let type = '';

          // Started sticking.
          if (rootBoundsInfo && target && Math.ceil(targetInfo.bottom) < rootBoundsInfo.top) {
            type = 'stuck';
            onStuck(target);
          }

// Stopped sticking.
          if (rootBoundsInfo && target &&
            targetInfo.bottom >= rootBoundsInfo.top &&
            targetInfo.bottom < rootBoundsInfo.bottom
          ) {
            type = 'unstuck';
            onUnstuck(target);
          }
          type && target && onChange({ type, target });
        }
      });
    }, options);

    if (topSentinelRef?.current) {
      const sentinel = topSentinelRef.current;
      sentinel && observer.observe(sentinel);
      // eslint-disable-next-line consistent-return
      return () => {
        observer.unobserve(sentinel);
      };
    }

  }, [topSentinelRef, onChange, onStuck, onUnstuck, stickyRefs, containerRef]);
}


function useObserveBottomSentinels(bottomSentinelRef: React.RefObject<HTMLDivElement>, events: EventsStickyType) {
  const { onStuck, onUnstuck, onChange } = events;
  const { stickyRefs, containerRef } = useStickyState();

  useEffect(() => {
    if (!containerRef) return;
    if (!containerRef.current) return;

    const root = containerRef.current;
    const options = {
      threshold: [1],
      root,
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const target = stickyRefs.get(entry.target);
        const targetRect = target?.getBoundingClientRect();
        const bottomSentinelRect = entry.boundingClientRect;
        const { rootBounds } = entry;
        const { intersectionRatio } = entry;

        let type = '';

        if (rootBounds && target &&
          bottomSentinelRect.top >= rootBounds.top &&
          bottomSentinelRect.bottom <= rootBounds.bottom &&
          intersectionRatio === 1 &&
          targetRect?.y === 0
        ) {
          type = 'stuck';
          onStuck(target);
        }

        if (target && rootBounds && bottomSentinelRect.top <= rootBounds.top) {
          type = 'unstuck';
          onUnstuck(target);
        }

        type && target && onChange({ type, target });

      });
    }, options);

    if (bottomSentinelRef?.current) {
      const sentinel = bottomSentinelRef.current;
      sentinel && observer.observe(sentinel);
      // eslint-disable-next-line consistent-return
      return () => {
        observer.unobserve(sentinel);
      };
    }


  }, [
    bottomSentinelRef,
    onChange,
    onStuck,
    onUnstuck,
    stickyRefs,
    containerRef,
  ]);
}

export {
  useSentinelOffsets,
  useObserveTopSentinels,
  useObserveBottomSentinels,
};
