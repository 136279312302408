import React, { FC, useEffect, useState } from 'react';
import './ImagesContent.scss';
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../../../../assets/deleteIcon.svg";
import plusIcon from "../../../../../assets/plus-black-icon.svg";
import imageIcon from "../../../../../assets/preview-image-icon.svg";
import { Product } from "../../../../../api/SalesAPI";
import { addAlertWithCustomText } from "../../../../../components/Alert/alertSlice";
import { useAppDispatch } from "../../../../../store/store";

type Props = {
  attachedFile: File[];
  setAttachedFile: React.Dispatch<React.SetStateAction<File[]>>;
  product?: Product;
  arrayImage: (string | File)[];
  setArrayImage: React.Dispatch<React.SetStateAction<(string | File)[]>>;
}

const ImagesContent: FC<Props> = ({ attachedFile, setAttachedFile, product, arrayImage, setArrayImage }) => {
  const [dragImage, setDragImage] = useState<number | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let countAttachedFile = 0;

  const fileValidation = (file: File) => {
    countAttachedFile += 1;
    const editImage = arrayImage.length >= 10 || (arrayImage.length + countAttachedFile - 1 >= 10);
    const createImage = attachedFile.length >= 10 || (attachedFile.length + countAttachedFile - 1 >= 10);
    if (file.size > 3145728) {
      dispatch(addAlertWithCustomText({
        message: 'Файл не должен превышать 3 МБ',
        color: '#F54242',
      }));
      return {
        code: 'size-to-large',
        message: 'The file size is more than 3 MB',
      };
    }
    if (product ? editImage : createImage) {
      return {
        code: 'limit-to-large',
        message: 'The file size is more than 10 image',
      };
    }
    return null;
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 10,
    validator: fileValidation,
  });

  useEffect(() => {
    if (attachedFile?.length) {
      setAttachedFile([...attachedFile, ...acceptedFiles])
    } else setAttachedFile(acceptedFiles)

    if (product) {
      setArrayImage([...arrayImage, ...acceptedFiles])
    }
  }, [acceptedFiles])

  useEffect(() => {
    if (product) {
      setArrayImage(product.images);
    }
  }, [])

  const onDeleteImage = (index: number) => {
    if (product) {
      const newFiles = [...arrayImage]
      newFiles.splice(index, 1)
      setArrayImage(newFiles)
    } else {
      const newFiles = [...attachedFile]
      newFiles.splice(index, 1)
      setAttachedFile(newFiles)
    }
  }

  const onDropImage = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();
    if (e.type === 'drag') {
      setDragImage(index);
    }
    if (e.type === 'drop' && dragImage !== null) {
      if (product) {
        const findDragImage = arrayImage[index]
        const findDropImage = arrayImage[dragImage!]
        const newFiles = [...arrayImage];
        newFiles.splice(dragImage!, 1, findDragImage)
        newFiles.splice(index, 1, findDropImage)
        setArrayImage(newFiles)
      } else {
        const findDragImage = attachedFile[index]
        const findDropImage = attachedFile[dragImage!]
        const newFiles = [...attachedFile];
        newFiles.splice(dragImage!, 1, findDragImage)
        newFiles.splice(index, 1, findDropImage)
        setAttachedFile(newFiles)
      }
    }
  }

  const getPreviewImage = () => {
    const viewImage = [];

    if (product) {
      for (let i = 0; i < 10; i++) {
        const filesImg = arrayImage[i] as File;
        const stringImg = arrayImage[i] as string;
        if (arrayImage[i]) {
          viewImage.push(<div key={typeof arrayImage[i] === 'object' ? filesImg.name + i : stringImg + i}
                              className='previewImage_wrapper'
                              onDrop={(e) => onDropImage(e, i)} onDrag={(e) => onDropImage(e, i)}>
            <div className='previewImage' onClick={(e) => e.stopPropagation()}>
              <div className='previewImage_delete' onClick={() => onDeleteImage(i)}><img alt='deleteIcon'
                                                                                         src={deleteIcon} /></div>
              <img alt='img'
                   src={typeof arrayImage[i] === 'object' ? URL.createObjectURL(filesImg) : stringImg} />
            </div>
          </div>)
        } else {
          viewImage.push(<div key={i.toString()} className='previewImage_wrapper'>
            <div className='previewEmpty'>
              <img alt='img' src={plusIcon} />
            </div>
          </div>)
        }
      }
      return (
        <div className='imageContainer'>
          <input {...getInputProps()} type="file" />
          {viewImage}
        </div>
      )
    }
    if (attachedFile) {
      for (let i = 0; i < 10; i++) {
        if (attachedFile[i]) {
          viewImage.push(<div key={attachedFile[i].name + i} className='previewImage_wrapper'
                              onDrop={(e) => onDropImage(e, i)} onDrag={(e) => onDropImage(e, i)}>
            <div className='previewImage' onClick={(e) => e.stopPropagation()}>
              <div className='previewImage_delete' onClick={() => onDeleteImage(i)}><img alt='deleteIcon'
                                                                                         src={deleteIcon} /></div>
              <img alt='img' src={URL.createObjectURL(attachedFile[i])} />
            </div>
          </div>)
        } else {
          viewImage.push(<div key={i.toString()} className='previewImage_wrapper'>
            <div className='previewEmpty'>
              <img alt='img' src={plusIcon} />
            </div>
          </div>)
        }
      }
      return (
        <div className='imageContainer'>
          <input {...getInputProps()} type="file" />
          {viewImage}
        </div>
      )
    }

    return null;
  }

  const emptyImage = () => (
    <>
      <input {...getInputProps()} type="file" />
      <img className='previewIcon' alt='images icon' src={imageIcon} />
      <p>{t('sales.products.add.image.load')}</p>
      <p>{t('sales.products.add.image.text1')}</p>
      <p>{t('sales.products.add.image.text2')}</p>
    </>
  )

  return (
    <div {...getRootProps()} className={`imagesGroup_container ${attachedFile.length && 'files'}`}>
      {product?.images.length || attachedFile?.length ? getPreviewImage() : emptyImage()}
    </div>
  )
};

export default ImagesContent;
