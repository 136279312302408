import { useRouteMatch } from 'react-router';
import { getServiceNameByUrl } from './utils/connections';
import OdnoklassnikiConnectGroup from
  './ConnectionsViews/Odnoklassniki/OdnoklassnikiConnectGroup/OdnoklassnikiConnectGroup';
import WhatsAppCreateConnectionView
  from './ConnectionsViews/WhatsApp/WhatsAppCreateConnectionView/WhatsAppCreateConnectionView';
import WabaCreateForm from './ConnectionsViews/Waba/WabaCreateForm';
import TelegramCreate from './ConnectionsViews/Telegram/TelegramPersonal/TelegramCreate';
import TelegramBotCreateUpdateConnection
  from './ConnectionsViews/Telegram/TelegramBot/TelegramBotCreateUpdateConnection/TelegramBotCreateUpdateConnection';
import TinkoffCreate from './ConnectionsViews/Tinkoff/TinkoffCreate';
import SberCreate from './ConnectionsViews/Sber/SberCreate';
import ModulBankCreate from './ConnectionsViews/ModulBank/ModulBankCreate';
import PayKeeperCreate from './ConnectionsViews/Paykeeper/PayKeeperCreate';
import BePaidCreate from './ConnectionsViews/BePaid/BePaidCreate';
import YookassaCreate from './ConnectionsViews/Yookassa/YookassaCreate';


type RouteMatchType = {
  companyId: string;
  serviceUrl: string;
};

const ConnectionCreate = () => {
  const params = useRouteMatch<RouteMatchType>('/companies/:companyId/settings/connections/:serviceUrl');
  const service = getServiceNameByUrl(params?.params.serviceUrl);
  if (service) {
      switch (service) {
        case 'waba':
          return (<WabaCreateForm />);
        case 'whatsapp':
          return (<WhatsAppCreateConnectionView />);
        case 'telegram':
          return (<TelegramCreate />);
        case 'telegram_bot':
          return (<TelegramBotCreateUpdateConnection />);
        case 'odnoklassniki':
          return (<OdnoklassnikiConnectGroup />);
        case 'bepaid':
          return (<BePaidCreate />);
        case 'tinkoff':
          return (<TinkoffCreate />);
        case 'sberbank':
          return (<SberCreate />);
        case 'modulbank':
          return (<ModulBankCreate />);
        case 'paykeeper':
          return (<PayKeeperCreate />);
        case 'yookassa':
          return (<YookassaCreate />);
        default:
          return (<></>);
      }
  }
  return(<></>);
}

export default ConnectionCreate;
