import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addChatToBlackList } from '../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { connectionItemContext } from '../../../utils/connections';
import PhoneField from '../../../../../../components/PhoneInput/PhoneField';
import Button from '../../../../../../components/Button/Button';
import './BlackListAdd.scss';


type PhoneType = {
  phone: string;
};

const BlackListAdd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const { connection } = useContext(connectionItemContext);

  const methods = useForm<PhoneType>({ mode: 'onSubmit', defaultValues: { phone: '' } });

  const onBlockNumber = (data: PhoneType) => {
    dispatch(addChatToBlackList({
      companyId: currentCompany.id,
      connectionId: connection.id,
      phone: data.phone
    }));
    methods.reset();
  };

  return (
    <div className="blackListAdd">
      <FormProvider {...methods}>
        <PhoneField name='phone' required={true} label={t('connections.black_list.text_phone')} />
        <Button type='submit' color='orange' textType='regular' text={t('connections.black_list.button_block')}
          onClick={methods.handleSubmit(onBlockNumber)} disabled={!methods.formState.isDirty} />
      </FormProvider> 
    </div>
  );
};

export default BlackListAdd;
