import React from "react";
import '../../Subscriptions.scss'
import { useTranslation } from "react-i18next";
import { WrapperPermission } from "../../../../../utils/WrapperPermission/WrapperPermission";
import Button from "../../../../../components/Button/Button";


type Props = {
  setIsVisibleForm: React.Dispatch<React.SetStateAction<boolean>>;
};

function RequisitesPlug({ setIsVisibleForm }: Props) {
  const { t } = useTranslation();

  const onClickRequisite = () => {
    setIsVisibleForm(true);
  };

  return (
    <div className='requisitesPlug'>
      <div className='requisitesPlug_text'>
        <p>{t('subscriptions.requisites.plug.sub_text1')}</p>
        <p>{t('subscriptions.requisites.plug.sub_text2')}</p>
      </div>
      <WrapperPermission permission='subscriptionsEdit'>
        <Button color='orange' textType='regular' text={t('subscriptions.requisites.add_requisites')}
          onClick={onClickRequisite} />
      </WrapperPermission>
    </div>
  )
}

export default RequisitesPlug;
