import { RootState } from '../../../store/store';

export const RequisitesSelector = (state: RootState) => state.subscriptions.requisites;
export const IsLoaderSelector = (state: RootState) => state.subscriptions.isLoader;
export const EditRequisiteSelector = (state: RootState) => state.subscriptions.editRequisite;
export const SubscriptionsSelector = (state: RootState) => state.subscriptions.subscriptions;
export const InvoiceSelector = (state: RootState) => state.subscriptions.invoice;
export const InvoicesSelector = (state: RootState) => state.subscriptions.invoices;
export const MoreInvoicesSelector = (state: RootState) => state.subscriptions.hasMoreInvoices;
export const SubscriptionsDialogsSelector = (state: RootState) => state.subscriptions.dialogs;
