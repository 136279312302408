import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { broadcastsItemSelector } from '../BroadcastsSelector';
import { getTextForMessage, TextMessageType } from '../utils';
import { IBroadcastMessageCreate } from '../BroadcastsTypes';


export function useMessage() {
  const broadcast = useSelector(broadcastsItemSelector);
  const [messageText, setMessageText] = useState<TextMessageType>();
  const [message, setMessage] = useState<IBroadcastMessageCreate>({} as IBroadcastMessageCreate);
  const [isChangedMessage, setIsChangedMessage] = useState<boolean>(false);

  const isDirtyWaba = messageText?.wabaTemplate?.body_params.toString()
    !== broadcast?.message.wabaTemplate?.bodyParams?.toString()
    || JSON.stringify(messageText?.wabaTemplate?.header_params)
    !== JSON.stringify(broadcast?.message.wabaTemplate?.headerParams);

  useEffect(() => {
    if (messageText?.type !== 'text' && messageText?.type !== 'waba_template') {
      if (broadcast?.message.messageType !== 'text' && broadcast?.message.messageType !== 'waba_template') {
        if (messageText?.url !== broadcast?.message[broadcast.message.messageType]?.url
          || messageText?.text !== broadcast?.message[broadcast.message.messageType]?.caption) {
          setIsChangedMessage(true);
        } else setIsChangedMessage(false);
      }
    } else if (messageText?.type === 'waba_template') {
      if (isDirtyWaba) {
        setIsChangedMessage(true);
      }
       else setIsChangedMessage(false);
    } else if (messageText?.text !== broadcast?.message.text?.text) {
      setIsChangedMessage(true);
    } else setIsChangedMessage(false);
  }, [messageText, message]);

  useEffect(() => {
    if (messageText) {
      const text = getTextForMessage(messageText);
      setMessage(text!);
    }
  }, [messageText]);

  return { messageText, setMessageText, message, isChangedMessage };
}
