import React, { useRef, useState } from 'react';
import './CustomTemplateSettings.scss';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { goBack } from 'connected-react-router';
import { FormProvider, useForm } from 'react-hook-form';
import Textarea from '../Textarea/Textarea';
import { createCustomTemplate, editCustomTemplate } from '../../../Chat/templatesSlice';
import { ReactComponent as AttachmentIcon } from '../../../../assets/attachment-icon.svg';
import { CreateCustomTemplateRequestOptions, CustomTemplate } from '../../../../api/types';
import { templateTypeViews } from '../../../Chat/components/ChatField/WabaTemplateComponent/consts';
import { uploadFile } from '../../../../api/ChatAPI';
import { useAppDispatch } from '../../../../store/store';
import { getFileName } from '../../../../utils/utils';
import Button from '../../../../components/Button/Button';


type EditData = {
  name: string;
  text: string;
  fileUrl?: string | null;
  document?: string;
};

type Props = {
  editTemplateData?: CustomTemplate;
};

const CustomTemplateSettings: React.FC<Props> = ({ editTemplateData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const wrapperRef = useRef(null);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const [currentOpenedEmojiPickerId, setCurrentOpenedEmojiPickerId] = useState<number>();
  const [clicked, setClicked] = useState<boolean>(false);
  const methods = useForm<EditData>({
    mode: 'onChange',
    defaultValues: {
      name: editTemplateData?.name ?? '',
      text: editTemplateData?.text ?? '',
      fileUrl: editTemplateData?.fileUrl || null,
      document: editTemplateData?.fileUrl ? getFileName(editTemplateData?.fileUrl) : undefined,
    }
  });
  const gotoBack = () => {
    dispatch(goBack());
  };

  const onConfirmSendTemplate = async (data: EditData) => {
    if (currentCompanyId?.params.companyId) {
      setClicked(true);
      const newData: CreateCustomTemplateRequestOptions = {
         companyId: Number(currentCompanyId?.params.companyId),
         type: 'text',
         name: data.name,
         text: data.text,
      };
      if (attachedFile) {
        const { url: fileUrl } =
          await uploadFile({ file:attachedFile, companyId:Number(currentCompanyId?.params.companyId) });
        newData.fileUrl = fileUrl;
      }
      if (!editTemplateData) {
        dispatch(createCustomTemplate(newData)).then(() => gotoBack());
      } else {
        dispatch(editCustomTemplate({
          ...newData,
          templateId: editTemplateData.id,
          fileUrl:newData?.fileUrl ? newData.fileUrl : data.fileUrl
        })).then(() => gotoBack());
      }
    }
  };

  const onChangeFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let type = e.target.files[0].type.split('/')[0];
      if (type === 'application') {
        type = 'document';
      }
      setFileType(type);
      setAttachedFile(e.target.files[0]);
      methods.setValue('document', e.target.files[0].name, { shouldDirty: true });
    }
  };

  const onChangeFileAttachmentData = () => {
    setAttachedFile(undefined);
    methods.setValue('document', undefined, { shouldDirty: true });
    methods.setValue('fileUrl', undefined, { shouldDirty: true });
  };

  const getAttachmentsPreview = () => {
    if (attachedFile && fileType) {
      const TemplatePreviewComponent = templateTypeViews.get(fileType);
      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            attachedFile={attachedFile}
            key={attachedFile.name}
            setAttachedFiles={onChangeFileAttachmentData}
            width={150}
            height={100}
          />
        );
      }
    }
    if (editTemplateData && editTemplateData.fileUrl && methods.getValues('document')) {
      const TemplatePreviewComponent = templateTypeViews.get(editTemplateData.type);
      let documentName = null;
      if (editTemplateData.type === 'document' && editTemplateData.fileUrl) {
        documentName = getFileName(editTemplateData.fileUrl);
      }
      if (TemplatePreviewComponent) {
        return (
          <TemplatePreviewComponent
            key={editTemplateData.id}
            setAttachedFiles={onChangeFileAttachmentData}
            width={150}
            height={100}
            customTemplateEditPayload={{
              fileUrl: editTemplateData.fileUrl as string,
              documentName,
            }}
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="addTemplateView">
      <h1>{editTemplateData ? t('templates.edit_templates.title') : t('templates.add_templates.title')}</h1>
      <FormProvider {...methods} >
        <form onSubmit={methods.handleSubmit(onConfirmSendTemplate)}>
          <div className="addTemplateView__body">
              <div className="addTemplateView__body_nameTextField">
                <Textarea
                  title={t('templates.add_templates.heading_templates')}
                  maxRows={2}
                  minRows={1}
                  maxLength={256}
                  placeholder={t('templates.add_templates.placeholder_templates')}
                  {...methods.register('name', {
                    minLength: {
                      value: 1,
                      message: t('error.form.min_length', { limit: 1 }),
                    },
                    maxLength: {
                      value: 128,
                      message: t('error.form.max_length', { limit: 128 }),
                    },
                    required: {
                      value: true,
                      message: t('error.form.empty_field')
                    },
                  })}
                  errorMessage={
                    (methods.formState.errors.name && (
                      <span className="errorMessage">{methods.formState.errors.name.message}</span>
                    ))
                  }
                />
              </div>
              <div className="addTemplateView__body_textTextField">
                <Textarea
                  title={t('templates.add_templates.text_templates')}
                  minRows={8}
                  maxRows={22}
                  maxLength={8192}
                  placeholder={t('templates.add_templates.placeholder_text')}
                  {...methods.register('text')}
                  emojiPicker={{
                    isOpened: currentOpenedEmojiPickerId === 1,
                    setCurrentOpenedEmojiPickerId,
                    id: 1,
                    onPickEmoji: methods.setValue,
                    textAreaValue: methods.getValues,
                    textAreaId: 'text',
                  }}
                />
                <div className="addTemplateView__body_textTextField__content__footer">
                  <div className="addTemplateView__body_textTextField__content__footer_buttonGroup" ref={wrapperRef}>
                    <input
                      type="file"
                      id="file-attachment"
                      value=""
                      accept=".jpeg,.png,.pdf,video/mp4"
                      onKeyDown={(event) => event.preventDefault()}
                      onChange={onChangeFileInput}
                    />
                    <label htmlFor="file-attachment">
                      <AttachmentIcon />
                    </label>
                  </div>
                </div>
              </div>
              <div className="addTemplateView__attachmentsPreview">{getAttachmentsPreview()}</div>
          </div>
          <div className={`addTemplateView__footer ${clicked ? 'clicked': ''}`}>
            <Button type='submit' color='orange' text={t('save_edit')} textType='regular'
              disabled={!methods.formState.isDirty} />
            <Button type='button' color='white' text={t('cancel')} textType='regular' onClick={gotoBack} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CustomTemplateSettings;
