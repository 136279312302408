import React from 'react';
import './ListAction.scss';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Textarea from '../Textarea/Textarea';
import RemoveActionButton from '../RemoveActionButton/RemoveActionButton';
import { CreateInteractiveTemplateData } from '../../../../api/types';

type Props = {
  id: number;
};

const ListAction: React.FC<
  Props & UseFormReturn<CreateInteractiveTemplateData> & UseFieldArrayReturn<CreateInteractiveTemplateData>
> = ({ id, register, remove, formState }) => {
  const { t } = useTranslation();
  const onRemoveListAction = () => {
    remove(id);
  };

  return (
    <div className="listAction">
      <div className="listAction__head">
        <p className="listAction__head_title">{`${t(
          'templates.add_interactive_templates.template_actions.list.rows.title'
        )} ${id + 1}`}</p>
        {id > 0 && <RemoveActionButton onRemoveAction={onRemoveListAction} />}
      </div>
      <div className="listAction__textareaGroup">
        <Textarea
          title={t('templates.add_interactive_templates.template_actions.list.rows.title_name')}
          subtitle={t('templates.add_interactive_templates.template_actions.list.rows.subtitle')}
          minRows={1}
          maxRows={1}
          {...register(`waba_interactive.action.sections.sections.0.rows.${id}.title`, {
            minLength: { value: 1, message: 'Заполните это поле' },
            maxLength: { value: 20, message: 'Не более 24 символов' },
            required: true,
          })}
          maxLength={20}
          errorMessage={
            (formState.errors.waba_interactive?.action?.sections?.sections?.[0]?.rows?.[0]?.title?.type ===
              'required' && (
              <span className="errorMessage">
                {t('templates.add_interactive_templates.template_actions.list.errors.paragraph')}
              </span>
            )) ||
            (formState.errors.waba_interactive?.action?.sections?.sections?.[0]?.rows?.[0]?.title && (
              <span className="errorMessage">
                {formState.errors.waba_interactive?.action?.sections?.sections?.[0]?.rows?.[0].title?.message}
              </span>
            ))
          }
        />
        <Textarea
          title={t('templates.add_interactive_templates.template_actions.list.rows.title_description')}
          subtitle={t('templates.add_interactive_templates.template_actions.list.rows.subtitle_description')}
          minRows={2}
          maxRows={3}
          {...register(`waba_interactive.action.sections.sections.0.rows.${id}.description`, {
            minLength: { value: 1, message: 'Заполните это поле' },
            maxLength: { value: 72, message: 'Не более 72 символов' },
          })}
          maxLength={72}
          errorMessage={
            formState.errors.waba_interactive?.action?.sections?.sections?.[0]?.rows?.[0]?.description && (
              <span className="errorMessage">
                {formState.errors.waba_interactive?.action?.sections?.sections?.[0]?.rows?.[0].description?.message}
              </span>
            )
          }
        />
      </div>
    </div>
  );
};

export default ListAction;
