import React from 'react';
import { useTranslation } from 'react-i18next';

const EighthStep = () => {
  const { t } = useTranslation();

  return (
    <div className="eighthStep">
      <p className="secondStep__title">{t('onboarding.eighthStep.title')}</p>
      <p className="secondStep__description">{t('onboarding.eighthStep.description')}</p>
    </div>
  );
};

export default EighthStep;
