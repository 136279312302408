import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import playButtonIcon from '../../../../assets/play-icon.svg';
import pauseButtonIcon from '../../../../assets/pause-icon.svg';
import CustomAudioPlayerTimer from './CustomAudioPlayerTimer';


type Props = {
  url: string;
};

const CustomAudioPlayer: React.FC<Props> = React.memo(({ url }) => {
  const wavesurfer = useRef<WaveSurfer>();
  const wavesurferContainer = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState<boolean>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (wavesurferContainer.current) {
      wavesurfer.current = WaveSurfer.create({
        container: wavesurferContainer.current,
        backend: 'WebAudio',
        height: 70,
        progressColor: '#2CBA5F',
        waveColor: '#80D89F',
        cursorColor: 'transparent',
        barWidth: 3,
        cursorWidth: 1,
        barRadius: 2,
        barMinHeight: 4,
        barGap: 1,
        maxCanvasWidth: 400,
      });

      wavesurfer.current.load(url);
      wavesurfer.current.on('finish', handleFinishPlaying);
      wavesurfer.current.on('ready', handleIsReady);
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current.un('finish', handleFinishPlaying);
        wavesurfer.current.on('ready', handleIsReady);
      }
    };
  }, [wavesurferContainer.current]);

  const onTriggerPlayer = () => {
    wavesurfer.current!.playPause();
    setIsPlaying(!isPlaying);
  };

  const handleFinishPlaying = () => {
    setIsPlaying(false);
  };

  const handleIsReady = () => {
    setIsReady(true);
    if (wavesurfer.current) setDuration(wavesurfer.current.getDuration());
  };

  if (typeof isReady !== 'undefined' && !isReady) return null;

  return (
    <>
      <img src={isPlaying ? pauseButtonIcon : playButtonIcon} alt="pause btn" onClick={onTriggerPlayer} />
      <div className='waveSurferContainer' ref={wavesurferContainer} />
      {isReady && <CustomAudioPlayerTimer wavesurfer={wavesurfer} isPlaying={isPlaying} duration={duration} />}
    </>
  );
});

export default CustomAudioPlayer;
