import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { currentCompanySelector } from '../../../../Chat/companiesSelector';
import { updateSubscriptions } from '../../SubscriptionsSlice';
import { addAlertWithCustomText } from '../../../../../components/Alert/alertSlice';
import { ConnectionsType } from '../../../Connections/ConnectionsTypes';
import { fetchConnectionsList } from '../../../Connections/connectionsSlice';
import UserSubscription from '../../models/UserSubscription';
import ListIcon from '../../../ListIcon';
import { PaidWarn } from '../PaidWarn/PaidWarn';
import { PauseWarn } from '../PauseWarn/PauseWarn';
import { ReactComponent as PenIcon } from '../../../../../assets/pen-icon.svg';
import { ReactComponent as ArrowBack } from '../../../../../assets/arrow-back.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/info.svg';
import leftArrowIcon from '../../../../../assets/minus-arrow-left.svg';
import rightArrowIcon from '../../../../../assets/minus-arrow-right.svg';
import loader from '../../../../../assets/grid.svg';
import smallLoader from '../../../../../assets/small-loader.svg';
import './EditForm.scss';


const SUBSCRIPTION_NOT_ENOUGH_DAY = 22002;
const CONNECTIONS_LIMIT = 100;

const isDifference = (a: number[], b: number[]): boolean => {
  if (a.length !== b.length) {
    return true;
  }
  for (const i of a) {
    if (!b.includes(i)) {
      return true;
    }
  }
  return false;
};

type Props = {
  editSubscription: UserSubscription;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type ConnectionItemType = {
  connId: number;
  title: string;
  index?: number;
  type?: string;
};

type SubscriptionEditType = {
  connectionsIn: Array<ConnectionItemType>;
  connectionsOut: Array<ConnectionItemType>;
};

const EditForm: React.FC<Props> = ({ editSubscription, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const currentCompany = useSelector(currentCompanySelector);
  const allConnections =
    useAppSelector((state) => state.connections.connectionsStore[editSubscription.type as ConnectionsType]);
  const [paidWarn, setPaidWarn] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [connIndexOut, setConnIndexOut] = useState<number>();  // save index for return it back if paid warn occurs!
  const [pauseWarn, setPauseWarn] = useState<boolean>(false);
  const [pauseDays, setPauseDays] = useState<string>('');
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchConnectionsList({
      companyId: currentCompany.id,
      limit: CONNECTIONS_LIMIT,
      types: editSubscription.type as ConnectionsType
    }));
  }, []);

  const cancelHandler = () => {
    setIsOpen(false);
  };

  const formatSubscriptionStatus = (subscription: UserSubscription) => {
    const statusDescription = t(`subscriptions.card.status.${subscription.statusName}`);
    return `${statusDescription} ${subscription.formatSubscriptionDateStatus(i18n.language)}`;
  };
  const [status, setStatus] = useState<string>(formatSubscriptionStatus(editSubscription));

  const formatPaidDaysOnPause = (subscription: UserSubscription) => {
    const daysString = t(`subscriptions.pause_warn.days`);
    const paidUntil = subscription.formatPaidUntil(i18n.language);
    const daysPause = subscription.formatSubscriptionDateStatus(i18n.language);
    return `${paidUntil} (${daysString}: ${daysPause})`;
  };

  const getAllConnections = (): Array<ConnectionItemType> =>
    allConnections.map(item => ({ connId: item.id, title: item.name, type: item.type }));

  const getConnectionsIn = () => {
    const connections = getAllConnections();
    return connections?.filter((conn) => editSubscription.connections.includes(conn.connId));
  };

  const getConnectionsOut = () => {
    const connections = getAllConnections();
    return connections?.filter((conn) => conn.type === editSubscription.type)
      .filter((conn) => editSubscription.connections.includes(conn.connId) === false);
  };

  const defaultValues: SubscriptionEditType = {
    connectionsIn: getConnectionsIn(),
    connectionsOut: getConnectionsOut()
  };

  const methods = useForm({ mode: 'onSubmit', defaultValues });
  const { fields: connectionsIn, append: appendConsIn, remove: removeConsIn } =
    useFieldArray({ name: 'connectionsIn', control: methods.control });
  const {
    fields: connectionsOut, append: appendConsOut, remove: removeConsOut,
    insert: insertConsOut, prepend: prependConsOut
  } = useFieldArray({ name: 'connectionsOut', control: methods.control });

  useEffect(() => {
    methods.reset({
      connectionsIn: getConnectionsIn(),
      connectionsOut: getConnectionsOut(),
    });
  }, [allConnections]);

  const addConnection = (index: number) => {
    const connOut = methods.getValues(`connectionsOut.${index}`);
    removeConsOut(index);
    setConnIndexOut(index);
    appendConsIn({ ...connOut, index });
  };

  const delConnection = (index: number, indexOut?: number) => {
    const connIn = methods.getValues(`connectionsIn.${index}`);
    const conn = { connId: connIn.connId, title: connIn.title };
    removeConsIn(index);
    if (indexOut) {
      insertConsOut(indexOut, conn);
    } else if (connIn.index !== undefined) {
      if (connIn.index === 0) {
        prependConsOut(conn);
      } else {
        insertConsOut(connIn.index, conn);
      }
    } else {
      appendConsOut(conn);
    }
  };

  const retConnection = () => {
    // return last added connection back to list
    delConnection(connectionsIn.length - 1, connIndexOut);
    setConnIndexOut(undefined);
  };

  const paidWarnConfirmHandle = () => {
    if (connIndexOut !== undefined) {
      // if paid warn, so couldn't add new connection and move it back to connectionsOut
      retConnection();
    }
    setPaidWarn(false);
  };

  const pauseWarnConfirmHandle = async () => {
    // try to save pause subscription
    setPauseWarn(false);
    await saveSubscription({ connectionsIn, connectionsOut });
  };

  useEffect(() => {
    calcSubscription(true);
    setIsChanged(isDifference(
      defaultValues.connectionsIn.map((conn) => Number(conn.connId)),
      connectionsIn.map((conn) => Number(conn.connId))
    ));
  }, [connectionsIn]);

  const calcSubscription = async (preview: boolean) => {
    let responseStatus = false;
    const connections = connectionsIn.map((conn) => Number(conn.connId));
    setIsPending(true);
    await dispatch(updateSubscriptions({
      subscriptionId: editSubscription.id,
      companyId: currentCompany.id,
      data: { connections, preview }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (typeof res.payload === 'object') {
          const subscription = new UserSubscription(res.payload);
          setStatus(formatSubscriptionStatus(subscription));
          setPauseDays(formatPaidDaysOnPause(subscription));
          responseStatus = true;
        }
      } else if (connIndexOut !== undefined) {
        // on error move connection back to connectionsOut list
        if (res.payload === SUBSCRIPTION_NOT_ENOUGH_DAY) {
          // if error SUBSCRIPTION_NOT_ENOUGH_DAY: not enough days to recalculate, then show paid warn before move back
          setPaidWarn(true);
        } else {
          retConnection();
        }
      }
      setIsPending(false);
    });
    return responseStatus;
  };

  const saveSubscription = async (data: SubscriptionEditType) => {
    const responseStatus = await calcSubscription(false);
    if (responseStatus) {
      methods.reset(data);
      setIsChanged(false);
      dispatch(addAlertWithCustomText({ message: t('subscriptions.card.form.save_message') }));
      setIsOpen(false);
    }
  };

  const submitHandler = async (data: SubscriptionEditType) => {
    const statusesNoPause = UserSubscription.noWarningStatuses()
    if (data.connectionsIn.length === 0 && !statusesNoPause.includes(editSubscription.status)) {
      setPauseWarn(true);
    } else {
      await saveSubscription(data);
    }
  };

  const termComponent = () => {
    return (
      <p className="editForm__date">
        {isPending ? `${t(`subscriptions.card.status.${editSubscription.statusName}`)}` : status}
        {isPending && <img alt="loading..." src={smallLoader} />}
        {!isPending && (
          <div className="info">
            <InfoIcon className="info_icon" />
            <div className="info__message">
              <p>{t('subscriptions.card.info')}</p>
            </div>
          </div>
        )}
      </p>
    );
  }

  return (
    <>
      {paidWarn && (<PaidWarn handleConfirm={paidWarnConfirmHandle} />)}
      {pauseWarn && (
        <PauseWarn datePause={pauseDays} setIsOpen={setPauseWarn} handleConfirm={pauseWarnConfirmHandle} />)}
      {isPending && (<div className="load"><img src={loader} alt='loading...' /></div>)}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <div className='editForm__details'>
            <p className="editForm__connection">
              {`${t(`subscriptions.card.connections.${editSubscription.serviceType}`)}${editSubscription.currentSize}`}
            </p>
            {termComponent()}
          </div>
          <div className="editForm">
            <ListIcon
              fields={connectionsOut}
              label={t('subscriptions.card.form.list_out')}
              labelKey='title'
              image={rightArrowIcon}
              callback={addConnection}
            />
            <ListIcon
              fields={connectionsIn}
              label={t('subscriptions.card.form.list_in')}
              labelKey='title'
              image={leftArrowIcon}
              position='left'
              callback={delConnection}
              termComponent={termComponent}
            />
          </div>
          <div className="editForm__buttonsWrapper">
            <button type="submit" className="editForm__buttonsWrapper_edit" disabled={!isChanged}>
              <PenIcon />{t('save')}
            </button>
            <button className="editForm__buttonsWrapper_cancel" onClick={cancelHandler}>
              <ArrowBack width={16} height={16} />{t('cancel')}
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default EditForm;
