import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InvoiceStatuses, PostStatuses } from '../../../IntegrationTypes';
import { BankingSchema } from '../../../types';
import Button from '../../../../../../components/Button/Button';
import Dropdown from '../../../../Dropdown/Dropdown';
import './AmoInvoiceStatuses.scss';


type AmoInvoiceStatusProps = {
  setFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  createStatuses: (statuses: PostStatuses[]) => void;
  invoiceStatuses?: InvoiceStatuses;
};

const configStatuses = [
  { name: 'new', label: 'new' },
  { name: 'confirmed', label: 'confirmed' },
  { name: 'rejected', label: 'rejected' },
  { name: 'expired', label: 'expired' },
  { name: 'refunded', label: 'refunded' },
  { name: 'partialRefunded', label: 'partial_refunded' }];

const listStatuses = ['new', 'confirmed', 'rejected', 'expired', 'refunded', 'partialRefunded'] as const;

type ListStatuses = typeof listStatuses[number];

const defaultStatuses: Record<ListStatuses, string> = {
  new: 'Создан',
  confirmed: 'Оплачен',
  rejected: 'Ошибка',
  expired: 'Просрочен',
  refunded: 'Полный возврат',
  partialRefunded: 'Частичный возврат'
};

type StatusesSchema = {
  new: number;
  confirmed: number;
  rejected: number;
  expired: number;
  refunded: number;
  partialRefunded: number;
};

function AmoInvoiceStatuses({ invoiceStatuses, setFormOpen, createStatuses }: AmoInvoiceStatusProps) {
  const { t } = useTranslation();
  const { getValues, setValue } = useFormContext<BankingSchema>();

  const getDefaultValues = (): StatusesSchema => {
    const statuses: StatusesSchema = getValues("amoInvoices.statuses");
    if (statuses !== null && invoiceStatuses) {
      return { ...statuses };
    } else if (statuses === null && invoiceStatuses) {
      return {
        new: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.new)?.id || 0,
        confirmed: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.confirmed)?.id || 0,
        rejected: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.rejected)?.id || 0,
        expired: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.expired)?.id || 0,
        refunded: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.refunded)?.id || 0,
        partialRefunded: invoiceStatuses?.statuses.find(item => item.name === defaultStatuses.partialRefunded)?.id || 0
      };
    }
    return { new: 0, confirmed: 0, rejected: 0, expired: 0, refunded: 0, partialRefunded: 0 };
  };

  const methods = useForm<StatusesSchema>({ mode: 'onSubmit', defaultValues: getDefaultValues() });

  const onCreateStatuses = () => {
    const newStatuses: PostStatuses[] = [];
    listStatuses.forEach((status) => {
      if (methods.getValues(status) === 0) newStatuses.push({ value: defaultStatuses[status] });
    });
    if (newStatuses.length > 0) createStatuses(newStatuses);
  };

  const onSaveStatus = (data: StatusesSchema) => {
    if (invoiceStatuses !== undefined) {
      setValue('amoInvoices', { invoiceCatalogId: invoiceStatuses.invoiceCatalogId, statuses: { ...data } },
        { shouldDirty: true });
      methods.reset(data);
    }
    setFormOpen(false);
  };

  const checkUnfillStatuses = () => {
    const statuses = methods.getValues();
    if (statuses) {
      return Object.values(statuses).some(value => !value);
    } else {
      return true;
    }
  };

  return (
    <div className='amoInvoiceStatus'>
      <h2>{t('settings.integrations.invoice_status.title')}</h2>
      <div className='amoInvoiceStatus__title'>
        <p className='amoInvoiceStatus__title_text smallText bold'>
          {t('settings.integrations.invoice_status.radist_header')}
        </p>
        <p className='amoInvoiceStatus__title_text smallText bold'>
          {t('settings.integrations.invoice_status.amo_header')}
        </p>
      </div>
      <FormProvider {...methods}>
        {configStatuses.map(status =>
          <Dropdown key={status.name} label={t(`settings.integrations.invoice_status.status_${status.label}`)}
            items={invoiceStatuses?.statuses || []} labelKey='name' valueKey='id'
            name={status.name} placeholder={t('select')} />)}
        {checkUnfillStatuses() && <Button type='button' color='orange' textType='small' onClick={onCreateStatuses}
          text={t('settings.integrations.invoice_status.missing_statuses')} />}
        <div className='amoInvoiceStatus__bottom'>
          <Button type='submit' color='orange' textType='regular' text={t('ready')}
            disabled={checkUnfillStatuses()} onClick={methods.handleSubmit(onSaveStatus)} />
        </div>
      </FormProvider>
    </div>
  );
}

export default AmoInvoiceStatuses;
