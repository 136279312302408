import React from 'react';
import './NotificationBadge.scss';

type Props = {
  notificationCount: number | null;
};

const NotificationBadge: React.FC<Props> = React.memo(({ notificationCount }) => {
  if (notificationCount)
    return (
      <div className="notificationBadge">
        <span>{notificationCount}</span>
      </div>
    );
  return null;
});

export default NotificationBadge;
