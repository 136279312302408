import { useEffect, useState } from "react";

export const ITEMS_ONE_PAGE = [25, 50, 75, 100];

export const getPageLimit = (pageLimitIndex: string) => {
  const limitState = localStorage.getItem(pageLimitIndex);
  if (limitState !== null) return JSON.parse(limitState);
  return ITEMS_ONE_PAGE[0];
};

export const usePageLimit = (pageLimitIndex: string) => {
  const [limit, setLimit] = useState<number>(getPageLimit(pageLimitIndex));

  useEffect(() => {
    setLimit(getPageLimit(pageLimitIndex));
  }, []);

  useEffect(() => {
    const limitState = JSON.stringify(limit);
    localStorage.setItem(pageLimitIndex, limitState);
  }, [limit]);

  return { limit, setLimit };
};
