import React from "react";
import ReactDOM from "react-dom";
import Button from "../../Button/Button";
import { OverlayBackgroundType } from "../types";
import CloseIcon from "../../../assets/cancel.svg";
import "./ActionDialog.scss";


type ActionDialogProps = {
  title: string;
  message: string;
  action: string;
  cancel: string;
  onAction: () => void;
  onClose?: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  background?: OverlayBackgroundType;
};

const ActionDialog = (
  { title, message, action, cancel, onAction, onClose, setIsOpen, background='none' }: ActionDialogProps) => {

  const onCloseDefault = () => {
    setIsOpen(false);
  };

  return ReactDOM.createPortal(
    <div className="actionDialog__container">
      <div className={`actionDialog__wrapper ${background}`} onClick={onClose || onCloseDefault}>{}</div>
      <div className="actionDialog">
        <img alt="close" src={CloseIcon} onClick={onClose || onCloseDefault} />
        <h4>{title}</h4>
        <div className="actionDialog__message">{message}</div>
        <div className="actionDialog__bottom">
          <Button text={action} color='orange' textType='regular' onClick={onAction} />
          <Button text={cancel} color='white' textType='regular' onClick={onClose || onCloseDefault} />
        </div>
      </div>
    </div>, document.body
  );
};

export default ActionDialog;
