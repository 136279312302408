//* class for catalog  */


class UserCatalog {
  id: number;
  
  name: string;

  numberProducts: number;

  companyId: number;

  products: number[];

  currency: string = 'RUB';

  constructor(id: number, name: string, numberProducts: number, companyId: number) {
    this.id = id;
    this.name = name;
    this.numberProducts = numberProducts;
    this.companyId = companyId;
    this.products = [];
  }
};

export default UserCatalog;
