import React from 'react';
import { BaseMessageComponent } from './types';

const MessageImageType: BaseMessageComponent = ({ displayMessage, message }) =>
  <div className='messageImage'>
    <p>{displayMessage.image ? displayMessage.image?.caption : message.image?.caption}</p>
    <img alt='preview' src={displayMessage.image ? displayMessage.image?.url : message.image?.url} />
  </div>;

export default MessageImageType;
