import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button/Button';
import { bdAnchorsSupport } from '../../../../../pages/Home/utils/consts';
import closeIcon from "../../../../../assets/close-icon-black.svg";
import './PayLockedWarn.scss';


type PayLockedWarnProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PayLockedWarn = ({ setIsOpen }: PayLockedWarnProps) => {
  const { t, i18n } = useTranslation();

  const onCloseClick = () => {
    setIsOpen(false);
  }

  return (
    <div className="payLockedWarn__wrapper">
      <div className="payLockedWarn">
        <div className="payLockedWarn__close"><img alt='close' src={closeIcon} onClick={onCloseClick} /></div>
        <h4>{t('subscriptions.pay_locked_warn.head')}</h4>
        <div className="payLockedWarn__content">
          <p>{t('subscriptions.pay_locked_warn.line')}</p>
        </div>
        <div className="payLockedWarn__buttons">
          <a href={bdAnchorsSupport[i18n.language]} target='_blank' rel='noreferrer'>
            {t('subscriptions.pay_locked_warn.support')}
          </a>
          <Button color='white' textType='regular' text={t('cancel')} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default PayLockedWarn;
