import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateTimeFormat } from '../../utils';
import './ItemNameDate.scss';


type ItemNameDateProps = {
  name: string;
  date?: string;
};

function ItemNameDate({ name, date }: ItemNameDateProps) {
  const { i18n } = useTranslation();
  
  return (
    <div className='itemNameDate'>
      <p className='itemNameDate__name'>{name}</p>
      {date &&
        <p className='itemNameDate__date'>
        {dateTimeFormat(date, 'shortDate', i18n.language)}, {dateTimeFormat(date, 'shortTime', i18n.language)}
        </p>
      }
    </div>
  );
}

export default ItemNameDate;
