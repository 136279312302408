import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { broadcastsPlanSelector } from '../../BroadcastsSelector';
import { parseDate } from '../../utils';
import PlanItem from '../PlanItem';
import './PlanList.scss';


function PlanList() {
  const { t } = useTranslation();
  const planlist = [...useSelector(broadcastsPlanSelector)];
  planlist.sort((a, b) => {
    if (a.plannedTime && b.plannedTime) {
      return parseDate(a.plannedTime).valueOf() - parseDate(b.plannedTime).valueOf();
    }
    return 0;
  });
  
  return (
    <>
    {planlist.length > 0
      ? planlist.map((item) =><PlanItem key={item.id} item={item} />)
      : (<div className='noPlan'><p>{t('broadcasts.plan.no_plan')}</p></div>)
    }
    </>
  );
}

export default PlanList;
