import moment from 'moment-timezone';
import 'moment/locale/es';
import 'moment/locale/ru';
import React from "react";
import { ReactComponent as WabaIcon } from '../../assets/logo-whatsapp-buiness.svg';
import { ReactComponent as WhatsAppIcon } from '../../assets/logo-whatsapp.svg';
import { ReactComponent as TelegramIcon } from '../../assets/logo-telegram.svg';
import { ReactComponent as TelegramBotIcon } from '../../assets/logo-telegram-bot.svg';
import {
  BroadcastErrorType,
  BroadcastStatusType,
  ChatConnectionsType,
  ChatSubscriptionType, IBroadcastMessage,
  IBroadcastMessageCreate, IBroadcastTypeMessage,
  IChats,
  IConditions, WabaTemplateCreate
} from "./BroadcastsTypes";


export const broadcastLogPageLimit = 'broadcastLogPageLimit';

export const broadcastServices = ['waba', 'whatsapp', 'telegram', 'telegram_bot'];

export type BroadcastsServicesType = Extract<'telegram'|'telegram_bot'|'waba'|'whatsapp', ChatConnectionsType>;

export type ServicesIconsType = Record<BroadcastsServicesType, React.FC<React.SVGProps<SVGSVGElement>>>;

export type ServiceContactType = 'phone' | 'username';

export const servicesContacts: Record<BroadcastsServicesType, ServiceContactType> = {
  'telegram': 'phone', 'telegram_bot': 'username', 'waba': 'phone', 'whatsapp': 'phone'
};

export type SourceType = {
  id: number;
  name: string;
  subscription: ChatSubscriptionType;
  type: ChatConnectionsType;
};

export const servicesIcons: ServicesIconsType = {
  waba: WabaIcon,
  whatsapp: WhatsAppIcon,
  telegram: TelegramIcon,
  telegram_bot: TelegramBotIcon,
};

export type ItemWithLogo<T> = T & { logo?: React.FC<React.SVGProps<SVGSVGElement>> };

export const addLogosToSources = (services: Array<SourceType>): Array<ItemWithLogo<SourceType>> =>
  services.map((service) => ({ ...service, logo: servicesIcons[service.type as BroadcastsServicesType] }));

export function getSelectConnectionType(sources: ItemWithLogo<SourceType>[], id: number) {
  if (sources) {
    const conn = sources.find((item) => item.id === id);
    if (conn) return conn.type;
  }
  return null;
}

export type BroadcastStatusAliasType = 'planned' | 'deliver' | 'finish' | 'error';

export const broadcastStatusAlias: Record<BroadcastStatusType, BroadcastStatusAliasType> = {
  FAILED: 'error',
  FINISHED: 'finish',
  IN_PROGRESS: 'deliver',
  PENDING: 'planned'
};

export type BroadcastErrorAliasType = 'connection_not_in_subscription' | 'subscription_not_paid' | 'generic_error';

export const broadcastErrorAlias: Record<BroadcastErrorType, BroadcastErrorAliasType> = {
  CONNECTION_NOT_IN_SUBSCRIPTION: 'connection_not_in_subscription',
  GENERIC_ERROR: 'generic_error',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid'
};

export function checkIConditions(item: IConditions | IChats): item is IConditions {
  if (item && (item as IConditions).logicOperation) {
    return true;
  }
  return false;
}

export function calcDuration(recipients: number) {
  // working coefficient for one recipient in broadcast is 1.2 seconds, return in minutes
  return Math.ceil(recipients * 0.02);
}

export function calcPercentage(total: number, part: number): number | undefined {
  if (part > 0) {
    return part / total * 100;
  }
  return undefined;
}

export type DateTimeFormats = 'fullDate' | 'shortDate' | 'fullTime' | 'shortTime';

const dateTimeFormatsLocale: Record<DateTimeFormats, string> = {
  fullTime: 'LTS',
  shortTime: 'LT',
  fullDate: 'LL',
  shortDate: 'L'
};

export function dateTimeFormat(date: string, template: DateTimeFormats,  locale?: string) {
  moment.locale(locale || 'ru');
  return moment(date).format(dateTimeFormatsLocale[template]);
}

export type TextMessageType = {
  type: IBroadcastTypeMessage;
  text: string;
  url?: string;
  file?: File;
  wabaTemplate?: WabaTemplateCreate;
}

export const getMessageViaTypes = (messageBase: IBroadcastMessage) => {
  if (messageBase.messageType === 'text') {
    return {
      caption: messageBase.text?.text,
    };
  }
  if (messageBase.messageType === 'waba_template') {
    return {
      waba_template: messageBase.wabaTemplate
    };
  }
  return {
    type: messageBase.messageType,
    caption: messageBase[messageBase.messageType]?.caption,
    url: messageBase[messageBase.messageType]?.url
  };
}

export const getUrlMessage = (message: IBroadcastMessageCreate) => {
  const url: { image: string; file: string; video: string } = {
    image: message.image?.url!,
    file: message.file?.url!,
    video: message.video?.url!,
  }

  return url[message.message_type];
}

export const getTextForMessage = (messageText: TextMessageType): IBroadcastMessageCreate | undefined => {
  if (messageText.type === 'text') {
    return {
      message_type: 'text', text: {
        text: messageText.text
      }
    }
  }
  if (messageText.type === 'image') {
    return {
      message_type: 'image', image: {
        caption: messageText.text,
        url: messageText.url
      }
    }
  }
  if (messageText.type === 'video') {
    return {
      message_type: 'video', video: {
        caption: messageText.text,
        url: messageText.url
      }
    }
  }
  if (messageText.type === 'file') {
    return {
      message_type: 'file', file: {
        caption: messageText.text,
        url: messageText.url
      }
    }
  }
  if (messageText.type === 'waba_template') {
    return {
      message_type: 'waba_template', waba_template: {
        template_locale: messageText.wabaTemplate?.template_locale,
        template_name: messageText.wabaTemplate?.template_name,
        header_params: messageText.wabaTemplate?.header_params,
        body_params: messageText.wabaTemplate?.body_params,
        params_count: messageText.wabaTemplate?.params_count,
      }
    }
  }
  return undefined;
}

export const getMessageForSubmit = (message: IBroadcastMessageCreate, url: string):
  IBroadcastMessageCreate | undefined => {
  if (message.message_type === 'text' && message.text) {
    return {
      message_type: 'text', text: {
        text: message.text.text
      }
    }
  }
  if (message.message_type === 'image' && message.image) {
    return {
      message_type: 'image', image: {
        caption: message.image.caption,
        url
      }
    }
  }
  if (message.message_type === 'video' && message.video) {
    return {
      message_type: 'video', video: {
        caption: message.video.caption,
        url
      }
    }
  }
  if (message.message_type === 'file' && message.file) {
    return {
      message_type: 'file', file: {
        caption: message.file.caption,
        url
      }
    }
  }
  if (message.message_type === 'waba_template') {
    return {
      message_type: 'waba_template', waba_template: {
        template_locale: message.waba_template?.template_locale,
        template_name: message.waba_template?.template_name,
        header_params: message.waba_template?.header_params,
        body_params: message.waba_template?.body_params
      }
    }
  }
  return undefined;
}

export function isDateBefore(date: string | undefined, target: string = '') {
  if (target === '') {
    const now = moment()
    return moment(date).isBefore(now);
  }
  return moment(date).isBefore(target);
}

export function isDateAfter(date: string | undefined, target: string = '') {
  if (target === '') {
    const now = moment()
    return moment(date).isAfter(now);
  }
  return moment(date).isAfter(target);
}

export function parseDate(date: string) {
  return moment(date).toDate();
}
