import React from 'react';
import { BaseMessageComponent } from './types';

const MessageFileType: BaseMessageComponent = ({ displayMessage, message }) =>
  <div className='messageFile'>
    <p>{displayMessage.file ? displayMessage.file?.caption : message.file?.caption}</p>
    <a target='_blank' href={displayMessage.file?.url} rel="noreferrer">{displayMessage.file ? displayMessage.file.name : message.file?.url}</a>
  </div>
;

export default MessageFileType;
