import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConnectionStatus } from '../../utils/connections';
import './ConnectionStatus.scss';


const statuses: Record<string, string> = {
  qr: 'connections_statuses.qr',
  loading: 'connections_statuses.loading',
  ok: 'connections_statuses.ok',
  not_paid: 'connections_statuses.not_paid',
  conflict: 'connections_statuses.conflict',
};

const ConnectionStatus = () => {
  const { t } = useTranslation();
  const status = useConnectionStatus();
 
  return (
    <div className="connectionStatus">
      {status
        ? <>
            <div className={`connectionStatus_point ${status.status}`} />
            <p>{`${t('connections.whatsapp.main-view.status')}: ${t(statuses[status.status])}`}</p>
          </>
        : null
      }
    </div>
  );
};

export default ConnectionStatus;
