import './NotificationListEvents.scss'
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { ChannelSchema } from '../../types/types';
import SwitchControl from '../../../../../components/SwitchControl';


const NotificationListEvents = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext<ChannelSchema>();
  const isDisabled = getValues('phone') === '';

  return (
    <div className='NotificationListEvents'>
      <SwitchControl name='events.integration' label={t('settings.notifications.switches.integration')}
        disabled={isDisabled} />
      <SwitchControl name='events.payment' label={t('settings.notifications.switches.payment')}
        disabled={isDisabled} />
      <SwitchControl name='events.broadcast' label={t('settings.notifications.switches.broadcast')}
        disabled={isDisabled} />
    </div>
  );
};

export default NotificationListEvents;
