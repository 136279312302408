import './TinkoffCreate.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../../../../store/store';
import { createConnection } from '../../../connectionsSlice';
import { currentCompanySelector } from '../../../../../Chat/companiesSelector';
import { TinkoffCreateParams } from '../../../ConnectionsTypes';
import { settingConnections } from '../../../utils/connections';
import { DEFAULT_LIFETIME, MAX_LIFETIME, MIN_LIFETIME, taxationTypes } from '../../../utils/terminals';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import Button from '../../../../../../components/Button/Button';
import GoBackIcon from '../../../../../../assets/template-backward-icon.svg';
import Input from '../../../../../../components/Input/Input';
import Dropdown from '../../../../Dropdown/Dropdown';
import LifetimePayment from "../../../components/LifetimePayment";


type TinkoffSchema = {
  name: string;
  params: TinkoffCreateParams;
};

const TinkoffCreate = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      terminal_id: yup.string().required(t('error.form.empty_field'))
        .max(128, t('error.form.max_length', { limit: 128 })),
      terminal_password: yup.string().required(t('error.form.empty_field'))
        .max(128, t('error.form.max_length', { limit: 128 })),
      taxation: yup.string(),
      invoice_lifetime_minutes: yup.number().min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });
  const methods = useForm<TinkoffSchema>({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: {
      name: '',
      params: {
        terminal_id: '',
        terminal_password: '',
        taxation: 'osn',
        invoice_lifetime_minutes: DEFAULT_LIFETIME,
      },
    },
  });

  const gotoBack = () => goBack();

  const onSubmit = (data: TinkoffSchema) => {
    dispatch(createConnection({
      companyId: currentCompany.id,
      name: data.name,
      type: 'tinkoff',
      params: { ...data.params }
    })).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        if (currentCompany.hadConnections === false) {
          YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
          dispatch(setHadConnections(true));
        }
        gotoBack();
      }
    });
  };

  return (
    <div className="tinkoffCreate">
      <div className="tinkoffCreate__header">
        <Button color='white' textType='regular' text={t('back')} onClick={gotoBack} image={GoBackIcon} />
        <h3>{t(`${settingConnections.tinkoff?.name}`)}</h3>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input name="name" label={t('connections.connection_name')} />
          <Input name="params.terminal_id" label="Terminal key" />
          <Input name="params.terminal_password" label="Terminal Secret" inputType="password" />
          <Dropdown
            items={taxationTypes}
            label="Система налогообложения"
            name="params.taxation"
            labelKey="option"
            valueKey="id"
          />
          <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={DEFAULT_LIFETIME} />
          <Button type='submit' color='orange' textType='regular' text={t('connections.tinkoff.add_button')}
            disabled={!methods.formState.isDirty} />
        </form>
      </FormProvider>
    </div>
  );
}

export default TinkoffCreate;
