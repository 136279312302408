import React, { useState, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { useKeycloak } from '@react-keycloak/web';
import { convertor } from '../../../../api/messageConvertor';
import { MessageRequest, NewMessage, SocketDeliveryStatus } from '../../../../api/types';
import { AppDispatch, useAppDispatch } from '../../../../store/store';

type Sockets = {
  chatSocket: SocketWrapper;
};

export type ConvertedMessage = {
  connectionId: number;
  chatId: number;
  contactId: number;
  message: string;
};

export class SocketWrapper {
  socket: Socket;

  dispatch: AppDispatch | undefined;

  constructor(socket: Socket, dispatch?: AppDispatch) {
    this.socket = socket;
    if (dispatch) {
      this.dispatch = dispatch;
    }
  }

  emit(channel: string, payload: unknown) {
    this.socket.emit(channel, payload);
  }

  onNewMessage(handler: (convertedMessage: ConvertedMessage, message: MessageRequest) => void) {
    const handlerWrapper = (newMessage: NewMessage) => {
      const convertedMessage: ConvertedMessage = convertor(newMessage);
      const message = convertor(JSON.parse(convertedMessage.message));
      handler(convertedMessage, message);
    };
    this.socket.on('new_message', handlerWrapper);
  }

  offNewMessage(handler: (convertedMessage: ConvertedMessage, message: MessageRequest) => void) {
    this.socket.off('new_message');
  }

  onNewDeliveryStatus(handler: (deliveryStatus: SocketDeliveryStatus) => void) {
    const handleWrapper = (deliveryStatus: string) => {
      const convertedDeliveryStatus = convertor(deliveryStatus);
      handler(convertedDeliveryStatus);
    };
    this.socket.on('delivery_status', handleWrapper);
  }

  offNewDeliveryStatus(handler: (deliveryStatus: SocketDeliveryStatus) => void) {
    const handleWrapper = (deliveryStatus: string) => {
      const convertedDeliveryStatus = convertor(deliveryStatus);
      handler(convertedDeliveryStatus);
    };
    this.socket.off('delivery_status', handleWrapper);
  }

  off(event: string) {
    // @ts-ignore
    this.socket.removeAllListeners(event);
  }

  listeners(event: string): unknown {
    return this.socket.listeners(event);
  }

  onReconnect(handleReconnect: () => void) {
    this.socket.io.on('reconnect', handleReconnect);
  }

  offReconnect(handleReconnect: () => void) {
    this.socket.io.off('reconnect', handleReconnect);
  }
}

const SocketIOContext = React.createContext<Sockets | undefined>(undefined);

const SocketIOProvider: React.FC = ({ children }) => {
  const [sockets, setSockets] = useState<Sockets>();
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const wsUrl: string = process.env.REACT_APP_BASE_WS_URL as string;

  useEffect(() => {
    if (keycloak) {
      setSockets({
        chatSocket: new SocketWrapper(
          io(wsUrl, {
            path: '/ws/socket.io/',
            transports: ['websocket', 'polling'],
            auth: (cb) => {
              cb({ token: keycloak.token })
            },
          }),
          dispatch
        ),
      });
    }
  }, [keycloak]);

  return <>{sockets ? <SocketIOContext.Provider value={sockets}>{children}</SocketIOContext.Provider> : null}</>;
};

const useSocketIO = () => {
  const sockets = React.useContext(SocketIOContext);
  if (sockets === undefined) {
    throw new Error('useSocketIO must be used within a SocketIOContext');
  }
  return sockets;
};

export default SocketIOProvider;
export { useSocketIO };
