import React from 'react';
import ReactDOM from 'react-dom';

const UserConfirmation = (message: string, callback: (ok: boolean) => void) => {
  const data: { yes: string; no: string; message: string } = JSON.parse(message);
  const container = document.createElement('div');
  container.classList.add('prompt');
  document.body.appendChild(container);

  const onCloseModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <div className="promptContainer">
      <p className="promptContainer__message">{data.message}</p>
      <div className="promptContainer__buttonsContainer">
        <button className="promptContainer__confirmButton" type="button" onClick={() => onCloseModal(false)}>
          {data.no}
        </button>
        <button className="promptContainer__declineButton" type="button" onClick={() => onCloseModal(true)}>
          {data.yes}
        </button>
      </div>
    </div>,
    container
  );
};

export default UserConfirmation;
