import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubMenuTabsType } from '../../subMenuLists';


type Props = {
  tab: SubMenuTabsType;
  isActive: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const LinkForSubMenu = ({ tab, isActive, ...rest }: Props) => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ id: string }>('/companies/:companyId');
  
  return (
    <Link to={`${url?.url}/${tab.url}`}>
      <div className={`tabShadow ${isActive ? 'active' : ''}`} {...rest} >
        <div className='tab'>
          <tab.Icon />
          <p>{t(`${tab.title}`)}</p>
        </div>
      </div>
    </Link>
  );
};

export default LinkForSubMenu;
