import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import RoleModal from '../../../RoleModal/RoleModal';
import { deleteRoleMember, getMember, setDeleteRoleCompany } from '../../../../../Chat/companiesSlice';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { useAppDispatch } from '../../../../../../store/store';
import { RoleType } from '../../../../../../api/CompanyAPI';
import { getNameRole } from '../../utlity';
import RolesDropdown from './RolesDropdown/RolesDropdown';



type PermissionRemoveProps = {
  isRoleDelete: number | null
  setIsRoleDelete: React.Dispatch<React.SetStateAction<number | null>>
  roles: RoleType[]
  onCloseEditCard: () => void
  currentRole: RoleType | null
  isOwner: boolean
}
const RoleRemove = ({
                      isRoleDelete,
                      setIsRoleDelete,
                      roles,
                      onCloseEditCard,
                      currentRole,
                      isOwner,
                    }: PermissionRemoveProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [changeRole, setChangeRole] = useState<number>(0);

  const optionsRoles = roles.filter(i => i.id !== currentRole?.id)
    .map(m => ({ id: m.id, name: getNameRole(m.name, isOwner, t) }));


  const handlerCloseDeleteRoleModal = () => {
    setIsRoleDelete(null);
  };

  const submitDeleteRoleModal = () => {
    if (isRoleDelete && changeRole && currentCompanyId?.params.companyId) {
      dispatch(deleteRoleMember({
        roleId: isRoleDelete,
        companyId: Number(currentCompanyId?.params.companyId),
        replacement_role_id: changeRole,
      })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(setDeleteRoleCompany(isRoleDelete));
          dispatch(getMember(Number(currentCompanyId?.params.companyId)));
          setIsRoleDelete(null);
          onCloseEditCard();
          dispatch(addAlertWithCustomText({
            message: t(`errors.${100000}`),
            color: '#2CBA5F',
          }));
        }
      });
    }
  };

  useEffect(() => {
    const findManager = roles.find(i => i.name === 'MANAGER');
    setChangeRole(findManager ? findManager.id : 0);
  }, []);

  if (!isRoleDelete) {
    return null;
  }
  return (
    <RoleModal
      titleMain={t('settings.users.user.deleteRole')}
      textSubmit={t('settings.users.user.deleteRoleSubmit')}
      title={t('settings.users.user.deleteRole')}
      customText={t('settings.users.user.deleteRoleText')}
      handlerSubmitModal={submitDeleteRoleModal}
      handlerCloseModal={handlerCloseDeleteRoleModal}
      disabled={false}
      error={false}
      errorText=''
    >
      <RolesDropdown
        list={optionsRoles}
        value={changeRole}
        onChange={setChangeRole}
      />
    </RoleModal>
  );
};

export default RoleRemove;
