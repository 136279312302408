import React from 'react';
import ReactDOM from 'react-dom';
import './MobileInfo.scss';
import CloseIcon from '../../../assets/cancel.svg';


type MobileInfoProps = {
  message: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

function MobileInfo({ message, setIsOpen }: MobileInfoProps) {

  const onCloseClick = () => setIsOpen(false);

  return ReactDOM.createPortal(
    <div className='mobileInfo'>
      <div className='mobileInfo__message'>{message}</div>
      <img alt='close' src={CloseIcon} onClick={onCloseClick} />
    </div>, document.body
  );
}

export default MobileInfo;
