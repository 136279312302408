import './ChatPlug.scss';
import { useTranslation } from 'react-i18next';
import plugImage from '../../../../assets/Saly-25.png';

function ChatPlug() {
  const { t } = useTranslation();

  return (
    <div className="chatPlug">
      <img src={plugImage} alt="plug img" />
      <p>{t('chats.plug')}</p>
    </div>
  );
}

export default ChatPlug;
