import React, { useRef } from 'react';
import './PopupWrapper.scss';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as TriangleTail } from '../../assets/dropdown-after-triangle.svg';
import { useMobile, useOutsideClick } from '../utils/hooks';


type Props = {
  direction: string;
  title: string;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClosePopupWrapper: (e: React.MouseEvent<HTMLElement>) => void;
  triggerButton?: React.RefObject<HTMLElement>;
} & React.HTMLAttributes<HTMLDivElement>;

const PopupWrapper: React.FC<Props> = ({ direction, title, children, isVisible, onClosePopupWrapper, triggerButton, ...props }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(wrapperRef, onClosePopupWrapper, triggerButton);
  const isMobile = useMobile();

  return (
    <div ref={wrapperRef} className={`popupWrapper ${direction}`}
         style={{
           visibility: isVisible ? 'visible' : 'hidden',
           opacity: isVisible ? '1' : '0',
         }}
         onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
         {...props}
    >
      <div className="popupWrapper__title">
        {isMobile && direction === 'top' ? <h3>{title}</h3> : <h4>{title}</h4>}
      </div>
      {children}
      <div className="popupWrapper__closeIcon" onClick={onClosePopupWrapper}>
        <CloseIcon />
      </div>
      <TriangleTail className={`popupWrapper__triangleTail ${direction}`}/>
    </div>
  );
};

export default PopupWrapper;
