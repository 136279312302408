import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import SidePanel from '../../../../../components/Modals/SidePanel';
import CatalogsCard from '../CatalogsCard';
import CatalogsForm from '../CatalogsForm';
import UserCatalog from '../../models/UserCatalog';
import { clearProductsList, removeCatatlog, setCatalogIdRemove } from '../../../SalesSlice';
import { catalogsUploadContext } from '../../../types';
import './CatalogsList.scss';
import { useHasPermission } from '../../../../../utils/WrapperPermission/WrapperPermission';


type CatalogsListProps = {
  catalogs: UserCatalog[];
};

const CatalogsList = ({ catalogs }: CatalogsListProps) => {
  const { t } = useTranslation();
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const dispatch = useDispatch();
  const { hasMore, onUpload } = useContext(catalogsUploadContext);
  const [catalogSelected, setCatalogSelected] = useState<UserCatalog | undefined>();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const hasDelete = useHasPermission('salesCatalogDelete');
  const hasEdit = useHasPermission('salesCatalogEdit');

  const deleteCatalog = () => {
    if (catalogSelected) {
      dispatch(setCatalogIdRemove(catalogSelected.id));
      dispatch(removeCatatlog({ companyId: catalogSelected.companyId, catalogId: catalogSelected.id }));
      setCatalogSelected(undefined);
      setDeleteOpen(false);
    }
  };

  const onEditCatalog = async (catalog: UserCatalog) => {
    setCatalogSelected(catalog);
    setOpenCreateForm(true);
  };

  const onDeleteCatalog = (catalog: UserCatalog) => {
    setCatalogSelected(catalog);
    setDeleteOpen(true);
  };

  const gotoCatalog = (catalog: UserCatalog) => {
    dispatch(clearProductsList());
    dispatch(push(`${url?.url}/catalogs/${catalog.id}`));
  };

  const getDeleteConfimMessage = () => t('sales.catalogs.delete_confirm.warning', { catalog: catalogSelected?.name });

  return (
    <InfiniteScroll
      next={onUpload}
      hasMore={hasMore}
      inverse={false}
      scrollableTarget='scrollingDiv'
      loader={null}
      dataLength={catalogs.length}
    >
      <div className="catalogsList" id="scrollingDiv">
        {openCreateForm && (
          <SidePanel setIsOpen={setOpenCreateForm}>
            <CatalogsForm catalog={catalogSelected} setFormOpen={setOpenCreateForm}/>
          </SidePanel>
        )}
        {deleteOpen &&
          <ActionDialog action={t('delete')} cancel={t('cancel')} onAction={deleteCatalog} setIsOpen={setDeleteOpen}
            background='light' title={t('sales.catalogs.delete_confirm.head')} message={getDeleteConfimMessage()} />}
        {catalogs.map((item) => (
          <CatalogsCard key={item.id} catalog={item} onAbout={gotoCatalog}
            onDelete={hasDelete ? onDeleteCatalog : undefined} onEdit={hasEdit ? onEditCatalog : undefined} />
        ))}
      </div>
    </InfiniteScroll>
  );
}

export default CatalogsList;
