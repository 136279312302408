import { useEffect, useState } from 'react';
import './CreateEditShop.scss';
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import Dropdown from '../../../../Settings/Dropdown/Dropdown';
import ComeBackList from '../../../components/ComeBackList/ComeBackList';
import Button from '../../../../../components/Button/Button';
import { createShop, getCatalogs, updateShop } from '../../shopsSlice/shopsSlice';
import { catalogsSelector } from '../../shopsSlice/shopsSelector';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { fetchConnectionsList } from '../../../../Settings/Connections/connectionsSlice';


type SubmitSchema = {
  name: string;
  catalogId: number;
  connectionId: number;
};

const widthInput = { width: '100%' };
const defaultValues: SubmitSchema = {
  name: '',
  catalogId: 0,
  connectionId: 0,
};

const CreateEditShop = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const modeEdit = localStorage.getItem('editShop');
  const catalogsAndCount = useSelector(catalogsSelector);
  const telegramBot = "telegram_bot";
  const connectionsTelegramBot = useAppSelector((state) => state.connections.connectionsStore[telegramBot]);

  const [clicked, setClicked] = useState<boolean>(false);

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')),
    catalogId: yup.number()
      .min(1, t('error.form.empty_field'))
      .typeError(t('error.form.empty_field'))
      .required(t('error.form.empty_field'))
    ,
    connectionId: yup.number()
      .min(1, t('error.form.empty_field'))
      .typeError(t('error.form.empty_field'))
      .required(t('error.form.empty_field')),
  });

  const methods = useForm<SubmitSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handlerComeBack = () => {
    dispatch(push(`${params?.url}/shops`));
  };

  const onSubmit = (data: SubmitSchema) => {
    if (params?.params?.companyId) {
      if (modeEdit) {
        setClicked(true);
        const convertModeEdit = JSON.parse(modeEdit);
        dispatch(updateShop({
          companyId: params.params.companyId,
          catalogId: data.catalogId,
          connectionId: data.connectionId,
          name: data.name,
          shopId: convertModeEdit.id,
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            handlerComeBack();
          }
        });
      } else {
        setClicked(true);
        dispatch(createShop({
          companyId: params.params.companyId,
          catalogId: data.catalogId,
          connectionId: data.connectionId,
          name: data.name,
        })).then(res => {
          if (res.meta.requestStatus === 'fulfilled' && typeof res.payload === 'object') {
            history.push({ pathname: `${params?.url}/shops`, state: res.payload.link });
          }
        });
      }
    }
  };

  useEffect(() => {
    if (modeEdit) {
      const { name, connectionId, catalogId } = JSON.parse(modeEdit);
      methods.reset({
        name,
        catalogId,
        connectionId,
      });
    }
  }, [modeEdit]);

  useEffect(() => {
    if (params?.params.companyId) {
      dispatch(getCatalogs({
        companyId: params.params.companyId,
      }));

      dispatch(fetchConnectionsList({
        companyId: Number(params?.params.companyId),
        types: telegramBot
      }));
    }
    return () => {
      localStorage.removeItem('editShop');
    };
  }, []);

  return (
    <FormProvider {...methods} >
      <ComeBackList cb={handlerComeBack}
                    title={t(`${modeEdit ? 'sales.shops.editShop' : 'sales.shops.createShop'}`)}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className='createEditShop'>
            <div className='createEditShop__form'>
              <div className='createEditShop__form__list'>
                <div className='createEditShop__form__list__name'>
                  <p
                    className={`createEditShop__form__list__name_title ${methods.formState.errors.name ? 'error' : ''}`}>{t('sales.shops.nameShop')}</p>
                  <input {...methods.register('name')} type='text'
                         className={`createEditShop__form__list__name_input ${methods.formState.errors.name ? 'error' : ''}`} />
                  <ErrorMessage
                    errors={methods.formState.errors}
                    name='name'
                    render={({ message }) => <p
                      className='createEditShop__form__list__name_error'>{message}</p>}
                  />
                </div>
                <Dropdown label={t('sales.shops.selectCatalog')}
                          items={catalogsAndCount?.items || []}
                          labelKey='name' valueKey='id' name='catalogId'
                          style={widthInput}
                          placeholder={t('select')}
                />
                <Dropdown
                  label={t('sales.shops.selectConnection')}
                  items={connectionsTelegramBot} labelKey='name' valueKey='id'
                  name='connectionId' style={widthInput}
                  placeholder={t('select')}
                />
              </div>
            </div>
            <div className={`createEditShop__bottom ${clicked ? 'clicked': ''}`}>
              <Button text={modeEdit
                ? t('save_edit')
                : t('sales.shops.createShop')} color='orange'
                      textType='regular' disabled={!methods.formState.isDirty || clicked}
                      type='submit'
                      style={{ padding: '14px 16px' }} />
            </div>
          </div>
        </form>
      </ComeBackList>

    </FormProvider>

  );
};

export default CreateEditShop;



