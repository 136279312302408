import React, { useEffect, useRef, useState } from 'react';
import './ConversationHeader.scss';
import { useDispatch, useSelector } from 'react-redux';
import {useDebounce} from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from '../../../../assets/plus.svg';
import { ReactComponent as ClearIcon } from "../../../../assets/cancel.svg";
import { fetchChatsWithContactsBySearchedValue, updateSearchedChatsValue, updateSearchedChatsWithContact } from '../../chatsSlice';
import { currentStepSelector } from '../../../../pages/Onboarding/onboardingSelectors';
import { useMobile, useOutsideClick } from '../../../../components/utils/hooks';
import { WrapperPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import { updateIsViewNavbarMobile } from "../../companiesSlice";
import CreateChatPopup from '../CreateChatPopup/CreateChatPopup';
import Search from "../../../../components/Search/Search";


const ConversationHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isVisibleCreateChatPopup, setIsVisibleCreateChatPopup] = useState(false);
  const [isVisibleSearch, setIsVisibleSearch] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [query] = useDebounce(search, 400);
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const currentOnboardStep = useSelector(currentStepSelector);
  const isMobile = useMobile();

  useOutsideClick(ref, () => setIsVisibleCreateChatPopup(false), triggerRef);

  useEffect(() => () => {
    dispatch(updateSearchedChatsWithContact(null));
    dispatch(updateSearchedChatsValue(''));
  },[]);

  useEffect(() => {
    if (search.length === 0) {
      dispatch(updateSearchedChatsWithContact(null));
      dispatch(updateSearchedChatsValue(''));
    } else {
      dispatch(fetchChatsWithContactsBySearchedValue({ searchedValue: search }));
      dispatch(updateSearchedChatsValue(search));
    }
  },[query]);

  const handlerChatPopup = () => {
    setIsVisibleCreateChatPopup(!isVisibleCreateChatPopup);
  };

  const onClose = () => {
    setIsVisibleSearch(false);
    dispatch(updateIsViewNavbarMobile(true));
  }

  return (
    <div className='conversationHeader'>
      {!isVisibleSearch &&
        <>
          {isMobile && <h1>{t('navbar.chats')}</h1>}
          <WrapperPermission permission='messagingChatCreate'>
            <div className='conversationHeader__addChatButton' ref={triggerRef} onClick={handlerChatPopup}>
              <PlusIcon />
            </div>
          </WrapperPermission>
          <div className="conversationHeader__createChatPopup">
            {(isVisibleCreateChatPopup || currentOnboardStep === 6) && (
              <CreateChatPopup ref={ref} position='new' setIsVisibleCreateChatPopup={setIsVisibleCreateChatPopup} />
            )}
          </div>
        </>
      }
      <Search onChangeSearchInput={setSearch} value={search} isMobileRolled={isMobile && !isVisibleSearch}
        setIsVisibleSearch={setIsVisibleSearch} />
      {isVisibleSearch && <ClearIcon className='closeIcon' onClick={onClose} />}
    </div>
  );
};

export default ConversationHeader;
