import { useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getIntegrationsTypes, integrationsState } from "./integrationSlice";
import { integrationsTypesSelector } from "./integrationSelectors";
import IntegrationDescription from './IntegrationDescription';
import IntegrationDescriptionBitrix from './IntegrationDescriptionBitrix';


const IntegrationDescriptions = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const url = useRouteMatch<{ id: string }>('/companies/:id');
  const integrationsTypesArr = useSelector(integrationsTypesSelector);
  const { integrationName } = useParams<{ integrationName: string }>();
  const [integrationType, setIntegrationType] = useState<string>('');

  const getIntegrationConnectionType = () => {
    // get integration connection type
    let connectionType: string = '';
    if (integrationName) {
      if (integrationName) {
        const typeNameParts = integrationName.split("_");
        if (typeNameParts) {
            connectionType = typeNameParts[typeNameParts.length - 1].toLowerCase();
        }
      }
    }
    setIntegrationType(connectionType);
  };

  useEffect(() => {
    dispatch(integrationsState(Number(url?.params.id)));
    if (integrationsTypesArr.length === 0) {
      dispatch(getIntegrationsTypes({
        companyId: Number(url?.params.id),
        language: i18n.language
      }));
    }
  }, []);

  useEffect(() => getIntegrationConnectionType(), []);

  if (integrationType === 'amocrm') {
    return <IntegrationDescription />;
  }

  if (integrationType === 'bitrix') {
    return <IntegrationDescriptionBitrix />;
  }

  return <>&quot;UNSUPPORTED CONNECTION TYPE&quot;</>;
};

export default IntegrationDescriptions;
