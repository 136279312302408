import React, { useEffect, useState } from 'react';
import './Switch.scss';
import { useFormContext } from 'react-hook-form';

type Props<T> = {
  items: Array<T>;
  name: string;
};

const Switch = <T extends { id: number | string; payload: string | number | boolean; name: string }>({
  name,
  items,
}: Props<T>) => {
  const { setValue, watch, formState } = useFormContext();
  const [selectedItem, setSelectedItem] = useState<T>();

  const onSelectItem = (item: T) => {
    setValue(name, item.payload, { shouldDirty: true });
  };

  useEffect(() => {
    const currentItemValue = watch(name);

    if (currentItemValue === undefined) {
      return;
    }

    const foundItem = items.find((item) => item.payload === currentItemValue);

    setSelectedItem(foundItem);
  }, [formState]);

  return (
    <div className="switch">
      {items.map((item) => (
        <div
          className={`switch__item ${selectedItem && selectedItem.id === item.id && 'active'}`}
          key={item.id}
          onClick={() => onSelectItem(item)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default Switch;
