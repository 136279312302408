import React, { useEffect, useState } from 'react';
import './WhatsAppCreateConnectionView.scss';
import * as yup from 'yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouteMatch } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Trans, useTranslation } from 'react-i18next';
import { ConnectionStatus, WhatsAppConnection, WhatsAppSchema } from '../../../../../../api/CompanyAPI';
import { getConnectionStatus } from '../../../ConnectionsAPI';
import { useAppDispatch } from '../../../../../../store/store';
import { addAlertWithCustomText } from '../../../../../../components/Alert/alertSlice';
import { createConnection } from '../../../connectionsSlice';
import { YandexMetrikaCall } from '../../../../../../utils/utils';
import { setHadConnections } from "../../../../../Chat/companiesSlice";
import Input from '../../../../../../components/Input/Input';
import WhatsAppCopyQr from '../WhatsAppCopyQr';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import qrCodePlug from '../../../../../../assets/qrCode-mask.svg';
import loader from '../../../../../../assets/grid.svg';
import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../../../../Chat/companiesSelector";
import ListItems from "../../../../../../components/ListItems";


type Statuses = {
  [index: string]: {
    name: string;
    color: string;
    textColor: string;
  };
};

const WhatsAppCreateConnectionView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentCompany = useSelector(currentCompanySelector);
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const url = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const [status, setStatus] = useState<ConnectionStatus>({ status: 'not_created' });
  const [connection, setConnection] = useState<WhatsAppConnection>();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(2083, t('error.form.max_length', { limit: 2083 })),
  });

  const methods = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });

  const statuses: Statuses = {
    loading: {
      color: '#FFFFFF',
      name: t('connections_statuses.loading'),
      textColor: '#FE6D04',
    },
    not_created: {
      color: '#FE6D04',
      name: t('connections_statuses.not_created'),
      textColor: '#FFFFFF',
    },
    ok: {
      color: '#2CBA5F',
      name: t('connections_statuses.ok'),
      textColor: '#FFFFFF',
    },
    not_paid: {
      color: '#2CBA5F',
      name: t('connections_statuses.not_paid'),
      textColor: '#FFFFFF',
    },
    conflict: {
      color: '#2CBA5F',
      name: t('connections_statuses.conflict'),
      textColor: '#FFFFFF',
    },
  };

  useEffect(() => {
    if (status.status === 'ok' && url && connection) {
      if (currentCompany.hadConnections === false) {
        YandexMetrikaCall('reachGoal', 'firstSuccessChanel');
        dispatch(setHadConnections(true));
      }
      dispatch(
        addAlertWithCustomText({
          message: `${t('connections.whatsapp.add-view.number')} ${connection.params.phone || ''} ${t(
            'connections.whatsapp.add-view.connected'
          )}`,
        })
      );
      dispatch(push(`/companies/${url.params.companyId}/settings/whatsapp`));
    }
  }, [status, url, connection]);

  useEffect(
    () => () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    },
    [intervalId]
  );

  const onCreateConnection: SubmitHandler<WhatsAppSchema> = async (data) => {
    if (status.status === 'not_created') {
      setStatus({ status: 'loading' });
      const fetchedConnection = await dispatch(createConnection({
        companyId: Number(currentCompanyId?.params.companyId),
        name: data.name,
        type: 'whatsapp'
      })).unwrap();
      if (fetchedConnection && currentCompanyId?.params.companyId) {
        const callback = async () => {
          const connectionStatus = await getConnectionStatus({
            connectionId: fetchedConnection.id,
            companyId: Number(currentCompanyId?.params.companyId)
          });
          setStatus(connectionStatus);
          if (connectionStatus.status === 'ok') {
            clearInterval(id);
          }
        };
        // Немедленно выполнить
        callback();
        // После задать интервал
        const id = setInterval(callback, 15000);
        setIntervalId(id);
      }
      setConnection(fetchedConnection as WhatsAppConnection);
    }
  };

  return (
    <GoBackWrapper title={t('connections.whatsapp.add-view.title')}>
      <div className="whatsAppCreateConnectionView">
        <FormProvider {...methods}>
          <Input label={t('connections.whatsapp.add-view.input_title')} name="name" />
        </FormProvider>
        <div className="whatsAppCreateConnectionView__description">
          <Trans i18nKey="connections.whatsapp.add-view.description.text1">
            plane<strong>strong1</strong><strong>strong2</strong>
          </Trans>
          <p>
            <Trans i18nKey="connections.whatsapp.add-view.description.text2">
              plane<strong>strong1</strong>
            </Trans>
          </p>
        </div>
        <div className="whatsAppCreateConnectionView__recommendation">
          <h4>{t('connections.whatsapp.add-view.description.recommendation')}</h4>
          <ListItems typeList='ol' items={t('connections.whatsapp.add-view.description.steps').split('\n')} />
        </div>
        <div className="whatsAppCreateConnectionView__qrCodeWrapper">
          <div className="whatsAppCreateConnectionView__qrCodeWrapper_qrcode">
            <img
              alt="qr code"
              src={status.status !== 'qr' ? qrCodePlug : status.qrCode}
              style={{ opacity: status.status === 'qr' ? 1 : 0.1 }}
            />
            {status.status !== 'qr' && (
              <button
                onClick={status.status === 'not_created' ? methods.handleSubmit(onCreateConnection) : undefined}
                style={{
                  backgroundColor: statuses[status.status].color,
                  color: statuses[status.status].textColor,
                }}
              >
                {statuses[status.status].name}
                {status.status === 'loading' && (
                  <>
                    <br />
                    <img width={20} height={20} src={loader} alt="" style={{ marginTop: 4 }} />
                  </>
                )}
              </button>
            )}
            {status.status === 'qr' && (
              <div className="copyQR">
                <WhatsAppCopyQr codeQr={connection?.params.qrUrl || ''} />
              </div>
            )}
          </div>
          <div className="whatsAppCreateConnectionView__qrCodeWrapper_description">
            <p className="whatsAppCreateConnectionView__qrCodeWrapper_description-title">
              {t('connections.whatsapp.add-view.description.qr_code.description_title')}
            </p>
            <p className="whatsAppCreateConnectionView__qrCodeWrapper_description-subdescription">
              {t('connections.whatsapp.add-view.description.qr_code.description_subtitle')}
            </p>
          </div>
        </div>
      </div>
    </GoBackWrapper>
  );
};

export default WhatsAppCreateConnectionView;
