import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionType, ICondition } from '../../../BroadcastsTypes';
import TagItemName from '../TagItemName';
import { ReactComponent as DeleteIcon } from '../../../../../assets/cross-circled.svg';
import './ConditionItem.scss';


type ConditionItemProps = {
  condition: ICondition;
  onDelete?: (condition: ICondition) => void;
  viewOnly: boolean;
};

const conditionsItems: Record<ConditionType, React.FunctionComponent<{value: number}>> = {
  tag: TagItemName
};

function ConditionItem({ condition, onDelete, viewOnly }: ConditionItemProps) {
  const { t } = useTranslation();

  const ItemNameComponent = conditionsItems[condition.conditionType];
  const onDeleteHandler = () => {
    if (onDelete) onDelete(condition);
  };

  return (
    <div className='conditionItem'>
      <div className='conditionItem__title'>{t('broadcasts.form.tag')}</div>
      <div className='conditionItem__condition'>
        {condition.comparison === '=' ? t('broadcasts.form.condition_equal') : t('broadcasts.form.condition_noequal')}
      </div>
      <ItemNameComponent value={condition.value} />
      {!viewOnly && <DeleteIcon onClick={onDeleteHandler}/>}
    </div>
  );
}

export default ConditionItem;
