import React from 'react';
import './SwitchControl.scss';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';


type SwitchControlProps = {
  label: string;
  name: string;
  info?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

function SwitchControl({ label, name, info, ...props }: SwitchControlProps) {
  const { register } = useFormContext();

  return (
    <fieldset className='switchControl'>
      <label>
        <input {...register(name)} type="checkbox" id="switch" {...props} />
        <span id="checkmark"/>
        <span>{label}</span>
      </label>
        {info && <span className="info__block">
          <span className="info__icon"><InfoIcon /></span>
          <div className="info__message">{info}</div>
        </span>}
    </fieldset>
  );
}

export default SwitchControl;
