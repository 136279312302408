import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getIntegrationTypes,
  postIntegrationState,
  getIntegrationStateInfo,
  getIntegrations,
  updateIntegrations,
  postIntegrationAPI,
  deleteIntegration,
} from './IntegrationAPI';
import * as typ from './IntegrationTypes';


export type InitialState = {
  integrations: typ.Integrations;
  integrationsTypes: typ.IntegrationType[];
  state: typ.IntegrationsState;
  stateInfo: typ.IntegrationsStateInfo;
};

const initialState: InitialState = {
  integrations: {} as typ.Integrations,
  integrationsTypes: [],
  state: {} as typ.IntegrationsState,
  stateInfo: {} as typ.IntegrationsStateInfo,
};

export const getIntegrationsTypes = createAsyncThunk('integrations/getIntegrationsTypes',
  async (requestOptions: { language: string, companyId: number }) => await getIntegrationTypes(requestOptions)
);

export const getIntegrationsList = createAsyncThunk('integrations/getIntegrationsList',
  async (companyId: number) => await getIntegrations(companyId)
);

export const integrationsState = createAsyncThunk('integrations/integrationsState',
  async (companyId: number) => await postIntegrationState(companyId)
);

export const integrationsStateInfo =
  createAsyncThunk<typ.IntegrationsStateInfo, { companyId: number, state_value: string }, { rejectValue: number }>(
    'integrations/getIntegrationStateInfo',
    async (requestOptions, { rejectWithValue }) => {
      try {
        return await getIntegrationStateInfo(requestOptions);
      } catch (e) {
        return rejectWithValue(e.response.data.error_code);
      }
    }
  );

type IntegrationsUpdateType = {
  integration_id: number;
  companyId: number
  requestOption: typ.IntegrationsUpdate;
};

export const integrationsUpdate = 
createAsyncThunk<typ.IntegrationsItem, IntegrationsUpdateType, { rejectValue: number }>(
  'integrations/integrationsUpdate',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await updateIntegrations(
        requestOption.companyId,
        requestOption.integration_id,
        requestOption.requestOption);
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const createIntegrationAPI = createAsyncThunk<typ.IntegrationsItem,
  { company_id: number, data: typ.IntegrationAPISchema },
  { rejectValue: number }>(
  'integrations/createIntegrationAPI',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await postIntegrationAPI({
        companyId: requestOption.company_id,
        data: requestOption.data
      });
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

export const deleteIntegrationAPI = createAsyncThunk<typ.IntegrationsItem, {
  company_id: number,
  integration_id: number
},
  { rejectValue: number }>(
  'integrations/deleteIntegrationAPI',
  async (requestOption, { rejectWithValue }) => {
    try {
      return await deleteIntegration({
        companyId: requestOption.company_id,
        integrationId: requestOption.integration_id
      });
    } catch (e) {
      return rejectWithValue(e.response.data.error_code);
    }
  }
);

const integrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrationsTypes.fulfilled, (state, action) => {
        state.integrationsTypes = action.payload;
      })
      .addCase(getIntegrationsList.fulfilled, (state, action) => {
        state.integrations = action.payload;
      })
      .addCase(integrationsState.fulfilled, (state, action) => {
        state.state = action.payload;
      })
      .addCase(integrationsStateInfo.fulfilled, (state, action) => {
        state.stateInfo = action.payload;
      })
      .addCase(integrationsStateInfo.rejected, (state, action) => {
        state.stateInfo = { ...state.stateInfo, error: action.payload! };
      });
  },
});

export default integrationSlice.reducer;
