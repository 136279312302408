import {
  channelConnectionDeliveryStatus,
  ChannelConnectionDeliveryStatus,
  channelConnectionsNewMessage,
  ChannelConnectionsNewMessage
} from './handlersSubscribeUnsubscribe';

export type ReconnectConnectionsNewMessageType = Omit<ChannelConnectionsNewMessage, 'channel'>
export const handleReconnectConnectionsNewMessage = (props: ReconnectConnectionsNewMessageType) => {
  // Отписываемся от всех подключений и новых сообщений при переподключении
  channelConnectionsNewMessage({
    ...props, channel: 'unsubscribe',
  });

  // Переподписываемся на все подключение и на новые сообщение при переподключении
  channelConnectionsNewMessage({
    ...props, channel: 'subscribe',
  });
};

export type ReconnectConnectionDeliveryStatusType = Omit<ChannelConnectionDeliveryStatus, 'channel'>
export const handleReconnectConnectionDeliveryStatus = (props: ReconnectConnectionDeliveryStatusType) => {
  // Отписываемся от текущего подключения и получения уведомления о новом сообщении
  channelConnectionDeliveryStatus({
    ...props, channel: 'unsubscribe',
  });

  // Переподписываемся на текущего подключения и получения уведомления о новом сообщении
  channelConnectionDeliveryStatus({
    ...props, channel: 'subscribe',
  });
};
