import React from 'react';
import './Connections.scss';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { settingConnections, ConnectionServiceTypes } from './utils/connections';
import { ConnectionsType } from './ConnectionsTypes';

const Connections: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const filterConnections = (serviceType: ConnectionServiceTypes[]) => {
    const filterArray = Object.entries(settingConnections)
      .filter(([_, value]) => serviceType.includes(value.type));
    return filterArray;
  };

  const checkConnections = (service: ConnectionsType) => {
    history.push(`${url}/${settingConnections[service]?.url}`);
  };

  return (
    <div className="ConnectionsContainer">
      <h1>{t('settings.number_phone.title')}</h1>
      <div className="ConnectionsContent">
        <h2>{t('connections.online-chats')}</h2>
        <div className="ConnectionsSelect">
          {filterConnections(['mobile', 'social', 'bot']).map(([service, conn]) => {
            const { ComponentIcon } = conn;
            return (
              <div key={conn.url}
                className="connectionWrapper"
                onClick={() => checkConnections(service as ConnectionsType)}
              >
                <div className="connectionWrapper__connection" style={{ background: conn.background }}>
                  <ComponentIcon />
                </div>
                <p>{t(conn.name)}</p>
              </div>
            );})
          }
        </div>
        <h2>{t('connections.banks-payments')}</h2>
        <div className="ConnectionsSelect">
          {filterConnections(['banking']).map(([service, conn]) => {
            const { ComponentIcon } = conn
            return (
              <div key={conn.url}
                className="connectionWrapper"
                onClick={() => checkConnections(service as ConnectionsType)}
              >
                <div className="connectionWrapper__connection" style={{ background: conn.background }}>
                  <ComponentIcon />
                </div>
                <p>{t(conn.name)}</p>
              </div>
            );})
          }
        </div>
      </div>
    </div>
  );
};

export default Connections;
