import React from 'react';
import './ExitButton.scss';
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoutIcon } from "../../../../../assets/logout-icon.svg";


const ExitButton = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  return (
    <div className='logout' onClick={() => keycloak.logout()} >
      <LogoutIcon />
      <p>{t('navbar.exit')}</p>
    </div>
  );
};

export default ExitButton;
