import React, { FC } from 'react';
import './ComeBackList.scss'
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import {
  ReactComponent as ComeBackIcon
} from '../../../../assets/template-backward-icon.svg';

type ComeBackListType={
  cb:()=>void
  title:string
}
const ComeBackList:FC<ComeBackListType> = ({ cb,title,children }) => {
  const { t } = useTranslation();
  return (
    <div className='comeBackList'>
      <div className='comeBackList__header'>
        <Button text={t('back')} color='white' textType='small' image={<ComeBackIcon />} type='reset'
                style={{ padding: '6px 12px 6px 8px' }} onClick={cb} />
        <div><h2>{title}</h2></div>
      </div>
        {children}
    </div>
  );
};

export default ComeBackList;
