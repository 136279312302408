import { useEffect, useState } from 'react';
import { goBack, push } from 'connected-react-router';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import ActionDialog from '../../../../../components/Modals/ActionDialog';
import Button from '../../../../../components/Button/Button';
import ProductsList from '../../../Products/components/ProductsList/ProductsList';
import PreviewProducts from '../../../Products/components/PreviewProducts/PreviewProducts';
import Search from '../../../../../components/Search/Search';
import SidePanel from '../../../../../components/Modals/SidePanel';
import Paginator from '../../../../../components/Paginator';
import { Product } from '../../../../../api/SalesAPI';
import { fetchCatalogs, fetchProducts, removeProductsCatalog, setProductIdRemove } from '../../../SalesSlice';
import { ProductLoadSelector, ProductsSelector, SalesCatalogsSelector } from '../../../SalesSelector';
import UserCatalog from '../../models/UserCatalog';
import { productItemContext } from '../../../types';
import { ReactComponent as FolderOpenIcon } from '../../../../../assets/folder-open.svg';
import ComeBackIcon from '../../../../../assets/template-backward-icon.svg';
import loader from '../../../../../assets/grid.svg';
import './CatalogsContent.scss';
import { getPageLimit } from "../../../../../components/Paginator/utils";


const catalogContentPageLimitIndex = 'catalogContentPagesLimit';

type CatalogParamsType = { companyId: string, catalogId: string };

const CatalogsContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useRouteMatch<CatalogParamsType>('/companies/:companyId/catalogs/:catalogId');
  const products = useSelector(ProductsSelector);
  const catalogs = useSelector(SalesCatalogsSelector);
  const load = useSelector(ProductLoadSelector);
  const [productSelected, setProductSelected] = useState<Product>();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [productViewOn, setProductViewOn] = useState(false);
  const [search, setSearch] = useState('');
  const [query] = useDebounce(search, 400);
  const catalogCurrent = catalogs.find(catalog => catalog.id === Number(params?.params.catalogId));
  const catalog = catalogCurrent ?
    new UserCatalog(
      catalogCurrent?.id, catalogCurrent?.name, catalogCurrent?.productsCount, Number(params?.params.companyId)
      ) : undefined;

  const onUpdate = (page: number, limit: number) => {
    if (catalog) dispatch(fetchProducts({
      companyId: catalog.companyId, catalogId: catalog.id, limit, offset: (limit * (page - 1)),
      ...(query ? { query } : {}) }));
  };

  useEffect(() => {
    onUpdate(1, getPageLimit(catalogContentPageLimitIndex));
  }, [query]);

  useEffect(() => {
    if (params?.params.companyId) dispatch(fetchCatalogs({ companyId: Number(params.params.companyId) }));
  }, [params?.params.companyId]);

  useEffect(() => {
    if (catalog === undefined) dispatch(push(`/companies/${params?.params.companyId}/catalogs`));
  }, [catalog]);

  const gotoBack = () => {
    dispatch(goBack());
  };

  const onPreview = (product: Product) => {
    setProductSelected(product);
    setProductViewOn(true);
  };

  const onDelete = (product: Product) => {
    setProductSelected(product);
    setDeleteOpen(true);
  };

  const deleteProduct = () => {
    if (catalog && productSelected) {
      dispatch(setProductIdRemove(productSelected.id));
      dispatch(removeProductsCatalog({
        companyId: catalog?.companyId, catalogId: catalog?.id, products: [productSelected.id] }))
    }
    setDeleteOpen(false);
  };

  const getDeleteConfimMessage = () => t('sales.catalogs.delete_product.warning', { product: productSelected?.name });

  return (
    <div className="catalogsContent">
      {productViewOn && <SidePanel setIsOpen={setProductViewOn} background='light'>
        <PreviewProducts product={productSelected} />
      </SidePanel>}
      {deleteOpen &&
        <ActionDialog action={t('delete')} cancel={t('cancel')}
          onAction={deleteProduct} setIsOpen={setDeleteOpen} background='light'
          title={t('sales.catalogs.delete_product.head')} message={getDeleteConfimMessage()} />}
      <div className="catalogsContent__header">
        <div className="catalogsContent__header_left">
          <Button color='white' textType='regular' text={t('back')} onClick={gotoBack} image={ComeBackIcon} />
          <FolderOpenIcon />
          <h4>{catalog?.name}</h4>
        </div>
        <Search onChangeSearchInput={setSearch} value={search} />
      </div>
      {load || !products ? <div className="load"><img src={loader} alt='loading...' /></div> :
        <>
          {products.items.length === 0 ? (
            <div className="catalogsContent__no_products">
              {search ? t('sales.catalogs.no_products_found') : t('sales.catalogs.no_products')}
            </div>
          ) : (
            <>
            <productItemContext.Provider value={{ onDelete, onPreview }}>
              <ProductsList products={products.items} />
            </productItemContext.Provider>
            </>
          )}
        </>
      }
      {products &&
        <Paginator totalCount={products.totalCount} pageSelect={onUpdate}
          pageLimitStoreIndex={catalogContentPageLimitIndex} />
      }
    </div>
  );
};

export default CatalogsContent;
