import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Button from '../../../../components/Button/Button';
import { useHasPermission } from '../../../../utils/WrapperPermission/WrapperPermission';
import './BroadcastsPlug.scss';


function BroadcastsPlug() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const permissionCreate = useHasPermission('broadcastsCreate');

  const onCreate = () => {
    dispatch(push(`${url}/create`))
  };

  return (
    <div className='broadcastsPlug'>
      <h1>{t('broadcasts.plug.title')}</h1>
      {permissionCreate
        ? (
          <>
            <p>{t('broadcasts.plug.create_text')}</p>
            <Button type='button' color='orange' textType='regular' text={t('broadcasts.create')}
              onClick={onCreate}/>
          </>)
        : <p>{t('broadcasts.plug.no_create_text')}</p>
      }
    </div>
  );
};

export default BroadcastsPlug;
