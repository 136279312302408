import React, { useEffect, useRef, useState } from 'react';
import './TextMessage.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { BaseEmoji } from 'emoji-mart';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation, useRouteMatch } from "react-router";
import { getMessageViaTypes, TextMessageType } from '../../utils';
import { ErrorStateType, TextSchema } from "../../api/types";
import { useBroadcastItem } from "../../api";
import { addAlertWithCustomText } from '../../../../components/Alert/alertSlice';
import EmojiComponent from '../../../../components/EmojiComponent/EmojiComponent';
import FilePreview from './FilePreview';
import FileIconComponent from './FileIconComponent/FileIconComponent';


type TextMessageProps = {
  setMessageText: React.Dispatch<React.SetStateAction<TextMessageType | undefined>>;
  errorState?: ErrorStateType;
};

function TextMessage({ setMessageText, errorState }: TextMessageProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useRouteMatch<{ broadcastId: string }>('/companies/:companyId/broadcasts/edit/:broadcastId');
  const location = useLocation<number>();
  const { state } = location;
  const broadcastItem = useBroadcastItem();
  const pickerTriggerButtonRef = useRef<SVGSVGElement>(null);
  const [picker, setPicker] = useState(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [attachedUrl, setAttachedUrl] = useState<string>();
  const [fileType, setFileType] = useState('');
  const schema = yup.object().shape({
    text: yup.string().required(t('error.form.empty_field')).max(8192, t('error.form.max_length', { limit: 8192 })),
  });

  const methods = useForm<TextSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const text = methods.watch('text');
  const isError = methods.formState.errors.text || (!text && errorState?.text);

  useEffect(() => {
    switch (fileType) {
      case 'image': setMessageText({ type: 'image', text, url: attachedUrl, file: attachedFile }); break;
      case 'video': setMessageText({ type: 'video', text, url: attachedUrl, file: attachedFile }); break;
      case '': setMessageText({ type: 'text', text, url: attachedUrl }); break;
      default: setMessageText({ type: 'file', text, url: attachedUrl, file: attachedFile }); break;
    }
  }, [text, attachedFile, attachedUrl]);

  useEffect(() => {
    if (params?.params.broadcastId || state) {
      if (broadcastItem) {
        const { type, url, caption } = getMessageViaTypes(broadcastItem.message);
        setAttachedUrl(undefined);
        type && setFileType(type);
        caption && methods.setValue('text', caption);
        setAttachedUrl(url);
      }
    }
  }, [broadcastItem, state, params?.params.broadcastId]);

  const triggerPicker = () => {
    setPicker(!picker);
  };

  const onSelectEmoji = (emoji: BaseEmoji) => {
    const value = methods.getValues('text');
    methods.setValue('text', `${value}${emoji.native}`)
  };

  const onAttachmentFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size <= 104857600) {
        setFileType(e.target.files[0].type.split('/')[0]);
        setAttachedFile(e.target.files[0]);
      } else {
        setAttachedFile(undefined);
        dispatch(addAlertWithCustomText({ message: t('broadcasts.error.not_correct_size'), color: '#F54242' }));
      }
    }
  };

  return (
    <div className="textMessage">
      <h4>{t('broadcasts.form.message_lable')}</h4>
      <form {...methods}>
          <div className={`textMessage__field ${isError ? 'error' : ''}`}>
            <textarea
              placeholder={t('chats.chat.message')}
              {...methods.register('text')}
              maxLength={attachedFile ? 1024 : 8192} />
            {(attachedFile || attachedUrl) && (
              <FilePreview attachedFile={attachedFile} setAttachedFile={setAttachedFile}
                fileType={fileType} setFileType={setFileType} attachedUrl={attachedUrl}
                setAttachedUrl={setAttachedUrl} />
            )}
            <div className="textMessage__field_bottom">
              <EmojiComponent picker={picker} setPicker={setPicker} onSelect={onSelectEmoji}
                              pickerRef={pickerTriggerButtonRef} onClick={triggerPicker} />
              {!attachedUrl && !attachedFile && (
                <>
                  <FileIconComponent onAttachmentFile={onAttachmentFile} />
                  <p className="limitFile">{t('error.limit_file')}</p>
                </>
              )}
            </div>
          </div>
      </form>
      {(!text && errorState?.text) ? <p className="textMessage__field__errorMessage">{t('error.form.empty_field')}</p> :
        <ErrorMessage errors={methods.formState.errors} name="text"
          render={({message}) => <p className="textMessage__field__errorMessage">{message}</p>}
        />}
    </div>
  );
}

export default TextMessage;
