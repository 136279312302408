import { useSelector } from "react-redux";
import { currentCompanySelector } from "../../Chat/companiesSelector";


export const currenciesForBill = ['RUB'];

export function useCurrencyBill() {
  const currentCompany = useSelector(currentCompanySelector);

  return currenciesForBill.some((currency) => currency === currentCompany.paymentCurrency);
}
