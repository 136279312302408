import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { getIconSales, getViewPopup } from "../../utils";
import PopupTitle from '../../components/PopupTitle';


type Props = {
    tab: string;
    selectedTab: string | undefined;
}

const SalesItem = ({tab, selectedTab}: Props) => {
    const { t } = useTranslation();
    const url = useRouteMatch<{ id: string }>('/companies/:companyId');
    const { viewPopup, onMouseLeave, onMouseEnter } = getViewPopup();
    const tabRef = useRef<HTMLAnchorElement>(null);

    const Icon = getIconSales[tab];

    return (
      <Link key={tab} to={`${url!.url}/${tab}`} ref={tabRef}>
        <PopupTitle text={t(`navbar.${tab}`)} refPopup={tabRef}
          image={<Icon className={`tabItem ${selectedTab === tab && 'active'}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />}
          viewPopup={viewPopup} />
      </Link>
    );
}

export default SalesItem;
