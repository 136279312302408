import React from 'react';
import './Informing.scss';
import { ReactComponent as ExclamationIcon } from '../../../../assets/exclamation-point-icon.svg';

type InformingProps = {
  text: string
}

const Informing = ({ text }: InformingProps) =>
  <div className='informing'>
    <ExclamationIcon /><span>{text}</span>
  </div>;

export default Informing;
