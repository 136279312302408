import React from 'react';
import MyTemplatesLink from './MyTemplatesLink';
import './TemplatesList.scss';
import InteractiveMessagesLink from './InteractiveMessagesLink';

const TemplatesList = () => (
  <div className="templatesList">
    <MyTemplatesLink />
    <InteractiveMessagesLink />
  </div>
);

export default TemplatesList;
