import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './RolesList.scss';
import { ReactComponent as CheckMark } from '../../../../../../assets/checkMark-orange.svg';
import { ReactComponent as PlusBlackIcon } from '../../../../../../assets/plus-black-icon.svg';
import { getNameRole } from '../../utlity';
import { WrapperPermission } from '../../../../../../utils/WrapperPermission/WrapperPermission';
import Button from '../../../../../../components/Button/Button';
import PlusIcon from '../../../../../../assets/plus-icon.svg';
import RoleModal from '../../../RoleModal/RoleModal';
import { RoleType } from '../../../../../../api/CompanyAPI';
import { getRandomInt } from '../../../../../../utils/utils';


type RolesListProps = {
  isOwner: boolean
  currentAddRole: string
  currentRole: RoleType | null
  currentRoles: RoleType[]
  setCurrentRole: React.Dispatch<React.SetStateAction<RoleType | null>>
  setCurrentRoles: React.Dispatch<React.SetStateAction<RoleType[]>>
  setCurrentAddRole: React.Dispatch<React.SetStateAction<string>>
}
const RolesList = ({
                     currentRole,
                     setCurrentRole,
                     setCurrentRoles,
                     setCurrentAddRole,
                     currentRoles,
                     currentAddRole,
                     isOwner,
                   }: RolesListProps) => {
  const { t } = useTranslation();
  const [isAddRole, setIsAddRole] = useState(false);
  const [errorNameRole, setErrorNameRole] = useState(false);
  const isAddedRole = currentAddRole.length === 0;

  const handlerSwitchRole = (role: RoleType) => {
    setCurrentRole(role);
  };

  const handlerCloseAddRoleModal = () => {
    setCurrentAddRole('');
    setErrorNameRole(false);
    setIsAddRole(false);
  };
  const onChangeAddRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorNameRole(false);
    setCurrentAddRole(e.target.value);
  };

  const submitAddRoleModal = () => {
    const isRoleTitle = currentRoles.find(i => i.name === currentAddRole);
    if (isRoleTitle) {
      setErrorNameRole(true);
    } else {
      const mockId = getRandomInt(100000);
      const newRole: RoleType = {
        id: mockId,
        name: currentAddRole,
        permissions: [],
        systemType: null,
        mockId,
      };
      setCurrentRoles([...currentRoles, newRole]);
      setCurrentRole(newRole);
      setIsAddRole(false);
    }
  };
  return (
    <div className='rolesList'>
      <div><h2>{t('settings.users.user.employeeRole')}</h2></div>
      <div className='rolesList__list'>
        {currentRoles?.map(role =>
          <div onClick={() => handlerSwitchRole(role)} key={role.id}
               className={`rolesList__list__item ${
                 currentRole?.id === role.id ? 'selectedRole' : ''} ${role.name.length > 50 ? 'longWord' : ''}`}>
            {currentRole?.id === role.id ? <CheckMark /> : <PlusBlackIcon />}
            {getNameRole(role.name, isOwner, t)}
          </div>
        )}
        {isAddedRole &&
          <WrapperPermission permission='membersAdd'>
            <div className='rolesList__list__addRoleButton'>
              <Button text={t('settings.users.user.createRole')} type='button'
                      color='orange'
                      image={PlusIcon}
                      textType='regular'
                      onClick={() => setIsAddRole(true)}
              />
            </div>
          </WrapperPermission>
        }
        {isAddRole && <RoleModal
          textSubmit={t('settings.users.user.createRole')}
          titleMain={t('settings.users.user.createRole')}
          title={t('settings.users.user.roleName')}
          handlerSubmitModal={submitAddRoleModal}
          handlerCloseModal={handlerCloseAddRoleModal}
          disabled={isAddedRole}
          error={errorNameRole}
          errorText={t('errors.21000')}
        >
          <input
            style={{ border: `${errorNameRole ? '3px solid #F54242' : ''}` }}
            value={currentAddRole} onChange={onChangeAddRole} type='text' />
        </RoleModal>
        }
      </div>
    </div>
  );
};

export default RolesList;
