import { useEffect, useState } from 'react';
import './YookassaEdit.scss';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../../store/store';
import { YookassaConnection, YookassaEditSchema } from '../../../../../../api/CompanyAPI';
import { MAX_LIFETIME, MIN_LIFETIME, taxationTypes } from '../../../utils/terminals';
import { updateConnection } from '../../../connectionsSlice';
import { RuTaxation } from '../../../ConnectionsTypes';
import GoBackWrapper from '../../../../../../components/GoBackWrapper';
import Input from '../../../../../../components/Input';
import LifetimePayment from '../../../components/LifetimePayment';
import Button from '../../../../../../components/Button/Button';
import Dropdown from '../../../../Dropdown/Dropdown';


function YookassaEdit() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation<boolean>();
  const { state } = location;
  const { connectionId } = useParams<{ connectionId: string }>();
  const currentCompanyId = useRouteMatch<{ companyId: string }>('/companies/:companyId');
  const allConnections = useAppSelector((state) => state.connections.connectionsStore.yookassa);
  const [connection, setConnection] = useState<YookassaConnection>();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.form.empty_field')).max(128, t('error.form.max_length', { limit: 128 })),
    params: yup.object().shape({
      return_url: yup.string().required(t('error.form.empty_field')),
      invoice_lifetime_minutes: yup.number()
        .min(30, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME }))
        .max(43200, t('error.form.lifetime', { min_limit: MIN_LIFETIME, max_limit: MAX_LIFETIME })),
    }),
  });

  const methods = useForm<YookassaEditSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: connection?.name,
      params: {
        return_url: connection?.params.returnUrl,
        taxation: connection?.params.taxation as RuTaxation,
        invoice_lifetime_minutes: connection?.params.invoiceLifetimeMinutes,
      },
    },
  });

  const formResetData = () => {
    const connect =  allConnections.find((conn) => conn.id === Number(connectionId)) as YookassaConnection;
    if (connect) {
      setConnection(connect);
      methods.reset({
        name: connect.name,
        params: {
          return_url: connect.params.returnUrl,
          taxation: connect?.params.taxation,
          invoice_lifetime_minutes: connect.params.invoiceLifetimeMinutes,
        }
      }, { keepDefaultValues: state });
    }
  };

  useEffect(() => {
    formResetData();
  }, [allConnections]);

  const onSubmitChanges: SubmitHandler<YookassaEditSchema> = async (data) => {
    if (!methods.formState.isDirty) {
      return;
    }
    if (connection !== undefined && currentCompanyId?.params.companyId) {
      dispatch(updateConnection({
        companyId:Number(currentCompanyId?.params.companyId),
        itemId: Number(connectionId),
        name: data.name,
        type: 'yookassa',
        params: { ...data.params }
      })).then(res=>{
        if (res.meta.requestStatus === 'fulfilled') {
          history.push(`/companies/${currentCompanyId?.params.companyId}/settings/connections/yookassa`);
        }
      });
    }
  };

  return connection
  ? (
    <GoBackWrapper title={t('edit')}>
      <div className='yookassaEdit'>
        <p className="regularText">{t('connections.connection_number')}: #{connection.id}</p>
        <p className="regularText">{t('connections.yookassa.shop_id')}: {connection.params.shopId}</p>
        <FormProvider {...methods}>
          <Input name='name' label={t('connections.connection_name')} />
          <Input name='params.return_url' label={t('connections.yookassa.return_url')}
            placeholder='https://radist.online/' />
          <Dropdown name="params.taxation" label="Система налогообложения"
            items={taxationTypes} labelKey="option" valueKey="id" />
          <LifetimePayment name='params.invoice_lifetime_minutes' lifetime={connection.params.invoiceLifetimeMinutes} />
          <Button type='submit' color='orange' textType='regular' text={t('save_edit')}
            onClick={methods.handleSubmit(onSubmitChanges)} disabled={!methods.formState.isDirty} />
        </FormProvider>
      </div>
    </GoBackWrapper>
    )
  : null;
}

export default YookassaEdit;
