import React, { SetStateAction, useEffect, useState } from 'react';
import './ContactsForm.scss';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { RecalculatedContactsType, SelectedServiceContactType } from './utils/types';
import { examplesContactsForm, servicesContactsValidate } from './utils/consts';
import { CalculateValidContacts, trimInLines } from './utils/contacts';
import { BroadcastsServicesType } from '../../utils';
import { ErrorStateType } from '../../api/types';
import { useShowServiceInfo } from '../../api/source';
import Informing from '../Informing/Informing';


type ContactsFormProps = {
  contacts: RecalculatedContactsType;
  setContacts: React.Dispatch<SetStateAction<RecalculatedContactsType>>;
  selectedService: BroadcastsServicesType;
  errorState?: ErrorStateType;
};

function ContactsForm({ contacts, setContacts, selectedService, errorState }: ContactsFormProps) {
  const { t } = useTranslation();
  const [valueTextarea, setValueTextarea] = useState<string>('');
  const selectedServiceContact: SelectedServiceContactType = servicesContactsValidate[selectedService];
  const isError = errorState?.recipients && contacts.recipients.length <= 0;
  const showInfo = useShowServiceInfo(selectedService);

  useEffect(() => {
    if (contacts && contacts.all && valueTextarea === '') {
      setValueTextarea(contacts.all.join('\n'));
    }
  }, [contacts]);

  const handlerUpdateContacts = useDebouncedCallback(() => {
      setContacts(CalculateValidContacts({ selectedServiceContact, valueTextarea }));
      setValueTextarea(valueTextarea);
  }, 400);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValueTextarea(e.target.value);
    handlerUpdateContacts()
  };

  const onPaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const text = trimInLines(event.clipboardData.getData('text'));
    setValueTextarea(prevState => prevState + text);
    handlerUpdateContacts()
  };

  const onBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContacts(CalculateValidContacts({ selectedServiceContact, valueTextarea }));
    setValueTextarea(trimInLines(e.target.value));
  };

  return (
    <div className='contactsForm'>
      {selectedService &&
        <div className='contactsForm__header'>
          <div className='contactsForm__header__text'>
            {t('broadcasts.form.yourRecipientList.limit')}
            <span>5000</span>
            {t(`broadcasts.form.yourRecipientList.text.${selectedServiceContact}`)}
          </div>
          <div className='contactsForm__header__example'>
            <div>{t('broadcasts.form.yourRecipientList.example')}</div>
            {examplesContactsForm[selectedServiceContact]}
          </div>
        </div>
      }
      <div className='contactsForm__content'>
        <div className='contactsForm__content__textarea'>
          <textarea value={valueTextarea} onChange={onChange} onPaste={onPaste} onBlur={onBlur}/>
        </div>
        <div className='contactsForm__content__static'>
          <div className={`contactsForm__content__static__recipients ${isError ? 'error' : ''}`}>
            <div className='contactsForm__content__static__recipients_title'>
              {t('broadcasts.params.recievers')}
            </div>
            <div className='contactsForm__content__static__recipients_count'>{contacts.recipients.length}</div>
          </div>
          <div className='contactsForm__content__static__doubleError'>
            <div className='contactsForm__content__static__doubleError_double'>
              {t('broadcasts.form.yourRecipientList.replays')}
              <span>{contacts.duplicates}</span>
            </div>
            <div className='contactsForm__content__static__doubleError_error'>
                {t('broadcasts.form.yourRecipientList.errors')}
              <span>{contacts.errors}</span>
            </div>
          </div>
        </div>
        {selectedService && showInfo && <Informing text={t(`broadcasts.form.info.${selectedService}`)} />}
      </div>
    </div>
  );
}

export default ContactsForm;
