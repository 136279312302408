import React from 'react';
import './AttachmentDocumentPreview.scss';
import { AttachmentPreviewComponent } from '../ChatField/WabaTemplateComponent/types';
import { ReactComponent as TrashIcon } from '../../../../assets/trash-icon.svg';

const AttachmentDocumentPreview: AttachmentPreviewComponent = ({
  attachedFile,
  setAttachedFiles,
  customTemplateEditPayload,
  removeTrashIcon,
}) => {
  const getFileName = () => {
    if (attachedFile && attachedFile.name) {
      return attachedFile.name;
    }
    if (customTemplateEditPayload && customTemplateEditPayload.documentName) {
      return customTemplateEditPayload.documentName;
    }
    return null;
  };

  return (
    <div className="documentPreviewContainer">
      <p
        className="documentPreviewContainer__documentName"
        key={customTemplateEditPayload ? customTemplateEditPayload.fileUrl : attachedFile!.size}
      >
        {getFileName()}
      </p>
      {!removeTrashIcon && <TrashIcon className="documentPreviewContainer__trashIcon" onClick={setAttachedFiles} />}
    </div>
  );
};
export default AttachmentDocumentPreview;
