import React from 'react';
import { useTranslation } from 'react-i18next';
import './MessagePlug.scss';


function MessagePlug() {
  const { t } = useTranslation();

  return (
    <div className='messagePlug'>
      <h4>{t('broadcasts.form.message_lable')}</h4>
      <div className='messagePlug__field'>{t('broadcasts.form.message_plug')}</div>
    </div>
  );
}

export default MessagePlug;
