import InteractiveMessageSettingsButtonActions from '../InteractiveMessageSettingsButtonActions/InteractiveMessageSettingsButtonActions';
import InteractiveMessageSettingsListActions from '../InteractiveMessageSettingsListActions/InteractiveMessageSettingsListActions';

type Actions = {
  [key: string]: () => JSX.Element;
};

export const interactiveMessageActions: Actions = {
  button: InteractiveMessageSettingsButtonActions,
  list: InteractiveMessageSettingsListActions,
};
